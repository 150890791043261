import React, {createContext, useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { useCookies, Cookies } from 'react-cookie';

const AuthContext = createContext();

export function useAuthRequest() {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuthRequest must be used within a AuthProvider");
  }

  return context;
}


export const AuthProvider = ({ children }) => {

  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const authed = async() => {


    if (!cookies.token) {
      return false;
    }

    const options = {
      method: 'GET',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${cookies.token}`
      }
    };

    const url = `/api/auth`;


    const response = await fetch(url, options);

    if (response.status == 401) {
      return false;
    }

    const user = await response.json();


    if (user && user.is_brand == 0) {
      return false;
    }

    return true;


  }
  
  const authenticatedFetch = async(url, method='GET', body=null) => {

    const currentUri = document.location.href;

    if (!cookies.token) {
      setCookie('redirect_uri', currentUri, {
        path: '/'
      });

      if (currentUri.indexOf('/influencers/') >= 0) {
        navigate('/signin');
      } else {
        navigate('/');
      }
      
    }

    // console.log(cookies.token);
  	const options = {
  		method,
  		headers: {
  			Accept: 'application/json'
  		}
  	};

  	if (method != 'GET' && body != null) {
  		options['body'] = body;
  	}

  	if (cookies.token) {
  		options['headers']['Authorization'] = `Bearer ${cookies.token}`;
  		;
  	}

  	const response = await fetch(url, options);

  	if (response.status == 500) {
  		return {error: 'An error has occurred'};
  	}

  	if (response.status == 401) {
      setCookie('redirect_uri', currentUri, {
        path: '/'
      });

      if (currentUri.indexOf('/influencers/') >= 0) {
        navigate('/signin');
      } else {
        navigate('/');
      }
  	}

  	return response.json();
  }	

  return (
    <AuthContext.Provider value={{authenticatedFetch, authed}}>
        {children}
    </AuthContext.Provider>
  );
};