import React, { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { useCookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Section from '../components/Section';


function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function FromShopify({children}) {
  const { token } = useParams();

  let navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  React.useEffect(() => {

  	var d = new Date();
    d.setTime(d.getTime() + (30*24*60*60*1000));

    //console.log(d);

  	setCookie('token', token, {
  		expires: d,
  		path: '/'
  	});
  	navigate('/dashboard');
  }, []);

  return <Section title="Campaigns">
    Campaigns
  </Section>
};