import React, { Fragment, useState, useEffect, useRef} from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ChatAltIcon,
  RefreshIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getURL, PageTabs, InputField, Alert, TextArea, Select, FileDrop} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';

export default function GigOrderMessages() {

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const {id} = useParams();

  const messagesRef = useRef();

  const ref = useRef();

  const [gig, setGig] = useState(null);

  const [messages, setMessages] = useState([]);

  const [messageTxt, setMessageTxt] = useState('');

  const [order, setOrder] = useState(null);

  const [participant, setParticipant] = useState(null);

  const [selectedFiles, setSelectedFiles] = useState([]);

  const [uploading, setUploading] = useState(false);

  const [imageIds, setImageIds] = useState([]);

  const [user, setUser] = useState(null);

  const [sending, setSending] = useState(false);

  const load = async() => {

    const response = await authFetch(`/api/v2/gig-orders/${id}/messages?mark_seen=1`, 'GET');

    const {order, messages, error, participant, user} = response;

    if (order && messages) {
      setUser(user);
      setOrder(order);
      setParticipant(participant);
      setMessages(messages);
    }

  }

  const sendMessage = async() => {

    setSending(true);
    const body = new FormData();

    body.append('message_txt', messageTxt);

    imageIds.forEach(imageId => {
      body.append('image_id[]', imageId);
    })

    const response = await authFetch(`/api/v2/gig-orders/${id}/messages`, 'POST', body);

    const { messages } = response;

    setSending(false);

    if (messages) {
      setMessageTxt('');
      setImageIds([]);
      setMessages(messages);
    }
  }

  const handleFile = (e) => {
    setSelectedFiles(e.target.files);
  }

  const upload = async() => {

    setUploading(true);

    const body = new FormData();

    for(var i = 0; i < selectedFiles.length; i++) {
      body.append('images[]', selectedFiles[i]);
    }

    const response = await authFetch('/api/image', 'POST', body);

    setUploading(false);

    const { error, images } = response;

    const _imageIds = [...imageIds];

    if (images) {
      images.forEach(image => {
        _imageIds.push(image.id);
      });

      setImageIds(_imageIds);
    } 

    if (error) {
      toast(error);
    }
  }

  const checkForEnter = (e) => {
    if (e.keyCode == 13 || e.charCode == 13) {
      sendMessage();
    }
  }

  const deleteImage = (i) => {
    const _imageIds = [...imageIds];

    _imageIds.splice(i, 1);

    setImageIds(_imageIds);
  }

  React.useEffect(() => {
    if (sending == false && ref.current) {
      ref.current.focus();
    }
  }, [sending]);

  React.useEffect(() => {
    if (selectedFiles.length > 0) {
      upload();
    }
  }, [selectedFiles]);

  React.useEffect(() => {
    load();
  }, []);

  React.useEffect(() => {
    if (messages.length > 0) {

      messagesRef.current.scrollTo(0,999999);
    }
  }, [messages, messagesRef]);

  if (order == null) {
    return null;
  }

  const emptyState = user!=null&&user.is_brand?`Send a message to ${participant.name} to stay updated.`:`Send a message to ${participant.brand_name} regarding their gig order`;

  return <div className="">
    <div className="grid grid-cols-1 h-screen " style={{height: 'calc(100vh - 4rem)'}}>
      <div className="flex relative flex-col">
      <div className="header absolute w-full bg-white border-gray-200 border-t border-b py-2 px-4 -mt-1">
        <span className="text-sm font-medium text-gray-700 block">{participant.is_brand?participant.brand_name:participant.name}</span>
        
        <div className="space-x-2">
          <Link to={'/messages'} className="text-indigo-600 text-sm">Messages</Link>
          <ChevronRightIcon className="w-4 h-4 inline-block"/>
          <Link to={user.is_brand==1?`/order/${id}`:`/influencers/order/${id}`} className="text-indigo-600 text-sm"><span>Order #{order.id}</span></Link>        </div>
      </div>
      <div className="h-full bg-white overflow-y-auto pb-20 pt-20" style={{height: 'calc(100vh - 6rem)'}} id="messages" ref={messagesRef}>
        <div>
        {
          messages.map((message, i) => {

            const initial = message.from.is_brand?message.from.brand_name[0]:message.from.name[0];

            const name = message.from.is_brand?message.from.brand_name:message.from.name;

            const timeDivider = (i==0||moment(message.created_at).format('MM/DD/yyyy')!=moment(messages[i-1].created_at).format('MM/DD/yyyy'))?<div className="py-3 px-5 text-center w-full text-sm text-gray-400 flex justify-between items-center"><div className="h-0 border-b w-full">&nbsp;</div><div className="px-5 min-w-max">{moment(message.created_at).format('dddd, MMMM Do, YYYY')}</div><div className="h-0 border-b w-full">&nbsp;</div></div>:null;
            


            const showHeader = (i==0||message.from.id!=messages[i-1].from.id||moment(message.created_at).format('MM/DD/yyyy h:m a')!= moment(messages[i-1].created_at).format('MM/DD/yyyy h:m a'));
            return <div key={`message-${message.id}`} className="hover:bg-slate-50 py-1">
            {timeDivider}
            <div className={`mx-3 sm:mx-3 flex ${showHeader&&'mt-1'}`} key={`message-${message.id}`}>
              {showHeader?(message.from.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${message.from.image_id}.jpg`} className="w-10 h-10 rounded-full"/>:<div className="w-10 h-10 rounded-full bg-slate-500 flex items-center justify-center text-white font-medium uppercase">{initial}</div>):<div className="w-10 h-0 rounded-full bg-transparent flex items-center justify-center text-white font-medium uppercase">&nbsp;</div>}
              <div className="ml-2">
                {showHeader&&<div><strong className="font-medium text-gray-900 text-sm">{name}</strong>
                  <span className="text-gray-400 text-sm ml-2">{moment(message.created_at).format('h:mm a')}</span></div>}
                <div className="text-gray-700 text-sm">
                  {message.change_request==1&&<div className="mt-1 mb-1"><span className="text-xs font-medium px-2 py-1 rounded-md bg-red-600 text-white">Change Requested</span></div>}
                  {message.message_txt}
                  
                </div>
                
              </div>
            </div>
            {
                    message.attachments.length>0&&<div className="grid sm:grid-cols-3 md:grid-cols-4 gap-4 ml-16 mr-5">
                      {message.attachments.map(attachment => <div className="my-3" key={`attachment-${attachment.id}`}>
                          <a href={`https://res.cloudinary.com/wigmarket/${attachment.image_id}.jpg`} target="_blank"><img src={`https://res.cloudinary.com/wigmarket/w_800,c_fill/${attachment.image_id}.jpg`} className="w-full shadow"/></a>
                          </div>)}
                    </div>
                  }
            </div>
          })
        }
        </div>
        {
          messages.length==0&&<div className="h-full flex justify-center items-center">
            <div className="text-center">
            <ChatAltIcon className="block w-12 h-12 mb-3 mx-auto text-gray-400"/>
            <h2 className="font-bold text-gray-800 mb-3">Start the conversation</h2>
            <p className="text-sm text-gray-400">{emptyState}</p>
            </div>
          </div>
        }
      </div>
      <div className="flex border-t fixed bottom-0 left-0 w-full sm:absolute">
        <input multiple="multiple" type="file" accept="image/*" id="attachment-btn" hidden onChange={handleFile} disabled={uploading}/>
        <label htmlFor="attachment-btn" className="cursor-pointer border-r border-l bg-white flex items-center justify-center px-3">
          {uploading?<RefreshIcon className="w-5 h-5 animate-spin" />:<PaperClipIcon className="w-5 h-5" />}
          
        </label>
        <input type="text" ref={el => (ref.current = el)} id="message_txt"  disabled={sending}  placeholder="Write a message..." className="w-full rounded-none border-none disabled:text-gray-300 disabled:shadow-none w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-0 focus:ring-gray-500  sm:text-sm" value={messageTxt} onChange={e => setMessageTxt(e.target.value)} onKeyUp={checkForEnter}/>
        <Button disabled={sending} className="rounded-none" primary onClick={() => sendMessage()}>Send</Button>
      </div>
      <div className="absolute w-full flex" style={{bottom:'3rem', left: 0}}>
        {
          imageIds.map((imageId, i) => <div key={`image-${imageId}`} className=" relative bg-white border shadow mr-3">
            <button className="absolute top-[-10px] right-[-10px] bg-white h-5 w-5 flex items-center text-gray-700 justify-center shadow rounded-full font-bold text-md" onClick={() => deleteImage(i)}>&times;</button>
            <img src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${imageId}.jpg`} className="w-20 h-20"/>
          </div>)
        }
      </div>
      </div>
    </div>
    </div>
};