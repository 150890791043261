import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert, TextArea, Select, FileDrop} from '../components/Section';

function InfluencerSignUp({cookies}) {

  const [email, setEmail] = useState('');

  const [fullName, setFullName] = useState('');

  const [password, setPassword] = useState('');

  const [submitting, setSubmitting] = useState(false);

  const [title, setTitle] = useState('Sign up as an influencer');

  const [passwordConfirm, setPasswordConfirm] = useState('');

  const [agree, setAgreed] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);

  const [message, setMessage] = useState(null);

  const submitSignUp = async() => {

    setErrorMessage(null);

    setSubmitting(true);

    const body = new FormData();
    body.append('email', email);
    body.append('full_name', fullName);
    body.append('password', password);
    body.append('password_confirmation', passwordConfirm);
    body.append('agreement', agree);

    const response = await fetch(`/api/v2/influencers`, {
      method: 'POST',
      body
    }).then(resp => resp.json());

    const {error, message} = response;

    if (error) {
      setErrorMessage(error);
      setSubmitting(false);
    } else if (message) {
      setMessage(message);
      setTitle(response.title);
    }
  }

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Link to={'/influencer'}><img
                className="h-12 w-auto -ml-1"
                src="https://res.cloudinary.com/wigmarket/c_fill,h_400/beauty-clout/bc-horizontal.png"
                alt="Beauty Clout logo"
              /></Link>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{title}</h2>
              <p className="mt-2 text-sm text-gray-600">
                Have an account? Sign in {' '}
                <Link to="/signin" className="font-medium text-indigo-600 hover:text-indigo-500">
                 here
                </Link>
              </p>
            </div>

            <div className="mt-8">
              

              <div className="mt-6">
                {message==null?<form action="#" method="POST" className="space-y-6">
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Your Full Name
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="name"
                        type="text"
                        autoComplete="text"
                        value={fullName}
                        onChange={e => setFullName(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <small className="text-gray-500">Minimum 8 characters, must include an uppercase letter, a lowercase letter, a digit, and a symbol </small>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Confirm Password
                    </label>
                    {
                      password.length>=8&&(password==passwordConfirm?<small className="text-green-700">Passwords match!</small>:<small className="text-red-600">Passwords do not match</small>)
                    }
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={passwordConfirm}
                        onChange={e => setPasswordConfirm(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="agree"
                        name="agree"
                        type="checkbox"
                        checked={agree}
                        onChange={e => setAgreed(e.target.checked)}
                        className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                      />
                      <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                        I agree with the <a href="https://beautyclout.com/terms/" target="_blank" className="text-indigo-600 hover:text-indigo-700">terms & conditions</a>
                      </label>
                    </div>

                    
                  </div>
                  {errorMessage!=null&&<div><Alert title="Hold up!" status="danger">{errorMessage}</Alert></div>}
                  <div>
                    <button
                      type="button"
                      disabled={submitting}
                      onClick={() => submitSignUp()}
                      className="disabled:opacity-50 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign up
                    </button>
                  </div>
                  
                </form>:<div>

                  <p className="bg-green-50 border border-green-500 p-3 text-sm rounded-md text-green-700">{message}</p>
                </div>}
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 min-h-full">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1624400350686-89aac6cd9f2b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80"
            alt=""
          />
        </div>
      </div>
    </>);
}

export default withCookies(InfluencerSignUp);