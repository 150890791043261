import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { useAuthRequest } from '../Auth';
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert, TextArea, Select, FileDrop, Modal} from '../components/Section';
import { ToastContainer, toast } from 'react-toastify';

const defaultSteps = [
	{id: 1, name: 'Products', href: '/campaign/{id}/products', status: 'complete'},
	{id: 2, name: 'Social Media',href:  '/campaign/{id}/social',  status: 'complete'},
	{id: 3, name: 'Details', href:  '/campaign/{id}/details',  status: 'current'},
	{id: 4, name: 'Preview',  href: '/campaign/{id}/preview',  status: 'upcoming'},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function CampaignDetails({cookies}) {

	const { id } = useParams();

	const navigate = useNavigate();

	const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

	const [steps, setSteps] = useState(defaultSteps);
	const [campaign, setCampaign] = useState(null);
	const [campaignTitle, setCampaignTitle] = useState('');
	const [campaignDescription, setCampaignDescription] = useState('');
	const [category, setCategory] = useState({id: 0, name: '--Choose category', value: ''});
	const [categories, setCategories] = useState([]);
	const [image, setImage] = useState(null);
	const [budgets, setBudgets] = useState([]);
	const [budget, setBudget] = useState({id: 1, name: '$25-100'});
	const [imageId, setImageId] = useState(null);
	const [message, setMessage] = useState(null);
	const [uploading, setUploading] = useState(false);
	const [uploadError, setUploadError] = useState(false);
	const [websiteUrl, setWebsiteUrl] = useState('');
	const [daysExpiration, setDaysExpiration] = useState('');
	const [productDescription, setProductDescription] = useState('');
	const [deletionModal, setDeletionModal] = useState(false);

	const [productImages, setProductImages] = useState([]);

	const [saveError, setSaveError] = useState(null);

	const [selectedImage, setSelectedImage] = useState(null);

	const [productImageModal, setProductImageModal] = useState(false);

	const uploadImage = async(file) => {

		setUploadError(false);
		setUploading(true);
		
		const body = new FormData();

		body.append('file', file);

		const response = await fetch(`/api/dropzone`, {
			method: 'POST',
			headers: {
				Authorization: `Bearer ${cookies.get('token')}`
			},
			body
		}).then(resp => {
			if (resp.status != 200) {
				return {error: 'Uploads must be JPG or PNG, and smaller than 7MB'};
			}
			return resp.json();
		});

		const {error, id} = response;

		if (error) {
			setUploadError(error);
		}

		if (id) {
			setImageId(id);
		}

		setUploading(false);
	}

	const saveCampaign = async(draft=0, goToPreview=0) => {
		setSaveError(null);
		setMessage(null);

		const formData = new FormData();


		formData.append('title', campaignTitle);
		formData.append('description', campaignDescription);
		formData.append('product_description', productDescription);
		if (imageId != null) {
			formData.append('featured_image', imageId);
		}
		formData.append('website_url', websiteUrl);
		formData.append('expiration_days', daysExpiration);
		formData.append('budget', budget.id);
		formData.append('category', category.id);

		if (draft == 1) {
			formData.append('draft', 1);
		}
		if (goToPreview == 1) {
			formData.append('go_to_preview', goToPreview);
		}

		const response = await authFetch(`/api/v2/campaigns/${id}/details`, 'POST', formData);

		const {error, redirect_url, message} = response;

		if (error) {
			toast(error);
		}

		if (message) {
			toast(message);
		}

		if (redirect_url) {
			navigate(redirect_url);
		}
	}

	const doSelectedProductImage = async() => {

		setProductImageModal(false);

		const formData = new FormData();

		formData.append('image_id', selectedImage);

		const response = await authFetch(`/api/v2/cloudinary`, 'POST', formData);

		const { image_id } = response;

		if (image_id) {
			setImageId(image_id);
		}
	}

	const deleteCampaign = async() => {
		const body = new FormData();

	    body.append('status', 'deleted');

	    const response = await authFetch(`/api/v2/campaigns/${id}/status`, 'POST', body);

	    const {message, error} = response;

	    if (message) {
	      toast(message);
	      setDeletionModal(false);
	      setTimeout(() => {
	      	navigate('/campaigns/draft');
	      }, 1000);
	    }

	    if (error) {
	      toast(error);
	    }
	}

	const loadCampaign = async() => {
		const response = await authFetch(`/api/v2/campaigns/${id}?images=1`, 'GET');

		const {campaign, error, categories, budgets, product_images, user} = response;

		if (budgets) {
			setBudgets(budgets);
		}

		if (campaign) {
			setCampaign(campaign);
			setCampaignTitle(campaign.title);
			setCampaignDescription(campaign.description);
			if (campaign.image_id != null && campaign.image_id != '') {
				setImageId(campaign.image_id);
			}
			if (campaign.url != null) {
				setWebsiteUrl(campaign.url);
			}

			if (campaign.budget) {
				setBudget(campaign.budget);
			}

			if (campaign.expiration_days) {
				setDaysExpiration(campaign.expiration_days);
			}

			if (campaign.product_description != null) {
				setProductDescription(campaign.product_description);
			}

			if (campaign.category) {
				setCategory({
					id: campaign.category_id,
					name: campaign.category.category_name
				});
			}

			if (product_images) {
				setProductImages(product_images);
			}

			let _steps = [...defaultSteps];


			if (user && user.pro == 1) {

				_steps.splice(2, 0, {
					id: 3,
					name: 'Affiliate Program',
					status: 'complete',
					href: `/campaign/${id}/affiliates`
				});
				
		  	}

			if (campaign.status == 'active') {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: true};
				});
				
			} else {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: false};
				});
			}

			setSteps(_steps);
		}

		if (categories) {
			setCategories(categories);
		}



		
	}

	useEffect(() => {
		loadCampaign();
		window.scrollTo(0,0);
	}, []);

	const label = campaign!=null&&campaign.status=='draft'?'Delete Draft':'Delete Campaign';

	const primaryAction = campaign!=null&&campaign.status=='draft'?{label, action:  () => setDeletionModal(true)}:null;

	const title = campaign!=null?campaign.title:'Create a Campaign';

	return <><Section title={title} className="max-w-6xl">
		<Steps steps={steps} className="my-2 max-w-6xl" campaignId={id}/>
		<Card title="Campaign Details" className="m-3 max-w-6xl" primaryAction={primaryAction}>
			<div className="mb-3"/>
			<p className="text-sm text-gray-700 mb-3">Details about your campaign that will be presented to influencers seeking opportunities</p>
			<InputField value={campaignTitle} onChange={(e) => setCampaignTitle(e.target.value)} label="Campaign Title *" placeholder="Write a compelling title to attract influencers"/>
			<TextArea rows={4} className="my-3" label="Campaign Description *" placeholder="Describe your brand and campaign" value={campaignDescription} onChange={(e) => setCampaignDescription(e.target.value)}/>
			<Select label="Category *" options={categories} selected={category} setSelected={setCategory}/>

			<label className="text-sm font-medium text-gray-600 block mt-3">Campaign Image *</label>
			{productImages.length>0&&<><Button className="mb-2 w-full" onClick={() => setProductImageModal(true)}>Select Product Image</Button>
			<div className="text-center mb-2"><span className="font-medium text-gray-500 text-xs">OR</span></div>
			</>}
			<FileDrop error={uploadError} uploading={uploading} thumbnailUrl={imageId!=null?`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${imageId}.jpg`:false} onChange={uploadImage} helpText="JPG, PNG, maximum size of 7MB"/>
			
			<TextArea rows={4} className="my-3" label="Product Description" placeholder="Describe the products you would like to promote" value={productDescription} onChange={(e) => setProductDescription(e.target.value)}/>
			
			<InputField label="Product / Website URL *" value={websiteUrl} placeholder="https://..." type="url" onChange={e => setWebsiteUrl(e.target.value)}/>
			<div className="mb-3"/>
			
			
		</Card>
		<Card title="Budget" className="m-3 max-w-6xl">
			<div className="mb-3"/>
			<p className="text-sm text-gray-700 mb-3">Indicate how much you are expecting to pay each hired influencer for this campaign.</p>

			<Select label="Influencer fee *" options={budgets} selected={budget} setSelected={setBudget}/>
			<small className="text-gray-700">Pro users can create campaigns that do not include monetary payment. These types of campaigns offer influencers free products for review and as compensation.</small>
		</Card>
		{saveError!=null&&<Alert status="danger" className="m-3 max-w-6xl" title="Your campaign could not be saved">{saveError}</Alert>}
		{message!=null&&<Alert status="success" className="m-3" title="Success!">{message}</Alert>}
		<div className="mx-3 mb-5 max-w-6xl">
			<Button fullWidth onClick={() => saveCampaign(1, 0)} className="mb-3">{campaign!=null&&campaign.status=='active'?'Save Changes':'Save as Draft'}</Button>
			<Button primary fullWidth onClick={() => saveCampaign(1, 1)}>Continue to Preview</Button>
		</div>

	</Section>
	<Modal open={productImageModal} setOpen={() => setProductImageModal(false)} primary={{
		label: 'Select Image',
		action: () => doSelectedProductImage()
	}} secondary={{
		label: 'Close',
		action: () => setProductImageModal(false)
	}}>	
		<h3 className="text-gray-600 text-lg font-medium ">Select a product image</h3>
		<p className="text-gray-600 text-sm mb-3">These images can be used as the main image for your campaign.</p>
		<div className="grid grid-cols-3 gap-2">
		{
			productImages.map(image => <div key={`image-${image.id}`} className="relative">
					<button className={selectedImage==image.id?'border-2 border-indigo-600 w-full':'border border-gray-300 w-full'} onClick={() => setSelectedImage(image.id)}>
						<img src={image.src} className={"w-full aspect-square object-contain "}/>
					</button>
					{selectedImage==image.id&&<CheckCircleIcon className="w-5 h-5 text-indigo-600 absolute right-0.5 top-0.5"/>}
				</div>)
		}
		</div>
	</Modal>
	{campaign!=null&&<Modal open={deletionModal} setOpen={() => setDeletionModal(false)} primary={{label: `Delete ${campaign.status=='draft'?'Draft':'Campaign'}`, action: () => deleteCampaign() }} secondary={{label: 'Back', action: () => setDeletionModal(false)}}>
		<h3 className="font-bold text-md text-gray-900 mb-3">Delete this draft?</h3>
		<p className="text-gray-600 text-sm">Once deleted, this action cannot be reversed.</p>
	</Modal>}
	<ToastContainer theme="dark" autoClose={5000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/> 
	</>
}

export default withCookies(CampaignDetails);