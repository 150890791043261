import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';
import blogIcon from '../images/blog.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function SocialMediaSelector({service, enabled, setEnabled, disabled=false}) {

	const services = {
		tiktok: {name: 'TikTok', icon: tiktokIcon},
		facebook: {name: 'Facebook', icon: facebookIcon},
		instagram: {name: 'Instagram', icon: instagramIcon},
		youtube: {name: 'YouTube', icon: youtubeIcon},
		twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: blogIcon}
	};

	const platform = services[service];

	return <div className="flex justify-start items-center p-3">
			<Switch
      checked={enabled}
      disabled={disabled}
      onChange={setEnabled}
      className="flex-shrink-0 disabled:opacity-60 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-200',
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
        )}
      />
    </Switch>
    <div className={`ml-3`} style={{opacity: enabled?1:.33}}>
    <img src={platform.icon} className="fill-blue-600 h-14 w-14" style={{fill: '#002200'}}/>
    </div>
	</div>
}