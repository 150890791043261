import React, { Fragment, useState, useEffect , useRef} from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useSearchParams
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  StarIcon,
  UserIcon,
  LightningBoltIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ArrowLeftIcon, MailIcon, StarIcon as EmptyStarIcon, ArrowRightIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {AutoComplete, Steps, Card, Tabs, getHandle, getOrderStatus, InputField, Alert, PageTabs, Modal, TextArea, Pager, Select, FileDrop, getServiceName, getServiceNameFromString} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SidePanel from '../components/SidePanel';
import { useAuthRequest } from '../Auth';
import CardForm from '../components/CardForm';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    instagram_story: {name: 'Instagram Story', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: twitterIcon}
  };

const defaultTabs = [
  {name: 'Pending', id: 'pending', url: '/influencers/orders/pending', current: false},
  {name: 'Active', id: 'active', url: '/influencers/orders/active', current: false},
  {name: 'Completed', id: 'completed', url: '/influencers/orders/completed', current: false},
  {name: 'Expired', id: 'cancelled', url: '/influencers/orders/cancelled', current: false},
];

export default function GigOrders() {

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const { status } = useParams();

  const [orders, setOrders] = useState(null);

  const [counts, setCounts] = useState({pending: 0, active: 0});

  const [gigs, setGigs] = useState([]);

  const [tabs, setTabs] = useState(defaultTabs);

  const [selectedGig, setSelectedGig] = useState({name: 'All gigs', value: ''});

  const loadOrders = async(page=1) => {

    var url = `/api/v2/gig-orders?status=${status}&page=${page}`;

    if (searchParams.has('gig_id')) {
      url += `&gig_id=${searchParams.get('gig_id')}`;
    }
 
    const response = await fetch(url, 'GET');

    const {orders, error, counts, gigs} = response;

    setOrders(orders);

    if (counts) {

      var _tabs = tabs.map(tab => {

        let url = `/influencers/orders/${tab.id}`;

        if (searchParams.has('gig_id')) {
          url += `?gig_id=${searchParams.get('gig_id')}`;
        }

        if (counts[tab.id]) {
          return {...tab, count: counts[tab.id], current: (tab.url.indexOf(status) > 0), url};
        }

        return {...tab, current: (tab.url.indexOf(status) > 0), url};
      })

      setTabs(_tabs);
    }

    if (gigs) {
      var _gigs = [{name: 'All gigs', value: ''}];

      gigs.forEach(gig => {
       
        _gigs.push({name: gig.title, value: gig.id});
      });

      setGigs(_gigs);
    }

    

  }

  React.useEffect(() => {
    if (searchParams.has('gig_id') && gigs.length > 0) {

      let _selectedGig = gigs.find(gig => {

        return gig.value == searchParams.get('gig_id');

      });

      setSelectedGig(_selectedGig);
    }
  }, [gigs]);

  React.useEffect(() => {

    if (orders != null && selectedGig.value != searchParams.get('gig_id')) {
      
      navigate('?gig_id='+selectedGig.value);
    }
  }, [selectedGig]);

  React.useEffect(() => {

    //Initial load...
    setOrders(null);

    loadOrders();

    
  }, [status, searchParams]);


  return <Section title="Gig Orders">
    <div className="max-w-6xl mx-5">
      {orders!=null&&<div className="bg-white rounded-md  mt-4">
      <div className="px-4 sm:px-6 py-4">

        <Select options={gigs} label="Filter by Gig" selected={selectedGig} setSelected={setSelectedGig}/>

        <Tabs tabs={tabs} navigate={navigate} />
        </div>

        {
          orders.total>0&&<><ul className="divide-y">{ orders.data.map(order => <li className="" key={`order-${order.id}`}>
            <Link to={`/influencers/order/${order.id}`} className="grid grid-cols-3 sm:grid-cols-3 md:grid-cols-5  block hover:bg-slate-50 px-4 py-4 sm:px-6">
              <div className="col-span-2">
               
                  <h3 className="font-medium text-gray-700 block text-sm">#{order.id} &middot; <span className="text-indigo-600">{order.gig.title}</span></h3>
                  <span className=" text-gray-500 text-xs block">Ordered by <strong className="font-medium text-gray-700">{order.user.brand_name}</strong></span>
                  
              </div>
              <div className="hidden sm:flex items-center justify-start space-x-2">
                <div className="w-8 h-8 flex justify-center items-center rounded-full shadow inline-block">
                  <img src={socialMediaServices[order.gig.primary_service].icon} className="w-4 h-4 block" title={order.gig.primary_service}/>
                </div>

                  {
                    order.services.map(service => {
                      if (socialMediaServices[service.service]) {
                        return <div className="w-8 h-8 flex justify-center items-center rounded-full shadow inline-block"><img src={socialMediaServices[service.service].icon} className="w-4 h-4 inline-block" key={`${order.id}-${service.service}`} title={service.service}/></div>
                      }

                      if (service.description == 'Express delivery') {
                        return <div className="w-8 h-8 flex justify-center items-center rounded-full shadow inline-block"><LightningBoltIcon className="w-4 h-4 inline-block text-orange-400" key={`${order.id}-${service.service}`} title="Express delivery"/></div>
                      }


                      return null
                    })
                  }
              </div>
              <div className="hidden items-center sm:flex">
                {order.unread_messages_count>0&&<div className="inline-block bg-red-600 text-white py-1 px-2 rounded-md text-xs font-medium shadow"><div className=" flex items-center"><MailIcon className="w-4 h-4 inline mr-1"/>{order.unread_messages_count} New {order.unread_messages_count>1?'Messages':'Message'}</div></div>}
              </div>
              <div className=" text-right">
                <span className="block font-bold text-gray-700">${numeral(order.influencer_fee).format('0,0.00')}</span>
                <span className="text-xs block text-gray-500">{moment(order.created_at).fromNow()}</span>
              </div>
              </Link>
            </li>)
        }</ul>
        <div className="px-4 sm:px-6 py-4 border-t" >
          <Pager data={orders} changePage={loadOrders}/>
        </div>
        </>
      }
      {
        orders.total==0&&<div className="px-4 py-20 sm:px-6 flex items-center justify-center">
          <div className="text-center">
            <FolderIcon className="w-10 h-10 block mx-auto text-gray-600 mb-1"/>
            <h3 className="font-medium text-gray-600 mb-1">No orders found</h3>
            <p className="text-sm text-gray-500">We could not find any gig orders for this status.</p>
          </div>
        </div>
      }

      
      </div>
      }
    </div>
  </Section>
}