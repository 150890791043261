import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  StarIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, HeartIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, TextArea, Select, FileDrop, Gig} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Browser({children}) {
  return <div className="max-w-md mx-auto shadow my-5 rounded-b-lg overflow-hidden mx-auto">
    <div className="w-full h-8 relative rounded-t-lg bg-slate-600 flex overflow-hidden justify-start items-center space-x-1.5 px-2">
        <div className="absolute w-full h-full inset-0 bg-black opacity-50"></div>
        <span className="relative w-2 h-2 border-2 rounded-full bg-red-400 border-red-400"></span>
        <span className="relative w-2 h-2 border-2 rounded-full bg-yellow-400 border-yellow-400"></span>
        <span className="relative w-2 h-2 border-2 rounded-full bg-green-400 border-green-400"></span>
    </div>
    <div className="relative  w-full ">
          {children}
    </div>
</div>
}

export default function BrandPage() {
  return <><div className="container mx-auto h-[90vh] md:grid md:grid-cols-2">
    <div className="flex items-center h-full px-10 sm:px-0">
      <div className="sm:pr-10">
        <h1 className="text-6xl font-bold">Find beauty influencers for your beauty brand</h1>
        <p className="text-lg text-gray-500 my-3">Beauty Clout is a Shopify app that offers your brand a comprehensive suite of tools to work with influencers</p>
        <div className="flex space-x-2">
          <a href="https://apps.shopify.com/beauty-clout" className="block sm:inline-block bg-indigo-600 border border-indigo-600 text-white px-4 py-4 rounded-md block w-full sm:w-64 text-center font-medium">Start 14-Day Free Trial</a>
        </div>
      </div>
    </div>
    <div className="bg-cover bg-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1572112686886-5c0b5bc8dacd?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80')"}}>
    </div>
  </div>
  <div className="bg-slate-800 py-20">
    <div className="container mx-auto">
      <div className="text-center max-w-3xl mx-auto">
        <h3 className="text-3xl text-white font-bold mb-3">Two Ways to Hire Influencers</h3>
        <p className="text-gray-400">Beauty Clout offers you 2 ways to find and hire influencers, depending on how much time you have to dedicate to the hiring process.</p>
      </div>
      <div className=" mt-8 space-y-8 mx-10">
        <div>
          <div className="bg-white rounded-md px-4 py-4 sm:px-6 md:grid md:grid-cols-2">
          <div className="">
          <div>
          <h3 className="text-2xl text-black font-bold mb-3">Campaigns</h3>
          <p className="text-gray-700">Create campaigns, and have more control over the hiring process.</p>
          <ul className="block mt-3 mb-4">
            <li className="text-sm text-gray-600 flex"><CheckIcon className="text-green-600 inline-block mr-2 w-5 h-5"/><span className="flex-1">Set a budget, or exchange products for influencers' services. *</span></li>
            <li className="text-sm text-gray-600 flex"><CheckIcon className="text-green-600 inline-block mr-2 w-5 h-5"/><span className="flex-1">Invite influencers to submit proposals to your campaign.</span></li>
            
            <li className="text-sm text-gray-600 flex"><CheckIcon className="text-green-600 inline-block mr-2 w-5 h-5"/><span className="flex-1">Offer affiliate incentives to hired influencers. *</span></li>
            <li className="text-sm text-gray-600 flex"><CheckIcon className="text-green-600 inline-block mr-2 w-5 h-5"/><span className="flex-1">Monetary payment is held in escrow until hired influencers complete the assignment.</span></li>
            
          </ul>
          <p className="text-xs text-gray-600 mb-4">* Pro membership required for these features</p>
          <a href={'https://apps.shopify.com/beauty-clout'}  className="bg-indigo-600 border border-indigo-600 text-white px-4 py-3 rounded-md block md:inline-block md:w-48 text-center font-medium text-sm">Start Free Trial</a>
          </div>
          </div>
          <div className="hidden sm:block">
            <Browser>
              <img src={'https://res.cloudinary.com/wigmarket/image/upload/v1668707184/bc-screenshot.png'} className="w-full"/>
            </Browser>
          </div>
          </div>
        </div>
        <div>
          <div className="bg-white rounded-md px-4 py-4 sm:px-6 md:grid md:grid-cols-2">
          <div className="">
          <div>
          <h3 className="text-2xl text-black font-bold mb-3">Gigs</h3>
          <p className="text-gray-700">Browse listings of services offered by influencers, and purchase services on the spot.</p>
          <ul className="block mt-3 mb-4">
            <li className="text-sm text-gray-600 flex"><CheckIcon className="text-green-600 inline-block mr-2 w-5 h-5"/><span className="flex-1">Influencers offer their services and prices, including add-ons and extras.</span></li>
            <li className="text-sm text-gray-600 flex"><CheckIcon className="text-green-600 inline-block mr-2 w-5 h-5"/><span className="flex-1">Payment is only captured after the influencer accepts the assignment.</span></li>
            <li className="text-sm text-gray-600 flex"><CheckIcon className="text-green-600 inline-block mr-2 w-5 h-5"/><span className="flex-1">Monetary payment is held in escrow until hired influencers complete the assignment.</span></li>
            
          </ul>
          <Link to={'/gigs'}  className="bg-indigo-600 border border-indigo-600 text-white px-4 py-3 rounded-md block md:inline-block md:w-48 text-center font-medium text-sm">Browse Gigs</Link>
        </div>
        </div>
        <div>
            <Link to={'/gigs'}><img src="https://res.cloudinary.com/wigmarket/image/upload/v1668709930/gig.png" className="block max-w-sm shadow mx-auto w-full my-5" /></Link>
        </div>
        </div>
        </div>
      </div>
    </div>
  </div>  
  <div className="bg-gray-50 py-20">
    <div className="container mx-auto">
      <h1 className="text-3xl text-black font-bold text-center mb-3">More affordable than other platforms</h1>
      <p className="text-gray-600 mb-4 text-sm text-center">Many influencer marketing platforms charge brands over three times as much!</p>
      <div className="max-w-md bg-white divide-y sm:divide-y-0 sm:max-w-2xl md:grid md:grid-cols-2 border divide-x block mx-auto rounded shadow ">
        <div className="px-4 sm:px-6 py-4">
          <h3 className="text-2xl font-bold">Basic</h3>
          <h4 className="text-md font-bold text-gray-800 mb-3">$2.99 / mo</h4>
          <ul className="space-y-1">
            <li className="text-sm text-gray-600"><CheckCircleIcon className="text-slate-400 w-5 h-5 inline-block mr-2"/>Access to over 10,000 influencers</li>
            <li className="text-sm text-gray-600"><CheckCircleIcon className="text-slate-400 w-5 h-5 inline-block mr-2"/>14 day free trial</li>
            <li className="text-sm text-gray-600"><CheckCircleIcon className="text-slate-400 w-5 h-5 inline-block mr-2"/>10% hiring fee</li>
            <li className="text-sm text-gray-600 flex"><CheckCircleIcon className="text-slate-400 w-5 h-5 inline-block mr-2"/><span className="flex-1">Create campaigns for influencers to submit proposals</span></li>
            
            <li className="text-sm text-gray-600 flex items-center"><CheckCircleIcon className="text-slate-400 w-5 h-5 inline-block mr-2"/><span className="flex-1">Purchase Gig services from influencers</span></li>
          </ul>
          <div className="sm:hidden py-4">
            <a href="https://apps.shopify.com/beauty-clout" className="border px-4 py-4 rounded block text-center text-sm font-medium hover:bg-gray-50">Start Trial</a>
          </div>
        </div>
        <div className="px-4 sm:px-6 py-4">
          <h3 className="text-2xl font-bold">Pro 🔥🔥🔥</h3>
          <h4 className="text-md font-bold text-gray-800 mb-3">$29.99 / mo</h4>
          <ul className="space-y-1">
            <li className="text-sm text-gray-600 flex items-start"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Access to over 10,000 influencers</span></li>
            <li className="text-sm text-gray-600 flex items-center"><CheckCircleIcon className="text-green-600 w-5 h-5 inline-block mr-2"/><span className="flex-1">14 day free trial</span></li>
            <li className="text-sm text-gray-600 flex items-start"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">No hiring fees</span></li>
            <li className="text-sm text-gray-600 flex items-start"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Create campaigns for influencers to submit proposals</span></li>
            <li className="text-sm text-gray-600 flex items-start"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Built-in affiliate programs for campaigns</span></li>

            <li className="text-sm text-gray-600 flex items-start"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Product campaigns allow you pay influencers with free products instead of monetary compensation</span></li>

            <li className="text-sm text-gray-600 flex items-center"><CheckCircleIcon className="text-green-600 w-5 h-5 inline-block mr-2"/><span className="flex-1">Purchase Gig services from influencers</span></li>
          </ul>
          <div className=" py-4 sm:hidden">
            <a href="" className="border border-indigo-600 px-4 py-4 rounded block bg-indigo-600 text-white text-center text-sm font-medium hover:bg-indigo-700">Start Trial</a>
          </div>
        </div>
        <div className="hidden sm:block px-4 sm:px-6 py-4">
          <a href="https://apps.shopify.com/beauty-clout" className="border px-4 py-4 rounded block text-center text-sm font-medium hover:bg-gray-50">Start Trial</a>
        </div>
        <div className="hidden sm:block px-4 sm:px-6 py-4">
          <a href="https://apps.shopify.com/beauty-clout" className="border border-indigo-600 px-4 py-4 rounded block bg-indigo-600 text-white text-center text-sm font-medium hover:bg-indigo-700">Start Trial</a>
        </div>
      </div>
    </div>
  </div>
  </>;
}