import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ChevronDownIcon,
  ChevronRightIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, InputField, Alert, TextArea, Select, FileDrop} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import "react-datepicker/dist/react-datepicker.css";
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statuses = [
  {name: 'Invited', status: 'pending'},
  {name: 'Accepted', status: 'accepted'},
  {name: 'Declined', status: 'rejected'}
];

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
  };

const estimates = [
    {id: -1, name: '-- Select'},
    {id: 0, name: '< 1 week'},
    {id: 1, name: '1 week'},
    {id: 2, name: '2 weeks'},
    {id: 3, name: '3 weeks'},
  ];

var searchTimeoutId = null;

function CampaignPreview({cookies}) {

  const {id} = useParams();

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const navigate = useNavigate();

  const preview = (document.location.pathname==`/campaign/${id}/preview`);

  const [invite, setInvite] = useState(null);

  const [proposalTxt, setProposalTxt] = useState('');

  const [expanded, setExpanded] = useState([]);

  const [proposal, setProposal] = useState(null);

  const [campaign, setCampaign] = useState(null);

  const [user, setUser] = useState(null);

  const [services, setServices] = useState([]);

  const [error, setError] = useState(null);

  const [products, setProducts] = useState(null);

  const [expirationDate, setExpirationDate] = useState(null);

  const [page, setPage]= useState(1);

  const [query, setQuery] = useState('');

  const [cart, setCart] = useState([]);

  const [cartVariantIds, setCartVariantIds] = useState([]);

  const loadCampaign = async() => {
    const response = await authFetch(`/api/v2/campaigns/${id}?products=1`, 'GET');

    const {error, campaign, products, invite, user, proposal} = response;

    if (proposal) {
      setProposal(proposal);    
    }

    if (campaign && user) {

      setUser(user);

      var _services = campaign.services.map(service => {
        var proposalService = false;

        if (proposal) {
          proposalService = proposal.services.find(s => s.service==service.service);
        }

        if (proposalService) {
          var completion_weeks = estimates.find(estimate => estimate.id == proposalService.completion_weeks);
          if (!completion_weeks) {
            completion_weeks = {id: -1, name: '-- Select'};
          }
          return {...service, selected: proposalService.hired, fee: proposalService.fee, completion_weeks: completion_weeks};
        }


        return {...service, selected: true, fee: '', completion_weeks: {id: -1, name: '-- Select'}};
      });
      setServices(_services);

      setCampaign(campaign);

    }



    if (products) {
      setProducts(products);
    }

    if (invite) {
      setInvite(invite);
    }

    if (proposal) {
        var _cart = [...cart];
        setProposalTxt(proposal.proposal_txt);
        
        proposal.products.forEach(product => {
          var _product = products.data.find(p => p.id == product.product_id);

          //console.log(_product);

          if (_product) {
            var _variant = _product.options.find(_v => _v.id == product.variant_id);
            var productToAdd = {..._product, selected: _variant};
            cart.push(productToAdd);
            cartVariantIds.push(product.variant_id);
          }

        });
        setCart(_cart);
    }
  }

  const changeVariant = (i, variant) => {
    console.log(products);
    const _products = JSON.parse(JSON.stringify(products));

    _products.data[i].selected = variant;

    setProducts(_products);
  }

  const searchProducts = (value) => {
    if (searchTimeoutId != null) {
      clearTimeout(searchTimeoutId);
    }

    searchTimeoutId = setTimeout(() => {
      setQuery(value);
    }, 600);
  }

  const submitForReview = async() => {
    const response = await authFetch(`/api/v2/campaigns/${id}/submit`, 'POST');

    const {error, message, redirect_url } = response;

    if (error) {
      alert(error);
    }

    if (redirect_url) {
      navigate(redirect_url);
    }
  }

  const loadProducts = async() => {
    const response = await fetch(`/api/v2/campaigns/${id}/products?page=${page}&q=${query}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookies.get('token')}`
      }
    }).then(resp => {
      if (resp.status == 500) {

      }
      return resp.json();
    });

    const {error, products} = response;

    if (products) {
      setProducts(products);
    }

  };


  const declineInvitation = async() => {
    const response = await authFetch(`/api/v2/invites/${invite.id}/decline`, 'POST');

    const {error, message} = response;

    if (error) {

      toast(error);
    }

    if (message) {
      toast(message);
      setInvite(response.invite);
    }

  }

  const undeclineInvitation = async() => {
    const response = await authFetch(`/api/v2/invites/${invite.id}/undecline`, 'POST');

    const {error, message} = response;

    if (error) {

      toast(error);
    }

    if (message) {
      toast(message);
      setInvite(response.invite);
    }

  }

  const toggleProductExpand = (productId) => {

    const _expanded = [...expanded];
    const index = _expanded.indexOf(productId);

    if (index >= 0) {
      _expanded.splice(index, 1);
    } else {
      _expanded.push(productId);
    }

    setExpanded(_expanded);
  }

  const activateCampaign = async() => {

    const body = new FormData();

    body.append('status', 'active');

    const response = await authFetch(`/api/v2/campaigns/${id}/status`, 'POST', body);

    const {message, error} = response;

    if (error) {
      toast(error);
    }

    if (message) {
      toast(message);
      setTimeout(() => {
        navigate(`/campaign/${id}/invite`);
      }, 500);
    }
  }

  const removeProductFromCart = (index) => {
    const _cart = [...cart];
    const _product = _cart[index];
    const _cartVariantIds = [...cartVariantIds];
    const _variantId = _product.selected.id;

    if (_cartVariantIds.indexOf(_variantId) >= 0) {
      _cartVariantIds.splice(_cartVariantIds.indexOf(_variantId), 1);
    }

    _cart.splice(index, 1);

    setCart(_cart);
    setCartVariantIds(_cartVariantIds);


  }

  useEffect(() => {
    loadCampaign();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (campaign != null) {
      loadProducts();

    }
  }, [page, query]);

  if (campaign == null ) {
    return <Section title="Loading..."/>
  }

  const html = campaign.description.replaceAll("\n", '<br/>');

  const productHtml = campaign.product_description!=null?campaign.product_description.replaceAll("\n", '<br/>'):'';

  var cartTotal = 0;
  
  if (campaign.limit_type == 'total') {
    cart.forEach(item => {
      cartTotal += item.selected.price;
    })
  }

  
  const budgetWarning = (campaign!=null&&(services.reduce((prev, current) => {
    
    if (current.fee == '' || Object.is(NaN, current.fee)) {
      return prev;
    }
    return (prev+current.fee)
  }, 0)) > campaign.max_price);


  const canEdit = false;


  return <main className="pt-5 pb-32 bg-slate-50">
      
          {/* Page header */}
          <div className="max-w-3xl mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5 lg:max-w-7xl lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-32 w-32 rounded-md"
                    src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${campaign.image_id}.jpg`}
                    alt=""
                  />
                  <span className="absolute inset-0 shadow-inner rounded-md" aria-hidden="true" />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{campaign.title}</h1>
                <p className="text-sm font-medium text-gray-500">
                  Posted by{' '}
                  <span className="text-gray-900">
                    {campaign.user.brand_name}
                  </span>{' '}
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              
              
            </div>
          </div>

          <div className="px-3 mt-8 max-w-3xl mx-auto grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">
              


              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6 flex justify-between">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Campaign Details
                    </h2>
                    <Link to={`/campaign/${campaign.id}/details`} className="text-indigo-600 text-sm">Edit Details</Link>

                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Website URL</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <a href={campaign.url} target="_blank" className="hover:text-blue-600">{campaign.url}</a>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Estimated budget per hire</dt>
                        {campaign.max_price>0?
                            <dd className="mt-1 text-sm text-gray-900">${numeral(campaign.min_price).format('0,0')} - ${numeral(campaign.max_price).format('0,0')}</dd>:
                            <dd className="mt-1 text-sm text-gray-900">${numeral(campaign.max_price).format('0,0')}</dd>
                          }
                      </div>
                      {campaign.category!=null&&<div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Category</dt>
                        <dd className="mt-1 text-sm text-gray-900">{campaign.category.category_name}</dd>
                      </div>}
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">About this campaign</dt>
                        <dd className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html: html}}>
                          
                        </dd>
                      </div>
                      {
                        campaign.product_description!=null&&campaign.product_description.length>0&&<div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Product details</dt>
                        <dd className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html: productHtml}}>
                          
                        </dd>
                      </div>
                      }
                      
                    </dl>
                  </div>
                  
                </div>
              </section>

              {
                campaign!=null&&campaign.affiliate_program!=null&&<Card title="Affiliate Program">
                <div className="space-y-8">
                  <div className="grid sm:grid-cols-2 gap-x-4 gap-y-8">
                    <div>
                      <label className="text-sm font-medium text-gray-500 block">Commission type</label>
                      <span className="mt-1 text-sm text-gray-900 block">{campaign.affiliate_program.type=='percentage'?'Percentage of order total':'Fixed commission'}</span>
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-500 block">Earning per order</label>
                      <span className="mt-1 text-sm text-gray-900 block">{campaign.affiliate_program.type=='percentage'?`${campaign.affiliate_program.percentage}%`:`${numeral(campaign.affiliate_program.fixed_value).format('$0,0.00')}`}</span>
                    </div>
                  </div>
                  {
                    campaign.affiliate_program.coupon==1&&<div className="grid sm:grid-cols-2 gap-x-4 gap-y-8">
                    <div>
                      <label className="text-sm font-medium text-gray-500 block">Coupon type</label>
                      <span className="mt-1 text-sm text-gray-900 block">{campaign.affiliate_program.discount_type=='percentage'?'Percentage of order total':'Fixed commission'}</span>
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-500 block">Coupon value</label>
                      <span className="mt-1 text-sm text-gray-900 block">{campaign.affiliate_program.discount_type=='percentage'?`${campaign.affiliate_program.discount_percentage}%`:`${numeral(campaign.affiliate_program.discount_value).format('$0,0.00')}`}</span>
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-500 block">Order minimum for discount</label>
                      <span className="mt-1 text-sm text-gray-900 block">{numeral(campaign.affiliate_program.min_subtotal).format('$0,0.00')}</span>
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-500 block">Usage limits</label>
                      <span className="mt-1 text-sm text-gray-900 block">{campaign.affiliate_program.once_per_customer==1?'Coupon can be used once per customer':'No limitations'}</span>
                    </div>
                  </div>
                  }
                  </div>
                </Card>
              }

              {/* Comments*/}
              {products!=null&&campaign!=null&&campaign.campaign_variants_count>0&&<section ariaLabelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Products for Review
                      </h2>
                      <Link to={`/campaign/${campaign.id}/products`} className="text-indigo-600 text-sm">Edit Products</Link>
                    </div>
                    <div className="p-5">
                      <div className="mt-1 relative rounded-md shadow-sm border border-text-100">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="focus:ring-indigo-500 p-3 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search available products..."
                    onChange={(e) => searchProducts(e.target.value)}
                  />
                </div>
                    </div>
                    <div className="divide-y">
                    {
                      products.data.map((product, i) => {


                        return <div key={`product-${product.id}`} className="p-5 relative flex space-x-2 ">
                        {
                          product.image!=null?<img src={product.image.src} className=" object-center object-cover h-16 w-16 block rounded-md shadow"/>:<div className="bg-gray-50  w-16 h-16 block rounded-md shadow"/>
                        }
                        <div className="w-full md: w-1/2">
                          <strong className="text-sm font-medium text-gray-500 block">{product.title}</strong>
                          {product.options.length>1?<button className="flex items-center hover:text-gray-800 text-gray-500 text-sm block" onClick={() => toggleProductExpand(product.id)}>{expanded.includes(product.id)?<ChevronDownIcon className="w-5 h-5 inline-block text-gray-600"/>:<ChevronRightIcon className="w-5 h-5 inline-block text-gray-600"/>}{product.options.length} variants</button>:<span className="text-gray-500 text-sm block">1 variant</span>}
                          {
                            expanded.includes(product.id)&&<ul className="divide-y-2 divide-gray-100">
                              {
                                product.options.map(option => <li key={`product-${product.id}-${option.value}`} className="flex justify-between py-2 px-4">
                                    <span className="text-gray-600 text-sm">{option.name}</span>
                                    <span className="text-gray-600 text-sm">${numeral(option.price).format('0,0.00')}</span>
                                  </li>)
                              }
                            </ul>
                          }
                        </div>
                        
                        
                        </div>
                      })
                    }
                    </div>
                    <div className="p-5 flex justify-between">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => setPage(page-1)}
              disabled={page<=0}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            
            <button
              onClick={() => setPage(page+1)}
              disabled={page==products.last_page}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div className="pt-1">
            <span className="text-gray-500 text-sm">Showing <strong>{products.from}</strong> to <strong>{products.to}</strong> of <strong>{products.total}</strong> results</span>
          </div>
        </div>
                </div>
                </div>
              </section>}

            
               <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Social Media Services
                      </h2>
                      <Link to={`/campaign/${campaign.id}/social`} className="text-indigo-600 text-sm">Edit Services</Link>

                    </div>
                    <div className="grid grid-cols-3 md:grid-cols-6">

                      {
                        services.map(service => {

                          
                            return <div key={`service-${campaign.id}-${service.service}`} className="px-4 py-4 sm:py-6 sm:px-6">
                              <img src={socialMediaServices[service.service].icon} className="w-10 h-10 block mx-auto"/>
                            </div>
                          }
                        )
                      }
                      </div>
                  </div>
                </div>
                </section>

                <div className="space-y-2">
                  <button onClick={() => activateCampaign()} className="w-full bg-indigo-600 border-indigo-600 border font-medium text-white block rounded-md px-4 sm:px-6 py-3 text-center text-sm hover:bg-indigo-700">Invite Influencers</button>
                  <Link to={`/campaign/${campaign.id}/products`} className="border text-gray-700 bg-white font-medium block rounded-md px-4 sm:px-6 py-3 text-center text-sm hover:border-gray-300">Edit Campaign</Link>
                </div>
            </div>



          </div>
          <ToastContainer autoClose={2000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
        </main>;
}

export default withCookies(CampaignPreview);