import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link
} from "react-router-dom";
import { CookiesProvider } from 'react-cookie';

  //Components
import Frame from './components/Frame';
import Dashboard from './components/Dashboard';
import InfluencerDashboard from './components/InfluencerDashboard';

//Pages
import FromShopify from './pages/FromShopify';
import DashboardBody from './pages/DashboardBody';
import Campaigns from './pages/Campaigns';
import Campaign from './pages/Campaign';
import CampaignSocial from './pages/CampaignSocial';
import CampaignDetails from './pages/CampaignDetails';
import CampaignPreview from './pages/CampaignPreview';
import CampaignView from './pages/CampaignView';
import CampaignInvite from './pages/CampaignInvite';
import InfluencerSignUp from './pages/InfluencerSignUp';
import InfluencerSignIn from './pages/InfluencerSignIn';
import ProposalMessages from './pages/ProposalMessages';
import InfluencerDashboardBody from './pages/InfluencerDashboardBody';
import BrandProposal from './pages/BrandProposal';
import InfluencerProposal from './pages/InfluencerProposal';
import InfluencerInvites from './pages/InfluencerInvites';
import Notifications from './pages/Notifications';
import BrandSettings from './pages/BrandSettings';
import InfluencerSettings from './pages/InfluencerSettings';
import InfluencerGigs from './pages/InfluencerGigs';
import GigOrders from './pages/GigOrders';
import CreateGig from './pages/CreateGig';
import GigOrder from './pages/GigOrder';
import BrandGigOrder from './pages/BrandGigOrder';
import Gig from './pages/Gig';
import SignOut from './pages/Signout';
import { AuthProvider } from './Auth.js';
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import GigOrderMessages from './pages/GigOrderMessages';
import BrowseGigs from './pages/BrowseGigs';
import BrandGigOrders from './pages/BrandGigOrders';
import BrowseGigCategory from './pages/BrowseGigCategory';
import GigSearchResults from './pages/GigSearchResults';
import CampaignEnd from './pages/CampaignEnd';
import Messages from './pages/Messages';
import Products from './pages/Products';
import BrandProposals from './pages/BrandProposals';
import InfluencerStripe from './pages/InfluencerStripe';
import CampaignAffiliates from './pages/CampaignAffiliates';
import InfluencerAffiliates from './pages/InfluencerAffiliates';
import BrandAffiliates from './pages/BrandAffiliates';
import BrandViewAffiliate from './pages/BrandViewAffiliate';
import CheckAuth from './components/CheckAuth';
import PasswordReset from './pages/PasswordReset';
import HomePage from './pages/HomePage';
import BrandPage from './pages/BrandPage';
import InfluencerPage from './pages/InfluencerPage';
import InfluencerDeactivation from './pages/InfluencerDeactivation';
import Redirect from './pages/Redirect';




// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function App() {
  return (
    <CookiesProvider>
    
    <Router>
      <AuthProvider>
      <Elements stripe={stripePromise}>
      <Routes>
        <Route exact path="/signup" element={<InfluencerSignUp/>}/>
        <Route exact path="/signin" element={<InfluencerSignIn/>}/>
        <Route exact path="/signout" element={<SignOut/>}/>
        <Route exact path="/reset/:resetId/:userId/:token" element={<PasswordReset/>}/>

        <Route exact path="/token/:token" element={<FromShopify/>} />
        <Route path="/brands" element={<Dashboard/>}>
          <Route exact path="proposal/:id" element={<Redirect brand={true} campaign={false} proposal={true}/>}/>
          <Route exact path="campaigns" element={<Redirect brand={true} campaign={true}/>}/>
        </Route>
        <Route path="/influencers" element={<InfluencerDashboard/>}>
          <Route exact path="gig" element={<CreateGig/>}/>
          <Route exact path="gig/:id" element={<CreateGig/>}/>
          <Route exact path="gigs" element={<InfluencerGigs/>}/>
          <Route exact path="orders/:status" element={<GigOrders/>}/>
          <Route exact path="order/:id" element={<GigOrder/>}/>
          <Route exact path="order/:id/messages" element={<GigOrderMessages/>}/>
          <Route exact path="dashboard" element={<InfluencerDashboardBody/>}/>
          <Route exact path="campaigns/:status" element={<InfluencerInvites/>}/>
          <Route exact path="campaign/:id" element={<InfluencerProposal/>}/>
          <Route exact  path="proposal/:id/messages" element={<ProposalMessages/>}/>
          <Route exact path="settings/affiliates" exact element={<InfluencerAffiliates/>}/>
          <Route exact path="settings" element={<InfluencerSettings/>}/>
          <Route exact path="messages" element={<Messages/>}/>
          <Route exact path="stripe" element={<InfluencerStripe/>}/>
          <Route exact path="notifications" element={<Notifications/>}/>
          <Route exact path="deactivation" element={<InfluencerDeactivation/>}/>
          <Route exact path="campaigns" element={<Redirect campaign={true} brand={false}/>}/>
          <Route exact path="campaigns/:id/:slug" element={<Redirect campaign={true} brand={false}/>}/>
        </Route>
          <Route  element={<Dashboard/>}>
            <Route exact path="/dashboard" element={<DashboardBody/>}/>
            <Route exact path="/messages" element={<Messages/>}/>
            <Route exact path="/campaign/:id" element={<CampaignView/>}/>
            <Route exact path="/campaign/:id/proposals/:status" element={<CampaignView/>}/>
            <Route exact path="/proposal/:id" element={<BrandProposal/>}/>
            <Route exact path="/proposal/:id/messages" element={<ProposalMessages/>}/>
            <Route exact path="/campaign/:id/social" element={<CampaignSocial/>}/>
            <Route exact path="/campaign/:id/details" element={<CampaignDetails/>}/>
            <Route exact path="/campaign/:id/affiliates" element={<CampaignAffiliates/>}/>
            <Route exact path="/campaign/:id/preview" element={<CampaignPreview/>}/>
            <Route exact path="/campaign/:id/products" element={<Campaign/>}/>
            <Route exact path="/campaign/:id/invite" element={<CampaignInvite/>}/>
            <Route exact path="/campaign/:id/archive" element={<CampaignEnd/>}/>
            <Route exact path="/products" element={<Products/>}/>
            <Route exact path="/proposals/:status" element={<BrandProposals/>}/>
            <Route exact path="/affiliates/:id" element={<BrandViewAffiliate />}/>
            <Route exact path="/affiliates" element={<BrandAffiliates/>}/>
            
            <Route exact path="/order/:id" element={<BrandGigOrder/>}/>
            <Route exact path="/order/:id/messages" element={<GigOrderMessages/>}/>
            <Route exact path="/orders/:status" element={<BrandGigOrders/>}/>
            <Route exact path="/campaign" element={<Campaign/>}/>
            <Route exact path="/campaigns/:status" element={<Campaigns/>}/>
            <Route exact path="/settings" element={<BrandSettings/>}/>
            <Route exact path="/notifications" element={<Notifications/>}/>
          </Route>
          <Route element={<CheckAuth/>}>
            <Route exact path="/gigs" element={<BrowseGigs/>}/>
            <Route exact path="/gigs-search" element={<GigSearchResults/>}/>
            <Route exact path="/gigs/platform/:code/:subtype" element={<BrowseGigCategory/>}/>
            <Route exact path="/gigs/:id" element={<Gig/>}/>
          </Route>
          <Route exact path="/" element={<Frame/>}>
            <Route exact path="/" element={<HomePage/>}/>
            <Route exact path="/brand" element={<BrandPage/>}/>
            <Route exact path="/influencer" element={<InfluencerPage/>}/>
          </Route>

          
        </Routes>
        </Elements>
    </AuthProvider>
    </Router>
    </CookiesProvider>
  );
}

export default App;
