import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, DocumentIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert, Select, Nav, Tabs} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const labels = {
  proposed: <span className="bg-cyan-600 text-white text-xs font-medium px-2 py-1 rounded-md">Pending</span>,
  production: <span className="bg-green-600 text-white text-xs font-medium px-2 py-1 rounded-md">In progress</span>,
  completed: <span className="bg-orange-600 text-white text-xs font-medium px-2 py-1 rounded-md">Awaiting review</span>,
  change: <span className="bg-blue-600 text-white text-xs font-medium px-2 py-1 rounded-md">Change requested</span>,
  verified: <span className="bg-green-600 text-white text-xs font-medium px-2 py-1 rounded-md">Work verified</span>
}

const statuses = [
  {name: 'Invited', status: 'proposed'},
  {name: 'Accepted', status: 'accepted'},
  {name: 'Declined', status: 'rejected'}
];

const imgs = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  instagram: instagramIcon,
  tiktok: tiktokIcon,
  youtube: youtubeIcon
};

const titles = {
  proposed: 'Pending Proposals',
  hired: 'Hired Influencers',
  verified: 'Completed Assignments'
};

var searchTimeoutId = null;

function CampaignView() {

	const {id, status} = useParams();

  const navigate = useNavigate();

  const defaultTabs = [
    {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: (document.location.pathname.indexOf('proposed')>0)},
    {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: (document.location.pathname.indexOf('hired')>0)},
    {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: (document.location.pathname.indexOf('verified')>0)},
    {name: 'Invite Influencers', url: `/campaign/${id}/invite`, current: false},
    {name: 'Edit Campaign', url: `/campaign/${id}/products`, current: false}
  ];

  const [tabs, setTabs] = useState(defaultTabs);

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const [campaign, setCampaign] = useState(null);

  const [proposals, setProposals] = useState(null);

  const [page, setPage] = useState(1);

  const loadCampaign = async() => {
    const response = await authFetch(`/api/v2/campaigns/${id}?proposals=1&status=${status}&page=${page}`, 'GET');

    const {error, proposals} = response;

    if (proposals) {
      setProposals(proposals);
    }

    if (response.campaign) {
      setCampaign(response.campaign);
    }

    let tabs = defaultTabs;

    if (response.campaign.status == 'draft') {
      tabs = [
        {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: (document.location.pathname.indexOf('proposed')>0)},
        {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: (document.location.pathname.indexOf('hired')>0)},
        {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: (document.location.pathname.indexOf('verified')>0)},
        {name: 'Edit Campaign', url: `/campaign/${id}/details`, current: false},
        {name :'Delete Campaign', url: `/campaign/${id}/archive`, current: false}
      ];
      
    } else if (response.campaign.status != 'archive') {
      tabs = [
        {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: (document.location.pathname.indexOf('proposed')>0)},
        {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: (document.location.pathname.indexOf('hired')>0)},
        {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: (document.location.pathname.indexOf('verified')>0)},
        {name: 'Invite Influencers', url: `/campaign/${id}/invite`, current: false},
        {name: 'Edit Campaign', url: `/campaign/${id}/products`, current: false},
        {name :'End Campaign', url: `/campaign/${id}/archive`, current: false}
      ];
      //setTabs(tabs);
    } else {
      tabs = [
        {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: (document.location.pathname.indexOf('proposed')>0)},
        {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: (document.location.pathname.indexOf('hired')>0)},
        {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: (document.location.pathname.indexOf('verified')>0)},
        {name :'Reactivate Campaign', url: `/campaign/${id}/archive`, current: false}
      ];
      
    }

    if (response.campaign.affiliate_program && response.campaign.status != 'draft') {
      tabs.splice(3, 0, {
        name: 'Affiliates',
        url: `/affiliates?campaign_id=${response.campaign.id}`,
        current: false
      });
    }

    setTabs(tabs);

    
  }

  useEffect(() => {
    loadCampaign();
  }, [status, page]);

  useEffect(() => {
    setPage(1);
  }, [status]);

  if (campaign == null) {
    return null;
  }

  const title = status?titles[status]:'';

  return <div className="bg-gray-50 min-h-screen">
    <Nav homeUrl="/dashboard" links={[{name: 'Campaigns', url: '/campaigns/active'}, {name: campaign.title, url: `/campaign/${campaign.id}/proposals/proposed`}]}/>

    <div className="px-5 mt-0">
      <Tabs tabs={tabs} navigate={navigate}/>
      <div className="bg-white shadow overflow-hidden sm:rounded-md mt-5 max-w-5xl mb-5" >
      <div className="py-4 px-4 sm:px-6 border-b flex justify-between">
        <h3 className="font-medium text-lg text-gray-900">{title}</h3>
        
      </div>
      <ul role="list" className="divide-y divide-gray-200">
      {
        proposals.data.map(proposal => <li key={`proposal-${proposal.id}`} className="">

            <Link to={`/proposal/${proposal.id}`} className="block hover:bg-gray-50 bg-white">
            <div className="flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex items-center">
                  <div className="flex-shrink-0">
                    {
                      proposal.user.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${proposal.user.image_id}.jpg`} className="w-20 h-20 rounded-full"/>:
                      <div className="w-20 h-20 rounded-full flex items-center justify-center font-bold text-2xl bg-slate-100 text-slate-800">{proposal.user.name[0]}</div>
                    }
                  </div>
            <div className="min-w-0 flex-1 px-4 grid-cols-2 md:grid md:grid-cols-4 md:gap-4">
                    <div>
                <Link to={`/proposal/${proposal.id}`} className=" block font-medium text-sm text-indigo-600">{proposal.user.name}</Link>
                <small className="mt-1 text-gray-600">{moment(proposal.created_at).fromNow()}</small>
              </div>
              <div className="hidden md:block">
                <strong className="font-medium text-sm text-gray-600">{numeral(proposal.user.follower_count).format('0a')} total followers</strong>
                <div className="flex mt-1">
                  {
                    proposal.user.services.map(service => <img src={imgs[service.service]} className="w-4 h-4 mr-1"/>)
                  }
                </div>
              </div>
              <div className="">
                {
                  labels[proposal.status]
                }
              </div>
              <div className="flex items-center justify-end h-full">
                <ChevronRightIcon className="w-5 h-5 text-gray-400"/>
              </div>
            </div>
            </div>
            </div>
            </Link>
          </li>)
      }
    </ul>
    {
      proposals.total==0?<div className="empty-state py-20 px-3 text-center">
        <DocumentIcon className="w-20 h-20 text-gray-500 mx-auto block mb-2"/>
        <h3 className="font-medium text-gray-600 mb-2">No proposals yet</h3>
        <p className="text-sm text-gray-500">When influencers bid on your campaign, their proposals will appear here.</p>
      </div>:<div className="p-5 flex justify-between">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => setPage(page-1)}
              disabled={page<=0}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            
            <button
              onClick={() => setPage(page+1)}
              disabled={page==proposals.last_page}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div className="pt-1">
            <span className="text-gray-500 text-sm">Showing <strong>{proposals.from}</strong> to <strong>{proposals.to}</strong> of <strong>{proposals.total}</strong> results</span>
          </div>
        </div>
    }
    </div>
    </div>
  </div>

}

export default CampaignView;