import React, { Fragment, useState, useEffect , useRef} from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  StarIcon,
  UserIcon,
  LightningBoltIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { ShoppingBagIcon, CalendarIcon, TrashIcon, ArrowLeftIcon, StarIcon as EmptyStarIcon, LinkIcon, ArrowRightIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {getFulfillmentStatusName, getOrderStatusName, StarRatingSelector, AutoComplete, getURL, Steps, Card, Tabs, getHandle, getOrderStatus, Nav, InputField, Alert, PageTabs, Modal, TextArea, Pager, Select, FileDrop, getServiceName, getServiceNameFromString, MenuButton} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SidePanel from '../components/SidePanel';
import { useAuthRequest } from '../Auth';
import CardForm from '../components/CardForm';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    instagram_story: {name: 'Instagram Story', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: twitterIcon}
  };

const cancellationReasons = [
  { name: '-- Select a reason', value: ''},
  { name: 'Changed mind', value: 'change_mind'},
  { name: 'Influencer is not responsive', value: 'unresponsive'},
  { name: 'Unable to complete assigned work', value: 'unable'},
  { name: 'Fraud / Brand is not who they say they are', value: 'fraud'},
  { name: 'Other', value: 'other'}
];

function OrderItem({itemName, itemValue, primary=false, subtract=false, bold=false, badge=false}) {
  return <div className="px-4 py-4 sm:px-6 flex justify-between items-center">
    <div>
      <span className={`block text-sm text-gray-600 ${bold&&'font-bold'}`}>{itemName}{primary&&` (Primary Service)`}{badge!=false&&<span className="block sm:inline sm:ml-2">{badge}</span>}</span>
    </div>
    <div>
      <span className="block text-right text-sm text-gray-700 font-medium">{subtract&&'-'}${numeral(itemValue).format('0,0.00')}</span>
    </div>
  </div>
}

function Status({status}) {
  var label = '';
  var css = '';

  switch(status) {
    case 'authorized':
      label = 'Pending Approval';
      css = 'bg-orange-600';
      break;
    case 'products_submitted':
      label = 'Products Submitted';
      css = 'bg-green-600';
      break;
    case 'production':
      label = 'In Progress';
      css = 'bg-green-600';
      break;
    case 'order_created':
      label = 'Shopify Order Created';
      css = 'bg-green-600';
      break;
    case 'cancelled':
      label = 'Cancelled';
      css = 'bg-red-600';
      break;
    case 'expired':
      label = 'Expired';
      css = 'bg-slate-600';
      break;
    case 'completed':
      label = 'Awaiting Review';
      css = 'bg-cyan-600';
      break;
    case 'verified':
      label = 'Work Verified';
      css = 'bg-green-600';
      break;
    default:
      label = 'Unknown Status';
      css = 'bg-slate-600';

  }

  return <span className={"rounded-full py-1 px-3 text-xs text-white mb-1 inline-block font-medium "+css}>{label}</span>
}

function InfluencerImage({user}) {
  if (user.image_id == null) {
    return <div className="rounded-full w-12 h-12 shadow bg-slate-100 text-slate-600 font-bold text-white flex justify-center items-center">{user.name[0]}</div>
  }

  return <img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill,q_auto/${user.image_id}.jpg`} className="rounded-full w-12 h-12 shadow"/>
}



const defaultTabs = [
  {id: 'selected', name: 'Selected Products', value: 'selected', current: true},
  {id: 'all', name: 'All Products', value: 'all', current: false}
];

export default function BrandGigOrder() {

  /* Hooks */

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const { id } = useParams();

  const navigate = useNavigate();


  /* State variables */

  const [order, setOrder] = useState(null);

  const [verifyModal, setVerifyModal] = useState(false);

  const [reviewModal, setReviewModal] = useState(false);

  const [showSelectedOnly, setShowSelectedOnly] = useState(true);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [riskAcknowledged, setRiskAcknowledged] = useState(false);

  const [products, setProducts] = useState(null);

  const [backLink, setBackLink] = useState({name: 'Orders', url: '/orders/active'});

  const [gig, setGig] = useState(null);

  const [tabs, setTabs] = useState(defaultTabs);

  const [approveProductModal, setApproveProductModal] = useState(false);

  const [rating, setRating] = useState(0);

  const [review, setReview] = useState('');

  const [messageCount, setMessageCount] = useState(0);

  const [cancelModal, setCancelModal] = useState(false);

  const [cancelRequestModal, setCancelRequestModal] = useState(false);

  const [reasonCode, setReasonCode] = useState(cancellationReasons[0]);

  const [detailsTxt, setDetailsTxt] = useState('');

  const [withdrawCancellationRequestModal, setWithdrawCancellationRequestModal] = useState(false);

  const [cancelAgreeModal, setCancelAgreeModal] = useState(false);

  const [cancelDeclineModal, setCancelDeclineModal] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  /* End state variables */

  /* Server side functions */

  const loadOrder = async() => {
    const response = await fetch(`/api/v2/gig-orders/${id}`, 'GET');

    const { order, error, gig, products, message_count } = response;

    if (error) {
      navigate('/dashboard');
    }

    setMessageCount(message_count);

    if (order) {
      setOrder(order);
      setGig(gig);

      let _selectedProducts = [];

      order.products.forEach(product => {
        product.variants.forEach(variant => {
          if (variant.selected == true) {
            _selectedProducts.push({
              title: product.product.title,
              variant: variant.variant,
              image: product.product.image,
              id: product.product.id,
              selected: true
            });
          }
        });
      });

      setSelectedProducts(_selectedProducts);

      let backLink = {name: 'Orders', url: '#'};

      if (['production', 'products_submitted', 'order_created', 'completed'].includes(order.status)) {
        backLink = {name: 'Active Orders', url: '/orders/active'};
      } else if (['cancelled', 'expired'].includes(order.status)) {
        backLink = {name: 'Expired Orders', url: '/orders/expired'};
      } else if (order.status == 'pending'){
        backLink = {name: 'Pending Orders', url: '/orders/pending'};
      } else if (order.status == 'verified') {
        backLink = {name: 'Completed Orders', url: '/orders/completed'};
      }

      setBackLink(backLink);

    }

    if (products) {
      setProducts(products);
    }
  }

  const verifyGigOrder = async() => {
    setSubmitting(true);

    const body = new FormData();

    body.append('rating', rating);
    body.append('review_txt', review);

    const response = await fetch(`/api/gig-orders/${id}/verified`, 'POST', body);

    const { message, error } = response;

    if (message) {
      toast(message);
      loadOrder();
      setVerifyModal(false);
      window.scrollTo(0,0);
    }

    if (error) {
      toast(error);
    }

    setSubmitting(false);
  }

  const changeProductsPage = async(page) => {
    const response = await fetch(`/api/v2/gig-orders/${id}/products?page=${page}`, 'GET');

    const { products } = response;

    if (products) {
      setProducts(products);
    }
  }

  const cancelOrder = async() => {
    const response = await fetch(`/api/gig-orders/${id}/cancel`, 'POST');

    const { error, message } = response;

    if (error) {
      toast(error);
    }

    if (message) {
      toast(message);
      loadOrder();
      setCancelModal(false);
    }
  }

  const approveProducts = async() => {
    setSubmitting(true);

    const formData = new FormData();

    selectedProducts.forEach(product => {
      if (product.selected == 1) {
        formData.append('selected[]', product.variant.id);
      }
    });

    const response = await fetch(`/api/gig-orders/${id}/approve`, 'POST', formData);

    setApproveProductModal(false)

    const { error, message } = response;

    if (error) {
      toast(error);
    }

    if (message) {
      toast(message);
      loadOrder();
      window.scrollTo(0,0);
    }

    setSubmitting(false);
  }

  /* client side functions */

  const setProductSelected = (index, checked) => {
    const _selectedProducts = [...selectedProducts];

    _selectedProducts[index].selected = checked;

    setSelectedProducts(_selectedProducts);
  }

  const openVerifyModal = () => {

    if (rating == 0) {
      toast('Please rate working with this influencer from 1-5 stars');
      return false;
    }

    setReviewModal(false);

    setTimeout(() => {
      setVerifyModal(true);
    }, 500);
  }

  const respondToCancellationRequest = async(agree) => {
    const body = new FormData();

    body.append('agree', agree);

    const response = await fetch(`/api/v2/gig-orders/${order.id}/cancellationRequest`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      loadOrder();
      setCancelAgreeModal(false);
      setCancelDeclineModal(false);
      window.scrollTo(0,0);
    }

    if (error) {
      toast(error);
    }
  }

  const requestCancellation = async() => {
    const body = new FormData();

    body.append('reason_code', reasonCode.value);
    body.append('details_txt', detailsTxt);
    const response = await fetch(`/api/v2/gig-orders/${order.id}/requestCancellation`, 'POST', body);

    const {message, error}  = response;

    if (message) {
      toast(message);
      loadOrder();
      setCancelRequestModal(false);
    }

    if (error) {
      toast(error);
    }
  }

  const backToReviewModal = () => {
    setVerifyModal(false);

    setTimeout(() => {
      setReviewModal(true);
    }, 500);
  }

  const withdrawCancellationRequest = async() => {
    const response = await fetch(`/api/v2/gig-orders/${order.id}/withdrawCancellation`, 'POST');

    const {message, error} = response;

    if (message) {
      toast(message);
      setWithdrawCancellationRequestModal(false);
      loadOrder();

    }

    if (error) {
      toast(error);
    }
  }

  const changeTabs = (tab) => {
    const _tabs = tabs.map(_tab => {

      if (_tab.value == tab.value) {
        return {..._tab, current: true};
      }

      return {..._tab, current: false};
    });

    setTabs(_tabs);
  }

  /* useEffect hooks */

  useEffect(() => {
    loadOrder();
  }, []);

  if (order == null || gig == null) {
    return null;
  }

  const menuOptions = [];

  if (order != null && order.allow_cancellation == 1) {
    menuOptions.push({
      title: 'Cancel Order',
      action: () => setCancelModal(true)
    });
  } else if (order != null && order.request_cancellation == 1) {
    menuOptions.push({
      title: 'Request Cancellation',
      action: () => setCancelRequestModal(true)
    });
  }

  const proofsVisible = order.status=='verified'||order.status=='completed';

  let badge = <span className="bg-slate-700 text-white text-xs font-medium rounded-full px-2 py-1">In Escrow</span>;

  if (order.status == 'authorized') {
    badge = <span className="bg-cyan-500 text-white text-xs font-medium rounded-full px-2 py-1">Not Yet Captured</span>;
  } else if (order.status == 'expired') {
    badge = <span className="bg-slate-700 text-white text-xs font-medium rounded-full px-2 py-1 ">Not Charged</span>;
  } else if (order.refund_id != null) {
    badge = <span className="bg-slate-700 text-white text-xs font-medium rounded-full px-2 py-1">Refunded</span>;
  } else if (order.transfer_id != null) {

    badge = <span className="bg-green-600 text-white text-xs font-medium rounded-full px-2 py-1">Funds Released</span>;
  }

  return <>
  <Nav homeUrl={'/orders/all'} links={[
      backLink,
      {name: `Order #${order.id}`, url: `/order/${order.id}`}
    ]}/>
  <Section title={`Gig Order #${order.id}`}>
    <div className="max-w-6xl mx-5 mt-2">
      <div className="flex justify-between">
      <Link to={`/gigs/${order.gig_id}`} className="text-indigo-600 hover:text-indigo-700 text-sm">{gig.title}</Link>
        {
          menuOptions.length>0&&<MenuButton options={menuOptions} label="..."/>
        }
      </div>

      <div className="grid md:grid-cols-3 mt-3 gap-4">
        <div className="md:col-span-2">
        
          <Card title="Order Summary" unstyled={true} subtitle="Description of this order" className="mb-8">
            <div className="divide-y">
              <div className="px-4 sm:px-6 py-4 sm:flex justify-between">
                <div className="flex space-x-2">
                  <InfluencerImage user={gig.user}/>
                  <div>
                    <h3 className="font-medium text-gray-700">{gig.user.name}</h3>
                    
                    <div className="space-x-2 flex">
                    {
                      gig.user.services.map(service => {
                        if (socialMediaServices[service.service]) {
                          return <a href={getURL(service)} key={`social-media-${service.service}`} className="block w-6 h-6 rounded-full flex items-center justify-center shadow"><img src={socialMediaServices[service.service].icon} className="w-4 h-4"/></a>
                        }

                        return null
                      })
                    }
                    </div>
                  </div>
                </div>
                <div className="sm:text-right mt-3 sm:mt-0">
                  <Status status={order.status}/>
                 
                  <span className="text-gray-500 text-xs block sm:text-right">Gig order sent {moment(order.created_at).fromNow()}</span>
                </div>
              </div>
              <div className="flex items-center px-4 sm:px-6 py-4">
              <Link to={`/order/${order.id}/messages`} className="text-indigo-600 text-sm">View Messages</Link> {messageCount>0&&<span className="font-medium text-xs ml-1 rounded-lg py-1 px-2 bg-red-600 text-white">{messageCount}</span>}
              </div>
              {order.note_txt!=''&&order.note_txt!=null&&<div className="px-4 sm:px-6 py-4">
                <label className="text-xs font-medium text-gray-600">Your Notes</label>
                <p className="text-sm text-gray-600" dangerouslySetInnerHTML={{__html: order.note_txt.replaceAll("\n", '<br/>')}}></p>
              </div>}
              <OrderItem itemName={socialMediaServices[gig.primary_service].name} itemValue={gig.fee} primary={true}/>
              {
                order.services.map(service => {
                  if (socialMediaServices[service.service]) {
                    return <OrderItem key={`order-service-${service.id}`} itemName={socialMediaServices[service.service].name} itemValue={service.fee}/>
                  }

                  if (service.description != '' ) {
                    return <OrderItem key={`order-service-${service.id}`} itemName={service.description} itemValue={service.fee} />
                  }

                  return null
                })
              }

              {
                <OrderItem itemName="Influencer Payment" itemValue={order.influencer_total} badge={badge}/>
              }

              {
                order.brand_fee>0&&<>
                  <OrderItem itemName="Beauty Clout Fee (10%)" itemValue={order.brand_fee}/>
                  <OrderItem itemName="Total Paid" itemValue={order.total_fee} bold={true}/>
                </>
              }
            </div>
          </Card>

          {
            order!=null&&order.cancellation_request!=null&&<Card title="Cancellation Request" className="mb-8" primaryAction={order.cancellation_request.disputed_at==null&&order.cancellation_request.requested_by_user_id==order.user_id?{label: 'Withdraw Request', action: () => setWithdrawCancellationRequestModal(true)}:null}>
              {
                order.cancellation_request.requested_by_user_id==order.user_id&&order.cancellation_request.disputed_at==null&&<>
                  <p className="text-gray-600 text-sm mb-3">You have requested cancellation of this order. If <strong className="font-medium">{gig.user.name}</strong> agrees, you will be refunded any owed funds, and this order will be terminated.</p>
                  <p className="text-gray-600 text-sm">Otherwise, a dispute will be opened for review by Beauty Clout staff</p>
                </>
              }
              {
                order.cancellation_request.requested_by_user_id!=order.user_id&&order.cancellation_request.disputed_at==null&&<>
                  <p className="text-gray-600 text-sm mb-3"><strong className="font-medium text-indigo-600">{gig.user.name}</strong> has requested cancellation of this order. If you agree, you will be refunded <strong className="font-medium text-indigo-600">${numeral(order.total_fee).format('0,0.00')}</strong>, and this order will be cancelled.</p>
                  <p className="text-gray-600 text-sm">Otherwise, a dispute will be opened for review by Beauty Clout staff</p>
                  <div className="sm:grid sm:grid-cols-2 mt-5 gap-4">
                    <Button primary fullWidth onClick={() => setCancelAgreeModal(true)}>Cancel Order</Button>
                    <Button fullWidth onClick={() => setCancelDeclineModal(true)}>Decline Request</Button>
                  </div>
                </>
              }

              {
                order.cancellation_request.disputed_at!=null&&<>
                  <p className="text-gray-600 text-sm mb-3">A cancellation request has been declined, and a Beauty Clout administrator will review this dispute.</p>
                  <p className="text-gray-600 text-sm mb-3">Field any questions or concerns to our staff at <a href="mailto:info@beautyclout.com" className="text-indigo-600 hover:text-indigo-700">info@beautyclout.com</a></p>
                </>
              }
            </Card>
          }

          {
            proofsVisible==true&&<Card title="Proofs" subtitle={`Review the links that ${gig.user.name} has submitted`} className="mb-8" unstyled={true}>
            <div className="divide-y">
              {
                order.url_proofs.map(proof => <div className="px-4 sm:px-6 py-4">
                    <a href={proof.url} target="_blank" className="text-indigo-600 text-sm block truncate">{proof.url}</a>
                  </div>)
              }
              {
                order.url_proofs.length==0&&<div className="px-4 sm:px-6 py-12">
                  <div className="text-center">
                    <h3 className="font-medium text-md text-gray-600 mb-2">No URL Proofs Provided</h3>
                    <p className="text-gray-600 text-sm">This influencer has not provided any URL links to their work.</p>
                  </div>
                </div>
              }
              </div>
            </Card>
          }

          {
            proofsVisible==true&&<Card title="Attachments" subtitle={`Review the attachments that ${gig.user.name} has submitted, if applicable`} className="mb-8" unstyled={true}>
              {order.image_proofs.length>0?<div className="sm:px-6 px-4 py-4 grid md:grid-cols-2 lg:grid-cols-3">
                {
                  order.image_proofs.map(proof => <div>
                      <a href={`https://res.cloudinary.com/wigmarket/${proof.image_id}.jpg`} target="_blank" className="block w-full"><img src={`https://res.cloudinary.com/wigmarket/h_200,c_fill/${proof.image_id}.jpg`} className="object-contain w-full h-32 sm:h-48"/></a>
                    </div>)
                }
              </div>:<div className="text-center sm:px-6 px-4 py-12">
                    <h3 className="font-medium text-md text-gray-600 mb-2">No Attachments</h3>
                    <p className="text-gray-600 text-sm">This influencer has not provided any attachments or screenshots.</p>
                  </div>}
            </Card>
          }

          {['authorized', 'production', 'cancelled', 'products_submitted', 'completed', 'verified', 'order_created'].includes(order.status)&&order.products.length>0&&<Card title="Products" unstyled={true} className="mb-8">
            <div>
            <div className="px-4 sm:px-6">
              <Tabs tabs={tabs} onClick={changeTabs}/>
              </div>
              {
                tabs.find(tab => tab.current).value=='selected'?<div className="divide-y">
                  
                    {
                      order.status=='products_submitted'&&<div className="px-4 sm:px-6 py-4"><p className="text-sm text-gray-600">{gig.user.name} selected <strong className="font-medium text-indigo-600">{selectedProducts.length} {selectedProducts.length==1?'product':'products'}</strong> to be received.</p></div>
                    }
                    {
                      ['order_created', 'production', 'completed', 'verified'].includes(order.status)&&<div className="px-4 sm:px-6 py-4"><p className="text-sm text-gray-600">You approved the following <strong className="font-medium text-indigo-600">{selectedProducts.length} {selectedProducts.length==1?'product':'products'}</strong> for {gig.user.name} to receive.</p></div>
                    }
                  {
                    selectedProducts.length>0?selectedProducts.map((product, i) => <div className="px-4 sm:px-6 py-4 flex justify-between" key={`product-${product.id}`}>
                      <div className="flex items-center">
                        {order.status=='products_submitted'&&<div className="mr-3">
                          <input type="checkbox" checked={product.selected} className="rounded-md border text-indigo-600" onChange={(e) => setProductSelected(i, e.target.checked)}/>
                        </div>}
                        <div className="relative">
                          <img src={product.image.src} className="w-12 h-12 rounded-md shadow"/>
                          
                        </div>
                        <div className="ml-3">
                          <h3 className="font-medium text-sm text-gray-600">{product.title}</h3>
                          <div className="space-x-2">
                            {product.variant.option_1!=null&&<span className="text-xs text-gray-600">{product.variant.option_1}</span>}
                            {product.variant.option_2!=null&&<span className="text-xs text-gray-600">{product.variant.option_2}</span>}
                            {product.variant.option_3!=null&&<span className="text-xs text-gray-600">{product.variant.option_3}</span>}
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className="text-sm text-gray-700 font-medium">${numeral(product.variant.price).format('0,0.00')}</span>
                      </div>
                    </div>):<div className="px-4 sm:px-6 py-20 flex justify-center items-center">
                      <div className="text-center">
                        <ShoppingBagIcon className="text-gray-500 w-10 h-10 block mx-auto mb-2"/>
                        <h3 className="font-medium mb-1">No products selected</h3>
                        <p className="text-gray-600 text-sm">{gig.user.name} did not select any products</p>
                      </div>
                    </div>
                  }
                </div>:<div>
                  {
                    products!=null&&products.data.length>0?<div>
                    <div className="px-4 py-4 sm:px-6 border-b">
                      <p className="text-sm text-gray-600 ">The following products were submitted to <strong className="font-medium text-indigo-600">{gig.user.name}</strong> for selection</p>
                    </div>
                    <div className="grid grid-cols-2 md:grid-cols-3">{
                      products.data.map(product => <div className="p-4 sm:px-6">
                        <img src={product.product.image.src} className="w-full aspect-square object-contain"/>
                        <h3 className="font-medium text-sm text-gray-700">{product.product.title}</h3>
                        <p className="text-xs">{product.variants.length} {product.variants.length>1?'variants':'variant'}</p>
                      </div>

                      )}</div>
                      <div className="px-4 sm:px-6 py-4 border-t">
                    <Pager data={products} changePage={changeProductsPage}/>
                  </div>

                      </div>:<div className="px-4 py-20">

                    </div>

                  }
                  </div>}
                  
                </div>
              
          </Card>}

          {
            order!=null&&order.shopify_order&&<Card title="Product shipment details" unstyled={true} subtitle={`Order #${order.shopify_order_number}`} className="mb-8">
              <div className="divide-y">
                <div className="px-4 sm:px-6 py-4 flex space-x-2 justify-between">
                  <div className="flex">
                    
                      <h3 className="text-md font-bold text-gray-700 p-0 m-0">#{order.shopify_order_number}</h3>
               
                    <div className="ml-3 md:ml-10">
                      <label className="font-medium text-xs text-gray-700 block">Shipping Address</label>
                      <p className="text-sm text-gray-700">{order.shopify_order.first_name} {order.shopify_order.last_name}</p>
                      <p className="text-sm text-gray-700">{order.shopify_order.address_1}</p>
                      {order.shopify_order.address_2!=null&&<p className="text-sm text-gray-700">{order.shopify_order.address_2}</p>}
                      <p className="text-sm text-gray-700">{order.shopify_order.city}, {order.shopify_order.state} {order.shopify_order.zip}</p>

                    </div>
                  </div>
                  <div>
                    <span className="text-gray-600 text-sm">{getOrderStatusName(order.shopify_order)}</span>
                  </div>
                </div>
                {
                  order.shopify_order.fulfillments.map(fulfillment => <div className="px-4 sm:px-6 py-4 grid grid-cols-3 md:grid-cols-8" key={`fulfillment-${fulfillment.id}`}>
                      <div>
                        <span className="text-xs text-gray-700 block font-medium">Carrier</span>
                        <span className="text-gray-700 text-sm block">{fulfillment.carrier}</span>
                      </div>
                      <div className="md:col-span-2">
                        <span className="text-xs text-gray-700 block font-medium">Tracking</span><a href={fulfillment.url} target="_blank" className=" truncate text-indigo-600 text-sm block">{fulfillment.tracking_number}</a>
                      </div>
                      <div className="md:col-span-5 text-right">
                        <span className="text-xs text-gray-700 block font-medium">Status</span>
                        <span className="text-gray-600 italic text-sm block">{getFulfillmentStatusName(fulfillment)}</span>
                      </div>
                    </div>
                  )
                }
              </div>

            </Card>
          }
          </div>

          


          <div> 
            {order.status=='authorized'&&<Card title="Next steps" unstyled={true} className="mb-8">
            <div className="px-4 sm:px-6 py-4">
              <p className="text-sm text-gray-600 mb-3">Nothing for you to do at this moment!</p>
              <p className="text-sm text-gray-600 mb-3">This order has been submitted, and will be reviewed by <span className="text-indigo-600">{gig.user.name}</span>.</p>
              {
                order.products.length>0?<p className="text-sm text-gray-600 mb-3">If approved, your payment will be held in escrow, and this order will be sent back to you with products that the influencer has chosen to receive. You can then approve or decline that selection.</p>:<p className="text-sm text-gray-600 mb-3">If approved, your payment will be held in escrow, and the influencer will begin work.</p>
              }
              <p className="text-sm text-gray-600 mb-3">If {gig.user.name} declines your order, or does not accept it within 7 days, your payment will not be captured.</p>
              </div>
              <div className="border-t px-4 sm:px-6 py-4">
                <Button fullWidth primary onClick={() =>  setCancelModal(true) }>Cancel Order</Button>
              </div>
            </Card>}

            {order.status=='products_submitted'&&<Card title="Next steps" unstyled={true} className="mb-8">
            <div className="px-4 sm:px-6 py-4">
              <p className="text-sm text-gray-600 mb-3">Review the products {gig.user.name} has selected.</p>
              <p className="text-sm text-gray-600 mb-3">If you approve this influencer's product selection, confirm below. An order for this {gig.user.name} will be created in your Shopify store to ship.</p>
          
              <p className="text-sm text-gray-600 mb-3">You may select / unselect products from {gig.user.name}'s selection, before approval.</p>
              </div>
              <div className="border-t px-4 sm:px-6 py-4">
                <Button fullWidth primary onClick={() => setApproveProductModal(true) }>Approve Product Selection</Button>
              </div>
            </Card>}

            {order.status=='order_created'&&<Card title="Next steps" unstyled={true} className="mb-8">
            <div className="px-4 sm:px-6 py-4">
              {order.shopify_order_number!=null&&<p className="text-sm text-gray-600 mb-3">Order #{order.shopify_order_number} has been created in your Shopify store to be shipped to {gig.user.name}.</p>}
              <p className="text-sm text-gray-600 mb-3">To proceed, fulfill this order. Beauty Clout will sync with your order's tracking info.</p>
              </div>
              <div className="border-t px-4 sm:px-6 py-4">
                <a href={`https://${order.user.store.shopify_domain}.myshopify.com/admin/orders/${order.shopify_order_id}`} target="_blank" className="disabled:opacity-50 flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-600 hover:bg-indigo-700 text-white inline-flex w-full">Go to Order #{order.shopify_order_number}</a>
              </div>
            </Card>}

            {order.status=='completed'&&<Card title="Next steps" unstyled={true} className="mb-8">
            <div className="px-4 sm:px-6 py-4">
              <p className="text-sm text-gray-600 mb-3">If <strong className="font-medium text-indigo-600">{gig.user.name}</strong> has completed their work to your satisfaction, mark this order as verified. Any payment held in escrow will be transferred to the influencer's possession.</p>
              <p className="text-sm text-gray-600 mb-3">If you would like any changes to their work, send them a message.</p>
              </div>
              <div className="border-t px-4 sm:px-6 py-4">
                <Button primary fullWidth onClick={() => setReviewModal(true)}>Verify Work + Release Funds</Button>
                <Link to={`/order/${order.id}/messages`} className="mt-3 block w-full border py-2 sm:px-6 px-4 text-gray-700 text-sm rounded-md hover:bg-gray-50 text-center">Send a Message</Link>
              </div>
            </Card>}
          </div>
          </div>
    </div>
    <Modal open={approveProductModal} setOpen={() => setApproveProductModal(false)} primary={{disabled: (!riskAcknowledged||submitting), label: 'Confirm', action: () => approveProducts()}} secondary={{label: 'Close', action: () => setApproveProductModal(false)}}>
      <div className="text-left">
      <h3 className="font-bold text-md mb-2">Confirm Product Selection?</h3>
      <p className="text-gray-600 text-sm mb-3">By confirming {gig.user.name}'s product selection, an order with this influencer's shipping address will be created in your Shopify store.</p>
      <div className="flex sm:items-center">
        <input type="checkbox" className="border-gray-400 mt-1 sm:mt-0 mr-1 rounded-md text-indigo-600" id="confirm-risk" checked={riskAcknowledged} onChange={e => setRiskAcknowledged(e.target.checked)}/>
        <label for="confirm-risk" htmlFor="confirm-risk" className="flex-1 text-gray-900 font-medium text-sm">I accept the risk of sending free products to influencers.</label>
      </div>

      </div>
    </Modal>
    <Modal open={reviewModal} setOpen={() => setReviewModal(false)} primary={{label: 'Continue', action: () => openVerifyModal()}} secondary={{label: 'Close', action: () => setReviewModal(false)}}>
      <div className="text-left">
      <h3 className="font-bold text-md mb-2">Review {gig.user.name}'s work.</h3>
      <p className="text-gray-600 text-sm mb-3 border-b pb-3">Before concluding this order, please rate &amp; review this {gig.user.name}'s work.</p>
        <label className="text-gray-700 text-xs font-medium">Rate {gig.user.name}'s work</label>
        <StarRatingSelector value={rating} onChange={setRating}/>
        <label className="text-gray-700 text-xs font-medium mt-2">Additional details</label>
        <TextArea value={review} onChange={(e) => setReview(e.target.value)} placeholder="Write a review for this influencer.." rows={3}/>
      </div>

    </Modal>
    <Modal open={cancelRequestModal} setOpen={() => setCancelRequestModal(false)} primary={{label: 'Request Cancellation', action: () => requestCancellation()}} secondary={{label: 'Back', action: () => setCancelRequestModal(false)}}>
      <div className="text-left">
      <h3 className="font-bold text-md mb-2">Request Cancellation?</h3>
        <p className="text-gray-600 text-sm mb-2">Because {gig.user.name} has completed the assigned work, you can only request cancellation.</p>
        <p className="text-gray-600 text-sm mb-3">If {gig.user.name} agrees to your request, you will be refunded any owed funds. Otherwise, a dispute will be opened for Beauty Clout staff to review.</p>
        <Select label="Cancellation reason *" options={cancellationReasons} selected={reasonCode} setSelected={setReasonCode}/>

        <TextArea className="mt-3" label="Additional details *" rows={4} placeholder="Provide any further information to help us understand the situation." helpText={'Your response will not be visible to the influencer'} value={detailsTxt} onChange={e => setDetailsTxt(e.target.value)}/>

      </div>
    </Modal>

    <Modal open={verifyModal} setOpen={() => setVerifyModal(false)} primary={{label: order.influencer_total>0?'Release Funds':'Verify Work', disabled: submitting, action: () => verifyGigOrder()}} secondary={{label: 'Back', action: () => backToReviewModal()}}>
      <div className="text-left">
      <h3 className="font-bold text-md mb-2">{order.influencer_total>0?'Verify work + release funds?':'Verify work?'}</h3>
      <p className="text-gray-600 text-sm mb-2">Performing this action will conclude this assignment. {order.influencer_total>0&&`Any payment held in escrow will be released to ${gig.user.name}.`}</p>
      <p className="text-gray-600 text-sm">Please note that this action cannot be undone. If you not satisfied with the influencer's work, please reach out to them to make modifications.</p>
      
      </div>
    </Modal>
    <Modal open={cancelModal} setOpen={() => setCancelModal(false)} primary={{label: 'Cancel Order', action: () => cancelOrder()}} secondary={{label: 'Back', action: () => setCancelModal(false)}}>
      <div className="text-left">
      <h3 className="font-bold text-md mb-2">Cancel this order?</h3>
      <p className="text-gray-600 text-sm mb-2">Are you sure you want to cancel this order? This action cannot be undone.</p>
      {order.total_fee>0&&<p className="text-gray-600 text-sm">You will be refunded an amount of <span className="font-medium text-indigo-600">${numeral(order.total_fee).format('0,0.00')}</span>.</p>}
      
      </div>
    </Modal>
    <Modal open={withdrawCancellationRequestModal} setOpen={() => setWithdrawCancellationRequestModal(false)} primary={{label: 'Withdraw Cancellation', action: () => withdrawCancellationRequest()}} secondary={{label: 'Go back', action: () => setWithdrawCancellationRequestModal(false)}}>
      <div className="text-left">
          <h3 className="font-bold text-md mb-2">Withdraw Cancellation Request?</h3>
          <p className="text-sm text-gray-600 mb-3">Please confirm that you would like to withdraw your cancellation request</p>
        </div>
    </Modal>
    <Modal open={cancelAgreeModal} setOpen={() => setCancelAgreeModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('agree')}} secondary={{label: 'Close', action: () => setCancelAgreeModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Agree to Cancel Assignment?</h2>
      <p className="text-gray-600 text-sm mb-3">By confirming, you will be cancelling this assignment. Any payment held in escrow will be refunded to you.</p>
      <p className="text-gray-600 text-sm mb-5">This action cannot be reversed.</p>
    </Modal>

    <Modal open={cancelDeclineModal} setOpen={() => setCancelDeclineModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('decline')}} secondary={{label: 'Close', action: () => setCancelDeclineModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Decline to Cancel Assignment?</h2>
      <p className="text-gray-600 text-sm mb-3">You are about to declining this brand's request to cancel this assignment</p>
      <p className="text-gray-600 text-sm mb-5">A Beauty Clout moderator may reach out to you to assist in any dispute.</p>
    </Modal>
              <ToastContainer theme="dark" autoClose={2000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/> 

  </Section>
  </>
}