import React, { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useLocation, useNavigate, useSearchParams
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChatIcon, UserIcon, PaperAirplaneIcon, CashIcon, MailIcon, DocumentTextIcon, CheckCircleIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, ArrowCircleRightIcon } from '@heroicons/react/outline'
import Section, {Card, Pager, DashboardCard} from '../components/Section';
import SidePanel from '../components/SidePanel';
import Button from '../components/Button';
import moment from 'moment';
import numeral from 'numeral';
import {useAuthRequest} from '../Auth';

function Avatar({name}) {
	const initial = name.substring(0, 1).toUpperCase();

	return <div className="bg-slate-50 flex rounded-full items-center justify-center w-12 h-12">
		<span className="uppercase text-bold text-slate-700">{initial}</span>
	</div>
}

export default function Messages() {

	const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

	const navigate = useNavigate();

	const [searchParams] = useSearchParams();

	const [threads, setThreads] = useState(null);

	const [searchQuery, setSearchQuery] = useState(searchParams.has('q')?searchParams.get('q'):'');

	const input = React.useRef(null);

	const [user, setUser] = useState(null);

	const loadThreads = async() => {

		let page = searchParams.has('page')?searchParams.get('page'):1;

		let searchQuery = searchParams.has('q')?searchParams.get('q'):'';

		const response = await fetch(`/api/v2/threads?page=${page}&q=${searchQuery}`, 'GET');

		const {threads, user,  error} = response;

		if (error) {

		}

		if (threads && user) {
			setUser(user);
			setThreads(threads);

		}

		
	}

	const changePage = (page) => {
		let url = user.is_brand==1?'/messages?':'/influencers/messages?';

		if (searchParams.has('q')) {
			url += `q=${searchParams.get('q')}&`;
		}

		url += `page=${page}`;

		navigate(url);
	}

	

	const doSearch = async(e) => {
		e.preventDefault();
		if (input.current) {
			if (user.is_brand == 1) {
				navigate(`/messages?q=${escape(input.current.value)}`);

			} else {

				navigate(`/influencers/messages?q=${escape(input.current.value)}`);
			}

		}
		return false;
	}

	React.useEffect(() => {
		loadThreads();

		if (!searchParams.has('q') && searchQuery != '') {
			setSearchQuery('');
		}

		window.scrollTo(0,0);
 
	}, [searchParams])

	if (threads == null) {
		return null;
	}

	return <Section title="Messages">
		<div className="max-w-6xl mx-5 bg-white mt-5 rounded-md shadow">
			<div className="divide-y">
			<div className="px-4 sm:px-6 py-4">
				<form action="" onSubmit={doSearch}>
				<div className="flex">
					<div className="rounded-l-md border-l border-t border-b py-2  md:px-4 px-1">
					<SearchIcon className="w-5 h-5 text-gray-400 hidden md:block"/>
					</div>
					<input value={searchQuery} onChange={e => setSearchQuery(e.target.value)} ref={input} type="text" className="focus:ring-0 focus:border-gray-200 border-l-0 border-t border-b pl-1 pr-4 py-2 flex-1 text-sm text-gray-600 border-gray-200" placeholder="Search message threads..." />
					<button className="bg-indigo-600 px-4 py-2 text-sm text-white rounded-r-md" type="submit">Search</button>
				</div>
				</form>
			</div>
			{
				threads.data.map(thread => {
					let url = thread.proposal_id!=0?`/proposal/${thread.proposal_id}/messages`:`/order/${thread.gig_order_id}/messages`;

					if (user.is_influencer == 1) {
						url =  thread.proposal_id!=0?`/influencers/proposal/${thread.proposal_id}/messages`:`/influencers/order/${thread.gig_order_id}/messages`;
					}

					return <Link to={url} key={`thread-${thread.proposal_id}-${thread.gig_order_id}`} className="hover:bg-slate-50 px-4 sm:px-6 py-4 grid md:grid-cols-2">
					<div className="flex space-x-2 items-center">
						{
							thread.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill/${thread.image_id}.jpg`} className="rounded-full w-12 h-12"/>:<Avatar name={thread.name}/>
						}
						<div className="">
							<strong className="font-medium text-gray-700 text-sm block">{thread.title}</strong>
							<span className="text-gray-600 block text-xs">{thread.name}{thread.gig_order_id!=0&&<span className="text-gray-500"> &middot; Gig Order #{thread.gig_order_id}</span>}</span>
						</div>
					</div>
					<div className="md:truncate flex items-center space-x-2">
					<div className="opacity-0 w-12 h-12 md:hidden">&nbsp;</div>
					<div className="flex-1">
						<span className="text-gray-600 text-sm md:truncate block">{thread.message_txt!=null?thread.message_txt:'...'}</span>

						{thread.sort_dt!=null&&<span className="text-xs block text-gray-500">{moment(thread.sort_dt).fromNow()}</span>}
						</div>
					</div>
					<div className="sm:text-right flex items-center justify-end">
					</div>
				</Link>})
			}
			{
				threads.total==0&&<div className="flex items-center justify-center px-4 sm:px-6 py-20">
					<div className="text-center">
						<MailIcon className="w-12 h-12 text-gray-600 block mx-auto mb-4"/>
						{
							(searchParams.has('q')&&searchParams.get('q').length>0)?<>
								<h3 className="font-medium text-gray-800">No message threads found</h3>
								<p className="text-sm text-gray-600 mt-3">Try to refine you search for better results.</p>
							</>:<>
								<h3 className="font-medium text-gray-800">This is your messages section</h3>
								{user.is_brand==1?<p className="text-sm text-gray-600 mt-3">Conversations between you and influencers will show here.</p>:<p className="text-sm text-gray-600 mt-3">Conversations between you and brands will show here.</p>}
							</>
						}
					</div>

				</div>
			}


			<div className="px-4 sm:px-6 py-4">
				<Pager data={threads} changePage={changePage}/>
			</div>
			</div>
		</div>
	</Section>
}