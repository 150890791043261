import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon,
  LinkIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, UploadIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, TrashIcon, RefreshIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {Modal, getHandle, getURL, getServiceName,getOrderStatus, PageTabs, InputField, Alert, TextArea, Select, FileDrop, Card} from '../components/Section';
import CardForm from '../components/CardForm';
import moment from 'moment';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';

export default function BrandSettings() {
    const {authenticatedFetch} = useAuthRequest();

    const authFetch = authenticatedFetch;

    const [user, setUser] = useState(null);
    const [cards, setCards] = useState(null);
    const [charge, setCharge] = useState(null);

    const [brandName, setBrandName] = useState('');
    const [saving, setSaving] = useState(false);
    const [shortBio, setShortBio] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [uploading, setUploading] = useState(false);
    const [imageId, setImageId] = useState(null);

    const [cancelModal, setCancelModal] = useState(false);

    const [deletedCard, setDeletedCard] = useState(null);

    const loadSettings = async() => {
      const response = await authFetch(`/api/user?load_charges=1&store=1`, 'GET');

      const {error, user, cards, charge} = response;

      if (error) {
        toast(error);
      } else {

        setUser(user);
        setBrandName(user.brand_name);
        if (user.short_description != null) {
          setShortBio(user.short_description);

        }

        setImageId(user.image_id);
        setCards(cards);
        setCharge(charge);
      }

    }

    const deleteCard = async() => {
      setDisabled(true);

      const body = new FormData();

      body.append('source', deletedCard.id);

      const response = await authFetch('/api/v2/deleteCard', 'POST', body);

      const {message, error, card} = response;

      if (message) {
        toast(message);
        loadSettings();
        setDeletedCard(null);
      }

      if (error) {
        toast(error);
      }

      setDisabled(false);
    }

    const addCard = async(source) => {
      const body = new FormData();

      body.append('source', source.source.id);

      const response = await authFetch('/api/v2/stripe', 'POST', body);

      const {message, error, card} = response;

      if (message) {
        toast(message);
        loadSettings();
      }

      if (error) {
        toast(error);
      }
    }

    const updateDescription = (description) => {
      if (description.length > 255) {
        return false;
      }

      setShortBio(description);
    }

    const getCharge = async(planCode) => {
      const response = await authFetch(`/api/plans/${planCode}`, 'GET');

      const {redirect_url, error, message} = response;

      if (error) {
        toast(error);
      }

      if (redirect_url) {
        window.location.replace(redirect_url);
      }
    }

    const uploadImage = async(e) => {
      const file = e.target.files[0];

      setUploading(true);
      
      const body = new FormData();

      body.append('file', file);

      const response = await authFetch('/api/dropzone', 'POST', body);

      const {error, id} = response;

      if (error) {
        toast(error);
      }

      if (id) {
         setImageId(id);
      }

      setUploading(false);
    }

    const cancelSubscription = async() => {
      const response = await authFetch(`/api/v2/cancelSubscription`, 'POST');

      const { error, message} = response;

      setCancelModal(false);

      if (error) {
        toast(error);
      }

      if (message) {
        toast(message);
        loadSettings();
      }

    }

    const saveProfile = async() => {
      const body = new FormData();

      if (imageId != null) {
        body.append('image_id', imageId);
      }
      body.append('short_description', shortBio);

      body.append('brand_name', brandName);

      setSaving(true);

      const response = await authFetch(`/api/v2/brands/profile`, 'POST', body);



      const { user, message, error } = response;

      if (message) {
        toast(message);
      }

      if (error) {
        toast(error);
      }

      if (user) {
        setImageId(user.image_id);
        setShortBio(user.short_description);
        setBrandName(user.brand_name);
      }

      setSaving(false);
    }

    useEffect(() => {
      loadSettings();
    }, []);

    return <Section title="Settings">
        <div className="max-w-6xl mx-5 my-8">
        {
          user!=null&&user.downgrade!=null&&<div className="mb-8"><Alert status="info" title="Plan change pending">
            {
              user.downgrade.new_plan_code=='none'?<p>You have opted to terminate your Beauty Clout subscription. You may still use your current plan until {moment(user.downgrade.execute_at).format('MMMM Do, YYYY')}.</p>:<p>
                You have opted to downgrade your Beauty Clout subscription. You may still use your current plan until {moment(user.downgrade.execute_at).format('MMMM Do, YYYY')}, at which you will be downgrade to a basic plan.
              </p>
            }
          </Alert></div>
        }

        {
          user!=null&&user.plan_code=='basic'&&charge==null&&<div className="mb-8"><Alert status="info" title="Notice about your basic plan">
            <p>Beauty Clout Basic was free prior to January 3rd, 2023. If you were on our free basic plan, you may continue to use it.</p>
          </Alert>
          </div>
        }

        {
          user!=null&&user.plan_code=='pro'&&charge!=null&&(parseFloat(charge.price) < 29.99)&&<div className="mb-8"><Alert status="info" title="Notice about your legacy pro plan">
            <p>You are on a legacy pro plan for ${charge.price}. You may continue to use this plan at the same rate.</p>
          </Alert>
          </div>
        }

        <Card title="Plans" unstyled={true} className="mb-5 sm:mb-8">
          <div className="grid sm:grid-cols-2 gap-3 sm:divide-x">
            <div className="p-10">
              <h3 className="font-medium text-gray-900 text-lg flex items-center justify-between">Basic {user!=null&&user.plan_code=='basic'&&<span className="py-1 px-2 rounded-lg text-xs bg-indigo-600 text-white ml-1">CURRENT PLAN</span>}</h3>
              <p className="text-gray-600 text-sm">Good for low volume hiring</p>
              <h1 className="text-3xl mt-2 font-extrabold text-black">$2.99<span className="text-sm ml-1 text-gray-600 font-medium">/ mo</span></h1>
              <button disabled={user==null||(user.plan_code=='basic'&&user.downgrade==null)} onClick={() => getCharge('basic')} className="hover:bg-slate-900 disabled:opacity-50 rounded-lg bg-slate-800 mt-3 text-white w-full block py-2 text-sm">Sign up</button>
              <label className="uppercase text-gray-600 text-xs font-medium block mt-3">WHAT'S INCLUDED</label>
              <div className="text-sm text-gray-600">
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">14 day trial for new users</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">Unlimited hires</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">Unlimited campaigns</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-gray-400 w-5 h-5 inline mr-5"/><span className="">10% hiring fee</span>
                </div>
              </div>
            </div>
            <div className="p-10 border-t sm:border-t-0">
              <h3 className="font-medium text-gray-900 text-lg flex items-center justify-between">Pro {user!=null&&user.plan_code=='pro'&&<span className="py-1 px-2 rounded-lg text-xs bg-indigo-600 text-white ml-1">CURRENT PLAN</span>}</h3>
              <p className="text-gray-600 text-sm">No hiring fees, all features</p>
              <h1 className="text-3xl mt-2 font-extrabold text-black">$29.99<span className="text-sm ml-1 text-gray-600 font-medium">/ mo</span></h1>
              <button disabled={user==null||(user.plan_code=='pro'&&user.downgrade==null)} onClick={() => getCharge('pro')} className="hover:bg-slate-900  disabled:opacity-50 rounded-lg bg-slate-800 mt-3 text-white w-full block py-2 text-sm">Sign up</button>
              <label className="uppercase text-gray-600 text-xs font-medium block mt-3">WHAT'S INCLUDED</label>
              <div className="text-sm text-gray-600">
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">14 day trial for new users</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">Unlimited hires</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">Unlimited campaigns</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">No hiring fee</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">Affiliate tracking</span>
                </div>
                <div className="mt-3 flex items-center">
                  <CheckIcon className="text-green-600 w-5 h-5 inline mr-5"/><span className="">Create campaigns that pay influencers with products</span>
                </div>
              </div>
            </div>
          </div>
          {user!=null&&user.plan_code!=null&&<div className="p-10 border-t">
            <p className="text-gray-600 text-sm">Cancel your membership anytime. If you have any active campaigns, you can continue to work with hired influencers, but you will no longer be able to create new campaigns, hire, nor invite new influencers. <button className="bg-transparent text-red-600" onClick={() => setCancelModal(true)}>Cancel subscription</button></p>
          </div>}
        </Card>
        <Card title="Cards" unstyled={true} subtitle="Add payment methods to be used when hiring influencers for your campaigns." className="mb-8">
          <div className="divide-y">
          <div className="px-4 sm:px-10 pb-6 pt-3">
              <CardForm onSuccess={addCard} onError={(error) => toast(error)} showLabel={false}/>
            </div>
          {
            cards!=null&&cards.map(card => <div className="px-4 sm:px-10 py-6 flex justify-between items-center" key={`card-${card.id}`}>
              <div><span className="block font-medium text-sm text-gray-600">{card.card.brand} ending in {card.card.last4}</span>
                <span className="block text-xs text-gray-500">Expires {card.card.exp_month} / {card.card.exp_year}</span>
              </div>
              <button onClick={() => setDeletedCard(card)} className="border-red-600 border rounded-md text-sm px-3 py-2 text-red-600"><TrashIcon className="w-5 h-5" /></button>
            </div>)
          }
            
          </div>
        </Card>

        <Card title="Profile" subtitle="Manage your profile and brand identity">
          <div className="grid sm:grid-cols-3 md:grid-cols-4 sm:gap-3">
            <div className="col-span-1 mb-5 sm:mb-0">
              <div className="relative">
                {imageId!=null?<img src={`https://res.cloudinary.com/wigmarket/w_500,h_500,c_fill,q_auto/${imageId}.jpg`} className="object-contain w-full rounded-lg"/>:<div className="w-full uppercase aspect-square bg-slate-100 rounded-lg"></div>}
                <input type="file" accept="image/*" onChange={e => uploadImage(e)} className="hidden" id="profile-image-upload" disabled={uploading}/>
                <label htmlFor="profile-image-upload" for="profile-image-upload" className="bottom-1 right-1 shadow bg-slate-50 w-10 h-10 flex items-center justify-center absolute rounded-full">{uploading?<RefreshIcon className="w-5 h-5 animate-spin"/>:<UploadIcon className="w-5 h-5"/>}</label>
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3">
              <InputField label="Brand / Company Name" value={brandName} onChange={e => setBrandName(e.target.value)} className="mb-3"/>
              <TextArea rows="4" placeholder="Describe your brand for influencers interested in working with you" helpText={`${(255-shortBio.length)} characters remaining`} label="Short Description" value={shortBio} onChange={(e) => updateDescription(e.target.value)}/>
              
            </div>
          </div>
          <div className="mt-3">
            <button disabled={user==null||saving==true} className="w-full bg-slate-800 disabled:opacity-50 hover:bg-slate-900 rounded-lg px-5 py-2 text-white text-sm mt-3" onClick={() => saveProfile()}>Save Profile</button>
          </div>
        </Card>

        </div>
        {deletedCard!=null&&<Modal open={(deletedCard!=null)} setOpen={() => setDeletedCard(null)} primary={{label: 'Remove Card', disabled: disabled, action: () => deleteCard() }} secondary={{label: 'Close', action: () => setDeletedCard(null)}}>
           <h3 className="font-bold mb-2">Remove card ending in {deletedCard.card.last4}?</h3>
          <p className="text-gray-600 text-sm">This action will permanently remove this card from your saved payment methods.</p>
          
        </Modal>}
        <Modal open={cancelModal} setOpen={() => setCancelModal(true)} primary={{label:'Cancel Subscription', action: () => cancelSubscription()}} secondary={{label: 'Nevermind', action: () => setCancelModal(false)}}>
          <h3 className="font-bold mb-2">We're sorry to see you go 😔</h3>
          <p className="text-gray-600 text-sm">We hope you got what you needed from Beauty Clout.</p>
          <p className="text-gray-600 text-sm">Please confirm that you would like to cancel your subscription.</p>
        </Modal>
        <ToastContainer theme="dark" autoClose={2000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/>
    </Section>
}