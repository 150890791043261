import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Menu, Transition, Switch, Listbox } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  XIcon,
  QuestionMarkCircleIcon,
  StarIcon,
  ChevronLeftIcon,
  PlusCircleIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import Slider from "react-slick";
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, RefreshIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {Pager, Steps, Card, SearchComboBox, Tabs, InputField, Alert, TextArea, Select, FileDrop, getURL, getHandle, getServiceName, Modal} from '../components/Section';
import moment from 'moment';
import SidePanel from '../components/SidePanel';
import "react-datepicker/dist/react-datepicker.css";
import UserInvite from '../components/UserInvite';
import { useAuthRequest } from '../Auth';
import numeral from 'numeral';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function getProposalStatus(invite) {

  const classNames = "rounded-full py-1 px-3 font-medium text-xs";

  if (invite.deleted_at != null) {
    return <span className={`${classNames} bg-gray-200 text-gray-600`}>Cancelled</span>;
  }

  if (invite.proposal_status) {
    switch(invite.proposal_status) {
      case 'proposed':
        return <span className={`${classNames} bg-cyan-600 text-white`}>Proposal Submitted</span>;
      case 'production':
        return <span className={`${classNames} bg-green-600 text-white`}>Hired</span>;
      case 'completed':
        return <span className={`${classNames} bg-orange-500 text-white`}>Awaiting Verification</span>;
      case 'verified':
        return <span className={`${classNames} bg-yellow-500 text-white`}>Work Verified</span>;
      case 'cancelled':
        return <span className={`${classNames} bg-gray-200 text-gray-600`}>Cancelled</span>;
    }
  } else {
    switch(invite.status) {
      case 'accepted':
        return <span className={`${classNames} bg-cyan-600 text-white`}>Invitation Accepted</span>;
      case 'declined':
        return <span className={`${classNames} bg-gray-200 text-gray-600`}>Declined</span>;
      case 'pending':
        return <span className={`${classNames} bg-orange-400 text-white`}>Awaiting Your Response!</span>;
      
    }
  }
}

export default function InfluencerInvites() {

  const navigate = useNavigate();

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const { status } = useParams();

  const [invitations, setInvitations] = useState(null);

  const [user, setUser] = useState(null);

  const [showExplainer, setShowExplainer] = useState(false);

  const sliderRef = useRef();

  const [slideIndex, setSlideIndex] = useState(0);

  const [tabs, setTabs] = useState([
      {name: 'Invited', url: '/influencers/campaigns/pending', current: true, count: 0},
      {name: 'Hired', url: '/influencers/campaigns/hired', current: true, count: 0},
      {name: 'Archive', url: '/influencers/campaigns/archive', current: true, count: 0},
      {name: 'Accepted', url: '/influencers/campaigns/accepted', current: true, count: 0},
      {name: 'Declined', url: '/influencers/campaigns/rejected', current: false, count: 0},
    ]);

  const changePage = (page) => {
    loadInvitations(page);
  }


  const loadInvitations = async(page=1) => {

    const response = await authFetch(`/api/v2/invitations?status=${status}&page=${page}`, 'GET');

    const { invitations, user } = response;

    if (invitations) {
      setInvitations(invitations);
      setUser(user);
      if (user.show_campaign_explainer == true) {
        setShowExplainer(true);
      }
    }
  }

  const nextSlide = () => {

    if (slideIndex >= 3) {
      setShowExplainer(false);

      let body = new FormData();

      body.append('value', '1');

      authFetch(`/api/v2/userSettings/campaigns`, 'POST', body);

    } else if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  }

  useEffect(() => {

    const _tabs = tabs.map(tab => {

      if (tab.url == '/influencers/campaigns/'+status) {
        return {...tab, current: true};
      }

      return {...tab, current: false};
    });

    setTabs(_tabs);

    loadInvitations();


  }, [status]);


  return <Section title="Campaign invitations">
  <div className="mx-5 mb-8 mt-2">
      <p className="text-sm text-gray-600">Brands will send invite you to bid on their campaigns. <button className="text-indigo-600 hover:text-indigo-700" onClick={() => {setShowExplainer(true); setSlideIndex(0); sliderRef.current.slickGoTo(0); }}>Learn more.</button></p>
      {
        user!=null&&user.stripe_account_id==null&&<div className="bg-orange-100 px-4 sm:px-6 py-3 mt-3 rounded-md md:flex justify-between mb-5">
          <div className="md:flex items-center">
          <ExclamationCircleIcon className="text-orange-900 mb-3 md:mb-0 w-10 h-10 md:w-5 md:h-5 block mx-auto md:mr-2"/>
          <p className="md:flex-1 text-orange-900 text-sm text-center md:text-left">You must create a Stripe Express account in order to accept invitations from brands.</p>
          </div>
          <Link to={`/influencers/stripe`} className="w-full block md:w-auto mt-3 md:mt-0 text-center border bg-indigo-600 text-white text-sm py-2 px-4 shadow font-medium border-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700">Connect Stripe</Link>
        </div>
      }
  </div>
    <div className="px-5 mx-5 bg-white rounded-md shadow mt-5">
    <Tabs tabs={tabs} navigate={navigate}/>
      {
        invitations!=null&&invitations.total>0&&<><ul role="list" className="mt-5 border-t border-gray-200 divide-y divide-gray-200 sm:mt-0 sm:border-t-0">
              {invitations.data.map((invite) => (
                <li key={`invite-${invite.id}`}>
                  <Link to={`/influencers/campaign/${invite.campaign_id}`} className="group block">
                    <div className="flex items-center py-5 px-4 sm:py-6 sm:px-0">
                      <div className="min-w-0 flex-1 flex items-center">
                        <div className="flex-shrink-0">
                          <img
                            className="h-12 w-12 rounded-full group-hover:opacity-75"
                            src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${invite.campaign.image_id}.jpg`}
                            alt=""
                          />
                        </div>
                        <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                          <div>
                            <p className="text-sm font-medium text-purple-600 truncate">{invite.campaign.title}</p>
                            <p className="mt-1 flex items-center text-sm text-gray-500">
                              <span className="truncate">{invite.campaign.user.brand_name}</span>
                            </p>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              {getProposalStatus(invite)}
                            </div>
                          </div>
                          <div className="hidden md:block">
                            <div>
                              {
                                invite.campaign.max_price>0?<p className="text-sm text-gray-700 font-medium">${numeral(invite.campaign.min_price).format('0,0')}-{numeral(invite.campaign.max_price).format('0,0')}</p>:<p className="text-sm text-gray-700 font-medium">{numeral(invite.campaign.max_price).format('$0,0')}</p>}
                              <p className="text-xs mt-1 text-gray-500">
                                Sent <time>{moment(invite.created_at).fromNow()}</time>
                              </p>
                              
                            </div>
                          </div>

                        </div>
                      </div>
                      <div>
                        <ChevronRightIcon
                          className="h-5 w-5 text-gray-400 group-hover:text-gray-700"
                          aria-hidden="true"
                        />
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>
            <div className="py-5">
            <Pager data={invitations} changePage={changePage}/>
            </div>
            </>
      }
      {
        invitations!=null&&invitations.total==0&&<div className="text-center px-5 py-20">
          <InboxIcon className="w-20 h-20 text-gray-500 block mx-auto"/>
          <h2 className="mt-2 text-lg font-medium text-gray-900">No {tabs.find(tab => tab.current).name} Campaigns Found</h2>
          <p className="mt-1 text-sm text-gray-500">
            We could not find any campaign invitations under this category.
          </p>
        </div>
      }
      <Modal open={showExplainer} setOpen={() => {return false;}} primary={{label: slideIndex>=3?'Got it':'Next', action: () => nextSlide() }}>
        <h3 className="font-bold text-md text-gray-900 mb-3 text-center">Learn About Beauty Clout Campaigns</h3>
        <Slider dots={true} ref={sliderRef} beforeChange={(current, next) => setSlideIndex(next)} infinite={false}>
          <div>
            <img src="https://res.cloudinary.com/wigmarket/image/upload/v1665003284/beauty-clout/invited-screenshot.png" className="w-full border rounded-md aspect-square mb-3"/>
            <p className="text-sm text-gray-700 text-center">Brands will invite influencers to bid on their <strong>campaigns</strong>.</p>
          </div>
          <div>
            <img src="https://res.cloudinary.com/wigmarket/image/upload/v1665003231/beauty-clout/invite-screenshot.png" className="w-full border rounded-md aspect-square mb-3"/>
            <p className="text-sm text-gray-600 text-center">New invitations appear in the <strong>Invited tab</strong>.</p>
          </div>
          <div>
            <img src="https://res.cloudinary.com/wigmarket/image/upload/v1665003064/beauty-clout/proposal-screenshot.png" className="w-full border rounded-md aspect-square mb-3"/>
            <p className="text-sm text-gray-600">Once invited to a campaign, you may <strong>submit a proposal</strong> detailing your services and fees.</p>
          </div>
          <div>
            <img src="https://res.cloudinary.com/wigmarket/image/upload/v1665003751/beauty-clout/recent-activity.png" className="w-full border rounded-md aspect-square mb-3"/>
            <p className="text-sm text-gray-600"><strong>Payment is held in escrow</strong>, until you complete the work. Brands will have a chance to review your work before payment is released.</p>
          </div>
        </Slider>
        <div className="mb-4">
          &nbsp;
        </div>
      </Modal>
    </div>
  </Section>
}