import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  XCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, ClipboardIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, ShoppingBagIcon, ExclamationCircleIcon} from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, TextArea, Select, Modal, FileDrop, MenuButton} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';
import blogIcon from '../images/blog.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statuses = [
  {name: 'Invited', status: 'pending'},
  {name: 'Accepted', status: 'accepted'},
  {name: 'Declined', status: 'rejected'},
  {name: 'Not Invited', status: 'legacy'}
];

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: blogIcon}
  };

const cancellationReasons = [
  { name: '-- Select a reason', value: ''},
  { name: 'Changed mind', value: 'change_mind'},
  { name: 'Brand is not responsive', value: 'unresponsive'},
  { name: 'Unable to complete assigned work', value: 'unable'},
  { name: 'Fraud / Brand is not who they say they are', value: 'fraud'},
  { name: 'Other', value: 'other'}
];

const estimates = [
    {id: -1, name: '-- Select'},
    {id: 0, name: '< 1 week'},
    {id: 1, name: '1 week'},
    {id: 2, name: '2 weeks'},
    {id: 3, name: '3 weeks'},
  ];

var searchTimeoutId = null;

const disputeResults = [
  {name: 'Cancelled / Refund Issued', value: 'completed'},
  {name: 'Cancellation Request Declined', value: 'declined'},
  {name: 'Other', value: 'other'},
  {name: 'Still Pending', value: 'pending'}
];



function InfluencerProposal({cookies}) {

  /* Provider hooks */

  const {id} = useParams();

  const defaultTabs = [
      {name: 'Details', current: true, url: `/influencers/campaign/${id}`},
      {name: 'Messages', current: false, count: 0,  url: `/influencers/campaign/${id}/messages`}
  ];

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const navigate = useNavigate();

  const preview = (document.location.pathname==`/campaign/${id}/preview`);

  /* State variables */

  const [invite, setInvite] = useState(null);

  const [proposalTxt, setProposalTxt] = useState('');

  const [tabs, setTabs] = useState(defaultTabs);

  const [proposal, setProposal] = useState(null);

  const [campaign, setCampaign] = useState(null);

  const [user, setUser] = useState(null);

  const [services, setServices] = useState([]);

  const [error, setError] = useState(null);

  const [products, setProducts] = useState(null);

  const [expirationDate, setExpirationDate] = useState(null);

  const [detailsTxt, setDetailsTxt] = useState('');

  const [reasonCode, setReasonCode] = useState(cancellationReasons[0]);

  const [cancellationRequestModal, setCancellationRequestModal] = useState(false);

  const [page, setPage]= useState(1);

  const [query, setQuery] = useState('');

  const [cart, setCart] = useState([]);

  const [cartVariantIds, setCartVariantIds] = useState([]);

  const [proofs, setProofs] = useState([]);

  const [uploading, setUploading] = useState(false);

  const [uploadError, setUploadError] = useState(null);

  const [attachments, setAttachments] = useState([]);

  const [withdrawModal, setWithdrawModal] = useState(false);

  const [withdrawCancellationRequestModal, setWithdrawCancellationRequestModal] = useState(false);

  const [cancelAgreeModal, setCancelAgreeModal] = useState(false);

  const [cancelDeclineModal, setCancelDeclineModal] = useState(false);

  const [couponCode, setCouponCode] = useState('');

  const couponRef = useRef();

  const affiliateRef = useRef();

  /* Serverside functions */

  const loadCampaign = async() => {
    const response = await authFetch(`/api/v2/campaigns/${id}?products=1&proposal=1`, 'GET');

    const {error, campaign, products, invite, user, proposal} = response;

    if (proposal) {
      setProposal(proposal);    
    }

    if (campaign && user) {

      setUser(user);

      var _services = campaign.services.map(service => {
        let proposalService = false;
        let fee = '';
        let couponCode = '';

        if (proposal) {
          proposalService = proposal.services.find(s => s.service==service.service);
          if (proposal.coupon_code != null) {
            couponCode = proposal.coupon_code;
          }
        } else if (campaign.affiliate_program && campaign.affiliate_program.coupon == 1) {
          let firstName = user.first_name!=null?user.first_name:(user.name.split(' ')[0]);
          let number = campaign.affiliate_program.discount_type=='percentage'?campaign.affiliate_program.discount_percentage:Math.floor(campaign.affiliate_program.discount_value);
          couponCode = `${firstName}${number}`;

          couponCode = couponCode.toUpperCase();

          setCouponCode(couponCode);
        }

        if (proposalService) {
          var completion_weeks = estimates.find(estimate => estimate.id == proposalService.completion_weeks);
          fee = proposalService.fee;
          if (!completion_weeks) {
            completion_weeks = {id: -1, name: '-- Select'};
          }

          return {...service, selected: proposalService.hired, fee: fee, completion_weeks: completion_weeks};
        } else if (campaign.max_price == 0) {
          fee = '0.00';
        }


        return {...service, selected: true, fee: fee, completion_weeks: {id: -1, name: '-- Select'}};
      });
      setServices(_services);

      setCampaign(campaign);

    }



    if (products) {
      setProducts(products);
    }

    if (invite) {
      setInvite(invite);
    }

    if (proposal) {
        var _cart = [...cart];
        setProposalTxt(proposal.proposal_txt);
        
        proposal.products.forEach(product => {
          var _product = products.data.find(p => p.id == product.product_id);

          //console.log(_product);

          if (_product) {
            var _variant = _product.options.find(_v => _v.id == product.variant_id);
            var productToAdd = {..._product, selected: _variant};
            cart.push(productToAdd);
            cartVariantIds.push(product.variant_id);
          }

        });
        setCart(_cart);

        if (proposal.proofs.length > 0) {

          var _proofs = [];

          proposal.proofs.forEach(proof => {
            _proofs.push({
              id: proof.id,
              edit: false,
              url: proof.url,
              savedURL: proof.url,
              image_id: null
            });
          });

          setProofs(_proofs);
        }

        if (proposal.attachments.length > 0) {

          var _attachments = [];

          proposal.attachments.forEach(attachment => {
            _attachments.push(attachment.image_id);
          });

          setAttachments(_attachments);
        }

        /* Set tab URL and message count*/

        var _tabs = [...tabs];
        _tabs[1].url = '/influencers/proposal/'+proposal.id+'/messages';
        _tabs[1].count = proposal.unread.length;
        setTabs(_tabs);
    }
  }

  const submitForReview = async() => {
    const response = await authFetch(`/api/v2/campaigns/${id}/submit`, 'POST');

    const {error, message, redirect_url } = response;

    if (error) {
      alert(error);
    }

    if (redirect_url) {
      navigate(redirect_url);
    }
  }

  const loadProducts = async() => {
    const response = await fetch(`/api/v2/campaigns/${id}/products?page=${page}&q=${query}`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${cookies.get('token')}`
      }
    }).then(resp => {
      if (resp.status == 500) {

      }
      return resp.json();
    });

    const {error, products} = response;

    if (products) {
      setProducts(products);
    }

  };

  const respondToCancellationRequest = async(agree) => {

    const body = new FormData();

    body.append('agree', agree);

    const response = await authFetch(`/api/v2/proposals/${proposal.id}/cancellationRequest`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      loadCampaign();
      setCancelAgreeModal(false);
      setCancelDeclineModal(false);
      window.scrollTo(0,0);
    }

    if (error) {
      toast(error);
    }
  }

  /* Client side functions */


  const copyText = (ref) => {
    console.log(ref.current);

    ref.current.select();
    ref.current.setSelectionRange(0,9999);

    navigator.clipboard.writeText(ref.current.value);

    toast('Copied to clipboard!');
  }

  const changeVariant = (i, variant) => {
    console.log(products);
    const _products = JSON.parse(JSON.stringify(products));

    _products.data[i].selected = variant;

    setProducts(_products);
  }

  const searchProducts = (value) => {
    if (searchTimeoutId != null) {
      clearTimeout(searchTimeoutId);
    }

    searchTimeoutId = setTimeout(() => {
      setQuery(value);
    }, 600);
  }

  const toggleSocialMedia = ( serviceType, selected) => {
    const _services = services.map(service => {
      if (service.service == serviceType) {

        return {...service, selected: selected};
      }

      return {...service};
    })

    setServices(_services);
  }

  const setFee = (serviceType, fee) => {
    const _services = services.map(service => {
      if (service.service == serviceType) {

        return {...service, fee: parseFloat(fee)};
      }

      return {...service};
    })

    setServices(_services);
  };

  const setEstimate = (serviceType, estimate) => {
    const _services = services.map(service => {
      if (service.service == serviceType) {

        return {...service, completion_weeks: estimate};
      }

      return {...service};
    })

    setServices(_services);
  }

  const withdrawProposal = async() => {
    const response = await authFetch(`/api/v2/proposals/${proposal.id}/withdraw`, 'POST');

    const {message, error } = response;

    if (message) {
      toast(message);
      setWithdrawModal(false);
      loadCampaign();
    }

    if (error) {
      toast(error);
    }
  }

  const requestCancellation = async() => {

    const body = new FormData();

    body.append('details_txt', detailsTxt);

    body.append('reason_code', reasonCode.value);

    const response = await authFetch(`/api/v2/proposals/${proposal.id}/requestCancellation`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      setCancellationRequestModal(false);
      loadCampaign();
    }

    if (error) {
      toast(error);
    }
  }

  const addVariant = (product) => {
    var product = JSON.parse(JSON.stringify(product));
    const _cart = [...cart];

    var found = false;
    var total = 0;
    var _cartVariantIds = [];


    _cart.forEach(item => {
      if (item.id == product.selected.id) {
        found = true;
      }
      total += item.selected.price;
      _cartVariantIds.push(item.selected.id);
    });

    if (found) {
      return false;
    }

    if (campaign.limit_type == 'total' &&  total+product.selected.price > campaign.limit_total) {
      toast('Cannot add. You are over your spend limit.');
      return false;
    } else if (campaign.limit_type == 'quantity' && (_cart.length+1) > campaign.limit_quantity) {
      toast('Cannot add. You can choose a maximum of '+campaign.limit_quantity+' products.');
      return false;
    }

    _cart.push(product);
    _cartVariantIds.push(product.selected.id);

    setCart(_cart);
    setCartVariantIds(_cartVariantIds);
    toast('Added to bag');
  }

  const submitProposal = async() => {
    setError(null);

    const formData = new FormData();

    cartVariantIds.forEach(variantId => {
      formData.append('variant_id[]', variantId);
    })

    services.forEach(service => {
      var userHasService = user.services.find(_service => (service.service == _service.service));
 

      if (userHasService && service.selected) {
        formData.append('service['+service.service+'][fee]', service.fee);
        formData.append('service['+service.service+'][completion_weeks]', service.completion_weeks.id);
      }
    })

    formData.append('proposal', proposalTxt);

    if (campaign.affiliate_program && campaign.affiliate_program.coupon == 1) {
      formData.append('coupon_code', couponCode);
    }

    const response = await authFetch(`/api/v2/campaigns/${id}/proposal`, 'POST', formData);

    const { error, message } = response;

    if (error) {
      setError(error);
    }

    if (message) {
      toast(message);
      loadCampaign();
      window.scrollTo(0, 0);

    }

  }

  const declineInvitation = async() => {
    const response = await authFetch(`/api/v2/invites/${invite.id}/decline`, 'POST');

    const {error, message} = response;

    if (error) {

      toast(error);
    }

    if (message) {
      toast(message);
      setInvite(response.invite);

      window.scrollTo(0, 0);
    }

  }

  const undeclineInvitation = async() => {
    const response = await authFetch(`/api/v2/invites/${invite.id}/undecline`, 'POST');

    const {error, message} = response;

    if (error) {

      toast(error);
    }

    if (message) {
      toast(message);
      setInvite(response.invite);
      
      window.scrollTo(0, 0);
    }

  }

  const addNewProof = () => {
    const proof = {
      id: moment().unix(),
      edit: true,
      url: '',
      savedURL: '',
      image_id: null
    };

    const _proofs = [...proofs];

    _proofs.push(proof);

    setProofs(_proofs);
  }

  const setProofURL = (proofId, url) => {
    const _proofs = proofs.map(proof => {
      if (proof.id == proofId) {
        return {...proof, url: url};
      }

      return proof;
    })

    setProofs(_proofs);
  }

  const saveProof = (id) => {
    var regex = new RegExp("^(http[s]?:\\/\\/(www\\.)?|ftp:\\/\\/(www\\.)?|www\\.){1}([0-9A-Za-z-\\.@:%_\+~#=]+)+((\\.[a-zA-Z]{2,3})+)(/(.)*)?(\\?(.)*)?");

    const _proofs = proofs.map(proof => {
      if (proof.id == id) {

        if (!regex.test(proof.url)) {
          toast('Please enter or paste a valid URL');
          return proof;
        }


        return {...proof, savedURL: proof.url, edit: false};
      }

      return proof;
    });

    setProofs(_proofs);
  }

  const toggleProofEditable = (i) => {

    console.log(proofs);

    if (proofs[i].savedURL == '') {
      //alert('test');
      deleteProof(i);
      return false;
    }


    const _proofs = proofs.map((proof, index) => {
      if (i == index) {
        return {...proof, url: proof.savedURL, edit: !proof.edit};
      }

      return proof;
    });

    setProofs(_proofs);
  }

  const deleteProof = (index) => {
    const _proofs = [...proofs];

    _proofs.splice(index, 1);

    setProofs(_proofs);
  }

  const uploadImages = async(files) => {

    const _attachments = JSON.parse(JSON.stringify(attachments));

    setUploadError(false);
    setUploading(true);
    
    const body = new FormData();

    files.forEach(file => {
      body.append('images[]', file);
    });

    const response = await authFetch('/api/image', 'POST', body);

    const {error, images} = response;

    if (error) {
      setUploadError(error);
    }

    if (images) {

      images.forEach(image => {
        _attachments.push(image.id);
      });

      setAttachments(_attachments);
    }

    setUploading(false);
  }

  const deleteImage = (index) => {
    const _imageIds = [...attachments];

    _imageIds.splice(index, 1);

    setAttachments(_imageIds);
  }

  const saveProofs = async() => {
    const formData = new FormData();

    attachments.forEach(imageId => {
      formData.append('image_id[]', imageId);
    });

    proofs.forEach(proof => {
      formData.append('url[]', proof.savedURL);
    });

    const response = await authFetch(`/api/v2/proposals/${proposal.id}/proofs`, 'POST', formData);

    const { message, error } = response;

    if (message) {
      toast(message);
      return true;
    } 

    if (error) {
      toast(error);
    }
    return false;
  }

  const markAsComplete = async() => {

    saveProofs();

    const response = await authFetch(`/api/v2/proposals/${proposal.id}/completed`, 'POST');

    const { message, error } = response;

    if (message) {
      toast(message);
      loadCampaign();

      window.scrollTo(0, 0);
    }

    if (error) {
      toast(error);
    }
  }

  const removeProductFromCart = (index) => {
    const _cart = [...cart];
    const _product = _cart[index];
    const _cartVariantIds = [...cartVariantIds];
    const _variantId = _product.selected.id;

    if (_cartVariantIds.indexOf(_variantId) >= 0) {
      _cartVariantIds.splice(_cartVariantIds.indexOf(_variantId), 1);
    }

    _cart.splice(index, 1);

    setCart(_cart);
    setCartVariantIds(_cartVariantIds);


  }

  const withdrawCancellationRequest = async() => {
    const response = await authFetch(`/api/v2/proposals/${proposal.id}/withdrawCancellationRequest`, 'POST');

    const {message, error} = response;

    if (message) {
      toast(message);
      loadCampaign();
      setWithdrawCancellationRequestModal(false);
    }

    if (error) {
      toast(error);
    }
  }

  useEffect(() => {
    setCampaign(null);
    setProposal(null);
    loadCampaign();
    window.scrollTo(0, 0);
  }, [id]);

  useEffect(() => {
    if (campaign != null) {
      loadProducts();

    }
  }, [page, query]);


  if (campaign == null ) {
    return <Section title="Loading..."/>
  }

  const html = campaign.description.replaceAll("\n", '<br/>');

  const productHtml = campaign.product_description!=null?campaign.product_description.replaceAll("\n", '<br/>'):'';

  var cartTotal = 0;
  
  if (campaign.limit_type == 'total') {
    cart.forEach(item => {
      cartTotal += item.selected.price;
    })
  }

  const budgetWarning = (campaign!=null&&(services.reduce((prev, current) => {
    
    if (current.fee == '' || Object.is(NaN, current.fee)) {
      return prev;
    }
    return (prev+current.fee)
  }, 0)) > campaign.max_price);


  const canEdit = (proposal==null);

  const proposalActive = (proposal!=null&&['proposed', 'production', 'change', 'completed', 'verified'].indexOf(proposal.status) >= 0);

  const showProofsSection = (proposal!=null&&['production', 'change', 'completed', 'verified'].indexOf(proposal.status) >= 0);

  const canEditProofs = (proposal!=null&&['production', 'change'].indexOf(proposal.status) >= 0);

  const completedBtnLabel = (proposal!=null&&proposal.status=='production')?'I have completed the assigned work':'I have completed the necessary changes';

  return <main className="pt-5 pb-32 bg-slate-50">
      
          {/* Page header */}
          <div className="max-w-3xl px-4 sm:px-6 md:flex  md:justify-between md:space-x-5 lg:max-w-6xl">

            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    className="h-32 w-32 rounded-md"
                    src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${campaign.image_id}.jpg`}
                    alt=""
                  />
                  <span className="absolute inset-0 shadow-inner rounded-md" aria-hidden="true" />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">{campaign.title}</h1>
                <p className="text-sm font-medium text-gray-500">
                  Posted by{' '}
                  <span className="text-gray-900">
                    {campaign.user.brand_name}
                  </span>{' '}
                </p>
              </div>
              
            </div>

            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
              
              
            </div>
            <div className="">
                {proposal!=null&&proposal.allow_withdrawal==true&&<MenuButton options={[{
                  title: 'Withdraw Proposal',
                  action: () => setWithdrawModal(true)
                }]} label="..."/>}
                {proposal!=null&&proposal.allow_withdrawal==false&&proposal.allow_cancellation_request==true&&<MenuButton options={[{
                  title: 'Request Cancellation',
                  action: () => setCancellationRequestModal(true)
                }]} label="..."/>}
              </div>
          </div>


          <div className="px-3 mt-8 max-w-3xl grid grid-cols-1 gap-6 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense xl:grid-cols-3">
            <div className="space-y-6 lg:col-start-1 lg:col-span-2">

            {
              user!=null&&proposal==null&&user.stripe_account_id==null&&<div className="bg-orange-100 px-4 sm:px-6 py-3 rounded-md md:flex justify-between mb-5">
                <div className="flex items-center">
                <ExclamationCircleIcon className="text-orange-900 w-5 h-5 block mr-2"/>
                <p className="text-orange-900 text-sm md:max-w-lg flex-1">You must create a Stripe Express account in order to accept invitations from brands.</p>
                </div>
                <Link to={`/influencers/stripe`} className="w-full md:w-auto block text-center mt-4 md:mt-0 border bg-indigo-600 text-white text-sm py-2 px-4 shadow font-medium border-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700">Connect Stripe</Link>
              </div>
            }


              {invite!=null&&invite.status=='rejected'&&<Alert title="You have declined this invitation" status="info">
                <p>If you change your mind, and would like to submit a proposal to this campaign, you may un-decline your invitation.</p>
              </Alert>}

               {
                proposal!=null&&proposal.status=='verified'&&<Alert title="Good job!" status="info">
                  <p className="mb-2">This assignment has been completed, and the brand has verified your work.</p>
                  {
                    proposal.order!=null&&proposal.order.influencer_amount>0&&proposal.order.transferred_at!=null&&<p>
                      Your payment of <strong>${numeral(proposal.order.influencer_amount).format('0,0.00')}</strong> has been released to your possession on <strong>{moment(proposal.order.transferred_at).format('MMM Do, Y')}</strong>. It generally takes 1-3 business days for a transfer to appear in your bank account.
                    </p>
                  }
                </Alert>
              }

              {
                proposal==null&&campaign!=null&&campaign.status=='archive'&&<Alert title="This campaign is no longer active" status="danger">
                  <p>This brand is not accepting new proposals.</p>
                </Alert>
              }

              {proposal!=null && (proposal.status=='cancelled'||proposal.deleted_at!=null)&&<Alert title="This assignment has been terminated" status="danger">
                <p>This proposal has been cancelled. Do not continue any work associated with this campaign.</p>
              </Alert>}

              {
                proposal!=null&&proposal.status=='production'&&<Alert title="Next steps" status="info">
                  <p>Do the work as discussed between you and the brand. If there are products for review, wait to receive the products. Once you complete the work, you may add links to your posts in the <a href="#proofs" className="text-indigo-600 font-medium">Proofs</a> section and/or screenshots in the <a href="#attachments" className="text-indigo-600 font-medium">Attachments</a> section.</p>
                  <p>Once your proofs are added and saved, you can <a href="#completed" className="text-indigo-600">mark this proposal as <span className="italic font-medium">completed</span></a>.</p>
                </Alert>
              }

              {
                proposal!=null&&proposal.status=='proposed'&&<Alert title="You have submitted a proposal" status="success">
                  <p>Your proposal will be reviewed by the brand <strong className="font-medium">{campaign.user.brand_name}</strong></p>
                </Alert>
              }

              {
                proposal!=null&&proposal.status=='completed'&&<Alert title="You have submitted your work" status="success">
                  <p>Hang tight, while <span className="font-medium">{campaign.user.brand_name}</span> checks your work! If the brand verifies your work, this assignment will have concluded{proposal.order&&proposal.order.influencer_amount>0&&<span>, and your payment of <strong>${numeral(proposal.order.influencer_amount).format('0,0')}</strong> will be transferred to your bank account</span>}.</p>
                  <p>If the brand decides that changes are required, you will be notified, so you can make the necessary changes and then re-submit.</p>
                </Alert>
              }

              {
                proposal!=null&&proposal.status=='change'&&<Alert title={`Hiring brand ${campaign.user.brand_name} has requested a change!`} status="warning">
                  <p>Please take note of the requested changes from the hiring brand. Once you have made the necessary changes, you can send the proposal back to the brand by <a href="#completed" className="font-bold">resubmitting your work</a>.</p>
                </Alert>
              }

              {
          proposal!=null&&proposal.admin_cancellation_request!=null&&<Card title="Result of Dispute">
            <p className="text-sm text-gray-600 mb-3">A Beauty Clout administrator reviewed a dispute regarding this assignment..</p>

            <label className="text-xs font-medium">Decision</label>
            <p className="text-sm text-gray-600 mb-3">{disputeResults.find(result => result.value==proposal.admin_cancellation_request.result_code).name}</p>
            {
              proposal.admin_cancellation_request.admin_details_txt!=null&&<>
                <label className="text-xs font-medium">Additional details</label>
                <p className="text-sm text-gray-600 mb-3">{proposal.admin_cancellation_request.admin_details_txt}</p>
              </>
            }
            <p className="text-xs text-gray-600">Submitted {moment(proposal.admin_cancellation_request.completed_at).fromNow()}</p>

          </Card>
        }

              {
                proposal!=null&&<PageTabs tabs={tabs} navigate={navigate}/>
              }

              {
                proposal!=null&&proposal.pending_cancellation_request!=null&&proposal.pending_cancellation_request.disputed_at==null&&<Card title="Pending Cancellation Request" primaryAction={proposal.pending_cancellation_request.requested_by_user_id==proposal.user_id?{label: 'Withdraw Request', action: () => setWithdrawCancellationRequestModal(true)}:null}>
                  {
                    proposal.pending_cancellation_request.requested_by_user_id==proposal.user_id&&<>
                      <p className="text-gray-600 text-sm mb-3">You have requested cancellation of this assignment <span className="font-medium">{moment(proposal.pending_cancellation_request.created_at).fromNow()}</span>.</p>
                      <p className="text-gray-600 text-sm mb-3">If <span className="font-medium">{campaign.user.brand_name}</span> accepts your request, this assignment will be cancelled, and any money held in escrow will be returned to the brand.</p>
                      <p className="text-gray-600 text-sm">If you have completed the assigned work, and are having issues with the brand verifying your work, we recommend you file a dispute by emailing our team at <a className="text-indigo-600" href="mailto:info@beautyclout.com">info@beautyclout.com</a>. instead.</p>
                    </>
                  }
                  {
                    proposal.pending_cancellation_request.requested_by_user_id==campaign.user_id&&<>
                      <p className="text-gray-600 text-sm mb-3"><span className="font-medium">{campaign.user.brand_name}</span> has requested cancellation of this assignment.</p>
                      <p className="text-gray-600 text-sm mb-5">If you agree to this request, any held payment will be returned to the brand, and this assignment will be terminated.</p>
                      <div className="grid sm:grid-cols-2 gap-4 ">
                        <Button primary onClick={() => setCancelAgreeModal(true)}>Cancel assignment</Button>
                        <Button onClick={() => setCancelDeclineModal(true)}>Decline request</Button>
                      </div>
                    </>
                  }


                </Card>
              }

              {
                proposal!=null&&proposal.pending_cancellation_request!=null&&proposal.pending_cancellation_request.disputed_at!=null&&<Card title="Dispute opened">
                  <p className="text-gray-600 text-sm mb-3">A request to cancel this assignment has been declined by the other party.</p>
                  <p className="text-gray-600 text-sm">A Beauty Clout admin will reach out to you. If you have any questions, you can also reach out to our staff at <a href="mailto:info@beautyclout.com" className="text-indigo-600 hover:text-indigo-700">info@beautyclout.com</a>.</p>
                </Card>
              }

              {
                proposal!=null&&proposal.status=='change'&&proposal.last_change_request!=null&&<section ariaLabelledby="change-request">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="px-4 py-5 sm:px-6 justify-between items-center flex">
                      <h2 id="change-request-title" className="text-lg leading-6 font-medium text-gray-900">
                        Changes Requested
                      </h2>
                      <Link to={`/influencers/proposal/${proposal.id}/messages`} className="text-indigo-600 text-sm">Send reply</Link>
                    </div>
                    <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                      <p className="text-sm text-gray-600" dangerouslySetInnerHTML={{__html: proposal.last_change_request.message_txt.replaceAll("\n", '<br/>')}}></p>
                    </div>

                  </div>
                </section>
              }

              {invite!=null&&invite.status!='legacy'&&<section ariaLabelledby="invite-details">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Invitation
                    </h2>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-2 gap-x-4 gap-y-8 ">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Invited</dt>
                        {invite.created_at?<dd className="mt-1 text-sm text-gray-900">{moment(invite.created_at).fromNow()}</dd>:<dd className="mt-1 text-sm text-gray-900">n/a</dd>}
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Status</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {
                            statuses.find(status => (status.status == invite.status)).name
                          }
                        </dd>
                      </div>
                     {invite.status!='legacy'?<div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Message</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <p>
                            {invite.message_txt!=null?invite.message_txt:'This brand did not leave a message'}
                          </p>
                        </dd>
                      </div>:<div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Note</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <p>
                            This proposal was submitted prior to Beauty Clout changing campaigns to invitation-only.
                          </p>
                        </dd>
                      </div>}
                      
                      </dl>
                      
                      </div>
                  </div>

              </section>}


              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                      Campaign Details
                    </h2>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Proposals submitted</dt>
                        <dd className="mt-1 text-sm text-gray-900">{campaign.proposals_count}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Website URL</dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <a href={campaign.url} target="_blank" className="hover:text-blue-600">{campaign.url}</a>
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Estimated budget per hire</dt>
                        {campaign.max_price>0?
                          <dd className="mt-1 text-sm text-gray-900">${numeral(campaign.min_price).format('0,0')} - ${numeral(campaign.max_price).format('0,0')}</dd>:
                          <dd className="mt-1 text-sm text-gray-900">$0.00 (Non-payment)</dd>
                        }
                      </div>
                      {campaign.category!=null&&<div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Category</dt>
                        <dd className="mt-1 text-sm text-gray-900">{campaign.category.category_name}</dd>
                      </div>}
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">About this campaign</dt>
                        <dd className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html: html}}>
                          
                        </dd>
                      </div>
                      {
                        campaign.product_description!=null&&campaign.product_description.length>0&&<div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Product details</dt>
                        <dd className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html: productHtml}}>
                          
                        </dd>
                      </div>
                      }

                    
                      
                    </dl>
                  </div>
                  
                </div>
              </section>

              {
                campaign!=null&&campaign.affiliate_program!=null&&<Card title="Earn Commission from Sales" unstyled={true}>
                <div className="divide-y">
                <div className="px-4 sm:px-6 py-4">

                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">Affiliate Program</dt>
                          <dd className="mt-1 text-sm text-gray-900">This campaign has an affiliate offer</dd>
                        </div>
                        <div>
                          <dt className="text-sm font-medium text-gray-500">Commission</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {
                              campaign.affiliate_program.type=='fixed'?`$${numeral(campaign.affiliate_program.fixed_value).format('0,0.00')} per order`:`${campaign.affiliate_program.percentage}% of order total`
                            }
                          </dd>
                        </div>
                        {campaign.affiliate_program.coupon==1&&<div className="sm:col-span-2">
                          <dt className="text-sm font-medium text-gray-500">Unique Coupon Code</dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            You will be given a coupon code offering {campaign.affiliate_program.discount_type=='percentage'?`${campaign.affiliate_program.discount_percentage}% off`:`a $${numeral(campaign.affiliate_program.discount_value).format('0,0.00')} discount `}
                            {campaign.affiliate_program.min_subtotal>0&&`, minimum order of $${numeral(campaign.affiliate_program.min_subtotal).format('0,0.00')}`}
                          </dd>
                        </div>}
                        {
                          proposal!=null&&(['production', 'completed', 'verified', 'cancelled', 'change'].includes(proposal.status)&&<>
                              <div className="sm:col-span-2">
                                  <dt className="text-sm font-medium text-gray-500">Affiliate Status</dt>
                                  
                                    {
                                      proposal.affiliate_enabled==1?<>
                                      <dd className="mt-1 text-sm text-gray-900 flex items-center">
                                        <CheckCircleIcon className="text-green-600 w-5 h-5 mr-1"/>
                                        <span>Active</span>
                                      </dd>
                                      </>:<>
                                      <dd className="mt-1 text-sm text-gray-900 flex items-center">
                                        <XCircleIcon className="text-red-600 w-5 h-5 mr-1"/>
                                        <span>{campaign.user.brand_name} has ended affiliate commission.</span>
                                      </dd>
                                      </>
                                    }
                              </div>
                              <div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Affiliate Link</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  <div className="flex">
                                    <input ref={affiliateRef} type="text" readonly className="rounded-l-md px-4 py-2 border-gray-300 text-gray-700 text-sm border-l border-t border-b flex-1" value={`https://${campaign.user.store.domain}?bc_aff_id=${proposal.id}`}/>
                                    <button className="hover:bg-indigo-800 bg-indigo-600 py-2 px-6 border-indigo-600 border-r border-t border-b rounded-r-md" onClick={() => copyText(affiliateRef)}><ClipboardIcon className="text-white w-5 h-5"/></button>
                                  </div>
                                </dd>
                              </div>
                              {campaign.affiliate_program.coupon==1&&<div className="sm:col-span-2">
                                <dt className="text-sm font-medium text-gray-500">Coupon Code</dt>
                                <dd className="mt-1 text-sm text-gray-900">
                                  <div className="flex">
                                    <input ref={couponRef} type="text" readonly className="rounded-l-md px-4 py-2 border-gray-300 text-gray-700 text-sm border-l border-t border-b flex-1" value={proposal.coupon_code}/>
                                    <button className="hover:bg-indigo-800 bg-indigo-600 py-2 px-6 border-indigo-600 border-r border-t border-b rounded-r-md" onClick={() => copyText(couponRef)}><ClipboardIcon className="text-white w-5 h-5"/></button>
                                  </div>
                                </dd>
                              </div>}

                            </>)
                        }

                    </dl>
                    </div>
                    {
                      (proposal!=null&&(['production', 'completed', 'verified', 'cancelled', 'change'].includes(proposal.status)))&&<div className="px-4 sm:px-6 py-4 grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-x-4">
                            <div>
                                <span className="text-sm font-medium text-gray-500">Clicks</span>
                                <h3 className="text-lg font-bold text-gray-700">{numeral(proposal.affiliate_clicks_count).format('0,0')}</h3>
                            </div>
                            <div>
                                <span className="text-sm font-medium text-gray-500">Orders</span>
                                <h3 className="text-lg font-bold text-gray-700">{numeral(proposal.affiliate_conversions_count).format('0,0')}</h3>
                            </div>
                            <div>
                                <span className="text-sm font-medium text-gray-500">Total Earned</span>
                                <h3 className="text-lg font-bold text-gray-700">{numeral(proposal.affiliate_total_earned).format('$0,0.00')}</h3>
                            </div>
                            <div>
                                <span className="text-sm font-medium text-gray-500">Total Paid</span>
                                <h3 className="text-lg font-bold text-gray-700">{numeral(proposal.affiliate_total_paid).format('$0,0.00')}</h3>
                            </div>
                            <div>
                                <span className="text-sm font-medium text-gray-500">Pending Payment</span>
                                <h3 className="text-lg font-bold text-green-600">{numeral(proposal.affiliate_owed_amount).format('$0,0.00')}</h3>
                            </div>
                        </div>
                    }
                    </div>
                </Card>
              }

              {
                !canEdit&&<section ariaLabelledby="proposal-details-readonly">
                  <div className="bg-white shadow sm:rounded-lg">
                    <div className="divide-y divide-gray-200 ">
                      <div className="px-4 py-5 sm:px-6 flex justify-between">
                        <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                          Your Proposal
                        </h2>
                      </div>
                      <div className="px-4 py-5 sm:px-6">
                        {proposal.order&&<div className="grid sm:grid-cols-3 mb-5">
                            <div>
                              <dt className="text-sm font-medium text-gray-500">Hired Amount</dt>
                              <dd className="mt- text-sm text-gray-900">
                                  ${numeral(proposal.order.total_amount).format('0,0.00')}
                              </dd>
                            </div>
                            {proposal.deleted_at==null&&<>
                            <div>
                              <dt className="text-sm font-medium text-gray-500">Beauty Clout's Fee (10%)</dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                  ${numeral(proposal.order.influencer_fee).format('0,0.00')}
                              </dd>
                            </div>
                            <div>
                              <dt className="text-sm font-medium text-gray-500">Your Payout</dt>
                              <dd className="mt-0 text-sm font-bold text-green-600">
                              <small className="block text-gray-600 font-normal">This amount is being held in escrow</small>
                                  ${numeral(proposal.order.influencer_amount).format('0,0.00')}

                              </dd>
                            </div>
                            </>}
                        </div>}
                        <div>
                          <dt className="text-sm font-medium text-gray-500">Details</dt>
                          <dd className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html: proposal.proposal_txt.replaceAll("\n", '<br/>')}}>
                            
                          </dd>
                        </div>
                      </div>
                      {
                        proposal.services.map(service => {

                          if (service.hired == 0) {
                            return null;
                          }
                          var userService = user.services.find(_service => (service.service == _service.service));

                          var weeks = '';

                          switch(service.completion_weeks) {
                            case 0:
                              weeks = '< 1 week';
                              break;
                            case 1:
                              weeks = '1 week';
                              break;
                            default:
                              weeks = service.completion_weeks+' weeks';
                          }

                          return <div className="px-4 py-5 sm:px-6 flex items-center space-x-3" key={`service-${service.id}`}>
                            <img src={socialMediaServices[service.service].icon} className="w-10 h-10"/>
                            <div className="">
                              <span className="block text-sm font-medium text-indigo-600">{getHandle(userService)}</span>
                              <span className="text-xs text-gray-600 font-bold">${numeral(service.fee).format('0,0.00')} &middot; </span>
                              <span className="text-xs text-gray-600">To be completed in {weeks}</span>
                              
                            </div>
                          </div>
                        })
                      }
                    </div>
                  </div>
                </section>
              }

              {

              }

              {/* Comments*/}
              {products!=null&&canEdit&&products!=null&&products.total>0&&<section ariaLabelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Products for Review
                      </h2>
                      {
                        campaign.limit_type=='quantity' && <span className="text-gray-500 text-sm pt-1">{cart.length} / {campaign.limit_quantity} products selected</span>
                      }
                      {
                        campaign.limit_type=='total' && <span className="text-gray-500 text-sm pt-1">You have ${(campaign.limit_total- cartTotal ).toFixed(2)} credit left</span>
                      }
                    </div>
                    <div className="p-5">
                      <div className="mt-1 relative rounded-md shadow-sm border border-text-100">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <SearchIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  </div>
                  <input
                    type="text"
                    name="search"
                    id="search"
                    className="focus:ring-indigo-500 p-3 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search available products..."
                    onChange={(e) => searchProducts(e.target.value)}
                  />
                </div>
                    </div>
                    <div className="grid grid-cols-2 lg:grid-cols-3">
                    {
                      products.data.map((product, i) => {


                        return <div key={`product-${product.id}`} className="p-5 relative">
                        {
                          product.image!=null?<img src={product.image.src} className="max-w-full object-center object-cover h-48 block"/>:<div className="bg-gray-50 max-w-full h-48 block"/>
                        }
                        <strong className="text-sm font-medium text-gray-500">{product.title}</strong>
                        <span className="text-xs font-bold text-green-600 block mb-1">${numeral(product.selected.price).format('0,0.00')}</span>
                          <Select options={product.options} selected={product.selected} setSelected={(e) => changeVariant(i, e)}/>
                          <Button disabled={invite.status=='rejected'||campaign.status=='archive'} primary fullWidth className="mt-3" onClick={() => addVariant(product)}>{
                            cartVariantIds.includes(product.selected.id)?<span><CheckCircleIcon className="w-5 h-5"/></span>:<span className="h-5">Add to Bag</span>
                          }</Button>
                        </div>
                      })
                    }
                    </div>
                    <div className="p-5 flex justify-between">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => setPage(page-1)}
              disabled={page<=0}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            
            <button
              onClick={() => setPage(page+1)}
              disabled={page==products.last_page}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div className="pt-1">
            <span className="text-gray-500 text-sm">Showing <strong>{products.from}</strong> to <strong>{products.to}</strong> of <strong>{products.total}</strong> results</span>
          </div>
        </div>
                </div>
                </div>
              </section>}

              {invite!=null&&invite.status=='pending'&&products!=null&&products.total>0&&<section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Your Bag
                      </h2>
                      {
                        campaign.limit_type=='quantity' && <span className="text-gray-500 text-sm pt-1">{cart.length} / {campaign.limit_quantity} products selected</span>
                      }
                      {
                        campaign.limit_type=='total' && <span className="text-gray-500 text-sm pt-1">You have ${(campaign.limit_total- cartTotal ).toFixed(2)} credit left</span>
                      }
                    </div>
                    
                    {
                      cart.map((product, i) => {
                        return <div key={`cart-${product.selected.id}-${i}`}>
                          <div className="p-5 flex items-center justify-between">
                            <div className="flex items-center justify-start w-2/3">
                            <img src={product.image.src} className="w-12 h-12"/>
                            <div className="ml-3">
                              <strong className="text-sm font-medium text-gray-700 block">{product.title}</strong>
                              <span className="text-xs text-gray-500 block">{product.selected.name}</span>
                            </div>
                            </div>
                            <div>
                              <Button onClick={() => removeProductFromCart(i)}>Remove</Button>
                            </div>
                          </div>

                        </div>
                      })
                    }
                    {
                      cart.length==0&&<div className="px-5 py-12 justify-center items-center">
                        {
                          campaign.limit_type=='quantity'&&<div className="text-center">
                            <ShoppingBagIcon className="w-10 h-10 text-gray-400 block mx-auto mb-3"/>
                            <strong className="text-md font-medium text-gray-700 block mb-2">Your bag is empty</strong>
                            <p className="text-sm text-gray-500">You may choose <strong className="text-sm text-gray-700">{campaign.limit_quantity}</strong> products to review.</p>
                          </div>
                        }
                        {
                          campaign.limit_type=='total'&&<div className="text-center">
                            <strong className="text-md font-medium text-gray-700 block mb-2">Your bag is empty</strong>
                            <p className="text-sm text-gray-400">You have a spend limit of <strong className="text-sm text-gray-700">${numeral(campaign.limit_total).format('0,0.00')}</strong> for products to review.</p>
                          </div>
                        }
                      </div>
                    }
                    </div>
                    </div>
               </section>}

               {!preview&&invite.status!='rejected'&&canEdit&&<section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Social Media &amp; Fees
                      </h2>
                      {
                        campaign.max_price>0?<span className="text-gray-500 text-sm pt-1">Total budget: ${numeral(campaign.min_price).format('0,0')}-${numeral(campaign.max_price).format('0,0')}</span>:
                        <span className="text-gray-500 text-sm pt-1">Total budget: ${numeral(campaign.max_price).format('0,0')}</span>
                      }
                    </div>
                    {preview&&<div className="px-4 py-5 sm:px-6 flex justify-between">
                      <p className="text-gray-700 text-sm">This section is a preview and will be editable for influencers.</p>
                    </div>}
                    {
                      budgetWarning&&<div className="px-4 py-5 sm:px-6 bg-orange-50">
                        <strong className="text-sm font-bold text-orange-500">Warning: You are over budget!</strong>
                        <p className="text-orange-500 text-sm">This brand has specified a max. budget of ${numeral(campaign.max_price).format('0,0.00')}. You may still submit your proposal as is, but the hiring brand may take this into consideration.</p>
                      </div>
                    }
                    {
                      campaign.max_price==0&&proposal==null&&<div className="px-4 py-5 sm:px-6 bg-green-50">
                        <strong className="text-sm font-bold text-green-800">This campaign doesn't offer monetary payment</strong>
                        <p className="text-green-800 text-sm">Brand <strong className="font-medium">{campaign.user.brand_name}</strong> is only offering products in exchange for services. You cannot specify a fee.</p>
                      </div>
                    }

                      {
                        services.map(service => {

                          if (preview == false && user.services.find(_service => (service.service == _service.service))) {
                            
                            return <div className="grid grid-cols-3">
                              <div className="p-2">
                              <SocialMediaSelector disabled={campaign.status=='archive'} service={service.service} enabled={service.selected} setEnabled={(e) => toggleSocialMedia(service.service, e)}/>
                              </div>
                              <div className="p-3">
                                <InputField disabled={!service.selected||preview||campaign.status=='archive'||campaign.max_price==0} type="number" label="Your fee ($)" value={service.fee} onChange={(e) => setFee(service.service, e.target.value)} placeholder="$0.00" className="w-full"/>
                              </div>
                               <div className="p-3">
                                <Select disabled={!service.selected||campaign.status=='archive'} options={estimates} label="Time Estimate" selected={service.completion_weeks} setSelected={(e) => setEstimate(service.service, e)} />
                               </div>
                            </div>
                          }
                          return null;
                        })
                      }
                  </div>
                </div>
                </section>}


                {preview==false&&invite.status!='rejected'&&canEdit&&<section>
                  <div className="bg-white shadow sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6 flex justify-between">
                      <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                        Proposal Details
                      </h2>
                    </div>
                    {
                      preview==true&&<div className="px-4 py-5 sm:px-6 flex justify-between">
                        <p className="text-sm text-gray-700">This section will be editable for influencers</p>
                      </div>
                    }
                    <div className="p-5">
                      <TextArea rows={5} helpText={`Sell yourself and your services here. What makes you stand out?`} label="Your proposal *" value={proposalTxt} onChange={e => setProposalTxt(e.target.value)} placeholder="What are you offering?" disabled={preview||(invite!=null&&invite.status=='rejected'||campaign.status=='archive')}/>
                      
                     
                    </div>
                    {
                      campaign.affiliate_program!=null&&campaign.affiliate_program.coupon==1&&<div className="px-4 sm:px-6 py-4">
                        <InputField type="text" value={couponCode} onChange={e => setCouponCode(e.target.value)} label="Preferred Coupon Code" helpText="The hiring brand can try to generate this code for you"/>
                      </div>
                    }
                    </div>
                    </div>
                </section>}

                {
                  proposalActive&&proposal.products.length>0&&<section>
                    <div className="bg-white shadow sm:rounded-lg">
                      <div className="divide-y divide-gray-200">
                        <div className="px-4 py-5 sm:px-6 flex justify-between">
                          <h2 id="notes-title" className="text-lg font-medium text-gray-900">
                            {proposal.status=='proposed'?'Products Submitted to be Received':'Products for Review'}
                          </h2>
                        </div>
                        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 px-4 py-5 sm:px-6">
                          {
                            proposal.products.map(row => {
                              const variant = row.variant;

                              const parts = [];

                              parts.push(variant.option_1);

                              if (variant.option_2 != null) {
                                parts.push(variant.option_2);
                              }

                              if (variant.option_3 != null) {
                                parts.push(variant.option_3);
                              }

                              return <div className="" key={`product-${row.id}`}>
                                <img src={row.product.image.src} className="object-contain w-full sm:h-48"/>
                                <strong className="text-sm text-gray-700 font-medium block">{row.product.title}</strong>
                                <span className="text-xs text-gray-500">{
                                  parts.join(' / ')
                                }</span>
                              </div>}
                              )
                          }
                        </div>
                        {
                          proposal.shopify_orders.map(shopifyOrder => <div className="px-4 py-5 sm:px-6 grid sm:grid-cols-4" key={`shopify-order-${shopifyOrder.id}`}>
                              <div>
                                <span className="text-xs font-medium">Order Number</span>
                                <p><strong className="text-gray-700 font-medium text-sm">#{shopifyOrder.number}</strong></p>
                              </div>
                              <div>
                              <span className="text-xs font-medium">Shipping Address</span>
                                <p className="text-sm text-gray-600 font-medium">{shopifyOrder.first_name} {shopifyOrder.last_name}</p>
                                <p className="text-sm text-gray-600">{shopifyOrder.address_1}</p>
                                {shopifyOrder.address_2!=null&&<p className="text-sm text-gray-600">{shopifyOrder.address_2}</p>}
                                <p className="text-sm text-gray-600">{shopifyOrder.city}, {shopifyOrder.state} {shopifyOrder.zip}</p>
                                <p className="text-sm text-gray-600">{shopifyOrder.country}</p>
                              </div>
                              <div>
                                {
                                  shopifyOrder.fulfillments.map(fulfillment => <div>
                                      <span className="text-xs font-medium">Tracking Number</span>
                                      <p><a href={fulfillment.url} target="_blank" className="text-indigo-600 text-sm">{fulfillment.tracking_number}</a></p>
                                    </div>)
                                }
                              </div>
                              <div className="sm:text-right">
                                <span className="text-xs font-medium">Status</span>
                                <p className="text-sm text-gray-600 font-medium">{getOrderStatus(shopifyOrder).status}</p>
                              </div>
                            </div>)
                        }
                      </div>
                    </div>
                  </section>
                }

                {
                  proposalActive&&showProofsSection&&<section id="proofs">
                      <div className="bg-white shadow sm:rounded-lg">
                      <div className="divide-y divide-gray-200">
                        <div className="px-4 py-5 sm:px-6 flex justify-between">
                          <h2 id="proof-title" className="text-lg font-medium text-gray-900">
                            Proofs &amp; Links
                          </h2>
                          {canEditProofs&&<div className="items-center">
                          <button className="bg-transparent text-sm text-indigo-600" onClick={() => addNewProof()}>Add proof</button>
                          </div>}
                        </div>
                        {
                          proofs.map((proof, i) => <div className="px-4 py-6 sm:px-6 flex" key={`proof-${proof.id}`}>
                              {
                                proof.edit?<><div className="w-full"><InputField type="url" className="w-full rounded-r-none" placeholder="https://..." label="URL to social media post" value={proof.url} onChange={(e) => setProofURL(proof.id, e.target.value)} /></div><div className="pt-5"><Button className="rounded-none border-indigo-600" primary onClick={() => saveProof(proof.id)}>Save</Button></div><div className="pt-5"><Button className="rounded-l-none" onClick={() => toggleProofEditable(i)}>Cancel</Button></div></>:<>
                                  <a href={proof.savedURL} className="w-full block text-indigo-600 truncate text-sm" target="_blank">{proof.savedURL}</a>
                                  {canEditProofs&&<div className="flex">
                                    <button className="bg-transparent text-sm text-indigo-600" onClick={() => toggleProofEditable(proof.id)}>Edit</button>
                                    <button className="bg-transparent text-sm text-red-600 ml-3" onClick={() => deleteProof(i)}>Delete</button>
                                  </div>}
                                </>
                              }
                              
                            </div>)
                        }
                        {
                          proofs.length==0&&<div className="px-4 py-20 sm:px-6 flex justify-center items-center">
                          {(proposal.status=='production'||proposal.status=='change')?<div className="text-center">
                            <h3 className="font-medium text-gray-700 text-md mb-1">Add proofs to show your work</h3>
                            <p className="text-gray-600 text-sm">After you created your social media posts, you can provide links or screenshots here.</p>
                            
                          </div>:<div className="text-center">
                            <h3 className="font-medium text-gray-700 text-md mb-1">No links attached</h3>
                            <p className="text-gray-600 text-sm">No links to social media proofs were attached.</p>
                            
                          </div>}
                          </div>
                        }
                        {canEditProofs&&<div className="px-4 sm:px-6 py-5">
                            <Button onClick={() => saveProofs()} disabled={proofs.length==0} className="border" fullWidth>Save Proofs</Button>
                          </div>}
                        </div>
                      </div>
                  </section>
                }

                {
                  proposalActive&&showProofsSection&&<section id="attachments">
                      <div className="bg-white shadow sm:rounded-lg">
                      <div className="divide-y divide-gray-200">
                        <div className="px-4 py-5 sm:px-6 flex justify-between">
                          <h2 id="proof-title" className="text-lg font-medium text-gray-900">
                            Attachments &amp; Screenshots
                          </h2>
                        </div>
                        {attachments.length>0&&<div className="grid gap-2 grid-cols-2 sm:grid-cols-4">
                          {
                            attachments.map((imageId, i) => <div className="px-4 py-4 sm:px-6" key={`image-${imageId}`}>
                                <img src={`https://res.cloudinary.com/wigmarket/h_200,c_fill/${imageId}.jpg`} className="object-contain w-full h-32 sm:h-48"/>
                                {canEditProofs&&<button className="w-full px-3 py-2 border rounded-md text-sm text-center" onClick={() => deleteImage(i)}><TrashIcon className="w-5 h-5 text-gray-600 block mx-auto"/></button>}
                              </div>)
                          }
                        </div>}
                        {
                          attachments.length==0&&<div className="px-5 py-20 flex items-center justify-center">
                            {(proposal.status=='production'||proposal.status=='change')?<div>
                              <h3 className="font-medium text-gray-700 text-md mb-1">Add attachments</h3>
                              <p className="text-gray-600 text-sm">You can provide images or screenshots here.</p>
                            
                            </div>:
                            <div className="text-center">
                              <h3 className="font-medium text-gray-700 text-md mb-1">No attachments</h3>
                              <p className="text-gray-600 text-sm">No images or screenshots were attached</p>

                            </div>}
                          </div>
                        }
                          {canEditProofs&&<div className="px-4 py-5 sm:px-6">
                            <FileDrop error={uploadError} multiple={true} uploading={uploading}  label="Upload screenshot" onChange={(files) => uploadImages(files)} helpText="JPG, PNG, maximum size of 7MB"/>           
                          </div>}
                          {canEditProofs&&<div className="px-4 sm:px-6 py-5">
                            <Button onClick={() => saveProofs()} fullWidth={1} disabled={attachments.length==0}>Save Attachments</Button>
                          </div>}
                        </div>
                      </div>
                  </section>
                }


                {
                  error!=null&&<Alert title="Your proposal could not be submitted" status="danger">
                    <p>{error}</p>
                  </Alert>
                }
                <div>

                {
                  invite.status!='rejected'&&(proposal==null||proposal.status=='pending')&&(proposal==null||proposal.deleted_at==null)&&<Button disabled={invite.status=='rejected'||campaign.status=='archive'||user.stripe_account_id==null} fullWidth primary onClick={() => submitProposal()}>Submit Proposal</Button>
                }

                {invite.status=='pending'&&proposal==null&&<div className="mt-2">
                <Button onClick={() => declineInvitation()} className="w-full border border-gray-200 text-gray-600 bg-white">Decline Invitation</Button>
                </div>}

                {invite.status=='rejected'&&<div className="mt-2">
                <Button onClick={() => undeclineInvitation()} primary fullWidth={1}>Un-Decline Invitation</Button>
                </div>}
                {
                  proposal!=null&&proposal.status=='production'&&proofs.length==0&&attachments.length==0&&<div className="mb-5"><Alert title="Before you mark this assignment as complete" status="warning">
                    <p>It is highly recommended that you add links to your social media posts in the Proofs section and/or attach screenshots of your work in the Attachments section.</p>
                  </Alert></div>
                }

                {
                  proposal!=null&&(proposal.status=='production'||proposal.status=='change')&&<div className="mt-2" id="completed">
                    <Button onClick={() => markAsComplete()} primary fullWidth={1}>{completedBtnLabel}</Button>
                  </div>
                }
                {
                  proposal!=null&&(proposal.status=='completed')&&<div className="mt-2" id="completed">
                    <Link to={`/influencers/proposal/${proposal.id}/messages`} className="w-full block bg-indigo-600 text-white rounded-md text-center py-2 px-5 text-sm">Send a message</Link>

                  </div>
                }
                </div>

            </div>
            
          </div>
          <ToastContainer theme="dark" autoClose={2000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>       
  <Modal open={withdrawModal} setOpen={() => setWithdrawModal(false)} primary={{disabled: (proposal==null||proposal.allow_withdrawal == false), label: 'Withdraw Proposal', action: () => withdrawProposal() }} secondary={{label: 'Close', action: () => setWithdrawModal(false)}}>
    {
      proposal!=null&&<div>
        {proposal.allow_withdrawal==true?<h3 className="font-bold text-md text-gray-900 mb-3">Withdraw Proposal?</h3>:<h3 className="font-bold text-md text-gray-900 mb-3">This proposal may not be able to be withdrawn</h3>}
        {proposal.allow_withdrawal==true&&<p className="text-sm text-gray-600">Please confirm that you would like to withdraw this proposal, and terminate the assignment. Any money held in escrow will be returned to the brand.</p>}

        {proposal.shopify_orders.length>0&&<p className="text-sm text-gray-600">This proposal cannot be withdrawn, because products were approved for shipment. To cancel this proposal, please reach out to our staff at <a href="mailto:info@beautyclout.com" className="text-indigo-600 hover:text-indigo-700">info@beautyclout.com</a></p>}
        {proposal.status=='verified'&&<p className="text-sm text-gray-600">This proposal cannot be withdrawn, because</p>}
      </div>
    }

  </Modal>

  <Modal open={cancellationRequestModal} setOpen={() => setCancellationRequestModal(false)} primary={{ label: 'Request Cancellation', action: () => requestCancellation() }} secondary={{label: 'Close', action: () => setCancellationRequestModal(false)}}>
    {
      proposal!=null&&<div>
        <h3 className="font-bold text-md text-gray-900 mb-3">Request Cancellation of Assignment?</h3>
        <p className="text-sm text-gray-600 mb-3">Since there is a shipment of products associated with this assignment, you can only <span className="italic">request</span> cancellation.</p><p className="mb-5 text-sm text-gray-600">If the brand agrees to your request, this assignment will be terminated. If the brand declines your request, Beauty Clout administrators will be notified of the dispute.</p>
        <p className="text-sm text-gray-600 mb-3"><span className="text-red-500 text-md">*</span>Please note that this action will lead to a refund for the brand. If you believe you deserve to be compensated for any completed work and you are unable to reach a compromise with the brand, file a dispute instead by emailing our staff at <a className="text-indigo-600" href="mailto:info@beautyclout.com">info@beautyclout.com</a>.</p>
        <Select label="Cancellation reason *" options={cancellationReasons} selected={reasonCode} setSelected={setReasonCode}/>

        <TextArea className="mt-3" label="Additional details *" rows={4} placeholder="Provide any further information to help us understand the situation." helpText={'Your response will not be visible to the brand'} value={detailsTxt} onChange={e => setDetailsTxt(e.target.value)}/>
      </div>
    }

  </Modal>

  <Modal open={withdrawCancellationRequestModal} setOpen={() => setWithdrawCancellationRequestModal(false)} primary={{ label: 'Confirm', action: () => withdrawCancellationRequest() }} secondary={{label: 'Close', action: () => setWithdrawCancellationRequestModal(false)}}>
    {
      proposal!=null&&<div>
        <h3 className="font-bold text-md text-gray-900 mb-3">Withdraw Your Cancellation Request?</h3>
        <p className="text-sm text-gray-600 mb-3">You are about to withdraw your request to cancel this assignment. Are you sure you would like to proceed?</p>
      </div>
    }

  </Modal>

  <Modal open={cancelAgreeModal} setOpen={() => setCancelAgreeModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('agree')}} secondary={{label: 'Close', action: () => setCancelAgreeModal(false)}}>
    <h2 className="text-xl font-bold text-gray-900 mb-5">Agree to Cancel Assignment?</h2>
    <p className="text-gray-600 text-sm mb-3">By confirming, you will be cancelling this assignment. Any payment held in escrow will be returned to the brand.</p>
    <p className="text-gray-600 text-sm mb-5">This action cannot be reversed.</p>
  </Modal>

  <Modal open={cancelDeclineModal} setOpen={() => setCancelDeclineModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('decline')}} secondary={{label: 'Close', action: () => setCancelDeclineModal(false)}}>
    <h2 className="text-xl font-bold text-gray-900 mb-5">Decline to Cancel Assignment?</h2>
    <p className="text-gray-600 text-sm mb-3">You are about to declining this brand's request to cancel this assignment</p>
    <p className="text-gray-600 text-sm mb-5">A Beauty Clout moderator may reach out to you to assist in any dispute.</p>
  </Modal>

        </main>;
}

export default withCookies(InfluencerProposal);