import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { CalendarIcon, DocumentIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {Card, Modal, Tabs, Nav} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import { ToastContainer, toast } from 'react-toastify';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const labels = {
  proposed: <span className="bg-cyan-600 text-white text-xs font-medium px-2 py-1 rounded-md">Pending</span>,
  production: <span className="bg-green-600 text-white text-xs font-medium px-2 py-1 rounded-md">In progress</span>,
  completed: <span className="bg-orange-600 text-white text-xs font-medium px-2 py-1 rounded-md">Awaiting review</span>,
  change: <span className="bg-blue-600 text-white text-xs font-medium px-2 py-1 rounded-md">Change requested</span>,
  verified: <span className="bg-green-600 text-white text-xs font-medium px-2 py-1 rounded-md">Work verified</span>
}

const statuses = [
  {name: 'Invited', status: 'proposed'},
  {name: 'Accepted', status: 'accepted'},
  {name: 'Declined', status: 'rejected'}
];

const imgs = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  instagram: instagramIcon,
  tiktok: tiktokIcon,
  youtube: youtubeIcon
};

const titles = {
  proposed: 'Pending Proposals',
  hired: 'Hired Influencers',
  verified: 'Completed Assignments'
};



var searchTimeoutId = null;

export default function CampaignEnd() {

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const {id} = useParams();

  const defaultTabs = [
    {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: false},
    {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: false},
    {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: false},
    {name: 'Invite Influencers', url: `/campaign/${id}/invite`, current: false},
    {name: 'Edit Campaign', url: `/campaign/${id}/products`, current: false},
    {name :'End Campaign', url: `/campaign/${id}/end`, current: true}
  ];

  const [tabs, setTabs] = useState(defaultTabs);

  const [campaign, setCampaign] = useState(null);

  const [archiveCampaignModal, setArchiveCampaignModal] = useState(false);

  const [unarchiveCampaignModal, setUnarchiveCampaignModal] = useState(false);

  const navigate = useNavigate();

  const loadCampaign = async() => {
    const response = await fetch(`/api/v2/campaigns/${id}`, 'GET');

    const {error, campaign} = response;

    if (campaign) {
      setCampaign(campaign);
    }
    let tabs = defaultTabs;

    if (response.campaign.status == 'draft') {
      tabs = [
        {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: (document.location.pathname.indexOf('proposed')>0)},
        {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: (document.location.pathname.indexOf('hired')>0)},
        {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: (document.location.pathname.indexOf('verified')>0)},
        {name: 'Finish Campaign', url: `/campaign/${id}/details`, current: false},
        {name :'Delete Campaign', url: `/campaign/${id}/archive`, current: true}
      ];
    } else if (response.campaign.status != 'archive') {
      tabs = [
        {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: (document.location.pathname.indexOf('proposed')>0)},
        {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: (document.location.pathname.indexOf('hired')>0)},
        {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: (document.location.pathname.indexOf('verified')>0)},
        {name: 'Invite Influencers', url: `/campaign/${id}/invite`, current: false},
        {name: 'Edit Campaign', url: `/campaign/${id}/products`, current: false},
        {name :'End Campaign', url: `/campaign/${id}/archive`, current: true}
      ];
    } else {
      tabs = [
        {name: 'Proposals', url: `/campaign/${id}/proposals/proposed`, current: (document.location.pathname.indexOf('proposed')>0)},
        {name: 'Hired', url: `/campaign/${id}/proposals/hired`, current: (document.location.pathname.indexOf('hired')>0)},
        {name: 'Verified', url: `/campaign/${id}/proposals/verified`, current: (document.location.pathname.indexOf('verified')>0)},
        {name :'Reactivate Campaign', url: `/campaign/${id}/archive`, current: true}
      ];
      
    }

    if (response.campaign.status != 'draft' && response.campaign.affiliate_program) {
      tabs.splice(3, 0, {
        name: 'Affiliates',
        current: false,
        url: `/affiliates?campaign_id=${id}`
      });
    }

    setTabs(tabs);

    
  }

  const submitCampaignStatus = async(status='archive') => {
    const body = new FormData();

    body.append('status', status);

    const response = await fetch(`/api/v2/campaigns/${id}/status`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      loadCampaign();
      setUnarchiveCampaignModal(false);
      setArchiveCampaignModal(false);
    }

    if (error) {
      toast(error);
    }
  }

  useEffect(() => {

    if (campaign == null) {
      loadCampaign();
    }
    
  }, []);

  if (campaign == null) {
    return null;
  }

  return <div className="bg-gray-50 min-h-screen">
    <Nav homeUrl="/dashboard" links={[{name: 'Campaigns', url: '/campaigns/active'}, {name: campaign.title, url: `/campaign/${campaign.id}/proposals/proposed`}]}/>

    <div className="max-w-5xl mx-5 mt-0">
      <Tabs tabs={tabs} navigate={navigate}/>

      {campaign.status!='archive'?<Card title="End this campaign?" className="my-4">
        <p className="text-gray-600 text-sm mb-3  ">By ending this campaign, invited influencers will no longer be able to submit proposals.</p>
        <p className="text-gray-600 text-sm mb-3">If you are currently working with influencers through this campaign, you may continue to do so.</p>
        <p className="text-gray-600 text-sm mb-5">Ended campaigns appear in the archive section.</p>
        <button className="bg-indigo-600 border border-indigo-600 px-4 py-2 text-white rounded-md text-center text-sm" onClick={() => setArchiveCampaignModal(true)}>End Campaign</button>
      </Card>:<Card title="Reactivate this campaign?" className="my-4">
        <p className="text-gray-600 text-sm mb-3  ">By reactivating this campaign, you will be open to receiving proposals from invited influencers.</p>
        <p className="text-gray-600 text-sm mb-5">You will also be able to invite new influencers to this campaign.</p>
        <button className="bg-indigo-600 border border-indigo-600 px-4 py-2 text-white rounded-md text-center text-sm" onClick={() => setUnarchiveCampaignModal(true)}>Reactivate Campaign</button>
      </Card>}
    </div>
    <Modal open={archiveCampaignModal} setOpen={() => setArchiveCampaignModal(true)} primary={{label: 'End Campaign', action: () => submitCampaignStatus()}} secondary={{ label: 'Close', action: () => setArchiveCampaignModal(false)}}>
      <h3 className="font-bold text-md mb-3">Are you sure?</h3>
      <p className="text-gray-600 text-sm mb-3">Ending this campaign will disallow any influencers from submitting proposals.</p>
      <p className="text-gray-600 text-sm mb-3">You can always reactivate this campaign in the future by viewing it in the Archive section.</p>
    </Modal>
    <Modal open={unarchiveCampaignModal} setOpen={() => setUnarchiveCampaignModal(true)} primary={{label: 'Reactivate Campaign', action: () => submitCampaignStatus('active')}} secondary={{ label: 'Close', action: () => setArchiveCampaignModal(false)}}>
      <h3 className="font-bold text-md mb-3">Are you sure?</h3>
      <p className="text-gray-600 text-sm mb-3">Reactivating this campaign will allow invited influencers to submit proposals again.</p>
    </Modal>
    <ToastContainer theme="dark" autoClose={5000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/> 
  </div>
}