import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon,
  LinkIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, UploadIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, TrashIcon, RefreshIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {SocialMediaSetting, Modal, TagInputField, getHandle, getURL, getServiceName,getOrderStatus, PageTabs, InputField, Alert, TextArea, Select, FileDrop, Card, USStates} from '../components/Section';
import CardForm from '../components/CardForm';
import moment from 'moment';
import numeral from 'numeral';
import { initFacebookSdk } from '../Facebook';
import { useAuthRequest } from '../Auth';
import CryptoJS from 'crypto-js';


export default function InfluencerSettings() {
    const {authenticatedFetch} = useAuthRequest();

    const authFetch = authenticatedFetch;

    const [user, setUser] = useState(null);
    const [cards, setCards] = useState(null);
    const [charge, setCharge] = useState(null);

    const [name, setName] = useState('');
    const [saving, setSaving] = useState(false);
    const [shortBio, setShortBio] = useState('');
    const [uploading, setUploading] = useState(false);
    const [imageId, setImageId] = useState(null);
    const [tags, setTags] = useState([]);

    const [instagramAccessToken, setInstagramAccessToken] = useState('');

    const [facebookAccessToken, setFacebookAccessToken] = useState('');

    const [facebookModal, setFacebookModal] = useState(false);

    const [facebookPages, setFacebookPages] = useState([]);

    const [selectedFacebookPage, setSelectedFacebookPage] = useState(0);

    const [instagramModal, setInstagramModal] = React.useState(false);

    const [instagramAccounts, setInstagramAccounts] = React.useState([]);

    const [selectedInstagramAccount, setSelectedInstagramAccount] = React.useState(0);

    const [userState, setUserState] = useState(USStates[0]);

    const disconnectService = async(service) => {
      const response = await authFetch(`/api/v2/disconnect/${service}`, 'POST');

      const {error, message} = response;

      if (error) {
        toast(error);
      }

      if (message) {
        loadSettings();
        toast(message);

      }
    }

    const sendFacebookToServer = async() => {

      const body = new FormData();

      const page = facebookPages.find(page => page.id == selectedFacebookPage);

      const jsonString = JSON.stringify(page);

      const check = CryptoJS.MD5('fnc03jdsf'+jsonString+'bc29salty423dfg').toString();

      body.append('check', check);
      body.append('payload', jsonString);

      const response = await authFetch(`/api/v2/facebook`, 'POST', body);

      const { error, message } = response;

      if (error) {
        toast(error);
      }

      if (message) {
        setFacebookModal(false);
        toast(message);
        loadSettings();
      }
    }

    const addTag = (tag) => {
    if (tags.length >= 6) {
      return false;
    }
    
    tags.push(tag);

    setTags(tags);

  }

    const sendInstagramToServer = async() => {

      const body = new FormData();

      body.append('instagram_id', selectedInstagramAccount);
      body.append('instagram_access_token', instagramAccessToken);

      const response = await authFetch(`/api/v2/instagram`, 'POST', body);

      const { error, message } = response;

      if (error) {
        toast(error);
      }

      if (message) {
        setInstagramModal(false);
        toast(message);
        loadSettings();
      }
    }

    const connectTwitter = async() => {

      const response = await authFetch('/api/v2/twitter', 'GET');

      const {email, hash} = response;

      if (email && hash) {
        if (window.location.href.indexOf('localhost') >= 0) {
          window.location.replace(`https://beautyclout.dev/twitter?email=${email}&hash=${hash}`);
        } else {
          window.location.replace(`/twitter?email=${email}&hash=${hash}`);
        }
      }
      

      //
    }

    const connectInstagram = () => {
      window.FB.getLoginStatus(response => {
        var accessToken = '';
        if (response.status && response.status == 'connected') {
          accessToken = response.authResponse.accessToken;
          getInstagramAccounts(accessToken);
        } else {
          window.FB.login(function(response) {
            //console.log('fb.login', response);
            if (response.status == 'connected') {
              accessToken = response.authResponse.accessToken;
              setInstagramAccessToken(accessToken);
              getInstagramAccounts(accessToken);
            }
          }, {scope: 'instagram_basic,pages_show_list'});
        }

        if (accessToken.length > 0) {
          setInstagramAccessToken(accessToken);
        }

      });
    }

    const getInstagramAccounts = async(accessToken) => {

      window.FB.api(`/me/accounts?fields=instagram_business_account`, (response) => {
        
        var ids = [];
        if (response.data) {
          response.data.forEach(account => {
            if (account.instagram_business_account) {
              ids.push(account.instagram_business_account.id);
            } else if (account.id) {
              ids.push(account.id);
            }
          });

          if (ids.length > 0) {

            const body = new FormData();

            body.append('access_token', accessToken);
            ids.forEach((id, i) => {
              body.append('ids['+i+']', id);
            });

            authFetch(`/api/instagram-new`, 'POST', body).then(resp => {
              //console.log(resp.instagrams);
              if (resp.instagrams) {
                setSelectedInstagramAccount(resp.instagrams[0].id);
                setInstagramAccounts(resp.instagrams);
                setInstagramModal(true);
              }
            });

          } else {

          }

        } else {
          toast('Could not load instagram account');
        }
      });

    }

    const getFacebookPages = (accessToken) => {
      window.FB.api(`/me/accounts?fields=id,followers_count,name,username,picture`, (response) => {
        
          var ids = [];
          if (response.data) {
            setFacebookPages(response.data);
            setFacebookModal(true);
          } else {
            toast('Could not load facebook pages');
          }
      });
    }

    const connectFacebook = () => {
      window.FB.getLoginStatus(response => {
        var accessToken = '';
        if (response.status && response.status == 'connected') {
          accessToken = response.authResponse.accessToken;
          getFacebookPages(accessToken);
        } else {
          window.FB.login(function(response) {
            //console.log('fb.login', response);
            if (response.status == 'connected') {
              accessToken = response.authResponse.accessToken;
              setFacebookAccessToken(accessToken);
              getFacebookPages(accessToken);
            }
          }, {scope: 'pages_show_list'});
        }

        if (accessToken.length > 0) {
          setFacebookAccessToken(accessToken);
        }

      });
    }

    const saveHandle = async(value, type) => {
      const body = new FormData();

      body.append('handle', value);

      const response = await authFetch(`/api/v2/handles/${type}`, 'POST', body);

      const {error, message} = response;

      if (error) {
        toast(error);
      }

      if (message) {
        toast(message);
        loadSettings();
      }

    }

    const loadSettings = async() => {
      const response = await authFetch(`/api/user?show_address=1`, 'GET');

      const {error, user} = response;

      if (error) {
        toast(error);
      } else {

        setUser(user);
        var tags = user.tags.map(tag => tag.tag);
        setTags(tags);
        setName(user.name);
        if (user.short_description != null) {
          setShortBio(user.short_description);

        }

        if (user.state != null) {

          let state = USStates.find(state => state.value==user.state);

          if (state) {
            setUserState(state);

          }

        }

        setImageId(user.image_id);
      }

    }

    const deleteCard = async(source) => {
      const body = new FormData();

      body.append('source', source);

      const response = await authFetch('/api/v2/deleteCard', 'POST', body);

      const {message, error, card} = response;

      if (message) {
        toast(message);
        loadSettings();
      }

      if (error) {
        toast(error);
      }

    }

    

    const updateDescription = (description) => {
      if (description.length > 255) {
        return false;
      }

      setShortBio(description);
    }

    

    const uploadImage = async(e) => {
      const file = e.target.files[0];

      setUploading(true);
      
      const body = new FormData();

      body.append('file', file);

      const response = await authFetch('/api/dropzone', 'POST', body);

      const {error, id} = response;

      if (error) {
        toast(error);
      }

      if (id) {
         setImageId(id);
      }

      setUploading(false);
    }

    const removeTag = (index) => {
      var _tags = [...tags];
      
      _tags.splice(index, 1);

      setTags(_tags);
    }

    const handleUserAddress = (value, field) => {

      const allowed = ['first_name', 'last_name', 'address_1', 'address_2', 'city', 'state', 'zip'];

      if (!allowed.includes(field)) {
        return false;
      }

      const _user = {...user};

      _user[field] = value;

      setUser(_user);
    }

    const saveUserAddress = async() => {
      const body = new FormData();

      function nullEmpty(value) {
        if (value == null) {
          return '';
        }

        return value;
      }


      body.append('first_name', nullEmpty(user.first_name));
      body.append('last_name', nullEmpty(user.last_name));
      body.append('address_1', nullEmpty(user.address_1));
      body.append('address_2', nullEmpty(user.address_2));
      body.append('city', nullEmpty(user.city));
      body.append('state', nullEmpty(user.state));
      body.append('zip', nullEmpty(user.zip));

      const response = await authFetch(`/api/v2/address`, 'POST', body);

      const {message, error} = response;

      if (error) {
        toast(error);
      }

      if (message) {
        toast(message);
      }
    }

    const saveProfile = async() => {
      const body = new FormData();

      if (imageId != null) {
        body.append('image_id', imageId);
      }
      body.append('short_description', shortBio);

      body.append('name', name);

      body.append('tags', tags.join('|'));

      setSaving(true);

      const response = await authFetch(`/api/v2/influencers/profile`, 'POST', body);



      const { user, message, error } = response;

      if (message) {
        toast(message);
      }

      if (error) {
        toast(error);
      }

      if (user) {
        setImageId(user.image_id);
        setShortBio(user.short_description);
        setName(user.name);
      }

      setSaving(false);
    }

    useEffect(() => {
      loadSettings();
      initFacebookSdk();
    }, []);

    React.useEffect(() => {
      const _user = {...user};
      _user.state = userState.value;
      setUser(_user);
    }, [userState]);

    return <Section title="Settings">
        <div className="max-w-6xl mx-5 my-8">
        
        {user!=null&&<Card title="Profile" subtitle="Manage your profile" className="mb-8">
          <div className="grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 sm:gap-5">
            <div className="col-span-1 mb-5 sm:mb-0">
              <div className="relative">
                {imageId!=null?<img src={`https://res.cloudinary.com/wigmarket/w_500,h_500,c_fill,q_auto/${imageId}.jpg`} className="object-contain w-full rounded-lg"/>:<div className="w-full uppercase aspect-square bg-slate-100 rounded-lg"></div>}
                <input type="file" accept="image/*" onChange={e => uploadImage(e)} className="hidden" id="profile-image-upload" disabled={uploading}/>
                <label htmlFor="profile-image-upload" for="profile-image-upload" className="bottom-1 right-1 shadow bg-slate-50 w-10 h-10 flex items-center justify-center absolute rounded-full">{uploading?<RefreshIcon className="w-5 h-5 animate-spin"/>:<UploadIcon className="w-5 h-5"/>}</label>
              </div>
            </div>
            <div className="sm:col-span-2 md:col-span-3">
              <InputField label="Your Name" value={name} onChange={e => setName(e.target.value)} className="mb-3"/>
              <TextArea rows="4" placeholder="Describe your brand for influencers interested in working with you" helpText={`${(255-shortBio.length)} characters remaining`} label="Short Description" value={shortBio} onChange={(e) => updateDescription(e.target.value)}/>
              <TagInputField label="Tags" helpText="Enter up to 6 tags to help brands find you" addTag={addTag} removeTag={removeTag} tags={tags}/>
              
              <Button className="mt-5" primary disabled={user==null||saving==true}  onClick={() => saveProfile()}>Save Profile</Button>
            </div>

          </div>
        </Card>}

        {user!=null&&<Card title="Social Media Accounts" subtitle="Connect your social media accounts" className="mb-8">
          <div className="grid sm:grid-cols-2 gap-3">
            <SocialMediaSetting service={user.tiktok} type='tiktok' disconnectService={disconnectService} onSave={saveHandle}/>
             <SocialMediaSetting service={user.instagram} type='instagram' disconnectService={disconnectService} onClick={connectInstagram}/>
             <SocialMediaSetting service={user.facebook} type='facebook' disconnectService={disconnectService}  onClick={connectFacebook}/>
             <SocialMediaSetting service={user.twitter} type='twitter' disconnectService={disconnectService} onClick={connectTwitter}/>
             <SocialMediaSetting service={user.youtube} type='youtube' disconnectService={disconnectService} onSave={saveHandle}/>
          </div>
        </Card>}

        {
          user!=null&&<Card title="Shipping Address" subtitle="Your shipping address is required to receive products from brands for promotion" className="mb-8">
            <div className="space-y-3">
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3">
                <div>
                  <InputField label="First Name *" value={user.first_name} onChange={(e) => handleUserAddress(e.target.value, 'first_name')}/>
                </div>
                <div>
                  <InputField label="Last Name *" value={user.last_name} onChange={(e) => handleUserAddress(e.target.value, 'last_name')}/>
                </div>
              </div>
              <div>
              <InputField label="Address Line 1 *" value={user.address_1} onChange={(e) => handleUserAddress(e.target.value, 'address_1')}/>
              </div>
              <div>
              <InputField label="Address Line 2" value={user.address_2} onChange={(e) => handleUserAddress(e.target.value, 'address_2')}/>
              </div>
              <div className="grid grid-cols-1 sm:grid-cols-3 gap-3">
                <div>
                  <InputField label="City" value={user.city} onChange={(e) => handleUserAddress(e.target.value, 'city')} />
                </div>
                <div>
                  <Select options={USStates} selected={userState} setSelected={setUserState} label="State"/>
                </div>
                <div>
                  <InputField label="Zip" value={user.zip} onChange={(e) => handleUserAddress(e.target.value, 'zip')} maxLength={10}/>
                </div>
              </div>
              <Button primary onClick={() => saveUserAddress()}>Save Address</Button>
              </div>
          </Card>
        }

        {
          user!=null&&<Card title="Pause or delete your account" subtitle="You can take a break from Beauty Clout, or delete your account entirely.">
            <Link to={'/influencers/deactivation'} className="text-indigo-600 text-sm">Learn more</Link>
          </Card>
        }

        </div>
        <Modal open={instagramModal} setOpen={setInstagramModal} primary={{label: 'Connect Account', action: () => sendInstagramToServer() }} secondary={{label: 'Cancel', action: () => setInstagramModal(false)}}>
    <h3 className="text-md font-medium text-gray-600 mb-4">Connect an Instagram Account</h3>
    {
      instagramAccounts.map(account => <div className="flex justify-start items-center" key={`ig-account-${account.id}`}>
          <input
            id={account.id}
            name="ig-account"
            type="radio"
            onChange={(e) => setSelectedInstagramAccount(e.target.id)}
            defaultChecked={selectedInstagramAccount==account.id}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          
          <div className="flex justify-between w-4/5 ml-2">
            <div className="w-2/3">
              <h3 className="text-sm font-medium text-gray-700 mb-0 pb-0">{account.name}</h3>
              <small className="font-medium text-gray-500">@{account.username}</small>
            </div>
            <div className="w-1/3">
              <h3 className="text-sm block text-right font-bold text-gray-700 mb-0 pb-0">{numeral(account.followers_count).format('0,0')}</h3>
              <small className=" block text-right font-medium text-gray-500">followers</small>
            </div>
          </div>
        </div>)
    }
  </Modal>
  <Modal open={facebookModal} setOpen={setFacebookModal} primary={{label: 'Connect Facebook Page', action: () => sendFacebookToServer() }} secondary={{label: 'Cancel', action: () => setFacebookModal(false)}}>
    
    <h3 className="text-md font-medium text-gray-600 mb-4">Connect a Facebook Page</h3>
    {
      facebookPages.map(account => <div className="flex justify-start items-center mb-2" key={`fb-account-${account.id}`}>
          <input
            id={account.id}
            name="facebook-page"
            type="radio"
            onChange={(e) => setSelectedFacebookPage(e.target.id)}
            checked={selectedFacebookPage==account.id}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          <img src={account.picture.data.url} className="rounded-full h-12 w-12 ml-2"/>
          <div className="flex justify-between w-4/5 ml-2">
            <div className="w-2/3">
              <h3 className="text-sm font-medium text-gray-700 mb-0 pb-0">{account.name}</h3>
              <small className="font-medium text-gray-500">@{account.username}</small>
            </div>
            <div className="w-1/3">
              <h3 className="text-sm block text-right font-bold text-gray-700 mb-0 pb-0">{numeral(account.followers_count).format('0,0')}</h3>
              <small className=" block text-right font-medium text-gray-500">followers</small>
            </div>
          </div>
        </div>)
    }
  </Modal>
        <ToastContainer theme="dark" autoClose={2000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/>
    </Section>
}