import React, {useState, useEffect, Fragment, useCallback} from 'react'
import {
  Link
} from "react-router-dom";
import { CheckIcon, SelectorIcon, XCircleIcon, RefreshIcon, ChevronDownIcon, ChevronLeftIcon, ChevronRightIcon, StarIcon as SolidStarIcon} from '@heroicons/react/solid'
import { CalendarIcon, ChatIcon, ThumbUpIcon, UserIcon, HeartIcon, StarIcon, PaperAirplaneIcon, CashIcon,ShoppingBagIcon,  DocumentTextIcon, CheckCircleIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, XCircleIcon as XCircleIcon2, ArrowCircleRightIcon } from '@heroicons/react/outline';

import { Dialog, Combobox, Listbox, Transition } from '@headlessui/react'
import {useDropzone} from 'react-dropzone';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';
import blogIcon from '../images/blog.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    instagram_story: {name: 'Instagram Story', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: blogIcon},
  };


export function Stars({user}) {

  const rating = user.rating_average!=null?user.rating_average:0;

  const stars = [];
  const empty = [];
  for(var i = 0; i < Math.floor(user.rating_average); i++) {
    stars.push('star-'+i);
  }
  for(var i = Math.floor(user.rating_average); i < 5; i++) {
    empty.push('star-'+i);
  }

  return <div className="mt-0 pt-0">
    {
      stars.map((star, i) => <SolidStarIcon key={`star-${user.id}-${i}`} className="text-yellow-500 w-4 h-4 inline-block"/>)
    }
    {
      empty.map((star, i) => <SolidStarIcon key={`star-${user.id}-${i}`} className="text-gray-300 w-4 h-4 inline-block"/>)
    }
    <span className="text-gray-600 text-xs">({user.review_count})</span>

  </div>
}


export default function Section({title, children, primaryAction=null, condensed=false, className=''}) {

  return <main className={"flex-1 bg-gray-50 min-h-screen bg-gray-50 max-w-6xl"}>
            <div className={classNames("flex md:items-center justify-between px-5 pt-5 ", className)}>
              <div className="flex-1 min-w-0">
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">{title}</h2>
              </div>
              <div className="mt-4 flex md:mt-0 md:ml-4">
                
                {primaryAction!=null&&(primaryAction.url?<Link to={primaryAction.url} className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >{primaryAction.label}</Link>:<button onClick={primaryAction.onClick}
                  type="button"
                  className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {primaryAction.label}
                </button>)}
              </div>
            </div>
            { children }
          </main>
};

export function getURL(service) {
  switch(service.service) {
    case 'twitter':
      return 'https://twitter.com/'+service.handle;
    case 'facebook':
      return 'https://www.facebook.com/'+service.handle;
    case 'instagram':
      return 'https://instagram.com/'+service.handle;
    case 'tiktok':
      return 'https://tiktok.com/@'+service.handle;
    case 'youtube':
      return 'https://youtube.com/'+service.handle;
    case 'blog':
      return 'https://'+service.handle;
    default:
      return '#';
  }
}

export function getHandle(service) {

  if (!service) {
    return <span className="italic text-gray-500">Account Disconnected</span>
  }

  switch(service.service) {
    case 'blog':
      return service.handle;
    default:
      return '@'+service.handle;
  }
}

const getFulfillmentStatus = (fulfillment) => {
  switch(fulfillment.status) {
    case 'delivered':
      return {status: 'Delivered', code: 'delivered'};
    case 'pre_transit':
      return {status: 'Pre-transit', code: 'pre_transit'};
    case 'out_for_delivery':
      return {status: 'Out for Delivery', code: 'out_for_delivery'};
    case 'return_to_sender':
      return {status: 'Return to Sender', code: 'return_to_sender'};
    case 'in_transit':
      return {status: 'In transit', code: 'in_transit'};
    default:
      return {status: 'Unknown', code: fulfillment.status};
  }
}

export function getOrderStatus(order) {



  if (order.status == 'created') {
    return {status: 'Not Shipped', code: 'created'};
  }

  if (order.status == 'shipped') {
    if (order.fulfillments.length == 1) {
      return getFulfillmentStatus(order.fulfillments[0]);
    }

    if (order.fulfillments.length > 1) {
      var delivered = false;
      var notDelivered = false;

      order.fulfillments.forEach(fulfillment => {
        if (fulfillment.status == 'delivered') {
          delivered = true;
        } else {
          notDelivered = true;
        }
      });

      if (delivered == true && notDelivered == true) {
        return {status: 'Partially delivered', code: 'partially_delivered'};
      }

      if (notDelivered == false && delivered == true) {
        return {status: 'Delivered', code: 'delivered'};
      }

      return {status: 'Shipped', code: 'shipped'};


    }
  }
}

export function getOrderStatusName(order) {



  if (order.status == 'created') {
    return 'Not Shipped';
  }

  if (order.status == 'shipped') {
    if (order.fulfillments.length == 1) {
      return getFulfillmentStatus(order.fulfillments[0]).status;
    }

    if (order.fulfillments.length > 1) {
      var delivered = false;
      var notDelivered = false;

      order.fulfillments.forEach(fulfillment => {
        if (fulfillment.status == 'delivered') {
          delivered = true;
        } else {
          notDelivered = true;
        }
      });

      if (delivered == true && notDelivered == true) {
        return 'Partially delivered';
      }

      if (notDelivered == false && delivered == true) {
        return 'Delivered';
      }

      return 'Shipped';


    }
  }
}



export function getServiceName(service) {
  switch(service.service) {
    case 'twitter':
      return 'Twitter';
    case 'facebook':
      return 'Facebook';
    case 'instagram':
      return 'Instagram';
    case 'tiktok':
      return 'TikTok';
    case 'youtube':
      return 'YouTube';
    case 'blog':
      return 'Blog';
    default:
      return 'Social';
  }
}

export function getServiceNameFromString(service) {
  switch(service) {
    case 'twitter':
      return 'Twitter';
    case 'facebook':
      return 'Facebook';
    case 'instagram':
      return 'Instagram';
    case 'tiktok':
      return 'TikTok';
    case 'youtube':
      return 'YouTube';
    case 'blog':
      return 'Blog';
    default:
      return 'Social';
  }
}

export function Steps({steps, className, campaignId=''}) {
  return <nav aria-label="Progress" className={className}>
      <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8 p-5">
        {steps.map((step) => {
          var url = step.href.replace('{id}', campaignId).replace('//', '/');

          return (
          <li key={step.name} className="md:flex-1">
            {step.status === 'complete' || step.status === 'current' ? (
              <Link
                to={url}
                className="group pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
              >
                <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </Link>
            ) : step.linked ? (
              <Link
                to={url}
                className="pl-4 py-2 flex flex-col border-indigo-600 md:pl-0 md:pt-4 md:pb-0 md:border-l-0"
                aria-current="step"
              >
                <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase">{step.id}</span>
                <span className="text-sm font-medium">{step.name}</span>
              </Link>
            ) : (
              <span
                
                className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
              >
                <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                  {step.id}
                </span>
                <span className="text-sm font-medium">{step.name}</span>
              </span>
            )}
          </li>
        )
          })}
      </ol>
    </nav>
};

export function AutoComplete({endpoint, onSelect, label, placeholder}) {

  const {authenticatedFetch} = useAuthRequest();

  const [query, setQuery] = useState('');
  const [selected, setSelected] = useState();

  const [loading, setLoading] = useState(false);

  const [items, setItems] = useState([]);

  const [timeoutId, setTimeoutId] = useState(0);

  useEffect(() => {
    if (selected) {
      onSelect(selected);
      setQuery('');
    }
  }, [selected]);

  useEffect(() => {
    if (timeoutId != 0) {
      clearInterval(timeoutId);
    }

    let _timeoutId = setTimeout(async() => {

      setLoading(true);

      let response = await authenticatedFetch(`${endpoint}?q=${query}&limit=20`, 'GET');

      let { products } = response;

      setItems(products);

      setLoading(false);


    }, 500);
    setTimeoutId(_timeoutId);
  }, [query]);


  return <Combobox as="div" value={selected} onChange={setSelected}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={query}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {items.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {loading==true&&<div className="w-full h-full bg-white opacity-50 absolute top-0 left-0 z-40 flex items-center justify-center">
              <RefreshIcon className="animate-spin w-10 h-10 text-gray-800"/>
            </div>}
            {items.map((item) => (
              <Combobox.Option
                key={`item-${item.id}`}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <img src={item.image_url} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                      <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{item.name}</span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
};


export function SearchComboBox({items, onSelect, label, placeholder}) {

  const [query, setQuery] = useState('')
  const [selected, setSelected] = useState();

  useEffect(() => {
    if (selected) {
      onSelect(selected);
    }
  }, [selected]);

  const filteredItems =
    query == ''
      ? items
      : items.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase())
        });

  return <Combobox as="div" value={selected} onChange={setSelected}>
      <Combobox.Label className="block text-sm font-medium text-gray-700">{label}</Combobox.Label>
      <div className="relative mt-1">
        <Combobox.Input
          className="w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item) => item?.name}
          placeholder={placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredItems.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
            {filteredItems.map((item) => (
              <Combobox.Option
                key={`item-${item.id}`}
                value={item}
                className={({ active }) =>
                  classNames(
                    'relative cursor-default select-none py-2 pl-3 pr-9',
                    active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex items-center">
                      <img src={item.image_url} alt="" className="h-6 w-6 flex-shrink-0 rounded-full" />
                      <span className={classNames('ml-3 truncate', selected && 'font-semibold')}>{item.name}</span>
                    </div>

                    {selected && (
                      <span
                        className={classNames(
                          'absolute inset-y-0 right-0 flex items-center pr-4',
                          active ? 'text-white' : 'text-indigo-600'
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
};

export function Alert({children, title, status, className=''}) {

  const colors = {
    primary : 'blue',
    success: 'green',
    info: 'cyan',
    warning: 'orange',
    danger: 'red',
    default: 'gray'
  };

  var color;

  if (!colors[status]) {
    color = 'default';
  } else {
    color = colors[status];
  }


  return <div className={classNames(`rounded-md bg-${color}-50 p-4 shadow`, className)}>
      <div className="bg-red-50 bg-blue-50 bg-green-50 bg-cyan-50 bg-gray-50 bg-orange-50 text-red-400 text-blue-400 text-green-400 text-cyan-400 text-gray-400 text-orange-400 text-red-800 text-blue-800 text-green-800 text-cyan-800 text-gray-800 text-orange-800 text-red-700 text-blue-700 text-green-700 text-cyan-700 text-gray-700 text-orange-700 "/>
      <div className="flex">
        <div className="flex-shrink-0">
          {status=='danger'&&<XCircleIcon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />}
          {status=='success'&&<CheckIcon className={`h-5 w-5 text-${color}-400`} aria-hidden="true" />}
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-${color}-800`}>{title}</h3>
          <div className={`mt-2 text-sm text-${color}-700`}>
            {children}
          </div>
        </div>
      </div>
    </div>
}

export function InputField(props) {
  return <>
    {
      props.label&&<label className={classNames("block text-sm font-medium text-gray-700", props.labelClassName)}>{props.label}</label>
    }
    {props.helpText&&<div className="block mb-2 text-gray-600 text-xs">{props.helpText}</div>}
    <input {...props} disabled={props.disabled} className={classNames("disabled:opacity-50 disabled:shadow-none w-full rounded-md border border-gray-300 bg-white py-2 pl-3 pr-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm", props.className)}/>
    </>;
}

export function TagInputField(props) {

  const [currentInput, setCurrentInput] = React.useState('');

  const keyDown = (e) => {
    if (e.keyCode == 13 || e.charCode == 13 || e.keyCode==188) {
      addTag(e.target.value);
      e.preventDefault();
    }
  }

  const addTag = (tag) => {
    if (tag.length > 0) {
      props.addTag(tag);
      setCurrentInput('');
    }
  }

  return <>
    {
      props.label&&<label className="block text-sm font-medium text-gray-700 mt-3">{props.label}</label>
    }
    {props.helpText&&<div className="block mb-1 text-gray-400 text-sm">{props.helpText}</div>}
    <div className="w-full flex justify-start rounded-md border border-gray-300 bg-white pl-3 pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 sm:text-sm">
      {
        props.tags&&props.tags.length>0&&props.tags.map((tag, i) => <div className="flex items-center px-2 py-1 my-2 bg-gray-50 rounded-md font-bold text-gray-500 text-xs uppercase border border-gray-300 mr-1" key={`tag-${i}`}>
          {tag}
          <button className="border-transparent border bg-transparent mr-0 pr-0 ml-1 text-gray-700" onClick={() => props.removeTag(i)}><XCircleIcon className="h-4 w-4 fill-gray-700"/></button>
          </div>)
      }  

      <input value={currentInput} onChange={(e) => setCurrentInput(e.target.value)} onKeyDown={keyDown} className="border-transparent border focus:ring-transparent focus:border-0 focus:outline-none py-2" type="text"/>
    </div>
  </>
}

export function TextArea(props) {
  return <div className={props.className?props.className:''}>
      {
        props.label&&<label className="block text-sm font-medium text-gray-700">
        {props.label}
      </label>
      }

      {props.helpText&&<div className="block mb-1 text-gray-400 text-sm">{props.helpText}</div>}
      <div className="mt-1">
        <textarea
          {...props}
          className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full text-sm border border-gray-300 p-3 rounded-md"
          
        ></textarea>
      </div>
    </div>
}

export function Select(props) {
  return (
    <Listbox value={props.selected} onChange={props.setSelected} disabled={props.disabled?props.disabled:false}>
      {({ open }) => (
        <>
          {props.label&&<Listbox.Label className="block text-sm font-medium text-gray-700 mb-0">{props.label}</Listbox.Label>}
          {props.helpText&&<span className="text-xs text-gray-600 block mb-2">{props.helpText}</span>}
          <div className=" relative">
            <Listbox.Button className="bg-white disabled:opacity-60 relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm flex items-center">
              {props.selected.icon&&<img src={props.selected.icon} className="w-10 h-10 rounded-md mr-2"/>}
              <span className="block truncate text-sm text-gray-700">{props.selected.name}</span>
              <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute z-50 mt-1 w-full bg-white shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm">
                {props.options.map((option) => (
                  <Listbox.Option
                    key={(option.id||option.value)}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-600' : 'text-gray-900',
                        'cursor-default select-none relative py-2 pl-3 pr-9 text-sm'
                      )
                    }
                    value={option}
                  >
                    {({ selected, active }) => (
                      <>

                        <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate flex items-center')}>
                          {option.icon&&<img src={option.icon} className="w-10 h-10 bg-white p-1 rounded-md flex items-center justify-center mr-2"/>}
                          {option.name}
                        </span>

                        {selected ? (
                          <span
                            className={classNames(
                              active ? 'text-white' : 'text-indigo-600',
                              'absolute inset-y-0 right-0 flex items-center pr-4'
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export function FileDrop(props) {

  const onDrop = (acceptedFiles) => {
    if (props.multiple) {
      props.onChange(acceptedFiles);
    } else {
      props.onChange(acceptedFiles[0]);
    }
  }

  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop});

  return <div className="">
              {props.label&&<label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                {props.label}
              </label>}
              <div className="mt-1 sm:mt-0 sm:col-span-2">
                <div {...getRootProps()} className="max-w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md mt-1">
                  <div className="space-y-1 text-center">
                    {props.uploading?<RefreshIcon className="w-12 h-12 animate-spin text-gray-300 mx-auto my-3"/>:
                    (props.thumbnailUrl?<img src={props.thumbnailUrl} className="w-32 h-32 mx-auto"/>:<svg
                      className="mx-auto h-12 w-12 text-gray-400"
                      stroke="currentColor"
                      fill="none"
                      viewBox="0 0 48 48"
                      aria-hidden="true"
                    >
                      <path
                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>)}
                    <div className="flex text-sm text-gray-600  justify-center">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                      >
                        <span>Upload a file</span>
                        <input accept="image/*" className="sr-only" {...getInputProps()}/>
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    {props.helpText&&<p className="text-xs text-gray-500">{props.helpText}</p>}
                    {props.error&&<p className="text-xs text-red-700 bg-red-50 border-red-300 border px-2 py-1 rounded-md">{props.error}</p>}
                  </div>

                </div>
              </div>
            </div>
}

export function Card({title, className, children, subtitle='', unstyled=false, primaryAction=false}) {
  return <section>
        <div className={classNames("bg-white shadow sm:rounded-lg", className)}>
          <div className="border-b px-4 py-5 sm:px-6">
            <div className="flex items-center justify-between">
            <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
              {title}
            </h2>
            {
              primaryAction&&primaryAction.action&&<button onClick={primaryAction.action} disabled={primaryAction.disabled?'disabled':false} className="disabled:text-gray-400 bg-transparent text-indigo-600 hover:text-indigo-700 text-sm">{primaryAction.label}</button>
            }
            {
              primaryAction&&primaryAction.url&&<Link to={primaryAction.url} disabled={primaryAction.disabled?'disabled':false} className="disabled:text-gray-400 bg-transparent text-indigo-600 hover:text-indigo-700 text-sm">{primaryAction.label}</Link>
            }
            </div>
            {subtitle!=''&&<p className="mt-1 max-w-2xl text-sm text-gray-500">{subtitle}</p>}
          </div>
          {
            unstyled?children:<div className="px-4 py-5 sm:px-6">
              {children}
            </div>
          }
        </div>
      </section>
}

export function SocialMediaSetting({service, disconnectService, type, onClick, saveService, onSave, className=''}) {

  const services = {
      facebook : {bg: 'bg-slate-700', icon: facebookIcon, name: 'Facebook', oauth: true},
      instagram: {bg: 'bg-purple-700', icon: instagramIcon, name: 'Instagram', oauth: true},
      twitter: {bg: 'bg-cyan-600', icon: twitterIcon, name: 'Twitter', oauth: true, type: 'handle'},
      youtube: {bg: 'bg-red-500', icon: youtubeIcon, name: 'YouTube', oauth: false, type: 'username'},
      tiktok: {bg: 'bg-pink-500', icon: tiktokIcon, name: 'TikTok', oauth: false, type: 'username'}
  };

  const [open, setOpen] = useState(false);

  const [handle, setHandle] = useState('');

  const _service = services[type];

  const action = _service.oauth==true?onClick:() => setOpen(true);

  if (service == null) {
    if (open) {
      return <div className={classNames(`${_service.bg} px-3 py-4 text-sm w-full rounded-md mb-1`, className)}>
        <div className="max-w-lg flex rounded-md shadow-sm h-10">
          <span className="inline-flex items-center px-3 rounded-l-md border border-r-1 border-gray-300 bg-gray-50 text-gray-500 sm:text-sm">
            @
          </span>
          <input
            type="text"
            name="handle"
            placeholder={_service.name+' '+_service.type}
            id="handle"
            onChange={(e) => setHandle(e.target.value)}
            autoComplete="handle"
            className="pl-2 flex-1 block w-full min-w-0 rounded-none rounded-r-md sm:text-sm border-gray-300"
          />
          <button className="w-16 text-white" onClick={() => onSave(handle, type)}>Save</button>
          <button className="w-16 text-white" onClick={() => setOpen(false)}>Cancel</button>
        </div>
      </div>
    } else {
      return <button className={`${_service.bg} px-5 py-4 text-sm block font-medium text-white w-full rounded-md mb-1`} onClick={action}>Connect {_service.name}</button>
    }
  }


  return <div className={`flex mb-1 rounded-md justify-between items-center p-3 ${_service.bg} text-white  text-sm block font-medium`}>
        <div className="flex">
          <div className="bg-white rounded-full p-2">
            <img src={_service.icon} className="w-8 h-8"/>
          </div>
          <div className="ml-2 items-center h-full">
            <div className="block font-medium">@{service.handle}</div>
            <small>{numeral(service.followers).format('0a')} followers</small>
          </div>
        </div>
        <button className="bg-transparent border border-white text-white rounded-md text-xs px-2 py-1" onClick={() => disconnectService(service.service)}>Disconnect</button>
       </div>
}

export function PageTabs({navigate, tabs}) {

  const handleSelect = (e) => {

    const tab = tabs.find(tab => tab.name==e.target.value);

    if (tab) {
      navigate(tab.url);
    }
  }

  return <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
          onChange={handleSelect}
        >
          {tabs.map((tab) => (
            <option key={tab.name} value={tab.name}>{tab.name}{(tab.count&&tab.count>0)?' ('+tab.count+')':null}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <nav className="relative z-0 rounded-lg shadow flex divide-x divide-gray-200" aria-label="Tabs">
          {tabs.map((tab, tabIdx) => (
            <Link
              key={tab.name}
              to={tab.url}
              className={classNames(
                tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                tabIdx === 0 ? 'rounded-l-lg' : '',
                tabIdx === tabs.length - 1 ? 'rounded-r-lg' : '',
                'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-sm font-medium text-center hover:bg-gray-50 focus:z-10'
              )}
              aria-current={tab.current ? 'page' : undefined}
            >
              <span>{tab.name}
              {tab.count ? (
                  <span
                    className={classNames(
                      tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-red-600 text-white',
                      'hidden ml-1 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}

              </span>
              <span
                aria-hidden="true"
                className={classNames(
                  tab.current ? 'bg-indigo-500' : 'bg-transparent',
                  'absolute inset-x-0 bottom-0 h-0.5'
                )}
              />
            </Link>
          ))}
        </nav>
      </div>
    </div>
}

export function Tabs({navigate, tabs, onClick=null, className=''} ) {

  const handleSelect = (e) => {
    tabs.forEach(tab => {
      if (tab.name == e.target.value) {
        if (tab.url) {
          navigate(tab.url);
        } else {
          onClick(tab);
        }
      }
    });
  }

  var defaultTab = tabs.find((tab) => tab.current);

  var defaultValue = {name: '', value: ''};

  if (defaultTab) {
    defaultValue = defaultTab.name;
  }

  return (
    <div>
      <div className="sm:hidden mt-4">
        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 border-gray-300 text-gray-700 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-sm rounded-md"
          defaultValue={defaultValue}
          onChange={handleSelect}
        >
          {tabs.map((tab) => (
            <option key={tab.name}>{tab.name}</option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              tab.url?<Link
                key={tab.name}
                to={tab.url}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}
              >
                {tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                      'hidden ml-1 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}
              </Link>:<button key={tab.name}
                onClick={() => onClick(tab)}
                className={classNames(
                  tab.current
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.current ? 'page' : undefined}>{tab.name}
                {tab.count ? (
                  <span
                    className={classNames(
                      tab.current ? 'bg-indigo-100 text-indigo-600' : 'bg-gray-100 text-gray-900',
                      'hidden ml-1 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block'
                    )}
                  >
                    {tab.count}
                  </span>
                ) : null}</button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export function Modal({open, setOpen, secondary=null, primary, children}) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-5 pb-4 text-left shadow-xl transform transition-all sm:my-8 sm:max-w-lg w-full sm:p-6">
                <div>
                  {children}
                </div>
                <div className={`mt-5 sm:mt-6 sm:grid-flow-row-dense ${secondary!=null?'sm:grid sm:grid-cols-2 sm:gap-3':''}`}>
                  <button
                    type="button"
                    className="disabled:opacity-50 w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:col-start-2 sm:text-sm"
                    onClick={() => primary.action()}
                    disabled={primary.disabled?'disabled':false}
                  >
                    {primary.label}
                  </button>
                  {secondary!=null&&<button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={() => secondary.action()}
                  >
                    {secondary.label}
                  </button>}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}


export function getIcon(notification) {

  const className = 'w-6 h-6 text-gray-500 mr-2';

  switch (notification.icon) {
    case 'fa-envelope':
      return <ChatIcon className={className}/>
    case 'fa-envelope':
      return <ChatIcon className={className}/>
    case 'fa-remove':
      return <XCircleIcon2 className={className}/>
    case 'fa-check':
      return <CheckCircleIcon className={className}/>
    case 'fa-star':
      return <StarIcon className={className}/>
    case 'fa-thumbs-o-up':
      return <ThumbUpIcon className={className}/>
    case 'fa-arrow-right':
      return <ArrowCircleRightIcon className={className}/>
    case 'fa-document':
      return <DocumentTextIcon className={className}/>
    case 'fa-cart':
      return <ShoppingBagIcon className={className}/>
    default:
      return <ArrowCircleRightIcon className={className}/>
  }

}

export function DashboardCard({icon, label, value, url=false, id=''}) {


  return <div className="bg-white overflow-hidden shadow rounded-lg" id={id}>
        <div className="p-5">
          <div className="flex items-center">
            <div className="flex-shrink-0">
              {icon}
            </div>
            <div className="ml-5 w-0 flex-1">
              <dl>
                <dt className="text-sm font-medium text-gray-500 truncate">{label}</dt>
                <dd>
                  <div className="text-lg font-medium text-gray-900">{value}</div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
        {url!=null&&<div className="bg-gray-50 px-5 py-3">
          <div className="text-sm">
            <Link to={url} className="font-medium text-cyan-700 hover:text-cyan-900">
              View all
            </Link>
          </div>
        </div>}
      </div>
}

export function Pager({data, changePage}) {

  const prevPage = () => {
    
    if (data.current_page <= 1) {
      return false;
    }

    changePage(data.current_page-1);
  }

  const nextPage = () => {
    if (data.current_page >= data.last_page) {
      return false;
    }

    changePage(data.current_page+1);
  }

  return <div className="flex justify-between">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => prevPage()}
              disabled={data.current_page<=1}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            
            <button
              onClick={() => nextPage()}
              disabled={data.current_page==data.last_page}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div className="pt-1">
            <span className="text-gray-500 text-sm">Showing <strong>{data.from}</strong> to <strong>{data.to}</strong> of <strong>{data.total}</strong> results</span>
          </div>
        </div>
}

export function Gig({gig}) {
  return <Link className="block bg-white w-full shadow divide-y" to={`/gigs/${gig.id}`}>
    <div className="">
    <img src={`https://res.cloudinary.com/wigmarket/w_640,h_420,g_face,c_fill,q_auto/${gig.image.image_id}.jpg`} className="w-full"/>
    <div className="mt-3 px-4 pb-3">
      <div className="flex mt-1 items-start space-x-2 justify-between">
        <div className="flex space-x-2 items-center">
          <img src={`https://res.cloudinary.com/wigmarket/w_50,h_50,c_fill,q_auto/${gig.user.image_id}.jpg`} className="rounded-full w-10 h-10"/>
          <div>
            <span className="text-gray-600 font-medium text-sm block mb-0 pb-0">{gig.user.name}</span>
            <Stars user={gig.user}/>
          </div>
        </div>

        <button className="p-0 hidden text-gray-500 hover:text-gray-900"><HeartIcon className="w-6 h-6 block "/></button>
      </div>
      <h3 className="text-sm mt-2 text-gray-800 hover:text-indigo-600 block sm:truncate">{gig.title}</h3>
      {
        false&&gig.subtype&&<div>
          <span className="text-gray-500 font-medium text-xs">{gig.subtype.name}</span>
        </div>
      }
      
    </div>
    </div>
    <div className="px-4 py-3 flex justify-between items-center">
      <div className="space-x-2">
        {socialMediaServices[gig.primary_service]&&<img src={socialMediaServices[gig.primary_service].icon} className="w-4 h-4 inline-block"/>}
        <span className="border-r border-gray-200"></span>
        {
          gig.services!=null&&gig.services.map(service => (socialMediaServices[service.service]?<img src={socialMediaServices[service.service].icon} key={`gig-${gig.id}-${service.service}`} className="w-4 h-4 inline-block"/>:null))
        }
      </div>
      <span className="text-sm">Starting at <strong className="text-green-600">${numeral(gig.fee).format('0,0.00')}</strong></span>
    </div>
  </Link>
}

export function GigsLoadingPage() {
  return <section className="h-screen bg-gray-50">
      <div className="mx-5 animate-pulse mt-5 space-y-8">
        <h1 className="bg-gray-200 text-2xl font-bold">&nbsp;</h1>
        <h1 className="bg-gray-200 text-2xl font-bold">&nbsp;</h1>
        <div className="grid md:grid-cols-3 lg:grid-cols-4 3xl:grid-cols-6 gap-8">
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
          <div className="h-72 bg-gray-200">
            &nbsp;
          </div>
        </div>
      </div>
    </section>
}

export function Nav({homeUrl, links}) {
  return <div className="bg-white px-4 sm:px-6 py-4"><nav className="flex" aria-label="Breadcrumb">
        <div className="flex sm:hidden">
          <Link to={homeUrl} className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700">
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600" xDescription="Heroicon name: solid/arrow-narrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
<path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
</svg>
            <span>Back</span>
          </Link>
        </div>
        <div className="hidden sm:block">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to={homeUrl} className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" x-description="Heroicon name: solid/home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
</svg>
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            {
              links.map((link, i) => <li key={`breadcrumb-link-${i}-${link.name}`}>
                <div className="flex items-center">
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"></path>
                  </svg>
                  <Link to={link.url} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{link.name}</Link>
                </div>
              </li>)
            }
              
            
            
            
          </ol>
        </div>
      </nav>
      </div>
}

export function MenuButton({options, label, className, fullWidth=false, primary=false}) {

  const doSelectedAction = (selected) => {
    if (selected.action) {
      selected.action();
    }

    return false;
  }

  return (
    <Listbox onChange={doSelectedAction} className={className}>
      {({ open }) => (
        <>
          <Listbox.Label className="sr-only"> </Listbox.Label>
          <div className="relative">
            <div className={"inline-flex divide-x divide-gray-200 rounded-md shadow "+(fullWidth?'w-full ':' ')+(primary?' border-indigo-600':"border-gray-200")}>
              <div className={"inline-flex divide-x divide-gray-200 rounded-md "+(fullWidth?'w-full':'')}>
                <div className={"flex-1 inline-flex items-center rounded-l-md border border-transparent  py-2 pl-3 pr-4 "+(primary?' bg-indigo-600 text-white ':' bg-white text-gray-600 ')}>
                
                  <p className="ml-2.5 text-sm font-medium">{label}</p>
                </div>
                <Listbox.Button className={(primary?'bg-indigo-600 text-white hover:bg-indigo-700 hover:text-white':'text-white text-gray-600 hover:bg-gray-50 hover:text-gray-700 ')+" inline-flex items-center rounded-l-none rounded-r-md p-2 text-sm font-medium text-white"}>
                  <span className="sr-only">{label}</span>
                  <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
                </Listbox.Button>
              </div>
            </div>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute right-0 z-10 mt-2 w-72 origin-top-right divide-y divide-gray-200 overflow-hidden rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                {options.map((option) => (
                  <Listbox.Option
                    key={option.title}
                    className={({ active }) =>
                      classNames(
                        active ? 'text-white bg-indigo-500' : 'text-gray-900',
                        'cursor-default select-none p-4 text-sm'
                      )
                    }
                    value={option}
                  >
                    
                      <div className="flex flex-col">
                        <div className="flex justify-between">
                          <p>{option.title}</p>
                          
                        </div>
                        {option.description&&<p className="text-gray-500 mt-2">
                          {option.description}
                        </p>}
                      </div>
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  )
}

export const USStates = [
{ name: '-- Select State', value: ''},
{ 'name':'Alabama', 'value': 'AL' },
{ 'name':'Alaska', 'value': 'AK'},
{ 'name':'Arizona', 'value': 'AZ'},
{ 'name':'Arkansas', 'value': 'AR'},
{ 'name':'California', 'value': 'CA'},
{ 'name':'Colorado', 'value': 'CO'},
{ 'name':'Connecticut', 'value': 'CT'},
{ 'name':'Delaware', 'value': 'DE'},
{ 'name':'District of Columbia', 'value': 'DC'},
{ 'name':'Florida', 'value': 'FL'},
{ 'name':'Georgia', 'value': 'GA'},
{ 'name':'Hawaii', 'value': 'HI'},
{ 'name':'Idaho', 'value': 'ID'},
{ 'name':'Illinois', 'value': 'IL'},
{ 'name':'Indiana', 'value': 'IN'},
{ 'name':'Iowa', 'value': 'IA'},
{ 'name':'Kansas', 'value': 'KS'},
{ 'name':'Kentucky', 'value': 'KY'},
{ 'name':'Louisiana', 'value': 'LA'},
{ 'name':'Maine', 'value': 'ME'},
{ 'name':'Maryland', 'value': 'MD'},
{ 'name':'Massachusetts', 'value': 'MA'},
{ 'name':'Michigan', 'value': 'MI'},
{ 'name':'Minnesota', 'value': 'MN'},
{ 'name':'Mississippi', 'value': 'MS'},
{ 'name':'Missouri', 'value': 'MO'},
{ 'name':'Montana', 'value': 'MT'},
{ 'name':'Nebraska', 'value': 'NE'},
{ 'name':'Nevada', 'value': 'NV'},
{ 'name':'New Hampshire', 'value': 'NH'},
{ 'name':'New Jersey', 'value': 'NJ'},
{ 'name':'New Mexico', 'value': 'NM'},
{ 'name':'New York', 'value': 'NY'},
{ 'name':'North Carolina', 'value': 'NC'},
{ 'name':'North Dakota', 'value': 'ND'},
{ 'name':'Ohio', 'value': 'OH'},
{ 'name':'Oklahoma', 'value': 'OK'},
{ 'name':'Oregon', 'value': 'OR'},
{ 'name':'Pennsilvania', 'value': 'PA'},
{ 'name':'Puerto Rico', 'value': 'PR'},
{ 'name':'Rhode Island', 'value': 'RI'},
{ 'name':'South Carolina', 'value': 'SC'},
{ 'name':'South Dakota', 'value': 'SD'},
{ 'name':'Tennessee', 'value': 'TN'},
{ 'name':'Texas', 'value': 'TX'},
{ 'name':'Utah', 'value': 'UT'},
{ 'name':'Vermont', 'value': 'VT'},
{ 'name':'Virgin Islands', 'value': 'VI'},
{ 'name':'Virginia', 'value': 'VA'},
{ 'name':'Washington', 'value': 'WA'},
{ 'name':'West Virginia', 'value': 'WV'},
{ 'name':'Wisconsin', 'value': 'WI'},
{ 'name':'Wyoming', 'value': 'WY'}
];

export function StarRatingSelector({value, onChange}) {

  const stars = [];
  const emptyStars = [];


  for(var i = 0; i < value; i++) {
    stars.push('star');
  }

  for(var j = value; j < 5; j++) {
    emptyStars.push('empty');
  }


  return <div>
    {stars.map((star, i) => <button key={`star-${i}`} onClick={() => onChange(i+1)} className=" inline-block"><SolidStarIcon key={`star-${i}`} className="text-yellow-500 w-8 h-8"/></button>)}
    {emptyStars.map((star, i) => <button key={`empty-star-${i}`} onClick={() => onChange(stars.length+i+1)} className=" inline-block"><SolidStarIcon key={`star-${i}`} className="text-gray-200 w-8 h-8"/></button>)}
  </div>
}

export function getFulfillmentStatusName(fulfillment) {
    switch (fulfillment.status) {
      case 'in_transit':
        return 'In transit';
      case 'available_for_pickup':
        return 'Available for pickup';
      case 'delivered':
        return 'Delivered';
      case 'out_for_delivery' :
        return 'Out for delivery';
      case 'pre_transit':
        return 'Pre-transit';
      case 'return_to_sender':
        return 'Return to sender';

      default:
        return 'Unknown';
    }
  }