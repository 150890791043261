import React from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

export default function CardForm({onError, onSuccess, className='', showLabel=true}) {
	
	const stripe = useStripe();
  	
  	const elements = useElements();

  	const handleSubmit = async (event) => {
	    // We don't want to let default form submission happen here,
	    // which would refresh the page.
	    event.preventDefault();

	    const card = elements.getElement(CardElement);

	    if (!stripe || !elements) {
	      // Stripe.js has not yet loaded.
	      // Make sure to disable form submission until Stripe.js has loaded.
	      return;
	    }

	    const result = await stripe.createSource(
	      //`Elements` instance that was used to create the Payment Element
	      card,
	      {
	      	type: 'card',
	      	currency: 'USD'
	      }
	    );

	    if (result.error) {
	      // Show error to your customer (for example, payment details incomplete)
	      	onError(result.error.message);
	    } else {
	      	onSuccess(result);
	      	card.clear();
	    }
	  };

	return <div className={"w-full mt-3 "+className}>
		<form onSubmit={handleSubmit} className="block w-full">
			<div className="w-full sm:flex">
				<div className="w-full">
				{showLabel&&<label className="text-sm font-medium text-gray-600">Add New Card</label>}
				<CardElement className="border-r sm:border-r-0 border-t border-l border-b border-gray-300 py-2.5 px-3 text-sm"/>
				</div>
				<div className={"sm:flex items-center w-full sm:w-32 "+(showLabel?'pt-6':'')}>
				<button type="submit" className="bg-indigo-600 block w-full sm:w-auto text-white text-sm border-indigo-600 py-2 px-4 font-medium text-center border">Add Card</button>
				</div>
			</div>
		</form>
	</div>
}