import React, { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useLocation
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, CheckCircleIcon as SolidCheckIcon, XCircleIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChatIcon, UserIcon, PaperAirplaneIcon, CashIcon, DocumentTextIcon, ClockIcon, CheckCircleIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, ArrowCircleRightIcon } from '@heroicons/react/outline'
import Section, {Card, Pager, DashboardCard, FileDrop, TextArea} from '../components/Section';
import SidePanel from '../components/SidePanel';
import Button from '../components/Button';
import moment from 'moment';
import numeral from 'numeral';
import {useAuthRequest} from '../Auth';
import { ToastContainer, toast } from 'react-toastify';



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const icons = {
  'fa-check': <CheckCircleIcon className="w-5 h-5 inline mr-1"/>,
  'fa-proposal': <PaperAirplaneIcon className="w-5 h-5 inline mr-1"/>,
  'fa-money': <CashIcon className="w-5 w-5 inline mr-1"/>,
  'fa-usd': <CashIcon className="w-5 w-5 inline mr-1"/>,
  'fa-document': <DocumentTextIcon className="w-5 w-5 inline mr-1"/>,
  'fa-send': <PaperAirplaneIcon className="w-5 h-5 inline mr-1"/>,
  'fa-envelope' : <ChatIcon className="w-5 h-5 inline mr-1"/>

};

function getActionLabel(status) {
  switch(status) {
    case 'completed':
      return 'Verify Work';
    case 'products_submitted':
      return 'Review Product Selection';
    default:
      return '';
  }
}

function DashboardBody({children, cookies}) {

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;


  const [user, setUser] = React.useState(null);

  const [sidePanelOpen, setSidePanelOpen] = React.useState(false);

  const [initialLoad, setInitialLoad] = React.useState(false);

  const [history, setHistory] = useState(null);

  const [actionsRequired, setActionsRequired] = useState(null);

  const [imageUploadPanel, setImageUploadPanel] = useState(false);

  const [messages, setMessages] = useState(null);

  const [uploading, setUploading] = useState(false);

  const [uploadError, setUploadError] = useState(false);

  const [imageId, setImageId] = useState(null);

  const [shortDescription, setShortDescription] = useState('');

  const [affiliateSetup, setAffiliateSetup] = useState(false);

  const [submitting, setSubmitting] = useState(false);

  const [productImportPanel, setProductImportPanel] = useState(false);

  const [counts, setCounts] = useState({
    active_count: 0,
    review_count: 0,
    pending_count: 0
  });


  const loadUser = async() => {

  	const response = await authFetch(`/api/user`, 'GET');

  	const { user } = response;

  	if (user) {
  		setUser(user);
  	}

  }


  const uploadImage = async(file) => {

    setUploadError(false);
    setUploading(true);
    
    const body = new FormData();

    body.append('file', file);

    const response = await authFetch('/api/dropzone', 'POST', body);

    const {error, id} = response;

    const _user = JSON.parse(JSON.stringify(user));

    if (error) {
      setUploadError(error);
    }

    if (id) {
       setImageId(id);
    }

    setUploading(false);
  }

  const loadDashboard = async() => {

    setInitialLoad(true);

    const response = await authFetch(`/api/v2/brands/dashboard`, 'GET');

    const {history, active_count, review_count, pending_count, messages, actions_required } = response;

    setHistory(history);
    const _counts = {
      active_count, review_count, pending_count
    };

    setCounts(_counts);

    setMessages(messages);


    setActionsRequired(actions_required);
  }

  const changeHistoryPage = async(page) => {
    const response = await authFetch(`/api/v2/history?page=${page}`, 'GET');

    const {history} = response;

    setHistory(history);
  }

  const changeMessagePage = async(page) => {
    const response = await authFetch(`/api/v2/messages?page=${page}`, 'GET');

    const {messages} = response;

    setMessages(messages);
  }

  const changeActionsRequiredPage = async(page) => {
    const response = await authFetch(`/api/v2/brands/actionsRequired?page=${page}`, 'GET');

    const {actions_required} = response;

    setActionsRequired(actions_required);
  }


  const getCharge = async(plan_code) => {
  	const response = await fetch(`/api/plans/${plan_code}?redirect=dashboard`, {
  		method: 'GET',
  		headers: {
  			Authorization: `Bearer ${cookies.get('token')}`
  		}
  	}).then(resp => resp.json());

  	const { redirect_url, error } = response;

  	if (error) {
  		alert(error);
  	}

  	if (redirect_url) {
  		window.location.assign(redirect_url);
  	}
  }

  const saveProfile = async() => {
    const body = new FormData();

    body.append('brand_name', user.brand_name);
    body.append('short_description', shortDescription);
    body.append('image_id', imageId);

    const response = await authFetch(`/api/v2/brands/profile`, 'POST', body);

    const { message, error } = response;

    if (message) {
      toast(message);
      loadDashboard();
      loadUser();
    }

    if (error) {
      toast(error);
    }
  }

  const respondToProductImport = async(choice=1) => {
    setSubmitting(true);

    const body = new FormData();

    if (choice == 0) {
      body.append('skip', 1);
    }

    const response = await authFetch(`/api/v2/shopify`, 'POST', body);

    console.log(response);

    const { message, error } =  response;

    if (message) {
      toast(message);
      loadDashboard();
      loadUser();
    }

    if (error) {
      toast(error);
      setSubmitting(false);
    }


  }

  const skipAffiliates = async() => {

    setSubmitting(true);
    const response = await authFetch(`/api/v2/skipAffiliates`, 'POST');

    const {message, error } = response;

    if (message) {
      toast(message);
      loadUser();
      loadDashboard();
    }

    if (error) {
      toast(error);

      setSubmitting(false);
    }
  }

  const enableAffiliates = async() => {
    setSubmitting(true);
    const response = await authFetch(`/api/v2/installAffiliates`, 'POST');

    const {message, error } = response;

    if (message) {
      toast(message);
      loadUser();
      loadDashboard();
    }

    if (error) {
      toast(error);
      setSubmitting(false);
    }

  }

  const updateShortDescription = (e) => {

    if (e.target.value.length > 100) {
      return false;
    }

    setShortDescription(e.target.value);
  }

  React.useEffect(() => {
  	if (user != null && user.is_brand == 1 && user.plan_code == null) {
  	
  		setSidePanelOpen(true);
  	} else if (user != null && user.is_brand && user.image_id == null) {
      setImageUploadPanel(true);
    } else if (user != null && user.is_brand && user.prompted_product_import == 0) {
      setImageUploadPanel(false);
      setProductImportPanel(true)
    } else if (user !=null && user.plan_code == 'pro' && user.skipped_affiliate_setup == 0 && user.affiliates_enabled_at == null) {
      setImageUploadPanel(false);
      setAffiliateSetup(true);
      setProductImportPanel(false);
    } else {
      setImageUploadPanel(false);
      setAffiliateSetup(false);

      setProductImportPanel(false);
    }

    setSubmitting(false);

  }, [user]);

  React.useEffect(() => {
    if (user == null) {
      loadUser();
    }
  	
    if (initialLoad == false) {
      loadDashboard();
    }
    
  }, []);


  const descriptionHelpText = (100-shortDescription.length)+' characters remaining';


  return <><section className="mt-0 pt-0 min-h-screen">
  {user!=null&&<div className="-mt-4 sm:mt-0 bg-white shadow lg:border-t lg:border-gray-200">
              <div className="px-4 sm:px-6">
                <div className="py-6 md:flex md:items-center md:justify-between ">
                  <div className="flex-1 min-w-0">
                    {/* Profile */}
                    <div className="flex items-center">
                      {user!=null&&user.image_id!=null?<img
                        className="hidden h-16 w-16 rounded-full sm:block"
                        src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${user.image_id}.jpg`}
                        alt={user.brand_name}
                      />:<div className="hidden h-16 w-16 rounded-full sm:block bg-gray-100">

                      </div>}
                      <div>
                        <div className="flex items-center">
                          {user.image_id!=null?<img
                            className="h-16 w-16 rounded-full sm:hidden"
                            src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${user.image_id}.jpg`}
                            alt=""
                          />:<div className="hidden h-16 w-16 rounded-full sm:hidden bg-gray-100"></div>}
                          <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                            {user.brand_name}
                          </h1>
                        </div>
                        <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                          
                          <dt className="sr-only">Membership Plan</dt>
                          <dd className="mt-3 flex items-center text-sm text-gray-500 font-medium sm:mr-6 sm:mt-0 capitalize">
                            {user.pro==1?<><CheckCircleIcon
                              className="flex-shrink-0 mr-1.5 h-5 w-5 text-green-600"
                              aria-hidden="true"
                            /><span>Pro membership</span></>:<span>Basic membership</span>}
                            
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </div>
                  <div className="mt-6 flex space-x-3 md:mt-0 md:ml-4 hidden">
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    >
                      Add money
                    </button>
                    <button
                      type="button"
                      className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-cyan-600 hover:bg-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500"
                    >
                      Send money
                    </button>
                  </div>
                </div>
              </div>
            </div>}

      <div className="max-w-6xl  mx-5 mt-5">

      {
        actionsRequired!=null&&actionsRequired.total>0&&<Card title="Action Required" unstyled={true}>
        <div className="divide-y">
          {
            actionsRequired.data.map(action => <div className="hover:bg-slate-50 px-4 sm:px-6 py-4 sm:flex justify-between items-center" key={`action-${action.proposal_id}-${action.gig_order_id}`}>
                <div className="flex space-x-2">
                  {action.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_120,h_120,c_fill/${action.image_id}.jpg`} className="rounded-full sm:rounded-md w-14 h-14 sm:w-12 sm:h-12"/>:<div className="slate-50 rounded-md w-12 h-12"></div>}
                  <div>
                    <strong className="text-gray-800 text-sm font-medium block">{action.gig_order_id!=0&&`Order #${action.gig_order_id}: `}{action.title}</strong>
                    <span className="block text-sm text-gray-600">{action.influencer_name}</span>
                  </div>
                </div>
                <Link to={action.gig_order_id!=0?`/order/${action.gig_order_id}`:`/proposal/${action.proposal_id}`} className="block w-full sm:w-auto text-center mt-3 sm:mt-0 bg-indigo-600 py-3 sm:py-2 px-4 rounded-md text-sm text-white font-medium">{getActionLabel(action.status)}</Link>

              </div>)
          }
          <div className="px-4 sm:px-6 py-4">
          <Pager data={actionsRequired} changePage={changeActionsRequiredPage}/>
          </div>
        </div>
        </Card>
      }

      <div className="mt-8 mb-8">
                <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
                <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                  {/* Card */}
                  <DashboardCard icon={<DocumentTextIcon className="w-10 h-10 text-gray-500"/>} label="Pending Proposals" value={numeral(counts.pending_count).format('0,0')} url={'/proposals/pending'} />
                  <DashboardCard icon={<UserIcon className="w-10 h-10 text-gray-500"/>} label="Active Hires (Campaigns)" value={numeral(counts.active_count).format('0,0')} url={'/proposals/active'} />
                  <DashboardCard icon={<SearchIcon className="w-10 h-10 text-gray-500"/>} label="Awaiting Review" value={numeral(counts.review_count).format('0,0')} url={'/proposals/completed'} />
                </div>
              </div>

    {
      messages!=null&&<Card title="Unread Messages" unstyled={true} className="mb-8" primaryAction={{label: 'View all messages', url: '/messages'}}>
        {
          messages.length>0&&<table className="min-w-full divide-y divide-gray-200 ">
                        
                        <tbody className=" divide-y divide-gray-200">
                          {
                            messages.map(message => <tr key={`message-${message.id}`} className="">
                              
                              <td className="max-w-0 w-full px-4 sm:px-6 py-4 whitespace-nowrap text-sm text-gray-900">
                                <div className="sm:flex">
                                  <Link to={message.proposal_id!=null?`/proposal/${message.proposal_id}/messages`:`/order/${message.gig_order_id}/messages`} className="group w-full flex space-x-2 truncate text-sm">
                                    <ChatIcon className="hidden w-5 h-5 sm:inline mr-1 text-gray-500"/>
                                    <strong className="font-medium text-sm">{message.from_name}</strong>
                                    {message.message_count==1?<p className="text-gray-500 truncate group-hover:text-gray-900 flex items-center">
                                      {message.message_txt}
                                    </p>:<p className="text-gray-500 truncate group-hover:text-gray-900 italic flex items-center">
                                      {message.message_count} messages
                                    </p>}

                                  </Link>
                                  <time dateTime={message.created_at} className="block sm:hidden text-xs text-gray-400 pl-2">{moment(message.created_at).fromNow()}</time>
                                </div>
                              </td>
                              <td className="hidden sm:block px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                <time dateTime={message.created_at}>{moment(message.created_at).fromNow()}</time>
                              </td>
                            </tr>)
                          }

                        </tbody>

                        </table>
        }
        {
          messages.length==0&&<div className="px-4 sm:px-6 text-center py-10 items-center justify-center flex">
            <div>
              <ChatIcon className="w-10 h-10 text-gray-500 block mx-auto"/>
              <h3 className="text-gray-600 font-medium text-md my-2">No unread messages</h3>
              <p className="text-sm text-gray-500">When you have new unread messages, they will appear here.</p>
            </div>
          </div>
        }
      </Card>
    }


  	{history!=null&&<Card title="Recent Activity" unstyled={true}>
      <table className="min-w-full divide-y divide-gray-200 ">
                        
                        <tbody className=" divide-y divide-gray-200">
                          {history.data.map((item) => {
                            
                            var url = item.link;
                            var icon = icons[item.icon]?icons[item.icon]:<ArrowCircleRightIcon className="w-5 h-5 inline mr-1"/>

                            if (item.proposal_id) {
                              url = '/proposal/'+item.proposal_id;
                            } else if (item.campaign_id) {
                              url = '/campaign/'+item.campaign_id+'/proposals/proposed';
                            } 

                            return (
                            <tr key={item.id} className={item.seen_at==null?'bg-white':'opacity-80'}>
                              <td className="max-w-0 w-full px-6 py-4 sm:whitespace-nowrap text-sm text-gray-900">
                                <div className="flex">
                                  <Link to={url} className="group inline-flex space-x-2 sm:truncate text-sm">
                                    
                                    <p className={"text-gray-600 sm:truncate group-hover:text-gray-900 flex sm:items-center"}>
                                      {icon}
                                      <span className={item.seen_at==null?'font-medium text-gray-900 flex-1':'flex-1'}>{item.item_txt}</span>
                                    </p>
                                  </Link>
                                </div>
                                <time className="block sm:hidden text-xs text-gray-400 ml-6" dateTime={item.created_at}>{moment(item.created_at).fromNow()}</time>
                              </td>
                              <td className="hidden sm:blockpx-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                <time dateTime={item.created_at}>{moment(item.created_at).fromNow()}</time>
                              </td>
                            </tr>
                          )})}

                          {
                            history.total==0&&<tr>
                              <td>
                                <div className="py-20 px-4 sm:px-6 text-center">
                                  <ClockIcon className="w-10 h-10 mb-3 block mx-auto text-gray-500"/>
                                  <h3 className="font-medium text-md text-gray-600 mb-3">Your history will be shown here</h3>
                                  <p className="text-sm text-gray-500">You can keep track of your activity and updates here, as you continue to use Beauty Clout</p>
                                </div>
                              </td>

                            </tr>
                          }
                        </tbody>
                      </table>
                      <div className="px-4 sm:px-6 py-4">
                      <Pager data={history} changePage={changeHistoryPage}/>
                      </div>
    </Card>}
    </div>
    <div className="border-t border-gray-200 max-w-6xl mx-5 py-5 mt-10">
      <span className="block text-sm text-gray-400">&copy; {moment().format('YYYY')} Beauty Clout. All rights reserved.</span>
    </div>
  </section>
  <SidePanel open={sidePanelOpen} setOpen={setSidePanelOpen} dismissable={false} title={""}>
               <img className="h-20 w-auto mx-auto block"
                        src="https://res.cloudinary.com/wigmarket/c_fill,h_480/beauty-clout/bc-horizontal.png"
                        alt="Beauty Clout"
                      />
              <p className="text-sm text-gray-600 mb-3">Welcome to Beauty Clout!</p>

              <p className="text-sm text-gray-600 mb-5">Find and hire popular beauty influencers to promote your products.</p>


              <div className="grid sm:grid-cols-2 gap-x-4 border-t-2 pt-3 border-indigo-600">
                <div className="mb-8">
                  <div className="sm:h-96 mb-8 sm:mb-0">
                  <div className="h-16">
                  <h3 className="text-xl font-bold text-gray-900">Basic</h3>
                  <p className="text-xs text-gray-600 mb-3">New businesses, small number of hires</p>
                  </div>
                  <h3 className="text-lg font-bold text-gray-900">$2.99<span className="text-gray-500"> / mo</span></h3>
                  <ul className="text-sm text-gray-600 space-y-2 pt-2">
                    <li className="flex items-center"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Free 14-day trial</span></li>
                    <li className="flex items-center"><XCircleIcon className="mr-1 text-gray-400 w-5 h-5 block"/><span className="flex-1">10% hiring fee</span></li>
                    <li className="flex items-start"><XCircleIcon className="mr-1 text-gray-400 w-5 h-5 block"/><span className="flex-1">Influencers can only be paid monetary compensation</span></li>
                  </ul>
                  </div>
                  <Button className="" fullWidth={true} onClick={() => getCharge('basic')}>Continue as Basic</Button>
                </div>
                <div>
                <div className="sm:h-96 mb-8 sm:mb-0">
                  <div className="h-16">
                    <h3 className="text-lg font-bold text-gray-900">Pro</h3>
                    <p className="text-xs text-gray-600 mb-3">Growing businesses, large number of hires</p>
                  </div>
                  <h3 className="text-lg font-bold text-gray-900">$29.99<span className="text-gray-500"> / mo</span></h3>
                  <ul className="text-sm text-gray-600 space-y-2 pt-2">
                    <li className="flex items-center"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Free 14-day trial</span></li>
                    <li className="flex items-center"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">No hiring fee</span></li>
                    <li className="flex items-start"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Influencers can be paid with free products</span></li>
                    <li className="flex items-start"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Create affiliate links / coupon codes for influencers</span></li>
                    <li className="flex items-center"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Priority support</span></li>
                  </ul>
                  </div>

                  <Button className="border border-indigo-600" fullWidth={true} primary onClick={() => getCharge('pro')}>Continue as Pro</Button>
                </div>
              </div>

              
  </SidePanel>

  <SidePanel open={imageUploadPanel} setOpen={setImageUploadPanel} dismissable={false} title={""}>
     <img className="h-20 w-auto mx-auto block"
        src="https://res.cloudinary.com/wigmarket/c_fill,h_480/beauty-clout/bc-horizontal.png"
        alt="Beauty Clout"
      />
      <p className="text-sm text-gray-600 mb-3">Almost done!</p>
      <p className="text-sm text-gray-600 mb-3">Complete your brand profile with a description and profile image.</p>
    <FileDrop error={uploadError} uploading={uploading} thumbnailUrl={imageId!=null?`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${imageId}.jpg`:false} label="Profile icon *" onChange={uploadImage} helpText="JPG, PNG, maximum size of 7MB. Recommended dimensions 600px by 600px"/>           
    <TextArea label="Short description *" value={shortDescription} onChange={updateShortDescription} className="mt-3" helpText={descriptionHelpText}/>

    <Button primary disabled={shortDescription.length==0||imageId==null} fullWidth onClick={() => saveProfile()} className="mt-5">Save Profile</Button>
  </SidePanel>

  <SidePanel open={affiliateSetup} setOpen={setAffiliateSetup} dismissable={false} title={""}>
     <img className="h-20 w-auto mx-auto block"
        src="https://res.cloudinary.com/wigmarket/c_fill,h_480/beauty-clout/bc-horizontal.png"
        alt="Beauty Clout"
      />
      <h3 className="font-bold font-xl text-gray-900 mb-3">Would you like to run affiliate campaigns?</h3>
      <p className="text-sm text-gray-600 mb-3">One last step!</p>
      <p className="text-sm text-gray-600 mb-3">Beauty Clout now offers affiliate tracking and an affiliate payout feature for pro members. Offer commission to hired influencers for referral orders.</p>

      <button class="text-indigo-600 text-sm" disabled={submitting} onClick={() => skipAffiliates()}>Skip this step</button>
    <Button disabled={submitting} primary fullWidth onClick={() => enableAffiliates()} className="mt-3">Install Affiliate Code Instantly</Button>
  </SidePanel>

  <SidePanel open={productImportPanel} setOpen={setProductImportPanel} dismissable={false} title={""}>
     <img className="h-20 w-auto mx-auto block"
        src="https://res.cloudinary.com/wigmarket/c_fill,h_480/beauty-clout/bc-horizontal.png"
        alt="Beauty Clout"
      />
      <h3 className="font-bold font-xl text-gray-900 mb-3">Import your product catalog?</h3>
      <p className="text-sm text-gray-600 mb-3">Importing your catalog allows you to integrate products into your work with influencers.</p>
      <ul className="text-sm text-gray-600 space-y-2 pt-2 mb-3">
        <li className="flex items-center"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Allow influencers to choose products to receive</span></li>
        <li className="flex items-center"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Automatically create Shopify orders addressed to influencers whom you hire.</span></li>
        <li className="flex items-start"><SolidCheckIcon className="mr-1 text-green-600 w-5 h-5 block"/><span className="flex-1">Great for product reviews!</span></li>
      </ul>

      <button class="text-indigo-600 text-sm" disabled={submitting} onClick={() => respondToProductImport(0)}>Skip this step</button>
    <Button disabled={submitting} primary fullWidth onClick={() => respondToProductImport(1)} className="mt-3">Import Product Catalog</Button>
  </SidePanel>

<ToastContainer autoClose={4000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
  </>
};

export default withCookies(DashboardBody);