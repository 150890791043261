import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useSearchParams
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon,
  LinkIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {Modal, getHandle, getURL, getServiceName,getOrderStatus, PageTabs, InputField, Alert, TextArea, Select, FileDrop, Card, StarRatingSelector, Pager} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';

export default function BrandAffiliates() {

  /* provider hooks */

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  /* state variables */

  const [users, setUsers] = useState(null);

  const [user, setUser] = useState(null);

  const [selectedCampaign, setSelectedCampaign] = useState({name: 'All Campaigns', value: 'all'});

  const [showPositive, setShowPositive] = useState(false);

  const [campaigns, setCampaigns] = useState([]);

  const [showPayPal, setShowPayPal] = useState(false);

  const [clientId, setClientId] = useState('');

  const [secret, setSecret] = useState('');

  const [removePayPalModal, setRemovePayPalModal] = useState(false);

  const [affiliateModal, setAffiliateModal] = useState(false);

  const loadUsers = async() => {

    let q = searchParams.has('campaign_id')?`campaign_id=${searchParams.get('campaign_id')}&`:'';

    if (searchParams.has('balance')) {
      q += `balance=${searchParams.get('balance')}`;
    }

    const response = await fetch(`/api/v2/affiliates?${q}`, 'GET');

    const {users, campaigns, user} = response;

    setCampaigns(campaigns);

    if (!searchParams.has('campaign_id')) {
      setSelectedCampaign(campaigns[0]);
    } else {
      let campaign = campaigns.find(campaign => campaign.value==searchParams.get('campaign_id'));

      if (campaign && selectedCampaign.value != searchParams.get('campaign_id')) {
        setSelectedCampaign(campaign);
      }
    }
    

    setUsers(users);

    setUser(user);
  }

  const changePage = (page) => {
    let url = `/affiliates?`;

    if (searchParams.has('campaign_id')) {
      url += `campaign_id=${searchParams.get('campaign_id')}&`;
    }

    if (searchParams.has('balance')) {
      url += `balance=${searchParams.get('balance')}&`;
    }

    url += `page=${page}`;

    navigate(url);
  }

  const savePayPalCredentials = async() => {
    const body = new FormData();

    body.append('client_id', clientId);
    body.append('secret', secret);

    const response = await fetch(`/api/brands/paypal`, 'POST', body);

    const {message, error} = response;
    if (message) {
      toast(message);
      setShowPayPal(false);
    }

    if (error) {
      toast(error);
    }
  }

  const openPayPalDisconnectModal = () => {
    setShowPayPal(false);

    setTimeout(() => {
      setRemovePayPalModal(true);
    }, 500);
  }

  const loadPayPal = async() => {
    const response = await fetch(`/api/brands/paypal`, 'GET');

    const {client_id, secret} = response;

    setClientId(client_id);
    setShowPayPal(true);
  }

  const enableAffiliates = async() => {
    const response = await fetch(`/api/v2/installAffiliates`, 'POST');

    const {message, error } = response;

    if (message) {
      toast(message);
      loadUsers();
      setAffiliateModal(false);
    }

    if (error) {
      toast(error);
    }
  }

  const disconnectPayPal = async() => {

    const response = await fetch(`/api/brands/paypal/delete`, 'POST');

    const {message, error} = response;

    setRemovePayPalModal(false);

    if (message) {
      toast(message);
    }

    if (error) {
      toast(error);
    }

  }


  React.useEffect(() => {
    loadUsers();
  }, [searchParams]);

  React.useEffect(() => {

    if (users != null) {

      let urlWithQuery = `/affiliates?`;

      if (selectedCampaign != null && selectedCampaign.value != 'all') {
        urlWithQuery += `campaign_id=${selectedCampaign.value}&`;
      }

      if (showPositive == true) {
        urlWithQuery += `balance=1`;
      }

      let showPositiveParameter = (searchParams.has('balance')&&searchParams.get('balance')=='1')?true:false;
      let selectedCampaignParameter = searchParams.has('campaign_id')?searchParams.get('campaign_id'):'all';

      if (selectedCampaignParameter != selectedCampaign.value || showPositiveParameter != showPositive) {
        navigate(urlWithQuery);
      } 
    }

  }, [showPositive, selectedCampaign]);

  if (users == null) {
    return null;
  }


  return <Section title="Affiliates" primaryAction={{label: 'PayPal Settings', onClick: () => loadPayPal()}}>
    <div className="mx-5 max-w-6xl pt-5">
      {
        user!=null&&user.affiliates_enabled_at==null&&<div className="bg-white rounded-md px-4 items-center flex justify-between sm:px-6 py-4 mb-5 shadow-sm">
          <p className="text-gray-800 text-sm">Enable affiliates to create referral links for influencers hired for your campaigns.</p>
          <button onClick={() => setAffiliateModal(true)} className="border-indigo-600 border text-white bg-indigo-600 shadow-sm py-1 px-4 text-sm rounded-md">Enable Affiliates</button>
        </div>
      }
      {
        user!=null&&user.affiliates_enabled_at!=null&&<div className="bg-white rounded-md px-4 items-center flex sm:px-6 py-4 mb-5 shadow-sm">
          <CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/><p className="text-green-900 text-sm">Affiliates enabled</p>
        </div>
      }

      <div className="mb-5">
        <Select label="Select Campaign" options={campaigns} selected={selectedCampaign} setSelected={setSelectedCampaign}/>
        <input type="checkbox" id="show-positive" checked={showPositive} onChange={e => setShowPositive(e.target.checked)} value="1" className="ml-1 rounded-md border border-gray-400"/>
        <label htmlFor="show-positive" className="text-sm ml-2 text-gray-600">Show only affiliates with positive balances</label>
      </div>
      <Card title="Affiliates" unstyled={true}>
        {users.total>0&&<><table className="divide-y w-full">
          <thead>
            <tr className="text-left text-sm text-gray-900">
              <th className="px-4 sm:px-6 py-4 font-medium text-gray-700">Influencer</th>
              <th className="py-4 px-4 sm:px-0 text-left font-medium text-gray-700 text-right sm:text-left">Total Balance</th>
              <th className="py-4 text-left font-medium text-gray-700 hidden sm:table-cell">Balance for Campaign</th>
              <th className="px-4 sm:px-6 text-right font-medium text-gray-700 hidden  sm:table-cell">Action</th>
            </tr>
          </thead>
          {
            users.data.map(user => <tr key={`user-${user.id}`}>
                <td className="px-4 sm:px-6 py-4">
                  <div className="flex items-center space-x-2">
                  {
                    user.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill/${user.image_id}.jpg`} className="w-10 h-10 rounded-full shadow"/>:<div className="w-10 h-10 rounded-full shadow bg-slate-50 uppercase flex items-center justify-center">{user.name[0]}</div>
                  }
                  <Link to={`/affiliates/${user.id}`} className="text-sm text-indigo-600 hover:text-indigo-700">{user.name}</Link>
                  </div>
                </td>
                <td className="text-left px-4 sm:px-0 text-right sm:text-left">
                  <span className="text-sm text-gray-600">{numeral(user.owed).format('$0,0.00')}</span>
                </td>
                <td className="text-left hidden  sm:table-cell">
                  <span className="text-sm text-gray-600">{searchParams.has('campaign_id')?numeral(user.owed_campaign_amount).format('$0,0.00'):'n/a'}</span>
                </td>
                <td className="text-right px-4 sm:px-6 py-4 hidden  sm:table-cell">
                  <Link to={`/affiliates/${user.id}`} className="text-indigo-600 text-sm hover:text-indigo-700">View Affiliate</Link>
                </td>
              </tr>)
          }

        </table>
        <div className="px-4 sm:px-6 py-4 border-t">
          <Pager data={users} onChange={changePage}/>
        </div>
        </>}
        {
          users.total==0&&<div className="px-4 sm:px-6 py-20 text-center">
            <UsersIcon className="block mb-3 mx-auto w-10 h-10 text-gray-500"/>
            <h3 className="text-md font-medium text-gray-600 mb-3">No Affiliates found</h3>
            <p className="text-sm text-gray-600">When you hire influencers through campaigns with affiliate programs, they will appear here.</p>
          </div>
        }

      </Card>
    </div>
    <Modal open={showPayPal} setOpen={() => {return false; }} primary={{label: 'Save Settings', action: () => savePayPalCredentials()}} secondary={{label: 'Close', action: () => setShowPayPal(false)}}>
      <h3 className="font-bold text-md text-black mb-3">PayPal Settings</h3>
      <p className="text-sm text-gray-600 mb-4">To send PayPal payments to affiliates in Beauty Clout, you will need to <a href="https://developer.paypal.com/" className="text-indigo-600">create API credentials in PayPal</a>, and enter them below.</p>
      <p className="text-sm text-gray-600 mb-4">PayPal's official instructions can be read <a href="https://developer.paypal.com/docs/multiparty/get-started/" target="_blank" className="text-indigo-600">here</a>.</p>
      <InputField type="text" name="client_id" id="client_id" autocomplete="off" label="Client ID" value={clientId} onChange={e => setClientId(e.target.value)} className="mb-3"/>
      <InputField autocomplete="new-password" type="password" id="secret" name="secret" label="Secret" value={secret} onChange={e => setSecret(e.target.value)}/>
      <button onClick={() => openPayPalDisconnectModal()} className="text-indigo-600 text-sm mt-4">Remove PayPal Credentials</button>
    </Modal>

    <Modal open={affiliateModal} setOpen={() => {return false }} primary={{label: 'Install Snippet', action: () => enableAffiliates() }} secondary={{label:'Close', action: () => setAffiliateModal(false)}}>
      <h3 className="font-bold text-md text-black mb-3">We need to install a snippet of code to your Shopify theme</h3>
      <p className="text-sm text-gray-600 mb-3">Enabling affiliates will install a snippet of code in your Shopify theme.</p>
      <p className="text-sm text-gray-600">This snippet allows Beauty Clout to track clicks and orders referred by influencers you hire.</p>

    </Modal>

    <Modal open={removePayPalModal} setOpen={() => {return false; }} primary={{label: 'Remove PayPal', action: () => disconnectPayPal()}} secondary={{label: 'Close', action: () => setRemovePayPalModal(false)}}>
      <h3 className="font-bold text-md text-black mb-3">Are you sure you want to remove PayPal integration?</h3>
      <p className="text-sm text-gray-600">This action will disconnect PayPal payouts for affiliates. You can still use the manual payment option, and can always re-enter your PayPal credentials in the future.</p>
    </Modal>
    <ToastContainer theme="dark" autoClose={5000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/> 
  </Section>
}