import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { UserGroupIcon, CheckCircleIcon, XCircleIcon, ChevronRightIcon, MailIcon, StarIcon, PlusCircleIcon } from '@heroicons/react/solid';
import {StarIcon as EmptyStarIcon } from '@heroicons/react/outline'

import numeral from 'numeral';
import moment from 'moment';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

export default function UserInvite({user, url, onClick, onViewProfile, onInvite}) {
  return (
    <li className="w-full">
            <div className="hover:bg-gray-50 w-full" >
              <div className="sm:flex items-center px-4 py-4 sm:px-6">
                <div className="min-w-0 flex-1 flex sm:items-center">
                  <div className="flex-shrink-0">
                    {user.image_id!=null?<img className="h-12 w-12 rounded-full shadow" src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill,q_auto/${user.image_id}.jpg`} alt="" />:<div className="h-12 w-12 flex items-center justify-center rounded-full bg-slate-100 shadow"><span className="uppercase text-slate-400 font-medium">{user.name[0]}</span></div>}
                  </div>
                  <div className="min-w-0 flex-1 px-4 md:grid md:grid-cols-3 md:gap-4">
                    <div>
                      <p className="text-sm font-medium text-indigo-600 truncate text-left">{user.name}</p>
                      {user.rating_average!=null?<p className="sm:mt-1 flex justify-start text-xs items-center text-gray-600">
                        <StarIcon className="text-yellow-500 w-5 h-5 mr-1"/>{user.rating_average} / 5
                      </p>:<p className="mt-1 flex justify-start text-xs items-center text-gray-500">
                        <StarIcon className="text-gray-400 w-5 h-5 mr-1"/> Not reviewed
                      </p>}
                    </div>
                    <div className="hidden md:block">
                      <div>
                        <p className="text-sm text-gray-600 text-left">
                          {numeral(user.follower_count).format('0a')} total followers
                        </p>
                        <p className="mt-2 flex items-center text-sm text-gray-500 space-x-2">

                          {user.tiktok&&<img src={tiktokIcon} className="w-4 h-4"/>}
                          {user.instagram&&<img src={instagramIcon} className="w-4 h-" />}
                          {user.twitter&&<img src={twitterIcon} className="w-4 h-4" />}
                          {user.facebook&&<img src={facebookIcon} className="w-4 h-4"/>}
                          {user.youtube&&<img src={youtubeIcon} className="w-4 h-4"/>}
                        </p>
                      </div>
                      
                    </div>
                    <div className="">
                      
                      {user.completed.length>0?<p className=" flex justify-start text-xs sm:text-sm items-center text-gray-600">
                        <CheckCircleIcon className="text-green-500 w-5 h-5 mr-1"/> {user.completed.length} {(user.completed.length>1)?'campaigns':'campaign'}
                      </p>:<p className="flex justify-start text-xs sm:text-sm items-center text-gray-600">
                      <XCircleIcon className="text-gray-400 w-5 h-5 mr-1"/> 
                      0 campaigns
                      </p>}
                      <p className="text-left truncate text-xs text-gray-600 mt-1">Joined {moment(user.created_at).fromNow()}</p>
                    </div>
                   
                  </div>
                </div>
                <div className="w-full sm:w-auto grid grid-cols-2 mt-3 sm:mt-0">
                  

                  {user.invited==0?<button className="bg-indigo-600 border border-indigo-600 px-3 py-2 text-white hover:bg-indigo-700 rounded-l-md text-xs font-medium w-full sm:w-24" onClick={onInvite}><PlusCircleIcon className="w-3 h-3 inline-block mr-1"/>Invite</button>:<button className="bg-indigo-400 border border-indigo-400 px-3 py-2 text-white rounded-l-md text-xs font-medium w-auto sm:w-24" onClick={onInvite} disabled><CheckCircleIcon className="w-3 h-3 inline-block mr-1"/>Invited</button>}
                  <button className="bg-transparent  border border-l-0 border-gray-600 px-3 py-2 text-gray-600 hover:bg-gray-50 rounded-r-md text-xs font-medium w-full sm:w-24" onClick={onViewProfile}>View Profile</button>
                </div>
              </div>
            </div>
          </li>
  )
}