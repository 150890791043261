import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon,
  LinkIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, UploadIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, TrashIcon, RefreshIcon } from '@heroicons/react/outline'
import Section, {SocialMediaSetting, Modal, TagInputField, getHandle, getURL, getServiceName,getOrderStatus, PageTabs, InputField, Alert, TextArea, Select, FileDrop, Card, USStates} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';

export default function InfluencerDeactivation() {

  const {authenticatedFetch} = useAuthRequest();

  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const [modal, setModal] = useState(false);

  const [password, setPassword] = useState('');

  const loadUser = async() => {
    const response = await authenticatedFetch('/api/user', 'GET');

    const {user, error} = response;
    //console.log(response);

    if (user) {
      setUser(user);
    }

    if (error) {
      toast(error);
    }
  }

  const togglePause = async(unpause=0) => {
    const body = new FormData();

    if (unpause == 1) {
      body.append('unpause', 1);
    }
    const response = await authenticatedFetch('/api/v2/pauseAccount', 'POST', body);

    const {error, message} = response;

    setModal(false);

    if (message) {
      toast(message);
    }

    if (error) {
      toast(error);
    }

    loadUser();
  }

  const deleteAccount = async() => {
    const body = new FormData();

    body.append('password', password);
    
    const response = await authenticatedFetch('/api/v2/deleteAccount', 'POST', body);

    const {error, message} = response;

    

    if (message) {
      setModal(false);
      toast(message);
      setTimeout(() => {
        navigate('/signout');
      }, 1000);
    }

    if (error) {
      toast(error);
    }
  }

  useEffect(() => {
    loadUser();
  }, []);

  return <><Section title="Account deactivation">
  {
    user!=null&&user.paused_at!=null&&<div className="max-w-5xl mx-5 block mt-5">
      <div className="bg-red-50 text-red-700 px-4 sm:px-6 py-4 rounded shadow">
        <p className="text-sm">Your account is currently paused. You may reactivate your account below.</p>
      </div>
    </div>
  }
    <div className="max-w-5xl mx-5 block">
      <div className="bg-white px-4 sm:px-6 py-4 border rounded shadow-sm mb-8 mt-5">
        <h3 className="font-bold text-lg mb-2">We're very sorry to see you go 😔</h3>
        <p className="text-gray-700 mb-2 text-sm">We're sorry that you did not get what you need out of Beauty Clout</p>
        <p className='text-gray-700 mb-3 text-sm'>Please note that you can also pause your account.</p>
        <div className="grid sm:grid-cols-2 sm:divide-x">
          <div className="sm:pr-4">
            <h3 className="font-bold text-lg mb-2">Pause your account</h3>
            <p className="text-gray-600 text-sm mb-2">By pausing your account, your gigs, orders, campaign history, and account will remain in tact.</p>
            <p className="text-gray-600 text-sm mb-2">However, you simply will not show up in search results for brands. Your gig listings will also be hidden.</p>
            <p className="text-gray-600 text-sm mb-3">You can always unpause your account by signing back in, and visiting your <Link to={'/influencers/settings'} className="text-indigo-600">Settings</Link>.</p>
            {
              user!=null&&<>
               {user.paused_at==null?<button onClick={() => setModal('pause')} className="bg-indigo-600 px-4 py-3 rounded-md text-white text-sm block w-full text-center">Pause Account</button>:<button onClick={() => setModal('unpause')} className="bg-indigo-600 px-4 py-3 rounded-md text-white text-sm block w-full text-center">Unpause Account</button>}
               </>
             }
          </div>
          <div className="sm:pl-4">
            <h3 className="font-bold text-lg mb-2">Delete your account</h3>
            <p className="text-gray-600 text-sm mb-2">By deleting your account, your gigs and account will be removed from our system.</p>
            <p className="text-gray-600 text-sm mb-2">If you have worked with brands via gigs or campaigns, those records and any conversations you had on the Beauty Clout platform will remain.</p>
            <p className="text-gray-600 text-sm mb-3">Deleting your account is irreversible. You may always sign back up for Beauty Clout and create a new profile though.</p>
            <button onClick={() => setModal('delete')} className="bg-red-600 px-4 py-3 rounded-md text-white text-sm block w-full text-center">Delete Account</button>

          </div>
        </div>
      </div>
    </div>
  </Section>
  <Modal open={(modal=='pause')} setOpen={() => setModal(false)} primary={{label: 'Pause Account', action: () => togglePause()}} secondary={{label: 'Nevermind', action: () => setModal(false)}}>
    <h4 className="font-bold mb-2">Pause your Beauty Clout account?</h4>
    <p className="text-sm mb-3 text-gray-600">Your account will remain in our system, but you will no longer appear in search results. Your gigs will also be hidden from brands.</p>
  </Modal>
  <Modal open={(modal=='unpause')} setOpen={() => setModal(false)} primary={{label: 'Unpause Account', action: () => togglePause(1)}} secondary={{label: 'Nevermind', action: () => setModal(false)}}>
    <h4 className="font-bold mb-2">Unpause your Beauty Clout account?</h4>
    <p className="text-sm mb-3 text-gray-600">Your profile will be shown in search results again. Your gigs will be displayed again as well.</p>
  </Modal>
  <Modal open={(modal=='delete')} setOpen={() => setModal(false)} primary={{disabled: (password.length==0), label: 'Delete Account', action: () => deleteAccount()}} secondary={{label: 'Nevermind', action: () => setModal(false)}}>
    <h4 className="font-bold mb-2">Permanently delete your account?</h4>
    <p className="text-sm mb-3 text-gray-600">Please note that this action is irreversible.</p>
    <p className="text-sm mb-3 text-gray-600">To proceed, enter your password below, and submit.</p>
    <InputField type="password" label="Enter your password" value={password} onChange={e => setPassword(e.target.value)}/>
  </Modal>
  <ToastContainer theme="dark" autoClose={5000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/>
  </>


}