import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useSearchParams
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon,
  LinkIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, UploadIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, TrashIcon, RefreshIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {SocialMediaSetting, Modal, TagInputField, getHandle, getURL, getServiceName,getOrderStatus, PageTabs, InputField, Alert, TextArea, Select, FileDrop, Card, USStates} from '../components/Section';
import CardForm from '../components/CardForm';
import moment from 'moment';
import numeral from 'numeral';
import { initFacebookSdk } from '../Facebook';
import { useAuthRequest } from '../Auth';
import PayPalIcon from '../images/paypal.svg';


export default function InfluencerAffiliates() {

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;
  
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [user, setUser] = useState(null);

  const loadUser = async() => {

    const response = await fetch(`/api/user`, 'GET');

    const {user} = response;

    setUser(user);

  }

  const savePayPal = async(accessToken) => {
    const formData = new FormData();

    formData.append('access_token', accessToken);

    const response = await fetch(`/api/paypal`, 'POST', formData);

    const {message, error} = response;

    if (message) {
      navigate('/influencers/stripe');
    }

    if (error) {
      toast(error);
    }

  }

  const goToPayPal = async() => {
    const response = await fetch(`/api/paypal`, 'GET');

    const { redirect_url, error} = response;

   

    if (redirect_url) {
       toast('Sending you to PayPal');
      window.top.location.href = redirect_url;
    }
  }

  React.useEffect(() => {

    if (searchParams.has('access_token')) {
      savePayPal(searchParams.get('access_token'));
    }

  }, [searchParams]);

  React.useEffect(() => {
    loadUser();
  }, []);

  return <Section title="Redirecting...">
    
    <ToastContainer autoClose={4000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
  </Section>
}