import React, { Fragment, useState, useEffect , useRef} from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useLocation, useSearchParams
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  StarIcon,
  UserIcon,
  LightningBoltIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { ShoppingBagIcon, CalendarIcon, DocumentIcon, TrashIcon, ArrowLeftIcon, StarIcon as EmptyStarIcon, LinkIcon, ArrowRightIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {getFulfillmentStatusName, getOrderStatusName, StarRatingSelector, AutoComplete, getURL, Steps, Card, Tabs, getHandle, getOrderStatus, Nav, InputField, Alert, PageTabs, Modal, TextArea, Pager, Select, FileDrop, getServiceName, getServiceNameFromString, MenuButton} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SidePanel from '../components/SidePanel';
import { useAuthRequest } from '../Auth';
import CardForm from '../components/CardForm';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

export default function BrandProposals() {

  const {status} = useParams();

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const {pathname} = useLocation();

  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const [proposals, setProposals] = useState(null);

  const defaultTabs = [
    {name: 'Pending', url: `/proposals/pending`, current: (pathname.indexOf('pending')>0), id: 'pending'},
    {name: 'Hired', url: `/proposals/active`, current: (pathname.indexOf('active') > 0), id: 'active'},
    {name: 'Awaiting Review', url: `/proposals/completed`, current: (pathname.indexOf('completed')>0), id: 'completed'},
    {name: 'Verified', url: `/proposals/verified`, current: (pathname.indexOf('verified')>0), id: 'verified'},
    {name: 'Dismissed', url: `/proposals/dismissed`, current: (pathname.indexOf('dismissed') > 0), id: 'dismissed'}
  ];

  const [tabs, setTabs] = useState(defaultTabs);

  const loadProposals = async() => {

    let page = searchParams.has('page')?searchParams.get('page'):'1';

    const response = await fetch(`/api/v2/proposals?status=${status}&page=${page}`, 'GET');

    const { proposals, error, counts } = response;

    if (error) {
      toast(error);
    }

    if (proposals) {
      setProposals(proposals);
    }

    if (counts) {
      let _tabs = tabs.map(tab => {

        if (counts[tab.id]) {
          return {...tab, count: counts[tab.id], current:( pathname.indexOf(tab.id) > 0)};
        }

        return {...tab, count: 0, current: (pathname.indexOf(tab.id) > 0)};
      });

      setTabs(_tabs);
    }

  }

  const changePage = (page) => {
    navigate(`/proposals/${status}?page=${page}`);
  }

  useEffect(() => {

    loadProposals();

  }, [pathname, searchParams]);

  return <Section title="Influencers' Proposals">
    <div className="mx-5 max-w-5xl pt-5">
      <Tabs tabs={tabs} navigate={navigate}/>
      <div className="bg-white rounded-md shadow mt-5">
        {
          proposals!=null&&proposals.total>0&&<div className="divide-y">
            {
              proposals.data.map(proposal => <Link className="flex justify-between items-center px-4 sm:px-6 py-4 hover:bg-slate-50" to={`/proposal/${proposal.id}`} key={`proposal-${proposal.id}`}>
                  <div className="flex space-x-2 items-center">
                    {proposal.user&&proposal.user.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill/${proposal.user.image_id}.jpg`} className="w-14 h-14 rounded-full shadow block"/>:<div className="w-14 h-14 rounded-full shadow block bg-slate-200">&nbsp;</div>}
                    <div className="max-w-xs sm:max-w-sm md:max-w-md">

                      <span className="text-sm font-medium text-indigo-600 text-gray-700 block mb-0 pb-0">{proposal.user.name}</span>
                      <span className="text-xs text-gray-600 block mb-0 pb-0">{proposal.campaign.title}</span>
                      <span className="text-xs text-gray-600 block mt-0 truncate">{numeral(proposal.user.follower_count).format('0a')} followers &middot; {moment(proposal.created_at).fromNow()}</span>
               
                    </div>
                  </div>
                  <div>
                    <ChevronRightIcon className="text-gray-400 w-5 h-5"/>
                  </div>
                </Link>)
            }
            <div className="px-4 sm:px-6 py-4">
              <Pager data={proposals} changePage={changePage}/>
            </div>
          </div>
        }
        {
          proposals!=null&&proposals.total==0&&<div className="px-4 sm:px-6 py-20 text-center">
            <DocumentIcon className="w-20 h-20 block mx-auto text-gray-500 mb-3"/>
            <h3 className="font-medium text-md text-gray-700 mb-3">No Proposals Found</h3>
            <p className="text-sm text-gray-600">We could not find any proposals for this status.</p>
          </div>
        }
      </div>
    </div>
  </Section>
}