import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  StarIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, HeartIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, TextArea, Select, FileDrop, Gig, GigsLoadingPage, Pager} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statuses = [
  {name: 'Invited', status: 'pending'},
  {name: 'Accepted', status: 'accepted'},
  {name: 'Declined', status: 'rejected'}
];

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
  };

const estimates = [
    {id: -1, name: '-- Select'},
    {id: 0, name: '< 1 week'},
    {id: 1, name: '1 week'},
    {id: 2, name: '2 weeks'},
    {id: 3, name: '3 weeks'},
  ];

var searchTimeoutId = null;

function Nav({platform, type}) {
  return <nav className="flex" aria-label="Breadcrumb">
        <div className="flex sm:hidden">
          <Link to={'/gigs'} className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700">
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600" xDescription="Heroicon name: solid/arrow-narrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
<path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
</svg>
            <span>Back to Gigs</span>
          </Link>
        </div>
        <div className="hidden sm:block">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to={'/gigs'} className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" x-description="Heroicon name: solid/home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
</svg>
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            
              <li>
                <div className="flex items-center">
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"></path>
                  </svg>
                  <Link to={platform.url} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{platform.name}</Link>
                </div>
              </li>
            
              <li>
                <div className="flex items-center">
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"></path>
                  </svg>
                  <Link to={type.url} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{type.name}</Link>
                </div>
              </li>
            
            
          </ol>
        </div>
      </nav>
}

const sortOptions = [
  {name: 'Popular', value: 'order_count,desc'},
  {name: 'Newest', value: 'gigs.created_at,desc'},
  {name: 'Most followers', value: 'users.follower_count,desc'}
];


export default function BrowseGigCategory() {

  const {authenticatedFetch} = useAuthRequest();

  //const fetch = authenticatedFetch;

  const { code, subtype } = useParams();

  const [gigs, setGigs] = useState(null);

  const [subtypes, setSubtypes] = useState([]);

  const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

  const [gigType, setGigType] = useState(null);
  const [gigSubType, setGigSubType] = useState(null);

  const [loading, setLoading] = useState(true);


  const loadGigs = async(page=1) => {
    setLoading(true);

    const sortFields = selectedSort.value.split(',');

    const sortField = sortFields[0];

    const sortDirection = sortFields[1];

    const response = await fetch(`/api/v2/gigsByCategory?gig_type=${code}&subtype=${subtype}&page=${page}&sort_field=${sortField}&sort_direction=${sortDirection}`, {method: 'GET'}).then(resp => resp.json());

    const { subtypes, error, gig_type} = response;

    setSubtypes(subtypes);
    setGigType(gig_type);
    setGigSubType(response.subtype);
      
    if (response.gigs && response.gigs.data) {

      if (gigs != null) {
        let data = gigs.data.concat(response.gigs.data);

        let _gigs = {...response.gigs, data: data};

        setGigs(_gigs);
      } else {
        setGigs(response.gigs);
      }




    }

    setLoading(false);
  }

  const loadMoreGigs = () => {
    const nextPage = gigs.current_page+1;

    loadGigs(nextPage);
  }

  useEffect(() => {
    loadGigs();
  }, [selectedSort])


  useEffect(() => {
    setSelectedSort(sortOptions[0]);
    loadGigs();
  }, [code,subtype]);

  if (gigs == null) {
    return <GigsLoadingPage />
  }

  return <div className="bg-gray-50 min-h-screen">
  <div className="bg-white px-4 sm:px-6 py-4">
    <div className="container mx-auto">
      <Nav platform={{ name: gigType.name, url: `/gigs/platform/${gigType.code}/all`}} type={{ name: gigSubType.name, url: `/gigs/platform/${gigType.code}/${gigSubType.code}`}}/>
    </div>
  </div>
  <section className="pt-5 container px-5 sm:px-0 sm:mx-auto">
    <div className="sm:flex justify-between">
      <h1 className="font-bold text-3xl text-gray-900">{gigType.name} Gigs</h1>
      <div className="w-full mt-3 sm:mt-0 sm:w-48">
        <Select options={sortOptions} selected={selectedSort} setSelected={setSelectedSort}/>
      </div>
    </div>
    {false&&<div className="my-2 sm:space-x-3 space-y-3">
      {
        subtypes.map(type => <Link to={`/gigs/platform/${gigType.code}/${type.code}`} className={`${gigSubType.code==type.code?'bg-gray-800 text-white border-gray-800':'bg-white text-gray-700'} inline-block px-4 py-3 rounded-full border text-sm mr-2 sm:mr-0`} key={type.code}>{type.name}</Link>)
      }
    </div>}
    {
      gigs.total==0&&<div className="text-center px-4 sm:px-6 py-24 max-w-7xl">
        <UserIcon className="text-gray-400 w-16 h-16 block mx-auto"/>
        <h3 className="text-gray-600 text-md font-medium my-4">No Gigs Found</h3>
        <p className="text-gray-600 text-sm">We could not find any gigs for this category.</p>
      </div>
    }
    <div className="grid sm:grid-cols-2 xl:grid-cols-4  gap-4 mt-8">
      {
        gigs.data.map(gig => <Gig gig={gig}/>)
      }
    </div>
    {
      gigs.current_page<gigs.last_page&&<div className="mt-5">
        <button onClick={loadMoreGigs} disabled={loading} className="block w-full border hover:border-gray-600 text-sm font-medium shadow bg-white rounded-md py-2 px-4">Load More Gigs...</button>
      </div>
    }
    
  </section>
  </div>
}