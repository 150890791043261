import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link,Outlet, useLocation, useNavigate
} from "react-router-dom";
import { getIcon } from '../components/Section';
import {useAuthRequest} from '../Auth';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, BellIcon, ExclamationCircleIcon } from '@heroicons/react/solid'
import { CalendarIcon, ClockIcon, CogIcon, CurrencyDollarIcon, ChartBarIcon, GlobeAltIcon, MailIcon, MenuAlt1Icon, MenuIcon, XIcon, FolderIcon, ViewListIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'

const userNavigation = [
  { name: 'Profile / Settings', url: '/influencers/settings' },
  { name: 'Sign out', url: '/signout' },
];



function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function InfluencerDashboard({children}) {

  const navigate = useNavigate();

  const {pathname} = useLocation();

  const defaultNavigation = [
    { id: 'dashboard', name: 'Dashboard', icon: HomeIcon, href: '/influencers/dashboard', current: (pathname=='/influencers/dashboard') },
    { id: 'messages', name: 'Messages', icon: MailIcon, href: '/influencers/messages', count: 0, current:  (pathname.indexOf('/messages') > 0)  },
    { id: 'campaigns', name: 'Campaigns', icon: GlobeAltIcon, href: '/influencers/campaigns/pending', count: 0,  current: (pathname.indexOf('/campaigns') > 0 || pathname.indexOf('/campaign/') > 0) },
    { id: 'gig', name: 'Gigs', icon: UsersIcon, href: '/influencers/gigs', count: 0, current:  (pathname.indexOf('/gigs') > 0 || pathname.indexOf('/gig/') > 0)  },
    { id: 'order', name: 'Gig Orders', icon: FolderIcon, href: '/influencers/orders/pending', count: 0, current:  (pathname.indexOf('/orders/') > 0 || pathname.indexOf('/order/') > 0)  },
    { id: 'stripe', name: 'Payment Settings', icon: CurrencyDollarIcon, alert: false, href: '/influencers/stripe', current:  (pathname=='/influencers/stripe')  },
    { id: 'settings', name: 'Settings', icon: CogIcon, href: '/influencers/settings', current: (pathname=='/influencers/settings') }
  ];

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const [count, setCount] = useState(0);

  const [sidebarOpen, setSidebarOpen] = useState(false);

  const [notifications, setNotifications] = useState();

  const [user, setUser] = useState(null);

  const [showNotifications, setShowNotifications] = useState(false);

  const [navigation, setNavigation] = useState(defaultNavigation);

  const loadNotifications = async(markUnread=false) => {
    const q = markUnread?'?mark_unread=1':'';

    const response = await fetch(`/api/v2/notifications${q}`, 'GET');

    const { user, notifications, count, counts} = response;

    setNotifications(notifications);
    setUser(user);
    setCount(count);

    if (counts) {

      let _navigation = navigation.map(item => {

        if (counts[item.id] >= 0) {
          return {...item, count: counts[item.id], current: (pathname.indexOf('/'+item.id) >= 0)};
        }

        return {...item, current: (pathname.indexOf('/'+item.id) >= 0)};
      });

      setNavigation(_navigation);
    }
  }

  useEffect(() => {
    if (showNotifications) {
      loadNotifications(true);
    }
  }, [showNotifications]);

  useEffect(() => {
    loadNotifications();
    setShowNotifications(false);
    setSidebarOpen(false);

    /*const _navigation = navigation.map(nav => {
      return {...nav, current: (pathname.indexOf(nav.id)>=0)};
    });

    setNavigation(_navigation);*/

  }, [pathname]);

  useEffect(() => {
    if (user != null && user.is_influencer == 0) {
      navigate('/dashboard');
    }
  }, [user]);

  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full bg-white">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                    <div className="flex-shrink-0 flex items-center px-4">
                      <img
                        className="h-8 w-auto"
                        src="https://res.cloudinary.com/wigmarket/c_fill,h_200/beauty-clout/bc-horizontal.png"
                        alt="Beauty Clout"
                      />
                    </div>
                    <nav className="mt-5 px-2 space-y-1">
                      {navigation.map((item) => {
                        return (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-100 text-gray-900'
                              : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}

                        </Link>
                      )})}
                    </nav>
                  </div>
                  
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14">{/* Force sidebar to shrink to fit close icon */}</div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 border-r border-gray-200 bg-white">
            <div className="flex-1 flex flex-col pt-5 pb-4 overflow-y-auto">
              <div className="flex items-center flex-shrink-0 px-4">
                <img
                  className="h-8 w-auto"
                  src="https://res.cloudinary.com/wigmarket/c_fill,h_400/beauty-clout/bc-horizontal.png"
                  alt="Beauty Clout"
                />
              </div>
              <nav className="mt-5 flex-1 px-2 bg-white space-y-1">
                {navigation.map((item) => (
                  <Link
                    key={item.name}
                    to={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                      'group flex items-center justify-between px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <div className="flex items-center"><item.icon
                      className={classNames(
                        item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                    </div>
                    {
                      item.name=='Payment Settings'&&user!=null&&user.stripe_account_id==null&&<ExclamationCircleIcon className="text-red-600 w-7 h-7"/>
                    }
                    {
                      (item.count>0)&&<span className=" text-xs font-bold bg-gray-100 text-gray-800 text-sm py-1 px-2 rounded-full">{item.count}</span>
                    }
                  </Link>
                ))}
              </nav>
            </div>
           
          </div>
        </div>
        <div className="md:pl-64 flex flex-col flex-1">
          <div className="sticky top-0 z-10 md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3 bg-white">
            <button
              type="button"
              className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="flex-1 p-4 flex justify-between">
              <div className="flex-1 flex">
                <form className="w-full flex md:ml-0" action="#" method="GET">
                  <label htmlFor="search-field" className="sr-only">
                    Search
                  </label>
                  <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                      <SearchIcon className="h-5 w-5" aria-hidden="true" />
                    </div>
                    <input
                      id="search-field"
                      className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                      placeholder="Search"
                      type="search"
                      name="search"
                    />
                  </div>
                </form>
              </div>
              <div className="ml-4 flex items-center md:ml-6">
                <button
                  type="button"
                  onClick={() => setShowNotifications(!showNotifications)}
                  className="hidden sm:block relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                  {
                    count>0&&<span className="absolute px-2 -right-3 -top-3 py-1 bg-red-600 rounded-full text-white font-medium flex items-center justify-center text-xs">{count}</span>
                  }
                </button>

                <Link
                  to={'/influencers/notifications'}
                  className="sm:hidden block relative bg-white p-1 rounded-full text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <span className="sr-only">View notifications</span>
                  <BellIcon className="h-6 w-6" aria-hidden="true" />
                  {
                    count>0&&<span className="absolute px-2 -right-3 -top-3 py-1 bg-red-600 rounded-full text-white font-medium flex items-center justify-center text-xs">{count}</span>
                  }
                </Link>

                {
                  (showNotifications==true)&&<div className="bg-white shadow-lg absolute rounded-md divide-y w-80 right-0 top-[51px]" >
                    {
                      notifications.data.length>0 &&<div className="divide-y">
                      { notifications.data.map(notification => <div key={`notification-${notification.id}`} className="px-4 py-3 flex items-center">
                          {getIcon(notification)}<div className="w-60"><Link to={notification.link} className="text-sm text-gray-600 hover:text-indigo-600">{notification.notification_txt}</Link></div>
                        </div>)
                      }
                        <div className="px-4 py-3 text-center">
                          <Link to={`/influencers/notifications`} className="text-sm text-gray-700 font-medium hover:text-indigo-600">View all notifications</Link>
                        </div>
                      </div>
                    }


                  </div>
                }

                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                      <span className="sr-only">Open user menu</span>
                      {
                        user!=null&&user.image_id!=null?<img
                        className="h-8 w-8 rounded-full"
                        src={`https://res.cloudinary.com/wigmarket/w_50,h_50,c_fill/${user.image_id}.jpg`}
                        alt=""
                      />:<div className="h-8 w-8 rounded-full bg-slate-800 text-white font-bold flex items-center justify-center">{user!=null&&user.name[0]}</div>
                    }
                    </Menu.Button>
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <Link
                              to={item.url}
                              className={classNames(
                                active ? 'bg-gray-100' : '',
                                'block px-4 py-2 text-sm text-gray-700'
                              )}
                            >
                              {item.name}
                              
                            </Link>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
              </div>

              <div className="bg-gray-50">
          <Outlet/>
          </div>
        
      </div>
      </div>
    </>
    );
}