import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies, useCookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert, TextArea, Select, FileDrop, Modal} from '../components/Section';

function InfluencerSignIn() {

  const navigate = useNavigate();

  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  const [errorMessage, setErrorMessage] = useState(null);

  const [message, setMessage] = useState(null);

  const [sendVerification, setSendVerification] = useState(false);

  const [verificationEmail, setVerificationEmail] = useState(null);

  const [resetPasswordModal, setResetPasswordModal] = useState(false);

  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');

  const resendVerification = async() => {

    const body = new FormData();

    body.append('email', verificationEmail);
    const response = await fetch(`/api/v2/resendVerification`, {
      method: 'POST',
      body
    }).then(resp => resp.json());

    const {message, error} = response;

    if (message) {
      setMessage(message);
    } else if (error) {
      setErrorMessage(error);
    }


  }

  const sendResetPassword = async() => {
    const body = new FormData();

    body.append('email', forgotPasswordEmail);

    const response = await fetch(`/api/forgotPassword`, {
      method: 'POST',
      body
    }).then(resp => resp.json());

    const {message, error} = response;

    if (message) {
      setResetPasswordModal(false);
      setMessage(message);
    }

    if (error) {
      toast(error);
    }
  }

  const submitSignIn = async(e) => {
    e.preventDefault();

    setSendVerification(false);

    setErrorMessage(null);

    const body = new FormData();
    body.append('email', email);
    body.append('password', password);

    const response = await fetch(`/api/v2/login`, {
      method: 'POST',
      body
    }).then(resp => {
      if (resp.status == 401) {
        setErrorMessage('Incorrect email or password');
      }
      return resp.json()
    });

    const {error, verification_required, access_token} = response;

    if (verification_required) {
      setVerificationEmail(response.email);
    }

    if (error) {
      setErrorMessage(error);
    } else if (access_token) {
      var d = new Date();

      d.setTime(d.getTime() + (30*24*60*60*1000));

      setCookie('token', access_token, {
        expires: d,
        path: '/'
      });

      if (cookies.redirect_uri) {
        document.location.href = cookies.redirect_uri;
        removeCookie('redirect_uri');
      } else {
        navigate('/influencers/dashboard');
      }
    }
  }

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <Link to={'/'}><img
                className="h-12 w-auto -ml-1"
                src="https://res.cloudinary.com/wigmarket/c_fill,h_400/beauty-clout/bc-horizontal.png"
                alt="Workflow"
              /></Link>
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Sign in</h2>
              <p className="mt-2 text-sm text-gray-600">
                Need to create an influencer account? Sign up {' '}
                <Link to="/signup" className="font-medium text-indigo-600 hover:text-indigo-500">
                 here
                </Link>
              </p>
            </div>

            <div className="mt-8">
              

              <div className="mt-6">
                <form action="#" method="POST" className="space-y-6" onSubmit={submitSignIn}>
                  
                  <div>
                    <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                      Email Address
                    </label>
                    <div className="mt-1">
                      <input
                        id="email"
                        name="email"
                        value={email}
                        onChange={e => setEmail(e.target.value)}
                        type="email"
                        autoComplete="email"
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <div className="space-y-1">
                    <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                      Password
                    </label>
                    <div className="mt-1">
                      <input
                        id="password"
                        name="password"
                        type="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                        required
                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>

                  <button type="button" className="text-indigo-600 hover:text-indigo-700 text-sm" onClick={() => setResetPasswordModal(true)}>Forgot your password?</button>

                 
                  {errorMessage!=null&&<div><Alert title="Hold up!" status="danger">{errorMessage}</Alert></div>}
                  {message!=null&&<div><Alert title="Success!" status="success">{message}</Alert></div>}
                  {
                    verificationEmail!=null&&<div>
                      <button
                      onClick={() => resendVerification()}
                      type="button"
                      className="w-full flex justify-center py-2 px-4 border border-gray-400 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-gray-50 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      Resend Verification Email
                    </button>
                    </div>
                  }




                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Sign in
                    </button>
                  </div>
                  
                </form>
              </div>
            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 min-h-full">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1624400350686-89aac6cd9f2b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80"
            alt=""
          />
        </div>
      </div>
      <Modal open={resetPasswordModal} setOpen={() => setResetPasswordModal(false)} primary={{label: 'Send Instructions', action: () => sendResetPassword()}} secondary={{label: 'Close', action: () => setResetPasswordModal(false)}}>
        <h4 className="font-bold mb-4 text-md text-black">Forgot your password?</h4>
        <p className="text-sm text-gray-600 mb-4">If you're having trouble accessing your account, enter your email address below to receive instructions on how to reset your password.</p>
        <InputField label="Email Address" placeholder="you@email.com" type="email" value={forgotPasswordEmail} onChange={(e) => setForgotPasswordEmail(e.target.value)}/>
        
      </Modal>
      <ToastContainer theme="dark" autoClose={3000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/>
    
    </>);
}

export default withCookies(InfluencerSignIn);