import React from 'react'

export default function Button({type='button', children, primary, fullWidth=false, className='', onClick, disabled}) {

  var classes = 'inline-flex items-center justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500';
  if (primary) {
    classes = 'bg-indigo-600 hover:bg-indigo-700 text-white inline-flex';
  }

  if (fullWidth) {
    classes += ' w-full';
  }

  classes += ' '+className;

  var _disabled = disabled==1?true:false;

  return <button
      type={type}
      className={`disabled:opacity-50 flex justify-center py-2 px-4 border rounded-md shadow-sm text-sm font-medium  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 ${classes}`}
      onClick={onClick}
      disabled={_disabled}
    >
      {children}
    </button>
};

