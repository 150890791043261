import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useLocation, useSearchParams
} from "react-router-dom";
import moment from 'moment';
import {useAuthRequest} from '../Auth';
import { ToastContainer, toast } from 'react-toastify';

import { ChevronRightIcon, ChevronLeftIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, XIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, TrashIcon, ShoppingBagIcon} from '@heroicons/react/outline'
import Section, {Tabs, Pager, Card, Modal} from '../components/Section';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function Products() {

	/* Provider hooks */

	const {authenticatedFetch} = useAuthRequest();

  	const fetch = authenticatedFetch;

	const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    const ref = useRef();

	/* State variables */

	const [products, setProducts] = useState(null);

	const [query, setQuery] = useState('');

	const [user, setUser] = useState(null);

	const [shopify, setShopify] = useState(null);

	const [syncModal, setSyncModal] = useState(false)


	/* Serverside functions */

	const loadProducts = async() => {
		let page = searchParams.has('page')?searchParams.get('page'):'1';

		let q = searchParams.has('q')?searchParams.get('q'):'';

		const response = await fetch(`/api/v2/shopify?page=${page}&q=${q}`, 'GET');

		const { products, user, shopify } = response;

		if (products && user && shopify) {
			
			setProducts(products);
			setUser(user);
			setShopify(shopify);
		}

	}

	const syncCatalog = async() => {
		const response = await fetch(`/api/v2/shopify`, 'POST');

		const { message, error } =  response;

		if (message) {
			toast(message);
		}

		if (error) {
			toast(error);
		}

		setSyncModal(false);

	}

	/* Client-side functions */


	const changePage = (page) => {
		let url = `/products?page=${page}`;
		if (searchParams.has('q') && searchParams.get('q').length > 0) {
			url = `/products?page=${page}&q=${searchParams.get('q')}`
		}

		navigate(url);
	}

	const submitSearch = () => {
		const q = ref.current.value;

		navigate(`/products?q=${q}`);
	}

	const checkForEnter = (e) => {
		if (e.keyCode == 13 || e.charCode == 13) {
			submitSearch();
		}
	}

	/* useEffect hooks */

	useEffect(() => {
		if (searchParams.has('q') && searchParams.get('q') != '') {
			setQuery(searchParams.get('q'));
		} else {
			setQuery('');
			if (ref.current) {
				ref.current.value = '';
			}
		}
		
		loadProducts();

	}, [searchParams]);

	return <Section title="Products">
		<div className="max-w-6xl mx-5">
			<p className="text-sm text-gray-600 mt-1 mb-8">You can sync your product catalog to use in Beauty Clout campaigns &amp; gigs.</p>
			<Card title="Your catalog" primaryAction={{label: 'Import Catalog', action: () => setSyncModal(true) }} unstyled={true} className="mb-8">
				<div className="divide-y">
					{
						products!=null&&(products.total>0||searchParams.has('q'))&&<div className="flex px-4 sm:px-6 py-4">
							<input type="text" defaultValue={query} ref={ref} className="rounded-l-md border-gray-200 border-l border-t border-b text-sm text-gray-600 px-4 py-3 flex-1" onKeyUp={checkForEnter} placeholder="Search your product catalog..."/>
							<button className="rounded-r-md text-sm bg-indigo-600 text-white px-4 sm:px-6 py-3" onClick={() => submitSearch()}>Search</button>
						</div>
					}
					{
						searchParams.has('q')&&searchParams.get('q')!=''&&<div className="flex px-4 sm:px-6 py-4">
							<button className="border px-4 py-2 text-sm text-gray-600 rounded-md hover:bg-gray-50 flex items-center" onClick={() => navigate('/products')}><XIcon className="w-4 h-4 block text-gray-500 mr-2"/> Remove search for "{searchParams.get('q')}"</button>
						</div>
					}
					{
						products!=null&&products.total>0&&products.data.map(product => <div className="flex justify-between px-4 sm:px-6 py-4" key={`product-${product.id}`}>
								<div className="flex space-x-2">
									{
										product.image!=null?<img src={product.image.src} className="w-12 h-12 rounded-md shadow object-contain"/>:<div className="w-12 h-12 rounded-md shadow bg-slate-200">&nbsp;</div>
									}
									<div>
										<h3 className="font-medium text-sm text-gray-800 block">{product.title}</h3>
										<span className="text-gray-600 text-xs">{product.variants.length>1?`${product.variants.length} variants`:'1 variant'}</span>
									</div>
								</div>
								<div>
									<span className="text-gray-500 text-sm">Added {moment(product.created_at).fromNow()}</span>
								</div>
							</div>)

						
					}
					{
						products!=null&&products.total==0&&(!searchParams.has('q')||searchParams.get('q') == '')&&<div className="px-4 sm:px-6 py-48 flex items-center justify-center">
							<div className="text-center">
								<ShoppingBagIcon className="w-12 h-12 text-gray-600 block mx-auto"/>
								<h3 className="text-md text-gray-600 my-3 font-medium ">Import your products</h3>
								<p className="text-sm text-gray-600">Add products from your Shopify store to use in Beauty Clout campaigns.</p>
								<button className="bg-indigo-600 text-white px-4 py-2 mt-3 rounded-md text-sm" onClick={() => setSyncModal(true)}>Import Catalog</button>
							</div>

						</div>
					}
					{
						products!=null&&products.total==0&&(searchParams.has('q')&&searchParams.get('q') != '')&&<div className="px-4 sm:px-6 py-48 flex items-center justify-center">
							<div className="text-center">
								<ShoppingBagIcon className="w-12 h-12 text-gray-600 block mx-auto"/>
								<h3 className="text-md text-gray-600 my-3 font-medium ">No products found</h3>
								<p className="text-sm text-gray-600">Your search returned zero results.</p>
								<button className="bg-indigo-600 text-white px-4 py-2 mt-3 rounded-md text-sm" onClick={() => setSyncModal(true)}>Refresh Catalog</button>
							</div>

						</div>
					}
					
					{
						products!=null&&products.total>0&&<div className="px-4 sm:px-6 py-4"><Pager data={products} changePage={changePage}/></div>
					}
					
				</div>

			</Card>
		</div>
		<ToastContainer autoClose={4000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>

	<Modal open={syncModal} setOpen={() => setSyncModal(false)} primary={{label: 'Import / Sync Catalog', action: () => syncCatalog()}} secondary={{label: 'Close', action: () => setSyncModal(false)}}>
		<h3 className="font-bold text-md text-gray-700 mb-3">Import Products from Shopify?</h3>
		<p className="text-gray-600 text-sm mb-3">This will allow you to send products to influencers through campaigns and gigs.</p>
		<p className="text-gray-600 text-sm">Importing products takes time. Please be patient while we import your catalog into Beauty Clout</p>
	</Modal>
	</Section>
}