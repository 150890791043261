import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch, Listbox } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  XIcon,
  QuestionMarkCircleIcon,
  StarIcon,
  ChevronLeftIcon,
  PlusCircleIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, UserCircleIcon, RefreshIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert, TextArea, Select, FileDrop, getURL, getHandle, getServiceName} from '../components/Section';
import moment from 'moment';
import SidePanel from '../components/SidePanel';
import "react-datepicker/dist/react-datepicker.css";
import UserInvite from '../components/UserInvite';
import { useAuthRequest } from '../Auth';
import numeral from 'numeral';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function EmptyState() {
  return <div className="flex items-center justify-center py-20 px-4 sm:px-6">
    <div className="text-center">
      <UserCircleIcon className="w-8 h-8 text-gray-400 block mx-auto mb-2" />
      <p className="text-gray-400 text-sm">No influencers found for this category.</p>
    </div>
  </div>
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const states = [
{ 'label':'Alabama', 'value': 'AL' },
{ 'label':'Alaska', 'value': 'AK'},
{ 'label':'American Samoa', 'value': 'AS'},
{ 'label':'Arizona', 'value': 'AZ'},
{ 'label':'Arkansas', 'value': 'AR'},
{ 'label':'California', 'value': 'CA'},
{ 'label':'Colorado', 'value': 'CO'},
{ 'label':'Connecticut', 'value': 'CT'},
{ 'label':'Delaware', 'value': 'DE'},
{ 'label':'District of Columbia', 'value': 'DC'},
{ 'label':'States of Micronesia', 'value': 'FM'},
{ 'label':'Florida', 'value': 'FL'},
{ 'label':'Georgia', 'value': 'GA'},
{ 'label':'Guam', 'value': 'GU'},
{ 'label':'Hawaii', 'value': 'HI'},
{ 'label':'Idaho', 'value': 'ID'},
{ 'label':'Illinois', 'value': 'IL'},
{ 'label':'Indiana', 'value': 'IN'},
{ 'label':'Iowa', 'value': 'IA'},
{ 'label':'Kansas', 'value': 'KS'},
{ 'label':'Kentucky', 'value': 'KY'},
{ 'label':'Louisiana', 'value': 'LA'},
{ 'label':'Maine', 'value': 'ME'},
{ 'label':'Marshall Islands', 'value': 'MH'},
{ 'label':'Maryland', 'value': 'MD'},
{ 'label':'Massachusetts', 'value': 'MA'},
{ 'label':'Michigan', 'value': 'MI'},
{ 'label':'Minnesota', 'value': 'MN'},
{ 'label':'Mississippi', 'value': 'MS'},
{ 'label':'Missouri', 'value': 'MO'},
{ 'label':'Montana', 'value': 'MT'},
{ 'label':'Nebraska', 'value': 'NE'},
{ 'label':'Nevada', 'value': 'NV'},
{ 'label':'New Hampshire', 'value': 'NH'},
{ 'label':'New Jersey', 'value': 'NJ'},
{ 'label':'New Mexico', 'value': 'NM'},
{ 'label':'New York', 'value': 'NY'},
{ 'label':'North Carolina', 'value': 'NC'},
{ 'label':'North Dakota', 'value': 'ND'},
{ 'label':'Northern Mariana Islands', 'value': 'MP'},
{ 'label':'Ohio', 'value': 'OH'},
{ 'label':'Oklahoma', 'value': 'OK'},
{ 'label':'Oregan', 'value': 'OR'},
{ 'label':'Palau', 'value': 'PW'},
{ 'label':'Pennsilvania', 'value': 'PA'},
{ 'label':'Puerto Rico', 'value': 'PR'},
{ 'label':'Rhode Island', 'value': 'RI'},
{ 'label':'South Carolina', 'value': 'SC'},
{ 'label':'South Dakota', 'value': 'SD'},
{ 'label':'Tennessee', 'value': 'TN'},
{ 'label':'Texas', 'value': 'TX'},
{ 'label':'Utah', 'value': 'UT'},
{ 'label':'Vermont', 'value': 'VT'},
{ 'label':'Virgin Islands', 'value': 'VI'},
{ 'label':'Virginia', 'value': 'VA'},
{ 'label':'Washington', 'value': 'WA'},
{ 'label':'West Virginia', 'value': 'WV'},
{ 'label':'Wisconsin', 'value': 'WI'},
{ 'label':'Wyoming', 'value': 'WY'}
];

const defaultFilters = {
    tiktok: false,
    instagram: false,
    youtube: false,
    facebook: false,
    twitter: false,
    state: '',
    minRating: '',
    completed: '',
    audienceSize: '',
    query: '',
    page: 1
  };

function CampaignInvite({cookies}) {

  const { id } = useParams();

  const navigate = useNavigate();

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const [campaign, setCampaign] = useState(null);

  const [loading, setLoading] = useState(false);

  const [groups, setGroups] = useState([]);

  const [selectedInfluencer, setSelectedInfluencer] = useState(null);

  const [influencerName, setInfluencerName] = useState('');

  const [searchResults, setSearchResults] = useState(null);

  const [messageTxt, setMessageTxt] = useState('');

  const [filters, setFilters] = useState(defaultFilters);

  const [submitting, setSubmitting] = useState(false);

  const setFilterValue = (merge) => {
    const _filters = {...filters, ...merge};

    if (!merge.page) {
      _filters.page = 1;
    }


    setFilters(_filters);
  }

  const toggleFilter = (filterKey) => {
    const _filters = {...filters, page: 1};
    _filters[filterKey] = !_filters[filterKey];
    setFilters(_filters);
  }

  const loadUsers = async() => {
    const response = await authFetch(`/api/v2/influencers?campaign_id=${id}`, 'GET');

    const { groups, campaign } = response;
    setGroups(groups);
    setCampaign(campaign);

    if (campaign.status == 'draft' || campaign.status == 'archive') {
      navigate('/campaigns/active');
    }
  }

  const searchInfluencerByName = () => {
    const _filters = {...filters, query: influencerName, page: 1};
    setFilters(_filters);
  }

  const loadInfluencer = async(user_id) => {

    setMessageTxt('');

    const response = await authFetch(`/api/v2/influencers/${user_id}?campaign_id=${id}`, 'GET');

    const {influencer} = response;

    setSelectedInfluencer(influencer);
  }

  const loadSearchResults = async() => {

    let queryString = 'campaign_id='+id;

    for(var key in filters) {
      queryString += '&'+key+'='+escape(filters[key]);
    }

    setLoading(true);

    const response = await authFetch(`/api/v2/influencerSearch?${queryString}`, 'GET');

    const { results } = response;

    setSearchResults(results);

    setLoading(false);

  }

  const addInviteToUser = (id, invite) => {
    
    const _searchResults = {...searchResults};

    for(var i = 0; i < _searchResults.data.length; i++) {
      console.log(_searchResults.data[i].id);
      if (_searchResults.data[i].id == id) {
        _searchResults.data[i].invited = 1;
        break;
      }
    }

    const _groups = [...groups];

    for(var i = 0;i < groups.length; i++) {
      for(var j = 0; j < groups[i].influencers.data.length; j++) {
        if (groups[i].influencers.data[j].id == id) {
          groups[i].influencers.data[j].invited = 1;
        }
      }
    }

    setGroups(_groups);
    setSearchResults(_searchResults);

  }

  const loadPage = async(type, page) => {

    const __groups = groups.map(group => {
      if (group.type == type) {
        return {...group, loading: true};
      }

      return group;
    });

    setGroups(__groups);


    const response = await authFetch(`/api/v2/influencers?campaign_id=${id}&type=${type}&page=${page}`, 'GET');

    const { influencers, error} = response;
    
    const _groups = groups.map(group => {
      if (group.type == type) {
        return {...group, influencers: influencers, loading: false};
      }
      return group;
    });

    setGroups(_groups);
  }

  const checkForEnter = (e) => {
    if (e.keyCode == 13 || e.charCode == 13) {
      searchInfluencerByName();
    }
  }

  const inviteInfluencer = async(user_id=0) => {
    setSubmitting(true);

    const formData = new FormData();



    if (selectedInfluencer != null && messageTxt.trim().length > 0) {
      formData.append('influencer_user_id', selectedInfluencer.id);
      formData.append('message', messageTxt);
    } else {
      formData.append('influencer_user_id', user_id);
    }

    const response = await authFetch(`/api/v2/campaigns/${id}/invite`, 'POST', formData);

    const {error, message, invite} = response;

    if (error) {
      toast(error);
    }

    if (invite) {
      addInviteToUser(user_id, invite);
    }

    if (message) {
      toast(message);
    }

    setSubmitting(false);
  }


  //Hooks go here!

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    loadSearchResults();
  }, [filters]);

  if (campaign == null) {
    return <div className="max-w-6xl bg-gray-50 h-screen">
      <div className=" mx-5 mb-5 mt-5">
        <div className="animate-pulse bg-slate-100">
          <h3 className="font-medium text-md text-gray-800">&nbsp;</h3>
          <h4>&nbsp;</h4>
        </div>
      </div>
      <div className="mx-5 mb-5">
        <div className="animate-pulse bg-slate-100 h-96 mb-8 rounded-md">
          &nbsp;
        </div>
        <div className="animate-pulse bg-slate-100 h-96 mb-8 rounded-md">
          &nbsp;
        </div>
      </div>
    </div>
  }

  return <div className=" max-w-6xl">
  <div className="mx-5 mb-5 mt-3">
  <Link to={`/campaign/${campaign.id}/proposals/proposed`} className="font-bold text-xl block hover:text-indigo-600">{campaign.title}</Link>
  
    <p className="text-sm text-gray-500 mt-1">Browse influencers to invite to bid on your campaign.</p>
  </div>

  <div className="bg-white shadow overflow-hidden sm:rounded-md mx-5 max-w-5xl mb-5" >
      <div className="py-4 px-4 border-b flex justify-between">
        <h3 className="font-medium text-md text-gray-800">Search Influencers</h3>
        
      </div>
      <div className="pt-4 px-4  flex">
        <div className="w-full">
          <InputField value={influencerName} onChange={(e) => setInfluencerName(e.target.value)} label="Search influencers by name" labelClassName="text-xs" className="text=sm rounded-r-none" placeholder="Influencer's name..." onKeyUp={checkForEnter}/>
        </div>
        <div className="pt-4 sm:pt-0">
          <button className="sm:mt-4 text-center bg-indigo-600 border border-indigo-600 mr-1 px-3 py-2 text-white rounded-r-md text-sm font-medium w-24" onClick={() => searchInfluencerByName()}><SearchIcon className="w-5 h-5 sm:w-5 sm:h-5 block mx-auto"/></button>
        </div>
      </div>
      <div className="py-4 px-4 border-b sm:flex">
        <div>
          <label htmlFor="location" className="block text-xs font-medium text-gray-700">
            State / Province
          </label>
          <select
            id="location"
            name="location"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={filters.state}
            onChange={(e) => setFilterValue({state: e.target.value})}
          >
            <option value=""></option>
            {
              states.map(state => <option value={state.value}>{state.label}</option>)
            }
          </select>
        </div>
        <div className="sm:ml-3 mt-2 sm:mt-0">
          <label htmlFor="location" className="block text-xs font-medium text-gray-700">
            Min. Rating
          </label>
          <select
            id="min_rating"
            name="min_rating"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={filters.minRating}
            onChange={(e) => setFilterValue({minRating: e.target.value})}
          >
            <option value=""></option>
            <option value="5">5 stars</option>
            <option value="4">4 stars</option>
            <option value="3">3 stars</option>
            <option value="2">2 stars</option>
            <option value="1">1 star</option>
          </select>
        </div>
        <div className="sm:ml-3 mt-2 sm:mt-0">
          <label htmlFor="location" className="block text-xs font-medium text-gray-700">
            Completed a campaign
          </label>
          <select
            id="min_completed"
            name="min_completed"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={filters.completed}
            onChange={(e) => setFilterValue({completed: e.target.value})}
          >
            <option value=""></option>
            <option value="true">Yes</option>
          </select>
        </div>
        <div className="sm:ml-3 mt-2 sm:mt-0">
          <label htmlFor="location" className="block text-xs font-medium text-gray-700">
            Audience Size
          </label>
          <select
            id="audience_size"
            name="audience_size"
            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={filters.audienceSize}
            onChange={(e) => setFilterValue({audienceSize: e.target.value})}
          >
            <option value=""></option>
            <option value="huge">&gt; 1m</option>
            <option value="xl">500-1m</option>
            <option value="large">100-500k</option>
            <option value="medium">10-100k</option>
            <option value="small">5-10k</option>
            <option value="micro">&lt; 5k</option>
          </select>
        </div>
        <div className="sm:ml-3 mt-2 sm:mt-0">
          <label className="block text-xs font-medium text-gray-700">Social Media</label>
          <button className="bg-transparent p-3" onClick={() => toggleFilter('tiktok')}><img src={tiktokIcon} className="w-5 h-5 block mx-auto" style={{opacity: filters.tiktok == 1?1:.33}}/></button>
        
          <button className="bg-transparent p-3" onClick={() => toggleFilter('instagram')}><img src={instagramIcon} className="w-5 h-5 block mx-auto" style={{opacity: filters.instagram == 1?1:.33}}/></button>
       
          <button className="bg-transparent p-3" onClick={() => toggleFilter('facebook')}><img src={facebookIcon} className="w-5 h-5 block mx-auto" style={{opacity: filters.facebook == 1?1:.33}}/></button>
        
          <button className="bg-transparent p-3" onClick={() => toggleFilter('twitter')}><img src={twitterIcon} className="w-5 h-5 block mx-auto" style={{opacity: filters.twitter == 1?1:.33}}/></button>
          <button className="bg-transparent p-3" onClick={() => toggleFilter('youtube')}><img src={youtubeIcon} className="w-5 h-5 block mx-auto" style={{opacity: filters.youtube == 1?1:.33}}/></button>
        </div>
        
      </div>
      <div className="border-b px-4 py-2">
        <button className="bg-transparent py-1 px-2 text-xs border rounded-md text-gray-700" onClick={() => setFilters(defaultFilters)}>Clear filters</button>
        {
          filters.query.trim().length>0&&<span className="ml-2 border rounded-md text-gray-600 px-2 py-1 text-xs font-medium">Search query: {filters.query} <button className="bg-transparent ml-1" onClick={() => setFilters({query: ''})}><XIcon className="w-3 h-3 inline-block"/></button></span>
        }
      </div>
      <div className="relative">
      <ul role="list" className="divide-y divide-gray-200">
        {
          searchResults!=null&&searchResults.data.map((influencer) => <UserInvite user={influencer} key={`search-invite-influencer-${influencer.id}`} url={`#`} onViewProfile={() => loadInfluencer(influencer.id)} onInvite={() => inviteInfluencer(influencer.id)}/>)
        }
      </ul>
      {
        searchResults!=null&&searchResults.total==0&&<div className="text-center py-12 px-4">
          <h3 className="font-medium text-gray-700">No results found</h3>
          <p className="text-gray-500 text-sm">Sorry, we could not find any influencers based on your selected criteria.</p>
        </div>
      }
      {
        loading&&<div className="absolute w-full h-full bg-white/50 top-0 left-0 z-50 flex justify-center items-center">
          <RefreshIcon className="w-10 h-10 text-slate-800 animate-spin"/>
        </div>
      }
      </div>
      {searchResults!=null&&searchResults.total>0&&<div className="p-5 flex justify-between">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => setFilterValue({page: searchResults.current_page-1})}
              disabled={searchResults.current_page<=0||loading}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            
            <button
              onClick={() => setFilterValue({page: searchResults.current_page+1})}
              disabled={searchResults.current_page==searchResults.last_page||loading}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div className="pt-1">
            <span className="text-gray-500 text-sm">Showing <strong>{searchResults.from}</strong> to <strong>{searchResults.to}</strong> of <strong>{searchResults.total}</strong> results</span>
          </div>
        </div>}
    </div>


  {groups.map(group => <div className="bg-white shadow overflow-hidden sm:rounded-md mx-5 max-w-5xl mb-5" key={`group-${group.type}`}>
      <div className="py-4 px-4 border-b flex justify-between">
        <h3 className="font-bold text-md text-gray-600">{group.title}</h3>
        <button className="bg-transparent text-indigo-600 text-sm hidden">Invite all</button>
      </div>
      <div className="relative">
      {group.influencers.total>0&&<ul role="list" className="divide-y divide-gray-200">
    {
      group.influencers.data.map((influencer) => <UserInvite user={influencer} key={`invite-influencer-${influencer.id}`} url={`#`} onViewProfile={() => loadInfluencer(influencer.id)} onInvite={() => inviteInfluencer(influencer.id)}/>)
    }

    </ul>}
    {group.influencers.total==0&&<EmptyState/>}
      {
        group.loading&&<div className="absolute w-full h-full bg-white/50 top-0 left-0 z-50 flex justify-center items-center">
          <RefreshIcon className="w-10 h-10 text-slate-800 animate-spin"/>
        </div>
      }
    </div>

    <div className="p-5 flex justify-between border-t">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => loadPage(group.type, group.influencers.current_page-1)}
              disabled={(group.influencers.current_page<=1)||group.loading==true}
              className="disabled:opacity-50 relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            
            <button
              onClick={() => loadPage(group.type, group.influencers.current_page+1)}
              disabled={(group.influencers.current_page>=group.influencers.last_page)||group.loading==true}
              className="disabled:opacity-50 relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div className="pt-1">
            <span className="text-gray-500 text-sm">Showing <strong>{group.influencers.from}</strong> to <strong>{group.influencers.to}</strong> of <strong>{group.influencers.total}</strong> results</span>
          </div>
        </div>
    </div>)}

    {selectedInfluencer!=null&&<SidePanel title={''} dismissable={true} open={true} onDismiss={() => setSelectedInfluencer(null)} >
      
      <div className="">
                      <div className="pb-1 sm:pb-6">
                        <div>
                          <div className="relative sm:px-6 grid sm:grid-cols-2 gap-4">
                              <img src={`https://res.cloudinary.com/wigmarket/w_300,h_300,c_fill,q_auto/${selectedInfluencer.image_id}.jpg`} className=" w-full sm:rounded-md"/>
                              <div className="block relative w-full">
                                <div className="">
                                <h3 className="text-xl font-bold text-gray-900 sm:text-2xl">{selectedInfluencer.name}</h3>
                                {
                                  selectedInfluencer.rating_average!=null?<div className="-ml-1">
                                    <StarIcon className="text-yellow-500 w-6 h-6 inline-block"/> <span className="text-sm font-medium text-gray-600">{selectedInfluencer.rating_average} / 5 ({selectedInfluencer.reviews.length} reviews)</span>
                                  </div>:<div>
                                    <StarIcon className="text-gray-500 w-6 h-6 inline-block"/> <span className="text-sm font-medium text-gray-600">Not Reviewed</span>

                                  </div>
                                }
                                {
                                  selectedInfluencer.completed.length>0&&<div className="-ml-1">
                                    <CheckCircleIcon className="text-green-500 w-6 h-6 inline-block"/> <span className="text-sm text-gray-600">{selectedInfluencer.completed.length} {selectedInfluencer.completed.length>1?'campaigns':'campaign'} completed</span>
                                  </div>
                                }
                              </div>
                              </div>
                          </div>
                          {selectedInfluencer.invited==null?<div>
                          <div className="mt-2 sm:mt-3 sm:px-6">
                            <TextArea label={'Message'} placeholder="Write an optional message to this person" value={messageTxt} onChange={(e) => setMessageTxt(e.target.value)}/>
                          </div>
                          <div className="mt-0 sm:mt-3 sm:flex sm:items-end sm:px-6">
                            <div className="sm:flex-1">
                              <div className="mt-0 flex flex-wrap space-y-3 sm:space-y-0 sm:space-x-3">

                                <button
                                  type="button"
                                  className="inline-flex w-full flex-shrink-0 items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:flex-1 disabled:opacity-50"
                                  onClick={() => inviteInfluencer(selectedInfluencer.id)}
                                  disabled={submitting||selectedInfluencer.invited!=null}
                                >
                                  <PlusCircleIcon className="w-4 h-4 inline-block mr-1"/>
                                  Invite to Campaign
                                </button>
                                
                                
                              </div>
                            </div>
                          </div>
                          </div>:<div className="bg-green-50 mt-3 mx-5 sm:mt-5 sm:px-4 py-3 rounded-md">
                            <p className="text-sm text-green-900"><CheckCircleIcon className="w-4 h-4 text-green-600 inline-block mr-1"/> You have invited {selectedInfluencer.name} to this campaign</p>
                          </div>}
                        </div>
                      </div>
                      <div className="pt-5 pb-5 sm:px-0 sm:pt-0">
                        <dl className="space-y-8 sm:space-y-6 sm:px-6">
                          <div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Bio</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
                              <p>
                                {selectedInfluencer.short_description!=null?selectedInfluencer.short_description:'n/a'}
                              </p>
                            </dd>
                          </div>
                          {selectedInfluencer.state!=null&&<div>
                            <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">Location</dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{selectedInfluencer.city}, {selectedInfluencer.state}</dd>
                          </div>}
                          {
                            selectedInfluencer.services.map(service => <div className="" key={`service-${service.id}`}>
                                <dt className="text-sm font-medium text-gray-500 sm:w-40 sm:flex-shrink-0">{getServiceName(service)}</dt>
                                <dd className="mt-1 text-sm  sm:col-span-2 flex justify-between">
                                  <div>
                                    <a href={getURL(service)} target="_blank" className="text-indigo-600">{getHandle(service)}</a>
                                  </div>
                                  <div className="text-gray-500">{numeral(service.followers).format('0a')} followers</div>
                                </dd>
                              </div>)
                          }
                          <h3>Reviews</h3>
                          {
                            selectedInfluencer.reviews.map(review => <div key={`review-${review.id}`}>
                                <div className="flex justify-between">
                                  <div className="text-gray-600 text-sm"><span className="text-sm text-gray-500 font-medium">{review.reviewer_limited.brand_name}</span> &middot; <StarIcon className="text-yellow-500 w-5 h-5 inline-block"/> <span className="text-gray-500 text-sm font-medium">{review.rating} / 5</span> </div>
                                  <div className="text-sm text-gray-500">{moment(review.created_at).fromNow()}</div>
                                  
                                </div>
                                <p className="text-sm text-gray-500">{review.review_txt}</p>
                              </div>)
                          }
                          {
                            selectedInfluencer.reviews.length==0&&<div>
                              <p className="text-sm text-gray-500">This influencer has not yet been reviewed</p>
                            </div>
                          }
                        </dl>
                      </div>
                    </div>
    </SidePanel>}
 <ToastContainer autoClose={2000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
  </div>;

};

export default withCookies(CampaignInvite);