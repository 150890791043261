import React, { Fragment, useState, useEffect , useRef} from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useOutletContext
} from "react-router-dom";
import { withCookies, Cookies, useCookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  ExclamationCircleIcon,
  StarIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ArrowLeftIcon, StarIcon as EmptyStarIcon, ArrowRightIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {Gig as RelatedGig, AutoComplete, Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, Modal, TextArea, Pager, Select, FileDrop, getServiceName, getServiceNameFromString} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SidePanel from '../components/SidePanel';
import { useAuthRequest } from '../Auth';
import CardForm from '../components/CardForm';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    instagram_story: {name: 'Instagram Story', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: twitterIcon}
  };

function Nav({platform, type}) {
  return <nav className="flex" aria-label="Breadcrumb">
        <div className="flex sm:hidden">
          <Link to={'/gigs'} className="group inline-flex space-x-3 text-sm font-medium text-gray-500 hover:text-gray-700">
            <svg className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-600" xDescription="Heroicon name: solid/arrow-narrow-left" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
<path fill-rule="evenodd" d="M7.707 14.707a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 1.414L5.414 9H17a1 1 0 110 2H5.414l2.293 2.293a1 1 0 010 1.414z" clip-rule="evenodd"></path>
</svg>
            <span>Back to Gigs</span>
          </Link>
        </div>
        <div className="hidden sm:block">
          <ol role="list" className="flex items-center space-x-4">
            <li>
              <div>
                <Link to={'/gigs'} className="text-gray-400 hover:text-gray-500">
                  <svg className="flex-shrink-0 h-5 w-5" x-description="Heroicon name: solid/home" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
</svg>
                  <span className="sr-only">Home</span>
                </Link>
              </div>
            </li>
            
              <li>
                <div className="flex items-center">
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"></path>
                  </svg>
                  <Link to={platform.url} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{platform.name}</Link>
                </div>
              </li>
            
              <li>
                <div className="flex items-center">
                  <svg className="flex-shrink-0 h-5 w-5 text-gray-300" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M5.555 17.776l8-16 .894.448-8 16-.894-.448z"></path>
                  </svg>
                  <Link to={type.url} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">{type.name}</Link>
                </div>
              </li>
            
            
          </ol>
        </div>
      </nav>
}

function PrevArrow({onClick}) {
  return <button onClick={onClick} className="absolute z-10 top-[45%] border bg-white shadow w-10 h-10 aspect-square flex items-center justify-center rounded-full"><ArrowLeftIcon className="w-5 h-5"/></button>
}

function NextArrow({onClick}) {
  return <button onClick={onClick} className="absolute z-10 top-[45%] right-0 border bg-white shadow w-10 h-10 aspect-square flex items-center justify-center rounded-full"><ArrowRightIcon className="w-5 h-5"/></button>
}

function SocialMediaToggle({service, fee, onChange=false, checked=false, serviceId, name}) {
  if (service == null) {
    return null;
  }
  return <div className="flex items-center px-4 sm:px-6 py-4">
          {
            onChange!=false&&<div className=" pr-3">
              <input type="checkbox" className="text-indigo-600 bg-white border rounded-md" value={checked} onChange={() => onChange(service.service)}/>
            </div>
          }
          <div className="w-16 h-16 shadow flex items-center justify-center rounded-full">
            <img src={socialMediaServices[service.service].icon} className="w-10 h-10"/>
          </div>
          <div className="ml-2">
            <h3 className="font-medium text-sm text-indigo-600 block">{getHandle(service)}</h3>
            <span className="font-medium text-xs text-gray-600 block">{name} &middot; {numeral(service.followers).format('0a')} followers</span>
          </div>
          <div className="flex-1">
            <span className="block text-right font-medium text-md">${numeral(fee).format('0,0.00')}</span>
          </div>
        </div>
}

function SummaryItem({name, price, bold}) {

  const className = bold?'font-bold':'';

  return <div className="flex items-center justify-between py-4">
    <div><span className={`text-sm text-gray-600 ${className}`}>{name}</span></div>
    <div><span className={`text-sm text-gray-600 ${bold?'font-bold':'font-medium'}`}>${numeral(price).format('0,0.00')}</span></div>
  </div>
}

function StarReview({rating, id}) {

  const stars = [];
  const empty = [];

  for(var i = 0; i < rating; i++) {
    stars.push(i);
  }

  for(var i = rating; i < 5; i++) {
    empty.push('empty');
  }

  return <div className="flex">
    {
      stars.map(star => <StarIcon className="w-4 h-4 block text-yellow-600" key={`star-${id}-${star}`}/>)
    }
    {
      empty.map(star => <StarIcon className="w-4 h-4 block text-gray-300" key={`star-${id}-${star}`}/>)
    }
  </div>
}


export default function Gig() {

  const {authenticatedFetch} = useAuthRequest();

  const [cookies, setCookie, removeCookie] = useCookies(['token']);

  const navigate = useNavigate();

  const {id} = useParams();

  const [disabled, setDisabled] = useState(false);

  const [gig, setGig] = useState(null);

  const [influencer, setInfluencer] = useState(null);

  const [user, setUser] = useState(null);

  const [selectedServices, setSelectedServices] = useState([]);

  const [selectedVariantIds, setSelectedVariantIds] = useState([]);

  const [selectedExtras, setSelectedExtras] = useState([]);

  const [sidePanelOpen, setSidePanelOpen] = useState(false);

  const [reviews, setReviews] = useState(null);

  const [expandedProducts, setExpandedProducts] = useState([]);

  const [selectedProducts, setSelectedProducts] = useState([]);

  const [cards, setCards] = useState([]);

  const [message, setMessage] = useState('');

  const [error, setError] = useState('');

  const reviewCard = useRef();

  const [note, setNote] = useState('');

  const [otherGigs, setOtherGigs] = useState([]);

  const [selectedCard, setSelectedCard] = useState({name: '-- Select card', value: ''});

  const loadReviews = async(page) => {
    const response = await authenticatedFetch(`/api/v2/users/${gig.user_id}/reviews?page=${page}`, 'GET');

    const { reviews } = response;

    setReviews(reviews);
  } 

  const loadGig = async() => {

    const endpoint = cookies.token?`/api/auth/gigs/${id}`:`/api/gigs/${id}`;

    var response;

    if (cookies.token) {
      response = await authenticatedFetch(endpoint, 'GET');
    } else {
      response = await fetch(endpoint).then(resp => resp.json());
    }

    

    const {influencer, gig, cards, user, reviews, other_gigs} = response;

    if (reviews) {
      setReviews(reviews);
    }

    if (influencer) {
      setInfluencer(influencer);
    }

    if (user) {
      setUser(user);
    }

    if (gig) {
      setGig(gig);
    }

    if (cards) {
      setCards(cards);
    }

    if (other_gigs) {
      setOtherGigs(other_gigs);
    }


  }

  useEffect(() => {
    setGig(null);
    loadGig();
    window.scrollTo(0,0);
  }, [id]);

  const toggleService = (serviceType, checked) => {
    const _selectedServices = [...selectedServices];

    if (_selectedServices.indexOf(serviceType) > -1) {

      _selectedServices.splice(_selectedServices.indexOf(serviceType), 1);
    } else {
      _selectedServices.push(serviceType);
    }

    setSelectedServices(_selectedServices);
  }

  const setExtraChecked = (extraId, checked) => {
    const _selectedExtras = [...selectedExtras];

    if (checked) {
      _selectedExtras.push(extraId);
      
    } else {
      _selectedExtras.splice(_selectedExtras.indexOf(extraId), 1);
    }

    setSelectedExtras(_selectedExtras);
  }

  const selectProduct = (product) => {
    const _selectedProducts = [...selectedProducts];

    const _selectedVariantIds = [...selectedVariantIds];

    const found = _selectedProducts.find(_product => _product.id == product.id);

    if (!found) {

      _selectedProducts.push(product);

      product.variants.forEach(variant => {
        _selectedVariantIds.push(variant.id);
      })
    }

    setSelectedProducts(_selectedProducts);
    setSelectedVariantIds(_selectedVariantIds);

  }

  const removeProduct = (productId) => {
    const _selectedProducts = [...selectedProducts];

    const found = _selectedProducts.find(_product => _product.id == productId);

    if (found) {

      _selectedProducts.splice(_selectedProducts.indexOf(productId), 1);

    }

    setSelectedProducts(_selectedProducts);
  }

  const toggleExpanded = (productId) => {

    const _expandedProducts = [...expandedProducts];
    if (_expandedProducts.includes(productId)) {
      _expandedProducts.splice(_expandedProducts.indexOf(productId), 1);
    } else {
      _expandedProducts.push(productId);
    }

    setExpandedProducts(_expandedProducts);
  }



  const toggleVariantId = (variantId) => {
    const _selectedVariantIds = [...selectedVariantIds];

    if (_selectedVariantIds.includes(variantId) ) {
      _selectedVariantIds.splice(_selectedVariantIds.indexOf(variantId), 1);
    } else {
      _selectedVariantIds.push(variantId);
    }

    setSelectedVariantIds(_selectedVariantIds);
  }

  const addCard = async(source) => {
    const body = new FormData();

    body.append('source', source.source.id);

    const response = await authenticatedFetch('/api/v2/stripe', 'POST', body);

    const {message, error, card} = response;

    if (message) {
      toast(message);
      var _cards = [...cards];

      _cards.splice(_cards.length-1, 0, card);

      setCards(_cards);

      setSelectedCard(card);
    }

    if (error) {
      toast(error);
    }
  }

  const purchaseGig = async() => {
    if (selectedCard.value == '' || selectedCard.value == 'add') {
      setError('Please choose a payment option');
      return false;
    }


    setDisabled(true);
    setMessage('');
    setError('');

    const body = new FormData();

    body.append('source', selectedCard.value);

    selectedVariantIds.forEach(variantId => {
      body.append('variants[]', variantId);
    });

    selectedServices.forEach(service => {
      body.append('services[][service]', service);
    });

    selectedExtras.forEach(extraId => {
      body.append('extras[][id]', extraId);
    })

    body.append('note_txt', note);

    const response = await authenticatedFetch(`/api/gigs/${gig.id}/order`, 'POST', body);

    const {gig_order, message, error} = response;

    if (message) {
      setMessage(message);
    }

    if (gig_order) {
      setTimeout(() => {
        navigate(`/order/${gig_order.id}`);
      }, 1000);
    }

    if (error) {
      setError(error);

      setDisabled(false);
    }


  }


  if (gig == null) {
    return null;
  }


  var serviceTotal = gig.services.reduce((prev, item) => {
    if (selectedServices.indexOf(item.service) > -1) {
      return prev+item.fee;
    }

    return prev;
  }, 0);

  var extraTotal = gig.extras.reduce((prev, item) => {
    if (selectedExtras.indexOf(item.id) > -1) {
      return prev+item.fee;
    }

    return prev;
  }, 0);

  var total = gig.fee+serviceTotal+extraTotal;

  var fee = (user!=null&&user.pro==1)?0:(total*.1);



  return <>
  <div className="bg-white px-4 sm:px-6 py-4">
      <div className="container mx-auto">
        <Nav platform={{ name: getServiceNameFromString(gig.primary_service), url: `/gigs/platform/${gig.primary_service}/all`}} type={{ name: gig.subtype.name, url: `/gigs/platform/${gig.primary_service}/${gig.subtype.code}`}}/>
      </div>
  </div>
  <div className="bg-gray-50 min-h-screen container mx-auto">
    
    <div className="max-w-7xl mx-5">
      <div className="flex items-center mt-3">
        {gig.user.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_200,h_200,q_auto,c_fill/${gig.user.image_id}.jpg`} className="shadow w-20 h-20 rounded-full"/>:<div className="w-20 h-20 rounded-full bg-slate-100 text-slate-600 font-bold flex items-center text-2xl uppercase shadow justify-center">{gig.user.name[0]}</div>}
        <div className="ml-3">
          <Link to={'/'} className="text-lg font-bold text-black hover:text-indigo-600 block">{gig.title}</Link>
          <div>
          <span className="text-gray-600 text-sm">{gig.user.name}</span>
          {
            gig.user.rating_average!=null&&<span className="text-gray-600 text-sm"> &middot; <StarIcon className="w-5 h-5 inline-block text-yellow-600"/> <span className="font-medium">{gig.user.rating_average} / 5</span></span>
          }
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 mt-5 gap-4">
      
      <div className="col-span-1 lg:col-span-2">

        {
          gig.user.deleted_at==null&&gig.user.paused_at!=null&&<div className="bg-orange-100 px-4 sm:px-6 py-3 rounded-md md:flex justify-between mb-5">
            <div className="md:flex items-center">
              <ExclamationCircleIcon className="text-orange-900 mb-3 md:mb-0 w-10 h-10 md:w-5 md:h-5 block mx-auto md:mr-2"/>
              <p className="md:flex-1 text-orange-900 text-sm text-center md:text-left"><strong>Important!</strong> {gig.user.name} is not currently accepting new orders.</p>
              </div>
          </div>
        }

         {
          gig.user.deleted_at!=null&&<div className="bg-red-100 px-4 sm:px-6 py-3 rounded-md md:flex justify-between mb-5">
            <div className="md:flex items-center">
              <ExclamationCircleIcon className="text-orange-900 mb-3 md:mb-0 w-10 h-10 md:w-5 md:h-5 block mx-auto md:mr-2"/>
              <p className="md:flex-1 text-orange-900 text-sm text-center md:text-left">{gig.user.name} has deleted their account.</p>
              </div>
          </div>
        }

        {(gig.youtube_video_id!=null||gig.vimeo_video_id!=null||gig.images.length>0)&&<Slider arrows={true} dots={true} prevArrow={<PrevArrow/>} nextArrow={<NextArrow/>}>
          {
            gig.youtube_video_id!=null&&<div className=" h-[476px] w-full">
              <div className="flex items-center justify-center w-full h-full"><iframe className="block" width="560" height="315" src={`https://www.youtube.com/embed/${gig.youtube_video_id}`} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>
            </div> 
          }

          {
            gig.vimeo_video_id!=null&&<div className=" h-[476px] w-full">
              <div className="flex items-center justify-center w-full h-full">
                <iframe src={`https://player.vimeo.com/video/${gig.vimeo_video_id}?h=471083387f&title=0&byline=0&portrait=0`} width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
              </div>  
            </div>
          }

          {
            gig.images.map(image => <div key={`image-${image.id}`} className="h-[476px]">
                <div className="flex items-center h-[476px]">
                  <img src={`https://res.cloudinary.com/wigmarket/h_480,c_fill,q_auto/${image.image_id}.jpg`} className="object-contain block mx-auto"/>
                </div>
              </div>)
          }


        </Slider>}
        <Card title="Gig Details" className="mt-8">
          <div className="">
            <div>
              <label className="text-sm text-gray-600 font-medium block">Orders completed</label>
              <div className="text-sm text-gray-600">
                {gig.orders_completed_count}
              </div>
            </div>
            
          </div>
          <div className="mt-4">
            <label className="text-sm text-gray-600 font-medium">About this gig</label>
            <div dangerouslySetInnerHTML={{__html: gig.description_txt.replaceAll("\n", '<br/>')}} className="text-sm text-gray-600">

            </div>
          </div>
          {gig.user.short_description!=null&&<div className="mt-4">
            <label className="text-sm text-gray-600 font-medium">About {gig.user.name}</label>
            <div dangerouslySetInnerHTML={{__html: gig.user.short_description.replaceAll("\n", '<br/>')}} className="text-sm text-gray-600">

            </div>
          </div>}
        </Card>
        <Card title={`${(reviews.total>0?reviews.total:'No')} Reviews`} className="my-8" subtitle="Reviews for this influencer include gigs and campaigns." unstyled={true} id="review-card">
        <div className="divide-y">
          {
            reviews.data.map(review => <div key={`review-${review.id}`} className="flex px-4 sm:px-6 py-4">
                <div className="shrink-0">
                  <img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill/${review.reviewer_limited.image_id}.jpg`} className="w-16 h-16 aspect-square rounded-full"/>
                </div>
                <div className="ml-2">
                  <div className="flex">
                    <span className="text-sm font-medium text-gray-600 mr-2">{review.reviewer_limited.brand_name}</span>
                    <StarReview rating={review.rating} id={review.id}/>
                    </div>

                  <p className="text-sm text-gray-600 block" dangerouslySetInnerHTML={{__html: review.review_txt}}></p>
                  <span className="text-xs block mt-3 text-gray-600">{moment(review.created_at).fromNow()}</span>
                </div>
              </div>)
          }
          {
            reviews.total==0&&<div className="flex items-center justify-center">
              <div className="text-center px-4 py-10">
                <p className="text-sm text-gray-500">{gig.user.name} has not yet been reviewed.</p>
              </div>
            </div>
          }
          <div className="px-4 sm:px-6 py-4">
            <Pager data={reviews} changePage={loadReviews}/>
          </div>
          </div>
        </Card>
      </div>
      <div className="col-span-1 md:col-span-1">
        <Card title="Services" unstyled={true} className="mb-8">
          <div className="divide-y">
            <SocialMediaToggle service={gig.user[gig.primary_service]} fee={gig.fee} name={socialMediaServices[gig.primary_service].name}/>
            {
              gig.services.length>0&&<div className="px-4 sm:px-6 py-4">
                <label className="text-sm text-gray-500 font-medium">Additional Options</label>
              </div>
            }
            {gig.services.map(service => <SocialMediaToggle name={socialMediaServices[service.service].name} key={`service-${service.id}`} onChange={toggleService} service={gig.user[service.service]} fee={service.fee} serviceId={service.id} checked={selectedServices.includes(service.service)}/>)}
              {
              gig.extras.length>0&&<div className="px-4 sm:px-6 py-4">
                <label className="text-sm text-gray-500 font-medium">Add-ons</label>
              </div>
            }
            {
              gig.extras.map(extra => <div className="flex items-center px-4 sm:px-6 py-4" key={`extra-${extra.id}`}>
                <div className="pr-3">
                    <input type="checkbox" className="text-indigo-600 bg-white border rounded-md" checked={selectedExtras.indexOf(extra.id) > -1} onChange={(e) => setExtraChecked(extra.id, e.target.checked)}/>
                  </div>
                
                <div className="ml-2">
                  <h3 className="font-medium text-sm text-indigo-600 block">{extra.extra_name}</h3>
                  {extra.code=='express'&&<span className="font-medium text-xs text-gray-600 block">Completion expected in {extra.estimate_days} days</span>}
                </div>
                <div className="flex-1">
                  <span className="block text-right font-medium text-md">${numeral(extra.fee).format('0,0.00')}</span>
                </div>
              </div>)
            }
            <div className="px-4 sm:px-6 py-4">
              <Button disabled={gig.user.paused_at!=null||gig.deleted_at!=null||gig.user.deleted_at!=null} primary fullWidth={true} onClick={() => setSidePanelOpen(true)}>Purchase for ${numeral(total).format('0,0.00')}</Button>
            </div>
          </div>
        </Card>
        <h3 className="block sm:hidden font-bold mb-3 pt-3 border-t">Related Gigs</h3>
        {
          otherGigs.length>0&&<div className="space-y-8">
            {
              otherGigs.map(other => <RelatedGig gig={other} key={`other-gig-${other.id}`}/>)
            }
          </div>
        }
        <div className="block sm:hidden mb-8">&nbsp;</div>
      </div>
      </div>
    </div>
    <Modal open={(user==null&&sidePanelOpen)} setOpen={() => setSidePanelOpen(false)} primary={{label: 'Go to Shopify App Store', action: () => { document.location.href = 'https://apps.shopify.com/beauty-clout'; } }} secondary={{label: 'Close', action: () => setSidePanelOpen(false)}}>
      <h4 className="font-bold text-lg text-black mb-3">Ready to work with {gig.user.name}?</h4>
      <p className="text-gray-600 text-sm mb-3">Sign up for Beauty Clout through Shopify</p>
      <ul className="space-y-2">
        <li className="flex items-center text-sm text-gray-600"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Over 10,000 beauty influencers to work with.</span></li>
        <li className="flex items-center text-sm text-gray-600"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Seamlessly ship products to hired influencers through Shopify.</span></li>
        <li className="flex items-center text-sm text-gray-600"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Create campaigns for your brand + invite influencers to bid.</span></li>
        <li className="flex items-center text-sm text-gray-600"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Affiliate programs allow you to track referrals + reward influencers.</span></li>

        <li className="flex items-center text-sm text-gray-600"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Plans start at $2.99 / month.</span></li>

        <li className="flex items-center text-sm text-gray-600 font-medium"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-2"/><span className="flex-1">Free 14-day trial</span></li>

      </ul>

    </Modal>
    {user!=null&&<SidePanel open={sidePanelOpen} dismissable={true} onDismiss={() => setSidePanelOpen(false)} >
      <div>
        <h3 className="font-bold text-lg text-gray-900">Purchase Gig from {gig.user.name}</h3>
        <h3 className="font-medium text-gray-600 text-sm mb-8">{gig.title}</h3>
        <TextArea label={`Message to ${gig.user.name} *`} helpText="Provide details on what kind of work you need from this influencer..." rows={'4'} className="mb-8" placeholder="I would like for you to review my products on TikTok." value={note} onChange={e => setNote(e.target.value)}/>

        <AutoComplete label="Send this influencer a product for review?" endpoint="/api/v2/products" placeholder="Search your product catalog..." onSelect={selectProduct} />

        <div className="divide-y mb-8">
          {
            selectedProducts.map(product => <div><div className="px-3 flex py-4" key={`product-${product.id}`}>
                <img src={product.image_url} className="w-10 h-10 rounded-sm shadow"/>
                <div className="ml-3 ">
                  <strong className="text-gray-700 text-sm font-medium block">{product.name}</strong>
                  {product.variants.length>1?<button onClick={() => toggleExpanded(product.id)} className="pl-0  ml-0 -ml-1 flex text-xs items-center text-gray-700">{!expandedProducts.includes(product.id)?<ChevronRightIcon className="text-gray-400 w-5 h-5"/>:<ChevronDownIcon className="text-gray-400 w-5 h-5"/>} {product.variants.length} product variants</button>:<span className="text-gray-700 text-xs">1 product variant</span>}
                  
                </div>
                <div className="flex-1 text-right">
                  <button className="border rounded-md py-2 px-3 hover:bg-gray-50" onClick={() => removeProduct(product.id)}><TrashIcon className="w-5 h-5 text-gray-600"/></button>
                </div>
              </div>
              {expandedProducts.includes(product.id)&&<div className="max-h-96 overflow-y-auto ml-10">
                    {
                      product.variants.map(variant => <div className="px-4 py-2 flex items-center" key={`variant-${variant.id}`}>
                          <input type="checkbox" className="rounded-sm bg-white text-indigo-600 border border-gray-300" id={`variant-${variant.id}`} checked={selectedVariantIds.includes(variant.id)} onChange={() => toggleVariantId(variant.id)}/>
                          <div className="ml-3">
                          <label className="divide-x space-x-4  text-sm" for={`variant-${variant.id}`} htmlFor={`variant-${variant.id}`}>
                            {variant.option_1!=null&&<span>{variant.option_1}</span>}
                             {variant.option_2!=null&&<span>{variant.option_}2</span>}
                              {variant.option_3!=null&&<span>{variant.option_3}</span>}
                          </label>
                          <span className="text-xs font-medium text-gray-600 block">{variant.sku}</span>
                          </div>
                        </div>)
                    }
                  </div>}
              </div>)
          }

        </div>

        <Select options={cards} selected={selectedCard} setSelected={setSelectedCard} label="Payment Method"/>

        {
          selectedCard.value=='add'&&<CardForm onSuccess={addCard} onError={(error) => toast(error)} showLabel={false} />
        }

        <strong className="text-sm font-medium block mt-8">Order Summary</strong>
        <div className="border-b divide-y">
          <SummaryItem name={socialMediaServices[gig.primary_service].name} price={gig.fee}/>
          {
            gig.services.map(service => {
              if (selectedServices.includes(service.service)) {
                return <SummaryItem name={socialMediaServices[service.service].name} price={service.fee} key={`service-itemized-${service.service}`}/>
              }
              return null;
            })
          }
          {
            gig.extras.map(extra => {
              if (selectedExtras.includes(extra.id)) {
                return <SummaryItem name={extra.extra_name} price={extra.fee} key={`extra-itemized-${extra.code}`}/>
              }
              return null;
            })
          }
          {
            user.pro==0&&<SummaryItem name="Beauty Clout Fee (10%)" price={fee}/>
          }
          {
            <SummaryItem name="Total" price={total+fee} bold={true}/>
          }
        </div>
        {
          message!=''&&<div className="mt-4"><Alert title="Success!" status="success">
            <p>{message}</p>
          </Alert></div>
        }
        {
          error!=''&&<div className="mt-4"><Alert title="Hold up!" status="danger">
            <p>{error}</p>
          </Alert></div>
        }
        <div className="">
          <Button primary={1} fullWidth={1} className="mt-4" disabled={disabled} onClick={() => purchaseGig()}>Purchase Gig for ${numeral(total).format('0,0.00')}</Button>
        </div>
      </div>

    </SidePanel>}
  </div>
  </>
}