import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  CurrencyDollarIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  StarIcon,
  ThumbUpIcon,
  ShoppingBagIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, HeartIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, TextArea, Select, FileDrop, Gig} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function InfluencerPage() {
  return <><div className="container mx-auto h-[90vh] md:grid md:grid-cols-2 bg-cover bg-center"  style={{backgroundImage: "url('https://images.unsplash.com/photo-1620262497474-7fcc419e48a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80')"}}>
    <div className=" flex items-center h-full bg-white/75 sm:bg-gray-50">
      <div className="lg:pr-10 px-5">
        <h1 className="text-6xl font-bold">Partner with Beauty Brands seeking influencers</h1>
        <p className="sm:text-lg text-gray-700 my-3 text-md">Join tens of thousands of influencers getting paid by great beauty brands.</p>
        <div className="flex space-x-2">
          <Link to={'/signup'} className="block w-full bg-indigo-600 hover:bg-indigo-700 border border-indigo-600 text-white px-4 py-4 rounded-md block md:w-64 text-center font-medium">Sign up as an Influencer</Link>
          
        </div>
      </div>
    </div>
    <div className="bg-cover bg-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1620262497474-7fcc419e48a9?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80')"}}>
    </div>
  </div>
  <div className="container mx-auto py-20">
    <div className="text-center mb-8">
     <h1 className="font-bold text-black text-3xl mb-3">The Perks of Being an Influencer</h1>
     <p className="text-gray-600 text-sm">Beauty Clout campaigns can offer one or multiple incentive options for influencers.</p>
    </div>
    <div className="md:grid md:grid-cols-3 gap-8 space-y-8 md:space-y-0">
      <div className="mx-5 md:mx-0 bg-white rounded-lg shadow px-4 sm:px-6 py-6">

        <CurrencyDollarIcon className="block w-20 h-20 mx-auto mb-4 text-slate-300"/>
        <h3 className="font-bold text-black text-lg">Get Paid for Posts</h3>
        <p>After a brand invites you to their campaign, submit a proposal detailing what you're offering, and most importantly, your fees.</p>

      </div> 
      <div className="mx-5 md:mx-0 bg-white rounded-lg shadow px-4 sm:px-6 py-6">
        <ShoppingBagIcon className="block w-20 h-20 mx-auto mb-4 text-slate-300"/>
        <h3 className="font-bold text-black text-lg">Get Free Stuff</h3>
        <p>Receive beauty products to promote to your social media audience.</p>

      </div> 
      <div className="mx-5 md:mx-0 bg-white rounded-lg shadow px-4 sm:px-6 py-6">
        <UserIcon className="block w-20 h-20 mx-auto mb-4 text-slate-300"/>
        <h3 className="font-bold text-black text-lg">Earn Commission on Referrals</h3>
        <p>Get rewarded for referring sales to partnering brands through your own unique referral link.</p>

      </div>  
    </div>
  </div>
  <div className="py-20">
    <div className="container mx-auto px-4">
      <div className="mb-4">
        <h1 className="font-bold text-black text-3xl mb-3 text-center">How Does it Work?</h1>
        <p className="text-gray-600 text-center">There are two ways to connect with brands on Beauty Clout</p>

      </div>
      <div className="md:grid md:grid-cols-2 gap-8">
        <div className="mx-5 md:mx-0 bg-white rounded-lg shadow px-4 sm:px-6 py-6 mb-8 sm:mb-0">
          <h3 className="font-bold text-black text-xl mb-3">Campaigns</h3>
          <p className="text-gray-700 mb-3">With Beauty Clout Campaigns, brands invite influencers to submit proposals.</p>
          <p className="text-gray-700 mb-3">Brands set the parameters of their campaign, including budget, products offered, and affiliate incentives.</p>
          <p className="text-gray-700 mb-3">Influencers then set their fees, select products to receive, and submit their proposals for approval.</p>
          <Link to={'/signup'} className="block text-center sm:inline-block text-white bg-indigo-600 bg-white font-medium w-full sm:w-64 px-8 py-4 sm:py-3 rounded-md">Sign up for Beauty Clout</Link>
        </div>
        <div className="mx-5 md:mx-0 bg-white rounded-lg shadow px-4 sm:px-6 py-6">
          <h3 className="font-bold text-black text-xl mb-3">Gigs</h3>
          <p className="text-gray-700 mb-3">Gigs put the control in influencers' hands, while making it a convenient and quick option for brands.</p>
          <p className="text-gray-700 mb-3">Influencers create listings called gigs that detail their services and specialties. Set your fees, offer add-ons and extra services.</p>
          <Link to={'/gigs'} className="block text-center sm:inline-block text-white bg-indigo-600 bg-white px-8 font-medium w-full sm:w-64 py-4 sm:py-3 rounded-md">Browse Gigs</Link>

        </div>
      </div>
    </div>
  </div>
  </>;
}