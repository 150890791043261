import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  StarIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, HeartIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, TextArea, Select, FileDrop, Gig} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function HomePage() {
  return <div className="bg-cover lg:container mx-auto h-screen lg:h-[90vh] lg:grid lg:grid-cols-2"  style={{backgroundImage: "url('https://images.unsplash.com/photo-1603217192412-c933ccfac0fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2835&q=80')"}}>
    <div className="bg-white/80 md:bg-gray-50 flex items-center h-full">
      <div className=" lg:pr-10 px-5 md:px-0">
        <h1 className="text-5xl md:text-6xl font-bold">Beauty Brands 🤝 Beauty Influencers</h1>
        <p className="text-lg text-gray-500 my-4">Beauty Clout is the only influencer marketing platform designed for the beauty industry.</p>
        <div className="md:flex md:space-x-2">
          <Link to={'/brand'} className="w-full mb-2 md:mb-0 bg-indigo-600 border border-indigo-600 text-white px-4 py-4 rounded-md block md:w-48 text-center font-medium">I'm a brand</Link>
          <Link to={'/influencer'} className="w-full bg-white border-indigo-600 text-indigo-600 border px-4 py-4 rounded-md block md:w-48 text-center font-medium">I'm an influencer</Link>
        </div>
      </div>
    </div>
    <div className="bg-cover bg-center" style={{backgroundImage: "url('https://images.unsplash.com/photo-1603217192412-c933ccfac0fa?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2835&q=80')"}}>
    </div>
  </div>;
}