import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useParams
} from "react-router-dom";
import moment from 'moment';
import {useAuthRequest} from '../Auth';
import { ChevronRightIcon, ChevronLeftIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, PlusIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Section, {Nav} from '../components/Section';

const tabs = [
  { name: 'Active', url: '/campaigns/active', key: 'active' },
  { name: 'Drafts', url: '/campaigns/draft', key: 'draft' },
  { name: 'Archive', url: '/campaigns/archive', key: 'archive' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function LoadingBlock() {
	return <div className="p-3">
		<div className="aspect-square w-full animate-pulse bg-gray-200">

		</div>
		<span className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none animate-pulse bg-gray-200">&nbsp;</span>
		<p className="inline-block w-48 text-xs font-medium  animate-pulse bg-gray-200 mt-2">&nbsp;</p>
	</div>
}

export default function Campaigns({children}) {

	const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

	const { status } = useParams();

	const [campaigns, setCampaigns] = useState(null);

	const [page, setPage] = useState(1);

	const primaryAction = {
		url: '/campaign',
		label: 'New Campaign'
	};

	const loadCampaigns = async() => {
		const response = await authFetch(`/api/v2/campaigns?status=${status}&page=${page}`, 'GET');

		const { campaigns, error } = response;

		if (error) {
			alert(error);
		}

		if (campaigns) {
			setCampaigns(campaigns);
		}
	}

	useEffect(() => {
		setCampaigns(null);
		setPage(1);
		loadCampaigns();
		window.scrollTo(0,0);
	}, [status]);

	useEffect(() => {
		if (campaigns != null) {
			setCampaigns(null);
			loadCampaigns();
			window.scrollTo(0,0);
		}
	}, [page]);


  return <div className="bg-gray-50 min-h-screen">
  	<Nav homeUrl="/dashboard" links={[{name: 'Campaigns', url: '/campaigns/active'}]}/>

    <div className="max-w-7xl">
    	<div className="flex">
    		<Link to={'/campaign'} className="bg-indigo-600 text-white px-4 py-3 mb-4 mx-5 text-sm w-full sm:w-auto rounded-md mt-4 sm:ml-5 flex items-center justify-center"><PlusIcon className="w-5 h-5 inline-block mr-1"/> Create New Campaign</Link>
    	</div>
    	<div>
	      <div className="sm:hidden mx-5 sm:mx-0">
	        <label htmlFor="tabs" className="sr-only">
	          Select a tab
	        </label>
	        {/* Use an "onChange" listener to redirect the user to the selected tab URL. */}
	        <select
	          id="tabs"
	          name="tabs"
	          className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
	          defaultValue={tabs.find((tab) => (tab.key==status)).key}
	        >
	          {tabs.map((tab) => (
	            <option key={tab.key}>{tab.name}</option>
	          ))}
	        </select>
	      </div>
	      <div className="hidden sm:block">
	        <div className="border-b border-gray-200 px-5">
	          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
	            {tabs.map((tab) => (
	              <Link
	                key={tab.name}
	                to={tab.url}
	                className={classNames(
	                  tab.key==status
	                    ? 'border-indigo-500 text-indigo-600'
	                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
	                  'whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm'
	                )}
	                aria-current={tab.current ? 'page' : undefined}
	              >
	                {tab.name}
	              </Link>
	            ))}
	          </nav>
	        </div>
	      </div>

	      {
	      	campaigns==null&&<div className="px-5 py-3">
	      		<div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
	      			<LoadingBlock/>
	      			<LoadingBlock/>
	      			<LoadingBlock/>
	      			<LoadingBlock/>
	      			<LoadingBlock/>
	      			<LoadingBlock/>
	      			<LoadingBlock/>
	      			<LoadingBlock/>
	      		</div>
	      	</div>
	      }

	      {campaigns!=null&&campaigns.total>0&&<div className="px-5 py-3"><div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
	      	{
	      		campaigns.data.map((campaign, i) => <div key={`campaign-${campaign.id}`} className="p-3">
	      				<Link to={campaign.status!='draft'?`/campaign/${campaign.id}/proposals/proposed`:`/campaign/${campaign.id}/details`}>

	      					{campaign.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_800,h_800,c_fill,q_auto/${campaign.image_id}.jpg`} className="rounded-md w-full shadow"/>:<div className="rounded-md aspect-square w-full shadow bg-slate-200 text-slate-400 flex font-medium text-xs items-center justify-center uppercase">No Image</div>}
	      					<span className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{campaign.title}</span>
	      					<p className="block text-xs font-medium text-gray-500 pointer-events-none">Created {moment(campaign.created_at).fromNow()}</p>
	      				</Link>
	      			</div>)
	      	}
	      	</div>
	      	<div className="px-3 mt-5 flex justify-between">
          <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
            <button
              onClick={() => setPage(page-1)}
              disabled={page<=0}
              className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
            </button>
            
            <button
              onClick={() => setPage(page+1)}
              disabled={page==campaigns.last_page}
              className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </nav>
          <div className="pt-1">
            <span className="text-gray-500 text-sm">Showing <strong>{campaigns.from}</strong> to <strong>{campaigns.to}</strong> of <strong>{campaigns.total}</strong> results</span>
          </div>
        </div>
	      </div>}

	      {campaigns!=null&&campaigns.total==0&&<div className="text-center px-5 py-12 bg-white m-5">
	      		<h3 className="font-medium text-md text-gray-700 mb-3">No campaigns found</h3>
	      		<p className="text-sm text-gray-500 mb-4">We could not find any campaigns of <strong className="text-xs font-bold uppercase">{status}</strong> status</p>
	      		<Link to={`/campaign`} className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">Create a Campaign</Link>
	      	</div>}
	    </div>
    </div>
  </div>
};