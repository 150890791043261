import React, { Fragment, useState, useEffect , useRef} from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckCircleIcon,
  ChevronDownIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  StarIcon,
  UserIcon,
  LightningBoltIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { ShoppingBagIcon, ExclamationCircleIcon, CheckIcon, XIcon, TrashIcon, PencilIcon, ArrowLeftIcon, StarIcon as EmptyStarIcon, LinkIcon, ArrowRightIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {getFulfillmentStatusName, AutoComplete, Steps, Card, Tabs, getHandle, getOrderStatus, InputField, Alert, PageTabs, Modal, TextArea, Pager, Select, FileDrop, getServiceName, getServiceNameFromString, MenuButton} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SidePanel from '../components/SidePanel';
import { useAuthRequest } from '../Auth';
import CardForm from '../components/CardForm';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    instagram_story: {name: 'Instagram Story', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: twitterIcon}
  };

const defaultTabs = [
  {name: 'Pending', id: 'pending', url: '/influencers/orders/pending', current: false},
  {name: 'Active', id: 'active', url: '/influencers/orders/active', current: false},
  {name: 'Completed', id: 'completed', url: '/influencers/orders/completed', current: false},
  {name: 'Expired', id: 'cancelled', url: '/influencers/orders/cancelled', current: false},
];

const cancellationReasons = [
  { name: '-- Select a reason', value: ''},
  { name: 'Changed mind', value: 'change_mind'},
  { name: 'Brand is not responsive', value: 'unresponsive'},
  { name: 'Unable to complete assigned work', value: 'unable'},
  { name: 'Fraud / Brand is not who they say they are', value: 'fraud'},
  { name: 'Other', value: 'other'}
];

function OrderItem({itemName, itemValue, primary=false, subtract=false, bold=false}) {
  return <div className="px-4 py-4 sm:px-6 flex justify-between items-center">
    <div>
      <span className={`block text-sm text-gray-600 ${bold&&'font-bold'}`}>{itemName}{primary&&` (Primary Service)`}</span>
    </div>
    <div>
      <span className="block text-right text-sm text-gray-700 font-medium">{subtract&&'-'}${numeral(itemValue).format('0,0.00')}</span>
    </div>
  </div>
}

function BrandImage({user}) {
  if (user.image_id == null) {
    return <div className="rounded-full w-12 h-12 shadow bg-slate-800 text-white flex justify-center items-center">{user.brand_name[0]}</div>
  }

  return <img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill,q_auto/${user.image_id}.jpg`} className="rounded-full w-12 h-12 shadow"/>
}

function Product({product, onView}) {
  return <div className="p-3 overflow-hidden">
    <img src={product.product.image.src} className="object-contain aspect-square w-full"/>
    <span className="text-ellipsis truncate block mb-2 text-sm text-gray-700 font-medium">{product.product.title}</span>
    <button className="rounded-md  text-gray-700 border border-gray-300 text-sm py-2 px-4 block w-full" onClick={() => onView(product)}>View Product</button>
  </div>
}

function Status({status}) {
  var label = '';
  var css = '';

  switch(status) {
    case 'authorized':
      label = 'Pending Approval';
      css = 'bg-orange-600';
      break;
    case 'products_submitted':
      label = 'Products Submitted';
      css = 'bg-green-600';
      break;
    case 'production':
      label = 'In Progress';
      css = 'bg-green-600';
      break;
    case 'order_created':
      label = 'Order Created';
      css = 'bg-green-600';
      break;
    case 'cancelled':
      label = 'Cancelled';
      css = 'bg-red-600';
      break;
    case 'expired':
      label = 'Expired';
      css = 'bg-slate-600';
      break;
    case 'completed':
      label = 'Awaiting Review';
      css = 'bg-cyan-600';
      break;
    case 'verified':
      label = 'Work Verified';
      css = 'bg-green-600';
      break;
    default:
      label = 'Unknown Status';
      css = 'bg-slate-600';

  }

  return <span className={"rounded-full py-1 px-3 text-xs text-white mb-1 inline-block text-center font-medium "+css}>{label}</span>
}

function VariantSelector({variants, onVariantChange}) {

  const [options1, setOptions1] = useState([]);
  const [options2, setOptions2] = useState([]);
  const [options3, setOptions3] = useState([]);

  const [option1, setOption1] = useState(null);
  const [option2, setOption2] = useState(null);
  const [option3, setOption3] = useState(null);

  const getClassName = (option, compare) => {
    var css = "rounded-md font-medium py-2 px-3 border text-xs disabled:opacity-50 disabled:line-through flex items-center ";

    if (compare == option) {
      css += ' bg-slate-700 text-white border-slate-700';
    }

    return css;
  }

  useEffect(() => {

    const options_1 = [];
    const options_2 = [];
    const options_3 = [];

    variants.forEach(variant => {
      if (variant.variant.option_1 != null && !options_1.includes(variant.variant.option_1)) {
        options_1.push(variant.variant.option_1);
      }

      if (variant.variant.option_2 != null && !options_2.includes(variant.variant.option_2)) {
        options_2.push(variant.variant.option_2);
      }

      if (variant.variant.option_3 != null && !options_3.includes(variant.variant.option_3)) {
        options_3.push(variant.variant.option_3);
      }


    })
    setOptions1(options_1);
    setOptions2(options_2);
    setOptions3(options_3);

    //setOption1(variants[0].variant.option_1);
    //setOption2(variants[0].variant.option_2);
    //setOption3(variants[0].variant.option_3);

  }, [variants]);

  useEffect(() => {

    const v = variants.find(variant => (variant.variant.option_1 == option1 && variant.variant.option_2 == option2 && variant.variant.option_3 == option3));

    onVariantChange(v);

  }, [option1, option2, option3]);

  return <div className="space-y-1">
    {options1.length>0&&<div className=""><label className="text-xs font-medium text-gray-600">Option 1</label><div className="space-x-1 flex-wrap flex space-y-1">
      {
        options1.map(option => <button className={getClassName(option, option1)} onClick={() => setOption1(option)}>{option1==option&&<CheckCircleIcon className="w-4 h-4 inline-block mr-1"/>}{option}</button>)
      }
      </div></div>
    }
    {options2.length>0&&<div><label className="text-xs font-medium text-gray-600">Option 2</label><div className="space-x-1 flex-wrap flex">
      {
        options2.map(option => <button className={getClassName(option, option2)} onClick={() => setOption2(option)}>{option2==option&&<CheckCircleIcon className="w-4 h-4 inline-block mr-1"/>}{option}</button>)
      }
      </div></div>
    }
    {options3.length>0&&<div><label className="text-xs font-medium text-gray-600">Option 3</label><div className="space-x-1 flex-wrap flex">
      {
        options3.map(option => <button className={getClassName(option, option3)} onClick={() => setOption3(option)}>{option3==option&&<CheckCircleIcon className="w-4 h-4 inline-block mr-1"/>}{option}</button>)
      }
      </div></div>
    }
  </div>
}



export default function GigOrder() {

  /* Provider hooks */

  const { id } = useParams();

  const defaultTabs = [
      {name: 'Details', current: true, url: `/influencers/order/${id}`},
      {name: 'Messages', current: false, count: 0,  url: `/influencers/order/${id}/messages`}
  ];

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const navigate = useNavigate();

  /* State variables */

  const [order, setOrder] = useState(null);

  const [gig, setGig] = useState(null);

  const [tabs, setTabs] = useState(defaultTabs);

  const [products, setProducts] = useState(null);

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [selectedVariant, setSelectedVariant] = useState(null);

  const [approvedProducts, setApprovedProducts] = useState([]);

  const [proofs, setProofs] = useState([]);

  const [orderSummaryShow, setOrderSummaryShow] = useState(true);

  const [attachmentsSaved, setAttachmentsSaved] = useState(true);

  const [proofsSaved, setProofsSaved] = useState(true);

  const [declineModal, setDeclineModal] = useState(false);

  const [bag, setBag] = useState([]);

  const [uploadError, setUploadError] = useState(null);

  const [uploading, setUploading] = useState(false);

  const [attachments, setAttachments] = useState([]);

  const [submissionModal, setSubmissionModal] = useState(false);

  const [proofWarningModal, setProofWarningModal] = useState(false);

  const [approvalModal, setApprovalModal] = useState(false);

  const [productSubmissionModal, setProductSubmissionModal] = useState(false);

  const [cancelModal, setCancelModal] = useState(false);

  const [cancelRequestModal, setCancelRequestModal] = useState(false);

  const [reasonCode, setReasonCode] = useState(cancellationReasons[0]);

  const [detailsTxt, setDetailsTxt] = useState('');

  const [withdrawCancellationRequestModal, setWithdrawCancellationRequestModal] = useState(false);

  const [cancelAgreeModal, setCancelAgreeModal] = useState(false);

  const [cancelDeclineModal, setCancelDeclineModal] = useState(false);

  /* Server-side Functions */

  const submitProofs = async(override=0) => {

    setSubmissionModal(false);
    setProofWarningModal(false);

    const formData = new FormData();

    if (override == 1) {
      formData.append('override', '1');
    }

    const response = await fetch(`/api/v2/gig-orders/${id}/submitProofs`, 'POST', formData);

    const { message, warning, error } = response;

    if (warning) {
      setTimeout(() => {
      setProofWarningModal(true);

      }, 500);
    } else if (message) {
      toast(message);
      loadOrder();
      setSubmissionModal(false);
      setProofWarningModal(false);
    } else if (error) {
      toast(error);
      setSubmissionModal(false);
      setProofWarningModal(false);
    }
  }

  const loadOrder = async(checkUrlProofs=false, checkImageProofs=false) => {

    const response = await fetch(`/api/v2/gig-orders/${id}`, 'GET');

    const { order, gig, error, products, message_count } = response;

    if (response.unauthorized) {
      navigate('/signin');
    }

    if (error) {
      navigate('/influencers/orders/pending');
    }

    if (order && gig) {

      setOrder(order);
      setGig(gig);

      if (products) {
        setProducts(products);
      }

      if (order.status == 'products_submitted') {

        let _bag = [];

        order.products.forEach(product => {

          product.variants.forEach(variant => {

            if (variant.selected == 1) {
              _bag.push({
                id: product.product.id,
                title: product.product.title,
                image: product.product.image,
                variant: variant.variant
              });
            }

          });

        });

        setBag(_bag);
      }

      if (['order_created', 'production', 'completed', 'verified'].includes(order.status)) {

        let _approved = [];

        order.products.forEach(product => {

          product.variants.forEach(variant => {

            if (variant.selected == 1) {
              _approved.push({
                id: product.product.id,
                title: product.product.title,
                image: product.product.image,
                variant: variant.variant
              });
            }

          });

        });

        setApprovedProducts(_approved);

      }

      if (order.status != 'cancelled' && order.status != 'authorized') {
        setOrderSummaryShow(false);
      }

      if (checkUrlProofs) {

        let _proofs = [];

        order.url_proofs.forEach(proof => {
          _proofs.push({
            url: proof.url,
            editUrl: proof.url,
            edit: false,
            id: proof.id
          });
        });

        setProofs(_proofs);
      }

      if (checkImageProofs) {

        let _attachments = [];

        order.image_proofs.forEach(proof => {
          _attachments.push(proof.image_id);
        });

        setAttachments(_attachments);
      }

      if (message_count) {
        var _tabs = [...tabs];
        _tabs[1].url = `/influencers/order/${id}/messages`;
        _tabs[1].count = message_count;
        setTabs(_tabs);
      }

    }


    
  }

  const uploadImages = async(files) => {

    const _attachments = JSON.parse(JSON.stringify(attachments));

    setUploadError(false);
    setUploading(true);
    
    const body = new FormData();

    files.forEach(file => {
      body.append('images[]', file);
    });

    const response = await fetch('/api/image', 'POST', body);

    const {error, images} = response;

    if (error) {
      setUploadError(error);
    }

    if (images) {

      images.forEach(image => {
        _attachments.push(image.id);
      });

      setAttachments(_attachments);
    }

    setUploading(false);
  }

  const deleteImage = (index) => {
    const _imageIds = [...attachments];

    _imageIds.splice(index, 1);

    setAttachments(_imageIds);
  }

  const declineOrder = async() => {
    const response = await fetch(`/api/gig-orders/${id}/cancel`, 'POST' );

    setDeclineModal(false);

    const { message, error } = response;

    if (message) {
      toast(message);
      loadOrder();
    }

    if (error) {
      toast(error);
    }
  }

  const loadProducts = async(page=1) => {
    const response = await fetch(`/api/v2/gig-orders/${id}/products?page=${page}`, 'GET');

    const { products } = response;

    if (products) {
      setProducts(products);
    }

  }

  const approveOrder = async() => {
    const response = await fetch(`/api/gig-orders/${id}/accept`, 'POST' );

    setApprovalModal(false);

    const { message, error } = response;

    if (message) {
      toast(message);
      loadOrder();
    }

    if (error) {
      toast(error);
    }
  }

  const submitProducts = async() => {

    const formData = new FormData();

    bag.forEach(product => {
      formData.append('selected[]', product.variant.id);
    });

    const response = await fetch(`/api/gig-orders/${id}/products`, 'POST', formData );

    const {message, error} = response;

    if (message) {
      toast(message);
      loadOrder();
      setProductSubmissionModal(false);
    }

    if (error) {
      toast(error);
    }
  }

  const saveProofs = async() => {
    const formData = new FormData();

    proofs.forEach(proof => {
      formData.append('url[]', proof.url);
    });

    const response = await fetch(`/api/v2/gig-orders/${id}/proofs`, 'POST', formData);

    const {message, error} = response;

    if (message) {
      toast(message);
      loadOrder(true, false);
    }

    if (error) {
      toast(error);
    }
  }

  const saveAttachments = async() => {
    const formData = new FormData();

    attachments.forEach(image_id => {
      formData.append('image_id[]', image_id);
    });

    const response = await fetch(`/api/v2/gig-orders/${id}/attachments`, 'POST', formData);

    const {message, error} = response;

    if (message) {
      toast(message);
      loadOrder(false, true);
    }

    if (error) {
      toast(error);
    }
  }

  /* Client-side functions */

  const addToBag = () => {

    const found = bag.find(product => (product.variant.id==selectedVariant.variant.id));

    if (found) {
      toast('This product variant is already in your bag');
      return false;
    }

    const _product = {
      id: selectedProduct.product.id,
      title: selectedProduct.product.title,
      image: selectedProduct.product.image,
      variant: selectedVariant.variant
    }

    const _bag = [...bag];

    _bag.push(_product);

    setBag(_bag);
    setSelectedProduct(null);
    setSelectedVariant(null);
    toast(`${_product.title} added to your bag`);
  }

  const removeFromBag = (index) => {
    const _bag = [...bag];

    _bag.splice(index, 1);

    setBag(_bag);
  }

  

  const getOrderStatus = (order) => {
    if (order.fulfillments.length == 0) {
      return <span className="bg-slate-600 text-white text-xs py-1 px-3 rounded-full font-medium">Not yet shipped</span>
    }

    var notDelivered = order.fulfillments.find(fulfillment => (fulfillment.status != 'delivered'));

    if (notDelivered) {
      return <span className="bg-cyan-600  text-white text-xs py-1 px-3 rounded-full font-medium">Shipped</span>
    }

    return <span className="bg-green-600  text-white text-xs py-1 px-3 rounded-full font-medium">Delivered</span>
  }

  const chooseProduct = (product) => {
    setSelectedProduct(product);

    if (product.variants.length == 1) {
      setSelectedVariant(product.variants[0]);
    } else {
      setSelectedVariant(null);
    }
  }

  const validateUrl = (value) => {
    return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value);
  }

  const saveProofUrl = (id) => {

    const _proof = proofs.find(proof => (proof.id == id));

    if (!_proof) {
      return false;
    }

    if (!validateUrl(_proof.editUrl)) {
      toast('Invalid URL');
      return false;
    }


    const _proofs = proofs.map(proof => {

      if (proof.id == id) {
        return {...proof, url: proof.editUrl, edit: false};
      }

      return proof;
    });

    setProofs(_proofs);
  }

  const cancelProofUrl = (id) => {

    const _proof = proofs.find(proof => (proof.id == id));

    if (!_proof) {
      return false;
    }



    if (_proof.url.trim() == '') {
      let index = proofs.findIndex(proof => (proof.id == id));
      let _proofs = [...proofs];

      _proofs.splice(index, 1);

      setProofs(_proofs);
      return false;
    }

    const _proofs = proofs.map(proof => {

      if (proof.id == id) {
        return {...proof, editUrl: proof.url, edit: false};
      }

      return proof;
    });

    setProofs(_proofs);
  }

  const editProof = (id) => {
    const _proofs = proofs.map(proof => {

      if (proof.id == id) {
        return {...proof, edit: true};
      }

      return proof;
    });

    setProofs(_proofs);
  }

  const deleteProof = (index) => {
    const _proofs = [...proofs];

    _proofs.splice(index, 1);

    setProofs(_proofs);
  }

  const addEditableProof = () => {
    const editableProof = {
      id: `${proofs.length}-${moment().unix()}`,
      url: '',
      editUrl: '',
      edit: true
    };

    const _proofs = [...proofs];

    _proofs.push(editableProof);

    setProofs(_proofs);
  }

  const updateProofUrl = (id, url) => {
    const _proofs = proofs.map(proof => {
      if (proof.id == id) {
        return {...proof, editUrl: url};
      }

      return proof;
    });

    setProofs(_proofs);
  }

  const withdrawCancellationRequest = async() => {
    const response = await fetch(`/api/v2/gig-orders/${order.id}/withdrawCancellation`, 'POST');

    const {message, error} = response;

    if (message) {
      toast(message);
      setWithdrawCancellationRequestModal(false);
      loadOrder();

    }

    if (error) {
      toast(error);
    }
  }

  const requestCancellation = async() => {
    const body = new FormData();

    body.append('reason_code', reasonCode.value);
    body.append('details_txt', detailsTxt);

    const response = await fetch(`/api/v2/gig-orders/${order.id}/requestCancellation`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      setCancelRequestModal(false);
      loadOrder();
    }

    if (error) {
      toast(error);
    }
  }

  const respondToCancellationRequest = async(agree) => {
    const body = new FormData();

    body.append('agree', agree);

    const response = await fetch(`/api/v2/gig-orders/${order.id}/cancellationRequest`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      loadOrder();
      setCancelAgreeModal(false);
      setCancelDeclineModal(false);
      window.scrollTo(0,0);
    }

    if (error) {
      toast(error);
    }
  }

  /* Hooks */

  React.useEffect(() => {
    loadOrder(true, true);
  }, []);

  React.useEffect(() => {

    if (order != null) {

    if (proofs.length != order.url_proofs.length) {
      setProofsSaved(false);
    } else {
      let differenceFound = false;

      proofs.forEach((proof, i) => {
        if (proof.id != order.url_proofs[i].id) {
          differenceFound = true;
        }
      });

      setProofsSaved(!differenceFound);
    }

    if (attachments.length != order.image_proofs.length) {
      setAttachmentsSaved(false);
    } else {
      let image_ids = order.image_proofs.map(proof => proof.image_id);

      let notFound = false;

      attachments.forEach((attachment, i) => {
        if (!image_ids.includes(attachment)) {
          notFound = true;
        }
      });

      setAttachmentsSaved(!notFound);
    }
  }

    

  }, [proofs, attachments]);


  if (order == null) {
    return null;
  }

  const canChooseProducts = ['products_submitted', 'authorized'].includes(order.status);

  const showApprovedProducts = ['order_created', 'production', 'completed', 'verified'].includes(order.status);

  const canEditProofs = ['order_created', 'production', 'completed'].includes(order.status);

  const cantEditProofs = ['cancelled', 'verified'].includes(order.status);



  const proofInEditMode = typeof proofs.find(proof => proof.edit) != 'undefined';

  const menuOptions = [];

  if (order != null && order.allow_cancellation == 1) {
    menuOptions.push({
      title: 'Cancel Order',
      action: () => setCancelModal(true)
    });
  } else if (order != null && order.request_cancellation == 1) {
    menuOptions.push({
      title: 'Request Cancellation',
      action: () => setCancelRequestModal(true)
    });
  }

  return <Section title={`Gig Order #${order.id}`}>

    <div className="max-w-6xl mx-5">
      <div className="flex justify-between">
      <div>
       <Link to={`/influencers/gig/${gig.id}`} className="text-sm text-indigo-600 hover:text-indigo-500">{gig.title}</Link>
       </div>
       <div>
        {menuOptions.length>0&&<MenuButton options={menuOptions} label="..."/>}
       </div>
       </div>
      {
        order.status=='authorized'&&(order.products.length>0?<Alert title="You received an order!" status="success" className="mt-5">
            <p>{order.user.brand_name} has included products for you to choose to receive.</p>
            <p>If you would like to accept this order, browse the product selection below, and add any products to your bag. Then accept the order.</p>
          </Alert>:<Alert title="You received an order!" status="success" className="mt-5">
            <p>Great, read any notes left by the brand. Then, you can choose to accept or decline this order.</p>

          </Alert>)
      }
      
      <div className="grid sm:grid-cols-3 mt-5 gap-4">
        <div className="sm:col-span-2"> 
          {
            order!=null&&<PageTabs tabs={tabs} navigate={navigate}/>
          }
          <Card title="Order Summary" unstyled={true} className="mb-8">
            <div className="divide-y">
              <div className="px-4 sm:px-6 py-4 sm:flex justify-between">
                <div className="flex space-x-2">
                  <BrandImage user={order.user} />
                  <div>
                    <h3 className="font-medium text-gray-700">{order.user.brand_name}</h3>
                    <a href={order.user.brand_url} className="text-xs text-indigo-600"><LinkIcon className="w-3 h-3 inline-block"/> Website</a>
                  </div>
                </div>
                <div className="sm:text-right mt-3 sm:mt-0">
                  <Status status={order.status}/>
                  <span className="text-gray-500 text-xs block sm:text-right">Gig Order received {moment(order.created_at).fromNow()}</span>
                </div>
              </div>
              
              {order.note_txt!=''&&order.note_txt!=null&&<div className="px-4 sm:px-6 py-4">
                <label className="text-xs font-medium text-gray-600">Notes from the brand</label>
                <p className="text-sm text-gray-600" dangerouslySetInnerHTML={{__html: order.note_txt.replaceAll("\n", '<br/>')}}></p>
              </div>}
              <div className="px-4 sm:px-6 py-4">
                <button className="block text-sm text-indigo-600 mx-0 px-0" onClick={() => setOrderSummaryShow(!orderSummaryShow)}>{orderSummaryShow?<span className="flex items-center"><ChevronDownIcon className="w-4 h-4 block text-gray-600 mr-1"/>Hide Order Details</span>:<span className="flex items-center"><ChevronRightIcon className="w-4 h-4 block text-gray-600 mr-1"/>Expand Order Details</span>}</button>
              </div>


              {orderSummaryShow&&<>
              <OrderItem itemName={socialMediaServices[gig.primary_service].name} itemValue={gig.fee} primary={true}/>
              {
                order.services.map(service => {
                  if (socialMediaServices[service.service]) {
                    return <OrderItem key={`order-service-${service.id}`} itemName={socialMediaServices[service.service].name} itemValue={service.fee}/>
                  }

                  if (service.description != '' ) {
                    return <OrderItem key={`order-service-${service.id}`} itemName={service.description} itemValue={service.fee} />
                  }

                  return null
                })
              }

              {
                <OrderItem itemName="Total" itemValue={order.influencer_total}/>
              }

              {
                <OrderItem itemName="Beauty Clout Fee (10%)" itemValue={order.app_fee} subtract={true}/>
              }
              {
                <OrderItem itemName="Your Payout" itemValue={order.influencer_fee} bold={true}/>
              }
              </>}
            </div>
          </Card>

          {
            order!=null&&order.cancellation_request!=null&&<Card title="Cancellation Request" className="mb-8" primaryAction={order.cancellation_request.disputed_at==null&&order.cancellation_request.requested_by_user_id==order.gig.user_id?{label: 'Withdraw Request', action: () => setWithdrawCancellationRequestModal(true)}:null}>
              {
                order.cancellation_request.requested_by_user_id==order.gig.user_id&&order.cancellation_request.disputed_at==null&&<>
                  <p className="text-gray-600 text-sm mb-3">You have requested cancellation of this order. If <strong className="font-medium">{order.user.brand_name}</strong> agrees, the brand will be refunded, and this order will be terminated.</p>
                  <p className="text-gray-600 text-sm">Otherwise, a dispute will be opened for review by Beauty Clout staff</p>
                </>
              }
              {
                order.cancellation_request.requested_by_user_id==order.user_id&&order.cancellation_request.disputed_at==null&&<>
                  <p className="text-gray-600 text-sm mb-3">{order.user.brand_name} have requested cancellation of this order. If you agree, the brand will be refunded, and this order will be terminated.</p>
                  <p className="text-gray-600 text-sm">Otherwise, a dispute will be opened for review by Beauty Clout staff</p>
                  <div className="sm:grid sm:grid-cols-2 mt-5 gap-4">
                    <Button primary fullWidth onClick={() => setCancelAgreeModal(true)}>Cancel Order</Button>
                    <Button fullWidth onClick={() => setCancelDeclineModal(true)}>Decline Request</Button>
                  </div>
                </>
              }

              {
                order.cancellation_request.disputed_at!=null&&<>
                  <p className="text-gray-600 text-sm mb-3">A cancellation request has been declined, and a Beauty Clout administrator will review this dispute.</p>
                  <p className="text-gray-600 text-sm mb-3">Field any questions or concerns to our staff at <a href="mailto:info@beautyclout.com" className="text-indigo-600 hover:text-indigo-700">info@beautyclout.com</a></p>
                </>
              }
            </Card>
          }

          {
            order!=null&&order.shopify_order&&<Card title="Product shipment details" unstyled={true} subtitle={`Order #${order.shopify_order_number}`} className="mb-8">
              <div className="divide-y">
                <div className="px-4 sm:px-6 py-4 flex space-x-2 justify-between">
                  <div className="flex">
                    
                      <h3 className="text-md font-bold text-gray-700 p-0 m-0">#{order.shopify_order_number}</h3>
               
                    <div className="ml-3 md:ml-10">
                      <label className="font-medium text-xs text-gray-700 block">Shipping Address</label>
                      <p className="text-sm text-gray-700">{order.shopify_order.first_name} {order.shopify_order.last_name}</p>
                      <p className="text-sm text-gray-700">{order.shopify_order.address_1}</p>
                      {order.shopify_order.address_2!=null&&<p className="text-sm text-gray-700">{order.shopify_order.address_2}</p>}
                      <p className="text-sm text-gray-700">{order.shopify_order.city}, {order.shopify_order.state} {order.shopify_order.zip}</p>

                    </div>
                  </div>
                  <div>
                    <span className="text-gray-600 text-sm">{getOrderStatus(order.shopify_order)}</span>
                  </div>
                </div>
                {
                  order.shopify_order.fulfillments.map(fulfillment => <div className="px-4 sm:px-6 py-4 grid grid-cols-3 md:grid-cols-8" key={`fulfillment-${fulfillment.id}`}>
                      <div>
                        <span className="text-xs text-gray-700 block font-medium">Carrier</span>
                        <span className="text-gray-700 text-sm block">{fulfillment.carrier}</span>
                      </div>
                      <div className="md:col-span-2">
                        <span className="text-xs text-gray-700 block font-medium">Tracking</span><a href={fulfillment.url} target="_blank" className=" truncate text-indigo-600 text-sm block">{fulfillment.tracking_number}</a>
                      </div>
                      <div className="md:col-span-5 text-right">
                        <span className="text-xs text-gray-700 block font-medium">Status</span>
                        <span className="text-gray-600 italic text-sm block">{getFulfillmentStatusName(fulfillment)}</span>
                      </div>
                    </div>)
                }
              </div>

            </Card>
          }

          {
            showApprovedProducts&&approvedProducts.length>0&&<Card title="Approved Products" className="mb-8" unstyled={true} subtitle={`${order.user.brand_name} has approved the following ${approvedProducts.length} ${approvedProducts.length==1?'product':'products'}`}>
              {
                approvedProducts.map(product => <div className="px-4 sm:px-6 py-4 flex justify-between" key={`product-${product.id}`}>
                      <div className="flex items-center">
                        <div className="relative">
                          <img src={product.image.src} className="w-12 h-12 rounded-md shadow"/>
                          <CheckCircleIcon className="text-green-600 top-[-10px] absolute right-[-10px] w-5 h-5"/>
                        </div>
                        <div className="ml-3">
                          <h3 className="font-medium text-sm text-gray-600">{product.title}</h3>
                          <div className="space-x-2">
                            {product.variant.option_1!=null&&<span className="text-xs text-gray-600">{product.variant.option_1}</span>}
                            {product.variant.option_2!=null&&<span className="text-xs text-gray-600">{product.variant.option_2}</span>}
                            {product.variant.option_3!=null&&<span className="text-xs text-gray-600">{product.variant.option_3}</span>}
                          </div>
                        </div>
                      </div>
                      <div>
                        <span className="text-sm text-gray-600"><span className=" line-through">${numeral(product.variant.price).format('0,0.00')}</span> <span className="font-bold text-green-600">$0.00</span></span>
                      </div>
                    </div>)
              }
            </Card>
          }

          {
            canEditProofs&&<Card title="Your proofs" unstyled={true} className="mb-8" subtitle="This is where you show your work." primaryAction={{label: 'Add proof', action: () => addEditableProof()}}>
              {
                proofs.length==0&&<div className="px-4 sm:px-6 py-20 flex items-center justify-center">
                  <div className="text-center">
                    <h3 className="font-medium text-md text-gray-800 mb-2" >No proofs yet</h3>
                    <p className="text-gray-600 text-sm mb-2">Include a URL to your social media posts to show your work.</p>
                    <button className="border px-3 py-2 rounded-md text-sm text-gray-600" onClick={() => addEditableProof()}>Add new proof</button>
                  </div>
                </div>
              }

              {
                proofs.length>0&&<div className="divide-y">
                  {
                    proofs.map((proof, index) => {
                      if (proof.edit) {
                        return <div className="flex px-4 sm:px-6 py-4 " key={`proof-${proof.id}`}>
                          <div className="flex-1 pr-2">
                            <InputField value={proof.editUrl} onChange={(e) => updateProofUrl(proof.id, e.target.value)} placeholder="https://..."/>
                          </div>
                          <div className="space-x-1">
                            <button className="border rounded-md px-3 py-2" onClick={() => saveProofUrl(proof.id)}><CheckIcon className="w-5 h-5 text-green-600"/></button>
                            <button className="border rounded-md px-3 py-2" onClick={() => cancelProofUrl(proof.id)}><XIcon className="w-5 h-5 text-gray-500"/></button>
                          </div>
                        </div>
                      }
                      return <div className="flex px-4 sm:px-6 py-4 items-center " key={`proof-${proof.id}`}>
                          <div className="flex-1 pr-2">
                            <a href={proof.url} className="text-sm truncate block text-indigo-600">{proof.url}</a>
                          </div>
                          <div className="space-x-1">
                            <button className="border rounded-md px-3 py-2" onClick={() => editProof(proof.id)}><PencilIcon className="w-5 h-5 text-gray-600"/></button>
                            <button className="border rounded-md px-3 py-2" onClick={() => deleteProof(index)}><TrashIcon className="w-5 h-5 text-gray-500"/></button>
                          </div>
                        </div>
                    })
                  }
                </div>
              }
              <div className="px-4 sm:px-6 py-4">
                <Button onClick={() => saveProofs()} fullWidth disabled={proofsSaved||proofInEditMode} primary={!proofsSaved}>{proofsSaved?'Proofs Saved':'Save Changes'}</Button>
               </div>
            </Card>
          }

          {
            canEditProofs&&<Card title="Screenshots & Attachments" className="mb-8">
            {attachments.length>0&&<div className="grid gap-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                  {
                    attachments.map((imageId, i) => <div className="" key={`image-${imageId}`}>
                        <img src={`https://res.cloudinary.com/wigmarket/h_200,c_fill/${imageId}.jpg`} className="object-contain w-full h-32 sm:h-48"/>
                        {canEditProofs&&<button className="w-full px-3 py-2 border rounded-md text-sm text-center" onClick={() => deleteImage(i)}><TrashIcon className="w-5 h-5 text-gray-600 block mx-auto"/></button>}
                      </div>)
                  }
                </div>}
               <FileDrop error={uploadError} multiple={true} uploading={uploading}  label="Upload screenshot" onChange={(files) => uploadImages(files)} helpText="JPG, PNG, maximum size of 7MB"/>           
               <div className="mt-5">
                <Button onClick={() => saveAttachments()} fullWidth disabled={attachmentsSaved} primary={!attachmentsSaved}>{attachmentsSaved?'Attachments Saved':'Save Changes'}</Button>
               </div>
            </Card>
          }

          {products!=null&&products.total>0&&canChooseProducts&&<Card title="Proposed Products" className="mb-8" subtitle="This brand has chosen the following products for your to choose from" unstyled={true}>
            <div className="grid grid-cols-2 sm:grid-cols-3 px-4 py-4">
              {
                products.data.map(product => <Product product={product} key={`product-${product.id}`} onView={chooseProduct}/>)
              }
            </div>
            <div className="px-4 sm:px-6 py-4 border-t">
              <Pager data={products} changePage={loadProducts}/>
            </div>
          </Card>}

          

        </div>
        <div>   
          {
            products!=null&&products.total>0&&(order.status=='authorized'||order.status=='products_submitted')&&<Card title="Your Bag" className="mb-8" unstyled={true} subtitle="Add products that you want to review to your bag">
              {
                bag.length==0?<div className="px-4 sm:px-6 py-16">
                  <div className="text-center">
                    <ShoppingBagIcon className="block mx-auto w-10 h-10 text-gray-600 mb-3"/>
                    <h3 className="font-medium text-md text-gray-600 mb-1">Your bag is empty</h3>
                    <p className="text-xs text-gray-600">Choose products to receive for review / promotion</p>
                  </div>
                </div>
              :<div className="divide-y">
                {
                  bag.map((product, i) => <div key={`product-${product.id}-${product.variant.id}`} className="px-4 py-4 sm:px-6 flex space-x-1 justify-between items-center">
                      <div className="flex space-x-2 items-start">
                        <img src={product.image.src} className="h-12 rounded-md shadow aspect-square object-contain"/>
                        <div>
                          <h3 className="truncate text-sm font-medium text-gray-700 block">{product.title}</h3>
                          <span className="text-xs text-gray-600 block space-x-2">
                            {product.variant.option_1!=null&&<span>{product.variant.option_1}</span>}
                            {product.variant.option_2!=null&&<span>{product.variant.option_2}</span>}
                            {product.variant.option_3!=null&&<span>{product.variant.option_3}</span>}
                          </span>
                        </div>
                      </div>
                      <div>
                        <button className="border px-3 py-2 rounded-md" onClick={() => removeFromBag(i)}><TrashIcon className="w-5 h-5 text-gray-600"/></button>
                      </div>
                    </div>)
                }
              </div>}
              <div className="border-t px-4 py-4 sm:px-6">
                <Button disabled={bag.length==0} primary fullWidth onClick={() => setProductSubmissionModal(true)}>{order.status=='authorized'?'Accept Order + Submit Products':'Resubmit Products'}</Button>
                {order.status=='authorized'&&<Button fullWidth className="mt-2" onClick={() => setDeclineModal(true)}>Decline Order</Button>}
              </div>
            </Card>
          }

          {
            products!=null&&products.total==0&&(order.status=='authorized')&&<Card title="Next Steps" className="mb-8" unstyled={true}>
              <div className="px-4 sm:px-6 py-4">
                <p className="text-gray-600 text-sm mb-2">If you would like to accept this order, start by clicking the <strong className="font-medium text-indigo-600">Approve</strong> button. Refer to any notes that the brand has left, and start working.</p>
                {order.influencer_fee>0&&<p className="text-gray-600 text-sm mb-2"><strong className="font-medium text-indigo-600">${numeral(order.influencer_fee).format('0,0.00')}</strong> will be held in escrow, until you have completed the assignment.</p>}
                {order.influencer_fee>0&&<p className="text-gray-600 text-sm">This order must be accepted by <strong className="font-medium text-indigo-600">{moment(order.created_at).add(7, 'days').format('MMMM Do, YYYY')}</strong>, or it will automatically expire.</p>}
              </div>
              <div className="border-t px-4 py-4 sm:px-6">
                <Button primary fullWidth onClick={() => setApprovalModal(true)}>Accept Order</Button>
                {order.status=='authorized'&&<Button fullWidth className="mt-2" onClick={() => setDeclineModal(true)}>Decline Order</Button>}
              </div>
            </Card>
          }



          {
            order.status=='order_created'&&<Card title="Next Steps" unstyled={true}>
              <div className="divide-y">
                <div className="px-4 sm:px-6 py-4">
                  <p className="font-medium text-sm text-gray-700">Didn't receive your products yet?</p>
                  <p className="text-sm text-gray-600 mb-3">Keep track of your shipment. Reach out to the brand if you suspect there may be an issue with delivery.</p>
                  <p className="font-medium text-sm text-gray-700">Received your products?</p>
                  <p className="text-sm text-gray-600 mb-3">Great! Time to get to work. Once you have made your social media posts, enter proofs on this page, and submit for the brand to review.</p>
                </div>
                <div className="px-4 sm:px-6 py-4">
                  {(!proofsSaved||!attachmentsSaved)&&<span className="text-orange-600 text-sm block mb-3 text-center">Save your proof / attachment changes before submitting.</span>}
                  <Button primary fullWidth disabled={!proofsSaved||!attachmentsSaved} onClick={() => setSubmissionModal(true)}>Submit Proofs + Mark Complete</Button>
                  
                </div>
              </div>
            </Card>
          }

          {
            order.status=='completed'&&<Card title="Next Steps" unstyled={true}>
              <div className="divide-y">
                <div className="px-4 sm:px-6 py-4">
                  <p className=" text-sm text-gray-700 mb-3"><strong className="font-medium">The brand will review your work.</strong> If they are satisfied, they will mark this order as verified, and any owed payment will be transferred to your Stripe Express account.</p>
                  <p className="text-sm text-gray-700">Please note that you are still able to edit, delete, and add proof right now. Any saved changes will be visible to the brand immediately.</p>
                </div>
                
              </div>
            </Card>
          }

          {
            order.status=='production'&&<Card title="Next Steps" unstyled={true}>
               <div className="divide-y">
                <div className="px-4 sm:px-6 py-4">
                  <p className="text-sm text-gray-600 mb-2">Great! Time to get to work.</p>
                  <p className="text-sm text-gray-600 mb-2">Once you have done the assigned work, enter proofs on this page, and submit for the brand to review.</p>
                  <p className="text-sm text-gray-600 mb-2">Refer to any notes that <strong className="text-indigo-600 font-medium">{order.user.brand_name}</strong> has left, and correspond with them through the Messages section as needed.</p>
                </div>
                <div className="px-4 sm:px-6 py-4">
                  {(!proofsSaved||!attachmentsSaved)&&<span className="text-orange-600 text-sm block mb-3 text-center">Save your proof / attachment changes before submitting.</span>}
                  <Button primary fullWidth disabled={!proofsSaved||!attachmentsSaved} onClick={() => setSubmissionModal(true)} className="mb-2">Submit Proofs + Mark Complete</Button>
                  <Link to={`/influencers/order/${order.id}/messages`} className="py-2 px-5 border rounded-md text-sm text-center block text-gray-700 font-medium">Send a Message</Link>
                </div>
              </div>
            </Card>
          }

          {
            order.status=='verified'&&<Card title="Order Complete" unstyled={true}>
              <div className="divide-y">
                <div className="px-4 sm:px-6 py-4">
                  <p className=" text-sm text-gray-700 mb-3"><strong className="font-medium text-indigo-600">{order.user.brand_name}</strong> has reviewed your work, and verified its completion!</p>
                  {order.influencer_fee>0?<p className="text-sm text-gray-700">A transfer of <strong className="font-medium text-indigo-600">${numeral(order.influencer_fee).format('0,0.00')}</strong> was initiated on {moment(order.verified_at).format('MMM Do, YYYY')}.</p>:<p>This gig order did not include monetary compensation.</p>}
                </div>
                <div className="px-4 sm:px-6 py-4">
                  <Link to={'/influencers/orders/pending'} className="py-2 px-5 border rounded-md text-sm text-center block text-gray-700 font-medium">Back to Orders</Link>
                  
                </div>
              </div>
            </Card>
          }
        </div>    
      </div>
    </div>
    <SidePanel open={selectedProduct!=null} dismissable={true} onDismiss={() => setSelectedProduct(null)} >
      {
        selectedProduct!=null&&<div>
          {selectedProduct.product.image&&<img src={selectedProduct.product.image.src} className="rounded-md w-full aspect-square object-cover block mb-3"/>}
          <h3 className="font-bold text-xl">{selectedProduct.product.title}</h3>
          <div className="block mb-3"><span className="line-through text-sm text-gray-600">${numeral(selectedProduct.product.price).format('0,0.00')}</span> <span className="text-green-600 text-sm font-bold">$0.00 with Review</span></div>
          {selectedProduct.variants.length>1&&<VariantSelector variants={selectedProduct.variants} onVariantChange={setSelectedVariant}/>}
          <div className="mt-5">
          <Button primary disabled={!selectedVariant} onClick={() => addToBag()} fullWidth>{selectedVariant?'Add to Bag':'Combination not available'}</Button>
          </div>
        </div>
      }
    </SidePanel>
            <ToastContainer theme="dark" autoClose={2000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/>
    
    <Modal open={declineModal} setOpen={() => setDeclineModal(false)} primary={{label: 'Decline Order', action: () => declineOrder()}} secondary={{label: 'Close', action: () => setDeclineModal(false)}}>
  
        <div className="text-left">
          <h3 className="font-bold text-md mb-2">Are you sure?</h3>
          <p className="text-gray-600 text-sm">Confirm that you would like to decline this order. This action cannot be undone.</p>
        </div>
    </Modal>

    <Modal open={approvalModal} setOpen={() => setApprovalModal(false)} primary={{label: 'Accept Order', action: () => approveOrder()}} secondary={{label: 'Close', action: () => setApprovalModal(false)}}>
  
        <div className="text-left">
          <h3 className="font-bold text-md mb-2">Accept this order?</h3>
          {order.influencer_fee>0&&<p className="text-gray-600 text-sm mb-3">We will hold the brand's payment of <strong className="font-medium text-indigo-600">${numeral(order.influencer_fee).format('0,0.00')}</strong> (after fees) in escrow. It will be released to your possession after completion of the assigned work.</p>}
          {order.products.length>0&&<p className="text-gray-600 text-sm">Any products that you opted to receive will have to be approved by <strong className="font-medium text-indigo-600">{order.user.brand_name}</strong></p>}
        </div>
    </Modal>

    <Modal open={productSubmissionModal} setOpen={() => setProductSubmissionModal(false)} primary={{label: 'Confirm', action: () => submitProducts()}} secondary={{label: 'Close', action: () => setProductSubmissionModal(false)}}>
  
        <div className="text-left">
          <h3 className="font-bold text-md mb-2">Accept this order + submit products for approval?</h3>
          {order.influencer_fee>0&&<p className="text-gray-600 text-sm mb-3">We will hold the brand's payment of <strong className="font-medium text-indigo-600">${numeral(order.influencer_fee).format('0,0.00')}</strong> (after fees) in escrow. It will be released to your possession after completion of the assigned work.</p>}
          {order.products.length>0&&<p className="text-gray-600 text-sm">Any products that you opted to receive will have to be approved by <strong className="font-medium text-indigo-600">{order.user.brand_name}</strong></p>}
        </div>
    </Modal>

    <Modal open={submissionModal} setOpen={() => setSubmissionModal(false)} primary={{label: 'Confirm', action: () => submitProofs()}} secondary={{label: 'Close', action: () => setSubmissionModal(false)}}>
      <div className="text-left">
          <h3 className="font-bold text-md mb-2">Are you ready?</h3>
          <p className="text-gray-600 text-sm">Confirm that you would like to mark the work for this gig order as complete. This will notify the brand to review your work.</p>
        </div>
    </Modal>
    <Modal open={proofWarningModal} setOpen={() => setProofWarningModal(false)} primary={{label: 'Mark Complete', action: () => submitProofs(1)}} secondary={{label: 'Go back', action: () => setProofWarningModal(false)}}>
      <div className="text-left">
          <ExclamationCircleIcon className="w-10 h-10 block mx-auto text-orange-400 mb-3"/>
          <h3 className="font-bold text-md mb-2">Mark this order complete without proofs?</h3>
          <p className="text-gray-600 text-sm mb-2">It seems that you do not have any proofs or attachments for this gig order. It is useful to show the brand links to your posts, or to show screenshots in the attachments section.</p>
          <p className="text-gray-600 text-sm">You may still proceed to mark this order as complete, but would recommend you add proof of your work first.</p>
        </div>
    </Modal>
    <Modal open={cancelRequestModal} setOpen={() => setCancelRequestModal(false)} primary={{label: 'Request Cancellation', action: () => requestCancellation(1)}} secondary={{label: 'Go back', action: () => setCancelRequestModal(false)}}>
      <div className="text-left">
          <h3 className="font-bold text-md mb-2">Request Cancellation?</h3>
         <p className="text-sm text-gray-600 mb-3">Since there is a shipment of products associated with this assignment, you can only <span className="italic">request</span> cancellation.</p><p className="mb-5 text-sm text-gray-600">If the brand agrees to your request, this assignment will be terminated. If the brand declines your request, Beauty Clout administrators will be notified of the dispute.</p>
          <p className="text-sm text-gray-600 mb-3"><span className="text-red-500 text-md">*</span>Please note that this action will lead to a refund for the brand. If you believe you deserve to be compensated for any completed work and you are unable to reach a compromise with the brand, file a dispute instead by emailing our staff at <a className="text-indigo-600" href="mailto:info@beautyclout.com">info@beautyclout.com</a>.</p>
          <Select label="Cancellation reason *" options={cancellationReasons} selected={reasonCode} setSelected={setReasonCode}/>

          <TextArea className="mt-3" label="Additional details *" rows={4} placeholder="Provide any further information to help us understand the situation." helpText={'Your response will not be visible to the brand'} value={detailsTxt} onChange={e => setDetailsTxt(e.target.value)}/>
        </div>
    </Modal>

    <Modal open={withdrawCancellationRequestModal} setOpen={() => setWithdrawCancellationRequestModal(false)} primary={{label: 'Withdraw Cancellation', action: () => withdrawCancellationRequest()}} secondary={{label: 'Go back', action: () => setWithdrawCancellationRequestModal(false)}}>
      <div className="text-left">
          <h3 className="font-bold text-md mb-2">Withdraw Cancellation Request?</h3>
          <p className="text-sm text-gray-600 mb-3">Please confirm that you would like to withdraw your cancellation request</p>
        </div>
    </Modal>

    <Modal open={cancelAgreeModal} setOpen={() => setCancelAgreeModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('agree')}} secondary={{label: 'Close', action: () => setCancelAgreeModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Agree to Cancel Assignment?</h2>
      <p className="text-gray-600 text-sm mb-3">By confirming, you will be cancelling this assignment. Any payment held in escrow will be returned to the brand.</p>
      <p className="text-gray-600 text-sm mb-5">This action cannot be reversed.</p>
    </Modal>

    <Modal open={cancelDeclineModal} setOpen={() => setCancelDeclineModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('decline')}} secondary={{label: 'Close', action: () => setCancelDeclineModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Decline to Cancel Assignment?</h2>
      <p className="text-gray-600 text-sm mb-3">You are about to declining this brand's request to cancel this assignment</p>
      <p className="text-gray-600 text-sm mb-5">A Beauty Clout moderator may reach out to you to assist in any dispute.</p>
    </Modal>

  </Section>
}