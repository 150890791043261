import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useLocation
} from "react-router-dom";
import moment from 'moment';
import {useAuthRequest} from '../Auth';
import { ChevronRightIcon, ChevronLeftIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, MailIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, DocumentIcon } from '@heroicons/react/outline'
import Section, {Tabs, Pager} from '../components/Section';

const defaultTabs = [
  { name: 'All', url: '/orders/all', key: 'all', current: false },
  { name: 'Active', url: '/orders/active', key: 'active', current: false },
  { name: 'Pending', url: '/orders/pending', key: 'pending', current: false },
  { name: 'Completed', url: '/orders/complete', key: 'completed', current: false },
  { name: 'Expired', url: '/orders/expired', key: 'expired', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function getStatusLabel(status) {

	const css = "px-2 py-1 rounded-full text-xs font-medium";

	switch(status) {
		case 'authorized':
			return <span className={`bg-slate-600 text-white ${css}`}>Pending</span>;
		case 'production':
			return <span className={`bg-cyan-600 text-white ${css}`}>In Progress</span>;
		case 'products_submitted':
			return <span className={`bg-orange-600 text-white ${css}`}>Action Required</span>;
		case 'order_created':
			return <span className={`bg-cyan-600 text-white ${css}`}>In Progress</span>;
		case 'completed':
			return <span className={`bg-orange-600 text-white ${css}`}>Awaiting Review</span>;
		case 'verified':
			return <span className={`bg-green-600 text-white ${css}`}>Completed</span>;
		case 'expired':
			return <span className={`bg-gray-400 text-white ${css}`}>Expired</span>;
		default:
			return <span className={`bg-gray-400 text-white ${css}`}>Unknown</span>;
	}
}

export default function BrandGigOrders() {

	const {authenticatedFetch} = useAuthRequest();

  	const fetch = authenticatedFetch;

	const {status} = useParams();

	const navigate = useNavigate();

	const {pathname} = useLocation();

	const [tabs, setTabs] = useState(defaultTabs);

	const [orders, setOrders] = useState(null);

	const loadOrders = async(page=1) => {
		const response = await fetch(`/api/v2/brands/gig-orders?status=${status}&page=${page}`, 'GET');

		const { orders, error } = response;

		setOrders(orders);

		window.scrollTo(0,0);
	}

	useEffect(() => {

		let _tabs = tabs.map(tab => {
			return {...tab, current: pathname.indexOf(tab.key) > 0};
		});
		setTabs(_tabs);
		setOrders(null);
		loadOrders();
	}, [pathname]);

	return <Section title="Gig Orders">
		<div className="max-w-6xl mx-5 pt-5">
			<Tabs tabs={tabs} navigate={navigate}/>
			{
				orders==null&&<div className=" mt-4 space-y-1">
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100 rounded-md">
						&nbsp;
					</div>
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100  rounded-md">
						&nbsp;
					</div>
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100 rounded-md ">
						&nbsp;
					</div>
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100 rounded-md ">
						&nbsp;
					</div>
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100 rounded-md">
						&nbsp;
					</div>
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100  rounded-md">
						&nbsp;
					</div>
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100 rounded-md ">
						&nbsp;
					</div>
					<div className="px-4 sm:px-6 py-4 animate-pulse bg-gray-100 rounded-md ">
						&nbsp;
					</div>
				</div>
			}
			{
				orders!=null&&orders.total>0&&<div className="bg-white rounded-md shadow divide-y mt-4">
					{
						orders.data.map(order => <Link to={`/order/${order.id}`} key={`order-${order.id}`} className="px-4 sm:px-6 py-4 flex hover:bg-slate-50 space-x-2 justify-between">
								<div className="flex space-x-3 flex-1">
									<div className="">
										<h3 className="font-medium text-xs  text-gray-800 bg-slate-200 rounded-full py-1 px-3">#{order.id}</h3>
									</div>
									<div>
										{order.gig.user.image_id==null?<div className="w-12 h-12 rounded-md shadow bg-slate-100 text-slate-600 flex items-center justify-center font-bold">{order.gig.user.name[0]}</div>:<img src={`https://res.cloudinary.com/wigmarket/w_80,h_80,c_fill/${order.gig.user.image_id}.jpg`} className="w-12 h-12 rounded-md shadow"/>}
									</div>
									<div className="flex-1 sm:w-1/4 max-w-sm">
										<h3 className="font-medium text-sm text-gray-700 block flex">{order.gig.user.name}{order.unread_messages_count>0&&<span className="sm:hidden text-xs bg-red-600 py-0 px-2 rounded-full ml-1 flex items-center text-white"><MailIcon className="text-white w-3 h-3 inline-block mr-1"/> {order.unread_messages_count}</span>}</h3>
										<span className="block text-sm text-gray-600">{order.gig.title}</span>
									</div>
									<div className="hidden sm:block w-1/4 max-w-sm">
										{getStatusLabel(order.status)}

									</div>
									<div className="hidden sm:block w-1/4">
										{order.unread_messages_count>0&&<span className="text-xs font-medium bg-red-600 py-1 px-2 rounded-full ml-1 inline-block text-white"><div className="flex items-center"><MailIcon className="text-white w-3 h-3 inline-block mr-1 py-0 my-0"/> {order.unread_messages_count}</div></span>}
									</div>
								</div>
								<div className="hidden sm:blocktext-right w-1/6">
									<span className="text-xs text-gray-600">{moment(order.created_at).fromNow()}</span>
								</div>

							</Link>)
					}
					<div className="px-4 sm:px-6 py-4">
					<Pager data={orders} changePage={loadOrders}/>
					</div>
				</div>
			}
			{
				orders!=null&&orders.total==0&&<div className="bg-white rounded-md shadow mt-4">
					<div className="px-4 sm:px-6 py-20 text-center">
						<DocumentIcon className="w-12 h-12 block mx-auto text-gray-600 mb-3"/>
						<h3 className="font-medium text-md mb-2">No Orders</h3>
						<p className="text-sm text-gray-600">We could not find any orders of this status.</p>
					</div>
				</div>
			}
		</div>
	</Section>

}