import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useSearchParams
} from "react-router-dom";
import { withCookies, Cookies, useCookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert, TextArea, Select, FileDrop, Modal} from '../components/Section';

export default function PasswordReset() {

  const navigate = useNavigate();

  const {resetId, userId, token} = useParams();

  const [password, setPassword] = useState('');

  const [confirm, setConfirm] = useState('');

  const [user, setUser] = useState(null);

  const [error, setError] = useState(null);

  const loadPasswordReset = async() => {
    const response = await fetch(`/api/reset?reset_id=${resetId}&user_id=${userId}&token=${token}`, {
      method: 'GET'
    }).then(resp => resp.json());


    const {user, error} = response;

    if (user) {
      setUser(user);
    }

    if (error) {
      setError(error);
      setTimeout(() => {
        navigate('/signin');
      }, 2000);
    }
  }

  const submitPassword = async(e) => {
    e.preventDefault();
    const body = new FormData();

    body.append('password', password);
    body.append('password_confirmation', confirm);
    body.append('user_id', user.id);
    body.append('reset_id', resetId);
    body.append('token', token);

    const response = await fetch(`/api/reset`, {
      method: 'POST',
      body: body
    }).then(resp => resp.json());

    const {message, error} = response;

    if (error) {
      toast(error);
    }

    if (message) {
      toast(message);
      setTimeout(() => {
        navigate('/signin');
      }, 3500);
    }

    return false;
  }

  React.useEffect(() => {
    loadPasswordReset();
  }, []);

  return (
    <>
      <div className="min-h-screen flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <img
                className="h-12 w-auto -ml-1"
                src="https://res.cloudinary.com/wigmarket/c_fill,h_400/beauty-clout/bc-horizontal.png"
                alt="Workflow"
              />
              <h2 className="mt-6 text-3xl font-extrabold text-gray-900">{error==null?'Reset Your Password':'This link has expired. Redirecting you...'}</h2>
              <p className="mt-2 text-sm text-gray-600">
                You can sign in to your account <Link to='/signin' className="text-indigo-600">here</Link>
              </p>
            </div>

            <div className="mt-8">
              

              {user!=null?<div className="mt-6">
                <form action="#" method="POST" className="space-y-6" onSubmit={submitPassword}>
                    <div>

                      <h4 className="text-lg font-bold text-black mb-1">Welcome back, {user.name}!</h4>
                      <p className="text-gray-700 text-sm mb-5">You can reset your password by completing this form.</p>


                      <InputField type="password" label="New password" className="mb-4" value={password} onChange={e => setPassword(e.target.value)} helpText="Minimum 8 characters, must include an uppercase letter, a lowercase letter, a digit, and a symbol"/>

                      <InputField type="password" label="Confirm your password" value={confirm} onChange={(e) => setConfirm(e.target.value)}/>



                    </div>
                    
                  <div>
                    <button
                      type="submit"
                      className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Reset Password
                    </button>
                  </div>
                  
                </form>
              </div>:<div>
                <div className="mb-4 w-full animate-pulse bg-gray-200">&nbsp;</div>
                <div className="mb-4 w-full animate-pulse bg-gray-200">&nbsp;</div>
                <div className="mb-4 w-full animate-pulse bg-gray-200">&nbsp;</div>
              </div>}


            </div>
          </div>
        </div>
        <div className="hidden lg:block relative w-0 flex-1 min-h-full">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src="https://images.unsplash.com/photo-1624400350686-89aac6cd9f2b?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2787&q=80"
            alt=""
          />
        </div>
      </div>
     
      <ToastContainer theme="dark" autoClose={3000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/>
    
    </>);
}