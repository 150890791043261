import React, { Fragment, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChatIcon, MailIcon, BadgeCheckIcon, UserIcon, PaperAirplaneIcon, CashIcon, DocumentTextIcon, CheckCircleIcon, ClockIcon, ExclamationCircleIcon, CurrencyDollarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, ArrowCircleRightIcon } from '@heroicons/react/outline'

import Section, {InputField, TextArea, USStates, DashboardCard, FileDrop, TagInputField, Modal, SocialMediaSetting, Steps, Card, Pager, Select, StarRatingSelector} from '../components/Section';
import SidePanel from '../components/SidePanel';
import Button from '../components/Button';
import { ToastContainer, toast } from 'react-toastify';
import { useAuthRequest } from '../Auth';
import { initFacebookSdk } from '../Facebook';
import numeral from 'numeral';
import moment from 'moment';
import updateKey from '../components/UpdateUserSetting';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';
import 'react-toastify/dist/ReactToastify.css';
import CryptoJS from 'crypto-js';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const icons = {
  'fa-check': <CheckCircleIcon className="w-5 h-5 inline mr-1 "/>,
  'fa-proposal': <PaperAirplaneIcon className="w-5 h-5 inline mr-1"/>,
  'fa-money': <CashIcon className="w-5 w-5 inline mr-1"/>,
  'fa-usd': <CashIcon className="w-5 w-5 inline mr-1"/>,
  'fa-document': <DocumentTextIcon className="w-5 w-5 inline mr-1"/>,
  'fa-send': <PaperAirplaneIcon className="w-5 h-5 inline mr-1"/>,
  'fa-envelope' : <ChatIcon className="w-5 h-5 inline mr-1"/>

};

function getStatusName(status) {
  switch (status) {
    case 'proposed':
      return 'Pending';
    case 'production':
      return 'Hired';
    case 'change' :
      return 'Changes Requested';
    case 'products_submitted':
      return 'Products Submitted';
    case 'order_created':
      return 'Awaiting Product Shipment';
    case 'completed':
      return 'Awaiting Verification';
    case 'verified':
      return 'Work Verified';
    case 'cancelled':
      return 'Cancelled';
    default:
      return status;
  }
}

function getStatusClassName(status) {
  switch (status) {
    case 'proposed':
      return 'bg-cyan-600';
    case 'production':
      return 'bg-green-600';
    case 'products_submitted':
      return 'bg-green-600';
    case 'order_created':
      return 'bg-cyan-600';

    case 'change' :
      return 'bg-orange-600';
    case 'completed':
      return 'bg-orange-600';
    case 'verified':
      return 'bg-green-600';
    case 'cancelled':
      return 'bg-red-600';
    default:
      return 'bg-slate-600';
  }
}

function InfluencerDashboardBody({children, cookies}) {

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const [counts, setCounts] = useState({
    pending: 0,
    active: 0,
    paid_total: 0,
    pending_total: 0
  });

  const [instagramAccessToken, setInstagramAccessToken] = useState('');

  const [facebookAccessToken, setFacebookAccessToken] = useState('');

  const [facebookModal, setFacebookModal] = useState(false);

  const [facebookPages, setFacebookPages] = useState([]);
  const [initialLoad, setInitialLoad] = React.useState(false);

  const [selectedFacebookPage, setSelectedFacebookPage] = useState(0);

  const [user, setUser] = React.useState(null);

  const [instagramModal, setInstagramModal] = React.useState(false);

  const [sidePanelOpen, setSidePanelOpen] = React.useState(false);

  const [instagramAccounts, setInstagramAccounts] = React.useState([]);

  const [selectedInstagramAccount, setSelectedInstagramAccount] = React.useState(0);

  const [showStep1, setShowStep1] = React.useState(false);

  const [step, setStep] = React.useState(0);

  const [uploading, setUploading] = React.useState(false);

  const [uploadError, setUploadError] = React.useState(false);
  const [imageId, setImageId] = React.useState(null);

  const [history, setHistory] = React.useState(null);

  const [messages, setMessages] = useState(null);

  const [activeProposals, setActiveProposals] = useState(null);

  const [gigOrders, setGigOrders] = useState(null);

  const [userState, setUserState] = useState(USStates[0]);

  const [welcomeModal, setWelcomeModal] = useState(false);

  const [reviews, setReviews] = useState([]);

  const [selectedReview, setSelectedReview] = useState(null);

  const [review, setReview] = useState('');
  const [rating, setRating] = useState(0);

  const [warning, setWarning] = useState(false);

  const updateShortDescription = (e) => {

    if (e.target.value.length > 100) {
      return false;
    }

    const _user = JSON.parse(JSON.stringify(user));

    _user.short_description = e.target.value;

    setUser(_user);
  }

  const loadDashboard = async() => {

    setInitialLoad(true);

    const response = await authFetch(`/api/v2/influencerDashboard`, 'GET');

    const { history, messages, error, counts, active, orders, reviews } = response;

    if (error) {
      toast(error);
    }

    if (counts) {
      setCounts(counts);
    }

    if (history) {
      setHistory(history);
    }

    if (messages) {
      setMessages(messages);
    }

    if (active) {
      setActiveProposals(active);
    }

    if (orders) {
      setGigOrders(orders);
    }

    if (reviews.length > 0) {
      setReviews(reviews);
    }

    
  }

  const changeHistoryPage = async(page) => {
    const response = await authFetch(`/api/v2/history?page=${page}`, 'GET');

    const { history } = response;

    if (history) {
      setHistory(history);
    }
  }

  const changeMessagePage = async(page) => {
    const response = await authFetch(`/api/v2/influencerMessages?page=${page}`, 'GET');

    const { messages } = response;

    if (messages) {
      setMessages(messages);
    }
  }

  const changeProposalsPage = async(page) => {
    const response = await authFetch(`/api/v2/activeProposals?page=${page}`, 'GET');

    const { proposals } = response;

    if (proposals) {
      setActiveProposals(proposals);
    }
  }

  const skipStripe = async() => {
    const body = new FormData();

    body.append('skipped_stripe', 1);

    const response = await authFetch(`/api/user`, 'POST', body);

    const {id} =  response;

    if (id) {
      toast('Skipping Stripe setup...');
      loadUser();
    }
  }

  const loadUser = async() => {

  	const response = await authFetch(`/api/user`);

  	const { user, warning } = response;

  	if (user) {
  		setUser(user);

      if (user.image_id==null||user.short_description==null) {
        setStep(1);
      } else if (user.onboarding == true) {
        setStep(2);
      } else if (user.valid_address == false) {
        setStep(3);
      } else if (user.stripe_account_id == null && user.skipped_stripe == 0) {
        setStep(4);
      } else if (user.show_welcome_message) {
        setWelcomeModal(user.show_welcome_message);
        setStep(0);
      } else if (step != 0) {
        setStep(0);
      }

      if (user.state != null) {
        let state = USStates.find(state => state.value==user.state);

        if (state) {
          setUserState(state);
        }
      }

  	}

    if (warning) {
      setWarning(warning);
    }

  }

  const handleUserAddress = (value, field) => {

    const allowed = ['first_name', 'last_name', 'address_1', 'address_2', 'city', 'state', 'zip'];

    if (!allowed.includes(field)) {
      return false;
    }

    const _user = {...user};

    _user[field] = value;

    setUser(_user);
  }

  const uploadImage = async(file) => {

    setUploadError(false);
    setUploading(true);
    
    const body = new FormData();

    body.append('file', file);

    const response = await authFetch('/api/dropzone', 'POST', body);

    const {error, id} = response;

    const _user = JSON.parse(JSON.stringify(user));

    if (error) {
      setUploadError(error);
    }

    if (id) {
       _user.image_id = id;
       setUser(_user);
    }

    setUploading(false);
  }

  const addTag = (tag) => {
    if (user.flat_tags.length >= 6) {
      return false;
    }

    const _user = JSON.parse(JSON.stringify(user));
    
    _user.flat_tags.push(tag);

    setUser(_user);

  }

  const removeTag = (index) => {
    const _user = JSON.parse(JSON.stringify(user));
    
    _user.flat_tags.splice(index, 1);

    setUser(_user);
  }

   const saveStep1 = async() => {

    const body = new FormData();
    if (user.image_id != null) {
      body.append('profile_photo', user.image_id);

    }

    if (user.short_description != null) {
      body.append('short_description', user.short_description);
    }
    body.append('tags', user.flat_tags.join('|'));
    
    const response = await authFetch(`/api/v2/profile`, 'POST', body);

    const { error, message } = response;

    if (error) {
      toast(error);
    }

    if (message) {
      toast(message);
      setStep(2);
    }
  }

  const connectTikTok = async() => {
    const response = await authFetch(`/api/tiktok`);

    const {redirect_url} = response;

    if (redirect_url) {
      window.open(redirect_url);
    }
  }

  const getInstagramAccounts = async(accessToken) => {

      window.FB.api(`/me/accounts?fields=instagram_business_account`, (response) => {
        
        var ids = [];
        if (response.data) {
          response.data.forEach(account => {
            if (account.instagram_business_account) {
              ids.push(account.instagram_business_account.id);
            } else if (account.id) {
              ids.push(account.id);
            }
          });

          if (ids.length > 0) {

            const body = new FormData();

            body.append('access_token', accessToken);
            ids.forEach((id, i) => {
              body.append('ids['+i+']', id);
            });

            authFetch(`/api/instagram-new`, 'POST', body).then(resp => {
              //console.log(resp.instagrams);
              if (resp.instagrams) {
                setSelectedInstagramAccount(resp.instagrams[0].id);
                setInstagramAccounts(resp.instagrams);
                setInstagramModal(true);
              }
            });

          } else {

          }

        } else {
          toast('Could not load instagram account');
        }
      });

    }

  const sendFacebookToServer = async() => {

    const body = new FormData();

    const page = facebookPages.find(page => page.id == selectedFacebookPage);

    const jsonString = JSON.stringify(page);

    const check = CryptoJS.MD5('fnc03jdsf'+jsonString+'bc29salty423dfg').toString();

    body.append('check', check);
    body.append('payload', jsonString);

    const response = await authFetch(`/api/v2/facebook`, 'POST', body);

    const { error, message } = response;

    if (error) {
      toast(error);
    }

    if (message) {
      setFacebookModal(false);
      toast(message);
      loadUser();
    }
  }

  const goToStep3 = async() => {
    const response = await authFetch(`/api/v2/onboarding`, 'POST');

    const {saved} = response;

    if (saved) {
      toast('Social media accounts saved!');
      setStep(3);
    }
  }

  const sendInstagramToServer = async() => {

    const body = new FormData();

    body.append('instagram_id', selectedInstagramAccount);
    body.append('instagram_access_token', instagramAccessToken);

    const response = await authFetch(`/api/v2/instagram`, 'POST', body);

    const { error, message } = response;

    if (error) {
      toast(error);
    }

    if (message) {
      setInstagramModal(false);
      toast(message);
      loadUser();
    }
  }

  const connectTwitter = async() => {

    const response = await authFetch('/api/v2/twitter', 'GET');

    const {email, hash} = response;

    if (email && hash) {
      if (window.location.href.indexOf('localhost') >= 0) {
        window.location.replace(`https://beautyclout.dev/twitter?email=${email}&hash=${hash}`);
      } else {
        window.location.replace(`/twitter?email=${email}&hash=${hash}`);
      }
    }
    

    //
  }

  const saveUserAddress = async() => {
    const body = new FormData();

    function nullEmpty(value) {
      if (value == null) {
        return '';
      }

      return value;
    }


    body.append('first_name', nullEmpty(user.first_name));
    body.append('last_name', nullEmpty(user.last_name));
    body.append('address_1', nullEmpty(user.address_1));
    body.append('address_2', nullEmpty(user.address_2));
    body.append('city', nullEmpty(user.city));
    body.append('state', nullEmpty(user.state));
    body.append('zip', nullEmpty(user.zip));

    const response = await authFetch(`/api/v2/address`, 'POST', body);

    const {message, error} = response;

    if (error) {
      toast(error);
    }

    if (message) {
      toast(message);
      setStep(4);
    }
  }

  const changeGigOrdersPage = async(page=1) => {
    const response = await authFetch(`/api/v2/dashboardOrders?page=${page}`, 'GET');

    const { orders } = response;

    if (orders) {
      setGigOrders(orders);
    }
  }

  const submitReview = async() => {

    const body = new FormData();
    body.append('rating', rating);
    body.append('review', review);

    const response = await authFetch(`/api/v2/reviews/${selectedReview.id}`, 'POST', body);

    const {message, error } = response;

    if (message) {
      toast(message);
      setSelectedReview(null);
      loadDashboard();

    }

    if (error) {
      toast(error);
    }
  }

  const saveHandle = async(value, type) => {
    const body = new FormData();

    body.append('handle', value);

    const response = await authFetch(`/api/v2/handles/${type}`, 'POST', body);

    const {error, message} = response;

    if (error) {
      toast(error);
    }

    if (message) {
      toast(message);
      loadUser();
    }

  }

  const connectInstagram = () => {
    window.FB.getLoginStatus(response => {
      var accessToken = '';
      if (response.status && response.status == 'connected') {
        accessToken = response.authResponse.accessToken;
        getInstagramAccounts(accessToken);
      } else {
        window.FB.login(function(response) {
          //console.log('fb.login', response);
          if (response.status == 'connected') {
            accessToken = response.authResponse.accessToken;
            setInstagramAccessToken(accessToken);
            getInstagramAccounts(accessToken);
          }
        }, {scope: 'instagram_basic,pages_show_list'});
      }

      if (accessToken.length > 0) {
        setInstagramAccessToken(accessToken);
      }

    });
  }

  const disconnectService = async(service) => {
    const response = await authFetch(`/api/v2/disconnect/${service}`, 'POST');

    const {error, message} = response;

    if (error) {
      toast(error);
    }

    if (message) {
      loadUser();
      toast(message);

    }
  }

  const goToStripe = async() => {
    const response = await authFetch('/api/stripe/express');

    const { url } = response;

    if (url) {
      window.open(url);
    }
  }

  const getFacebookPages = (accessToken) => {
    window.FB.api(`/me/accounts?fields=id,followers_count,name,username,picture`, (response) => {
      //console.log(response);
        var ids = [];
        if (response.data) {
          setFacebookPages(response.data);
          setFacebookModal(true);
        } else {
          toast('Could not load facebook pages');
        }
    });
  }

  const connectFacebook = () => {
    window.FB.getLoginStatus(response => {
      var accessToken = '';
      if (response.status && response.status == 'connected') {
        accessToken = response.authResponse.accessToken;
        getFacebookPages(accessToken);
      } else {
        window.FB.login(function(response) {
          //console.log('fb.login', response);
          if (response.status == 'connected') {
            accessToken = response.authResponse.accessToken;
            setFacebookAccessToken(accessToken);
            getFacebookPages(accessToken);
          }
        }, {scope: 'pages_show_list'});
      }

      if (accessToken.length > 0) {
        setFacebookAccessToken(accessToken);
      }

    });
  }

  React.useEffect(() => {
    if (user != null && welcomeModal == false) {
      updateKey(authFetch, 'welcome', '1');
    }
  }, [welcomeModal]);


  React.useEffect(() => {
  	const _user = {...user};
    _user.state = userState.value;
    setUser(_user);
  }, [userState]);

  React.useEffect(() => {
    if (selectedReview == null) {
      setRating(0);
      setReview('');
    }
  }, [selectedReview])

  React.useEffect(() => {
  	loadUser();
    initFacebookSdk();
    if (initialLoad == false) {

      //initialLoad boolean prevents double loading

      loadDashboard();

    }
  }, []);



  const showStep2 = (user!=null&&!showStep1)&&(user.onboarding == 1);
  const showStep3 = (user!=null&&!showStep1&&!showStep2)&&(user.stripe_account_id == null);
  const descriptionHelpText = (user!=null&&user.short_description!=null)?((100-user.short_description.length)+' characters remaining'):'100 characters remaining';

  return <><Section title={user!=null?`Hello, ${user.name}`:'Hello'}>
    
    <div className="px-5 max-w-6xl pt-1">
    <span className="text-gray-500 text-sm mb-5">Welcome to Beauty Clout!</span>

    {
      user!=null&&user.paused_at!=null&&<div className="bg-orange-100 px-4 sm:px-6 py-3 mt-3 rounded-md md:flex justify-between mb-5">
        <div className="md:flex items-center">
          <ExclamationCircleIcon className="text-orange-900 mb-3 md:mb-0 w-10 h-10 md:w-5 md:h-5 block mx-auto md:mr-2"/>
          <p className="md:flex-1 text-orange-900 text-sm text-center md:text-left">Your account is currently paused. Your profile will not appear in search, but you may continue work on existing hires.</p>
          </div>
          <Link to={`/influencers/deactivation`} className="w-full block md:w-auto mt-3 md:mt-0 text-center border bg-indigo-600 text-white text-sm py-2 px-4 shadow font-medium border-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700">Un-pause Account</Link>
      </div>
    }

    {
      user!=null&&user.skipped_stripe==1&&user.stripe_account_id==null&&<div className="bg-orange-100 px-4 sm:px-6 py-3 mt-3 rounded-md md:flex justify-between mb-5">
          <div className="md:flex items-center">
          <ExclamationCircleIcon className="text-orange-900 mb-3 md:mb-0 w-10 h-10 md:w-5 md:h-5 block mx-auto md:mr-2"/>
          <p className="md:flex-1 text-orange-900 text-sm text-center md:text-left">Don't forget to create a Stripe account to receive payments from brands.</p>
          </div>
          <Link to={`/influencers/stripe`} className="w-full block md:w-auto mt-3 md:mt-0 text-center border bg-indigo-600 text-white text-sm py-2 px-4 shadow font-medium border-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700">Connect Stripe</Link>
        </div>
    }

    <div className="mt-8 mb-8">
      <h2 className="text-lg leading-6 font-medium text-gray-900">Overview</h2>
      <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-4">
        {/* Card */}
        <DashboardCard icon={<DocumentTextIcon className="w-10 h-10 text-gray-500"/>} label="Pending Invitations" value={numeral(counts.pending_count).format('0,0')} url={'/influencers/campaigns/pending'} />
        <DashboardCard icon={<BadgeCheckIcon className="w-10 h-10 text-gray-500"/>} label="Active Hires" value={numeral(counts.active_count).format('0,0')} url={'/influencers/campaigns/accepted'} />
        <DashboardCard icon={<ClockIcon className="w-10 h-10 text-gray-500"/>} label="Payments in Escrow" value={`$${numeral(counts.pending_total).format('0,0.00')}`} url={'/influencers/campaigns/verified'} />

        <DashboardCard icon={<CashIcon className="w-10 h-10 text-gray-500"/>} label="Total Paid Out" value={`$${numeral(counts.paid_total).format('0,0.00')}`} url={'/influencers/campaigns/verified'} />
      </div>
    </div>

    {
      reviews.length>0&&<Card title="How was your experience working with these brands?" unstyled={true} className="mb-8">
        <div className="divide-y">
        {
          reviews.map(review => <div className="px-4 sm:px-6 py-4 md:flex justify-between items-center" key={`review-${review.id}`}>
            <div className="flex max-w-md space-x-2">
              {
                review.subject_limited.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill/${review.subject_limited.image_id}.jpg`} className="w-12 h-12 rounded-md shadow"/>:<div className="bg-slate-200 text-slate-400 rounded-md w-12 h-12 shadow flex items-center justify-center">{review.subject_limited.brand_name}</div>
              }
              <div>
                <span className="font-medium text-sm text-gray-600 block">{review.subject_limited.brand_name}</span>
                {
                  review.order!=null?<span className="text-xs text-gray-600 block">Gig Order #{review.gig_order_id} &middot; {review.order.gig.title}</span>:
                  <span className="text-xs text-gray-600 block">{review.proposal.campaign.title}</span>
                }
              </div>
            </div>
            <Button onClick={() => setSelectedReview(review)} className="w-full md:w-auto mt-3 md:mt-0">Write a Review</Button>
          </div>)
        }
        </div>
      </Card>
    }


    {
      gigOrders!=null&&gigOrders.total>0&&<Card title="Active Orders" unstyled={true} className="mb-8">

            <table className="min-w-full divide-y divide-gray-200 ">
                        
              <tbody className=" divide-y divide-gray-200">
                {
                  gigOrders.data.map(order => <tr key={`proposal-${order.id}`}>
                    <td className="hover:bg-slate-50 max-w-0 w-full  whitespace-nowrap text-sm text-gray-900 ">
                      <Link className="px-6 block py-4 sm:flex justify-between items-center" to={`/influencers/order/${order.id}`}>
                        <div className="relative flex">
                          {order.unread_messages_count>0&&<span className="absolute font-medium py-2 rounded-full flex items-center text-white text-sm bg-red-500 px-2 rounded-md -top-4 -left-4"><MailIcon className="inline-block text-white w-4 h-4"/></span>}
                          <img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill/${order.user.image_id}.jpg`} className="w-12 h-12 rounded-md shadow"/>
                          <div className="ml-3">
                            <div className="flex items-center text-gray-600 text-sm font-medium">{order.gig.title}</div>
                            <span className="text-gray-600 text-xs block">{order.user.brand_name}</span>
                          </div>
                        </div>
                        <span className={getStatusClassName(order.status)+ ' inline-block my-3 hidden md:block font-medium text-xs shadow py-1 px-3 rounded-md text-white'}>{getStatusName(order.status)}</span>
                      </Link>
                    </td>
                  </tr>)
                }
              </tbody>

            </table>
            <div className="px-4 sm:px-6 py-4 border-t">
              <Pager data={gigOrders} changePage={changeGigOrdersPage}/>
            </div>
          
      </Card>
    }

    {
      activeProposals!=null&&activeProposals.total>0&&<Card title="Active Campaigns" unstyled={true} className="mb-8">
        {
          activeProposals.total>0?<>
            <table className="min-w-full divide-y divide-gray-200 ">
                        
              <tbody className=" divide-y divide-gray-200">
                {
                  activeProposals.data.map(proposal => <tr key={`proposal-${proposal.id}`}>
                    <td className="hover:bg-slate-50 max-w-0 w-full  whitespace-nowrap text-sm text-gray-900 ">
                      <Link className="px-6 py-4 flex justify-between items-center" to={`/influencers/campaign/${proposal.campaign_id}`}>
                        <div className="flex relative">
                          {proposal.unread_count>0&&<span className="absolute font-medium py-2 rounded-full flex items-center text-white text-sm bg-red-500 px-2 rounded-md -top-4 -left-4"><MailIcon className="inline-block text-white w-4 h-4"/></span>}
                          <img src={`https://res.cloudinary.com/wigmarket/w_100,h_100,c_fill/${proposal.campaign.image_id}.jpg`} className="w-12 h-12 rounded-md shadow"/>
                          <div className="ml-3">
                            <span className="flex text-gray-600 text-sm font-medium items-center">{proposal.campaign.title}
                            
                            </span>
                            <span className="text-gray-600 text-xs block">{proposal.campaign.user.brand_name}</span>
                          </div>
                        </div>
                        <span className={getStatusClassName(proposal.status)+ ' hidden md:block font-medium text-xs shadow py-1 px-3 rounded-md text-white'}>{getStatusName(proposal.status)}</span>
                      </Link>
                    </td>
                  </tr>)
                }
              </tbody>

            </table>
            <div className="px-4 sm:px-6 py-4 border-t">
              <Pager data={activeProposals} changePage={changeProposalsPage}/>
            </div>
          </>:<div className="px-5 py-10 flex justify-center items-center">
            <div>
              <DocumentTextIcon className="w-10 h-10 text-gray-500 block mx-auto"/>
              <h3 className="text-gray-600 font-medium text-md my-2">No active work at the moment</h3>
              <p className="text-sm text-gray-500">When you are hired by brands, this section will link to the campaigns.</p>
            </div>
          </div>
        }
      </Card>
    }

    {
      messages!=null&&<Card title="Unread Messages" unstyled={true} className="mb-8">
        {
          messages.length>0&&<table className="min-w-full divide-y divide-gray-200 ">
                        
                        <tbody className=" divide-y divide-gray-200">
                          {
                            messages.map(message => <tr key={`message-${message.id}`} className="">
                              
                              <td className="max-w-0 w-full px-6 py-4 md:whitespace-nowrap text-sm text-gray-900">
                                <div className="flex">
                                  <Link to={message.proposal_id!=null?`/influencers/proposal/${message.proposal_id}/messages`:`/influencers/order/${message.gig_order_id}/messages`} className="group block md:inline-flex space-x-2 md:truncate text-sm">
                                    <ChatIcon className="hidden md:block w-5 h-5 shrink-0 inline mr-1 text-gray-500"/>
                                    <strong className="font-medium text-xs md:text-sm">{message.from_name}</strong>
                                    {message.message_count==1?<p className="text-gray-500 md:truncate group-hover:text-gray-900 items-center">
                                      {message.message_txt}
                                    </p>:<p className="text-gray-500 truncate group-hover:text-gray-900 italic flex items-center">
                                      {message.message_count} messages
                                    </p>}
                                  </Link>
                                </div>
                              </td>
                              <td className="hidden md:block px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                <time dateTime={message.created_at}>{moment(message.created_at).fromNow()}</time>
                              </td>
                            </tr>)
                          }

                        </tbody>

                        </table>
        }
        {
          messages.length==0&&<div className="px-4 sm:px-6 text-center py-10 items-center justify-center flex">
            <div>
              <ChatIcon className="w-10 h-10 text-gray-500 block mx-auto"/>
              <h3 className="text-gray-600 font-medium text-md my-2">No unread messages</h3>
              <p className="text-sm text-gray-500">When you have new unread messages, they will appear here.</p>
            </div>
          </div>
        }
      </Card>
    }
      {history!=null&&<Card title="Recent Activity" unstyled={true} className="mb-10">
      <table className="min-w-full divide-y divide-gray-200 ">
                        
                        <tbody className=" divide-y divide-gray-200">


                        {
                          history.total==0&&<tr>
                            <td>
                              <div className="py-20 px-4 sm:px-6 text-center">
                                <ClockIcon className="w-10 h-10 mb-3 block mx-auto text-gray-500"/>
                                <h3 className="font-medium text-md text-gray-600 mb-3">Your history will be shown here</h3>
                                <p className="text-sm text-gray-500">You can keep track of your activity and updates here, as you continue to use Beauty Clout</p>
                              </div>
                            </td>

                          </tr>
                        }

                          {history.data.map((item) => {
                            var url = item.link;
                            var icon = icons[item.icon]?icons[item.icon]:<ArrowCircleRightIcon className="w-5 h-5 inline mr-1"/>

                            if (item.campaign_id) {
                              url = '/influencers/campaign/'+item.campaign_id+'';
                            }

                            return (
                            <tr key={item.id} className="">
                              <td className="max-w-0 w-full px-6 py-4 md:whitespace-nowrap text-sm text-gray-900">
                                <div className="flex">
                                  <Link to={url} className="group flex space-x-2 text-sm">
                                    
                                    <p className="text-gray-500 md:truncate group-hover:text-gray-900 flex md:items-center">
                                      {icon}
                                      <span className={item.seen_at==null?"font-medium text-gray-900 flex-1":" flex-1"}>{item.item_txt}</span>
                                    </p>
                                  </Link>
                                </div>
                              </td>
                              <td className="hidden md:block px-6 py-4 text-right whitespace-nowrap text-sm text-gray-500">
                                <time dateTime={item.created_at}>{moment(item.created_at).fromNow()}</time>
                              </td>
                            </tr>
                          )})}
                        </tbody>
                      </table>
                      <div className="px-4 sm:px-6 py-4 border-t">
                      <Pager data={history} changePage={changeHistoryPage}/>
                      </div>
    </Card>}
    </div>
    <div className="border-t border-gray-200 max-w-6xl mx-5 py-5 mt-10">
      <span className="block text-sm text-gray-400">&copy; {moment().format('YYYY')} Beauty Clout. All rights reserved.</span>
    </div>
  </Section>
  {user!=null&&step==1&&<SidePanel open={step==1} setOpen={() => console.log('')} onDismiss={() => { return false;}} dismissable={false} title={"Let's start by creating a profile"} subtitle="Step 1/4">
    

    <p className="text-gray-500 mb-3 00 text-sm">Just some basic details to help brands get to know you.</p>
  	<FileDrop error={uploadError} uploading={uploading} thumbnailUrl={user.image_id!=null?`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${user.image_id}.jpg`:false} label="Profile photo *" onChange={uploadImage} helpText="JPG, PNG, maximum size of 7MB"/>           
    <TextArea label="Short description *" value={user.short_description} onChange={updateShortDescription} className="mt-3" helpText={descriptionHelpText}/>
    <TagInputField label="Tags" helpText="Enter up to 6 tags to help brands find you" addTag={addTag} removeTag={removeTag} tags={user.flat_tags}/>
    <Button className="mb-3 mt-3 py-3" primary fullWidth={true} onClick={() => saveStep1()}>Continue</Button>
  </SidePanel>}
  {user!=null&&step==2&&<SidePanel open={step==2} setOpen={() => {return false; }} onDismiss={() => { return false;}} dismissable={false} title={"Connect your social media accounts"} subtitle="Step 2/4">
        {
          warning&&<div className="bg-orange-100 py-3 px-4 sm:px-6 mb-4 rounded-md">
            <p className="text-sm text-orange-900">{warning}</p>
          </div>
        }
       <p className="text-gray-500 mb-3 00 text-sm">Showcase your profiles to brands</p>
       <SocialMediaSetting service={user.tiktok} type='tiktok' disconnectService={disconnectService} onSave={saveHandle}/>
       <SocialMediaSetting service={user.instagram} type='instagram' disconnectService={disconnectService} onClick={connectInstagram}/>
       <SocialMediaSetting service={user.facebook} type='facebook' disconnectService={disconnectService}  onClick={connectFacebook}/>
       <SocialMediaSetting service={user.twitter} type='twitter' disconnectService={disconnectService} onClick={connectTwitter}/>
       <SocialMediaSetting service={user.youtube} type='youtube' disconnectService={disconnectService} onSave={saveHandle}/>

       <div className="space-y-2 mt-3">
       <Button primary fullWidth={true} className="mt-3 w-full py-3" onClick={() => goToStep3()}>Continue</Button>
       <Button fullWidth onClick={() => setStep(1)}>Back</Button>
       </div>
  </SidePanel>}

  {
    user!=null&&step==3&&<SidePanel open={(step==3)} onDismiss={() => {return false;}} setOpen={() => { return false; }} dismissable={false} title="Receive Products to Promote" subtitle="Step 3/4">
      <div className="mb-6">
        <p className="text-gray-500 mb-3 00 text-sm">To receive products from brands, we need your shipping address.</p>
      </div>
      <div className="space-y-3">
      <div className="grid grid-cols-2 gap-3">
        <div>
          <InputField label="First Name *" value={user.first_name} onChange={(e) => handleUserAddress(e.target.value, 'first_name')}/>
        </div>
        <div>
          <InputField label="Last Name *" value={user.last_name} onChange={(e) => handleUserAddress(e.target.value, 'last_name')}/>
        </div>
      </div>
      <div>
      <InputField label="Address Line 1 *" value={user.address_1} onChange={(e) => handleUserAddress(e.target.value, 'address_1')}/>
      </div>
      <div>
      <InputField label="Address Line 2" value={user.address_2} onChange={(e) => handleUserAddress(e.target.value, 'address_2')}/>
      </div>
      <div className="grid grid-cols-3 gap-3">
        <div>
          <InputField label="City *" value={user.city} onChange={(e) => handleUserAddress(e.target.value, 'city')} />
        </div>
        <div>
          <Select options={USStates} selected={userState} setSelected={setUserState} label="State *"/>
        </div>
        <div>
          <InputField label="Zip *" value={user.zip} onChange={(e) => handleUserAddress(e.target.value, 'zip')} maxLength={10}/>
        </div>
      </div>
      <div className="space-y-2 pt-5">
        <Button primary fullWidth onClick={() => saveUserAddress()}>Continue</Button>
        <Button fullWidth onClick={() => setStep(2)}>Back</Button>
      </div>
      </div>
    </SidePanel>
  }


  {
    user!=null&&step==4&&<SidePanel open={step==4} onDismiss={() => { return false;}} setOpen={() => console.log('')} dismissable={false} title={"Connect to Stripe for Payments"} subtitle="Step 4/4">
      <p className="text-gray-600 mb-3 text-sm">In order to get paid by brands, you will need to create a Stripe Express account.</p>
      <p className="text-gray-600 mb-3 text-sm"><a className="text-indigo-600 hover:text-indigo-700" href="https://stripe.com" target="_blank">Stripe</a> is a trusted solution for payments and bank transfers, used by many popular websites and apps.</p>
      <p className="text-gray-600 mb-3 text-sm">You can also browse Beauty Clout, and <button className="text-indigo-600 hover:text-indigo-700" onClick={() => skipStripe()}>skip this step</button> for now.</p>

      <div className="space-y-2">
      <Button primary fullWidth className="mt-5" onClick={() => goToStripe()}>Connect to Stripe</Button>

      <Button fullWidth onClick={() => setStep(3)}>Back</Button>
      </div>
    </SidePanel>
  }

  <Modal open={instagramModal} setOpen={setInstagramModal} primary={{label: 'Connect Account', action: () => sendInstagramToServer() }} secondary={{label: 'Cancel', action: () => setInstagramModal(false)}}>
    <h3 className="text-md font-medium text-gray-900 mb-4">Connect an Instagram Account</h3>
    {
      instagramAccounts.map(account => <div className="flex justify-start items-center" key={`ig-account-${account.id}`}>
          <input
            id={account.id}
            name="ig-account"
            type="radio"
            onChange={(e) => setSelectedInstagramAccount(e.target.id)}
            defaultChecked={selectedInstagramAccount==account.id}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          <img src={`https://graph.facebook.com/${account.id}/picture`} className="rounded-full h-12 w-12 ml-2"/>
          <div className="flex justify-between w-4/5 ml-2">
            <div className="w-2/3">
              <h3 className="text-sm font-medium text-gray-700 mb-0 pb-0">{account.name}</h3>
              <small className="font-medium text-gray-500">@{account.username}</small>
            </div>
            <div className="w-1/3">
              <h3 className="text-sm block text-right font-bold text-gray-700 mb-0 pb-0">{numeral(account.followers_count).format('0,0')}</h3>
              <small className=" block text-right font-medium text-gray-500">followers</small>
            </div>
          </div>
        </div>)
    }
  </Modal>
  <Modal open={welcomeModal} setOpen={() => { return false; }} primary={{label: 'Got it!', action: () => setWelcomeModal(false)}}>
    <h3 className="text-md font-bold text-gray-900 mb-4">Welcome to the New Beauty Clout!</h3>
    <p className="text-sm text-gray-600 mb-3">We hope to give you a more seamless experience working with great brands.</p>
    <strong className="block text-medium text-sm">What's new?</strong>
    <ul className="text-sm text-gray-600 list-disc pl-5">
      <li>Add your TikTok account to Beauty Clout.</li>
      <li>Earn commission on sales through affiliate links + coupons.</li>
      <li>Campaigns are now invite-only. Focus on build your profile, and brands will reach out to you!</li>
    </ul>
  </Modal>
  <Modal open={facebookModal} setOpen={setFacebookModal} primary={{label: 'Connect Facebook Page', action: () => sendFacebookToServer() }} secondary={{label: 'Cancel', action: () => setInstagramModal(false)}}>
    
    <h3 className="text-md font-medium text-gray-600 mb-4">Connect a Facebook Page</h3>
    {
      facebookPages.map(account => <div className="flex justify-start items-center mb-2" key={`fb-account-${account.id}`}>
          <input
            id={account.id}
            name="facebook-page"
            type="radio"
            onChange={(e) => setSelectedFacebookPage(e.target.id)}
            checked={selectedFacebookPage==account.id}
            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
          />
          <img src={account.picture.data.url} className="rounded-full h-12 w-12 ml-2"/>
          <div className="flex justify-between w-4/5 ml-2">
            <div className="w-2/3">
              <h3 className="text-sm font-medium text-gray-700 mb-0 pb-0">{account.name}</h3>
              <small className="font-medium text-gray-500">@{account.username}</small>
            </div>
            <div className="w-1/3">
              <h3 className="text-sm block text-right font-bold text-gray-700 mb-0 pb-0">{numeral(account.followers_count).format('0,0')}</h3>
              <small className=" block text-right font-medium text-gray-500">followers</small>
            </div>
          </div>
        </div>)
    }
  </Modal>

  {selectedReview!=null&&<Modal open={selectedReview!=null} setOpen={() => setSelectedReview(null)} primary={{label: 'Submit Review', action: () => submitReview(), disabled: (rating==0||review.length == 0)}} secondary={{label: 'Close', action: () => setSelectedReview(null)}}>
      <div className="text-left">
      <h3 className="font-bold text-md mb-2">Review your experience</h3>
      <p className="text-gray-600 text-sm mb-3 border-b pb-3">Now that you've completed work for this brand, please rate &amp; review your experience working with {selectedReview.subject_limited.brand_name}.</p>
        <label className="text-gray-700 text-xs font-medium">Rate {selectedReview.subject_limited.brand_name} *</label>
        <StarRatingSelector value={rating} onChange={setRating}/>
        <label className="text-gray-700 text-xs font-medium mt-2">Additional details *</label>
        <TextArea value={review} onChange={(e) => setReview(e.target.value)} placeholder="Write a review for this influencer.." rows={3}/>
      </div>

    </Modal>}

  <ToastContainer autoClose={4000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
  </>
};

export default withCookies(InfluencerDashboardBody);