import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { useAuthRequest } from '../Auth';
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, InformationCircleIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert, TextArea, Select, FileDrop, Modal} from '../components/Section';
import { ToastContainer, toast } from 'react-toastify';

const defaultSteps = [
	{id: 1, name: 'Products', href: '/campaign/{id}/products', status: 'complete'},
	{id: 2, name: 'Social Media',href:  '/campaign/{id}/social',  status: 'complete'},
	{id: 3, name: 'Details', href:  '/campaign/{id}/details',  status: 'upcoming'},
	{id: 4, name: 'Preview',  href: '/campaign/{id}/preview',  status: 'upcoming'},
];

const affiliateTypes = [
	{name: 'Fixed Amount', value: 'fixed'},
	{name: 'Percentage', value: 'percentage'}
];

const discountTypes = [
	{name: 'Fixed Amount', value: 'fixed'},
	{name: 'Percentage', value: 'percentage'}
];

const couponOptions = [
	{name: 'Generate coupon codes for affiliates', value: '1'},
	{name: 'Do not generate coupon codes', value: '0'}
];

const usageOptions = [
	{name: 'No limit per customer', value: '0'},
	{name: 'One use per customer', value: '1'}
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function CampaignAffiliates() {

	/* Provider hooks */

	const {authenticatedFetch} = useAuthRequest();

  	const fetch = authenticatedFetch;

	const {id} = useParams();

	const navigate = useNavigate();

	/* State variable hooks */

	const [campaign, setCampaign] = useState(null);

	const [disabled, setDisabled] = useState(false);

	const [steps, setSteps] = useState(defaultSteps);

	const [user, setUser] = useState(null);

	const [enabled, setEnabled] = useState(false);

	const [type, setType] = useState(affiliateTypes[0]);

	const [discountType, setDiscountType] = useState(discountTypes[0]);

	const [coupon, setCoupon] = useState(false);

	const [percentage, setPercentage] = useState('');

	const [fixedValue, setFixedValue] = useState('');

	const [discountPercentage, setDiscountPercentage] = useState('');

	const [discountValue, setDiscountValue] = useState('');

	const [minSubtotal, setMinSubtotal] = useState(0);

	const [usageOption, setUsageOption] = useState(usageOptions[0]);

	const [showInfo, setShowInfo] = useState(false);

	/* Serverside functions */

	const saveCampaign = async() => {
		const body = new FormData();

		if (enabled == true) {
			body.append('affiliates_enabled', '1');

			body.append('type', type.value);

			if (type.value == 'fixed') {
				body.append('fixed_value', fixedValue);
			} else {
				body.append('percentage', percentage);
			}

			if (coupon == true) {

				body.append('coupon', '1');
				
				body.append('discount_type', discountType.value);

				if (discountType.value == 'fixed') {
					body.append('discount_value', discountValue);
				} else {
					body.append('discount_percentage', discountPercentage);
				}

				body.append('min_subtotal', minSubtotal);

				body.append('once_per_customer', usageOption.value);
			}

		} else {
			body.append('affiliates_enabled', '0');
		}

		setDisabled(true);

		const response = await fetch(`/api/v2/campaigns/${id}/affiliates`, 'POST', body);

		const { message, error } = response;

		if (message) {
			toast(message);
			setTimeout(() => {
				navigate(`/campaign/${id}/details`);
			}, 1000);
		}

		if (error) {
			toast(error);
			setDisabled(false);
		}

	}

	const loadCampaign = async() => {
		const response = await fetch(`/api/v2/campaigns/${id}`, 'GET');

		const { campaign, user} = response;

		if (user.pro == 0) {
			navigate(`/campaign/${id}/details`);
		} else {
			let _steps = [...defaultSteps];

			_steps.splice(2, 0, {
				id: 3,
				name: 'Affiliate Program',
				status: 'current',
				href: `/campaign/${id}/affiliates`
			});
				
			if (campaign.status == 'active') {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: true};
				});
				
			} else {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: false};
				});
			}

			setSteps(_steps);
		}

		if (campaign) {
			setCampaign(campaign);

			if (campaign.affiliate_program) {
				setEnabled(true);
				
				let type = affiliateTypes.find(affiliateType => affiliateType.value==campaign.affiliate_program.type);
				
				if (type) {
					setType(type);
				}

				if (type.value == 'percentage') {
					setPercentage(campaign.affiliate_program.percentage);
				} else {
					setFixedValue(campaign.affiliate_program.fixed_value);
				}

				if (campaign.affiliate_program.coupon == 1) {
					setCoupon(true);

					let discountType = discountTypes.find(discType => discType.value==campaign.affiliate_program.discount_type);

					let usageOption = usageOptions.find(option => parseInt(option.value)==campaign.affiliate_program.once_per_customer);

					setDiscountType(discountType);

					if (campaign.affiliate_program.discount_type == 'percentage') {
						setDiscountPercentage(campaign.affiliate_program.discount_percentage);
					} else {
						setDiscountValue(campaign.affiliate_program.discount_value);
					}

					setMinSubtotal(campaign.affiliate_program.min_subtotal);

					setUsageOption(usageOption);

					setShowInfo(true);
				}
			}

		}

		setUser(user);

		window.scrollTo(0,0);
	}

	/* Use Effect Hooks */

	useEffect(() => {
		loadCampaign();
	}, []);

	const title = campaign!=null?campaign.title:'...';

	return <Section title={title}>
		<Steps steps={steps} className="my-2 max-w-6xl" campaignId={id}/>
		<div className="max-w-6xl mx-5">
			<div className="bg-cyan-50 text-cyan-900 px-4 sm:px-6 py-4 max-w-6xl mb-5 rounded">
				<h3 className="font-bold mb-2">About Beauty Clout Affiliates</h3>
				<p className="text-sm mb-2">Offer influencers a commission on sales they've referred.</p>
				<ul className="mb-3">
					<li className="flex items-center text-sm mb-2"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/> A personal link to your store will be generated to track orders that come from hired influencers.</li>
					<li className="flex items-center text-sm mb-2"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/> Optionally offer a coupon code for influencers to share with their audience.</li>
					<li className="flex items-center text-sm mb-2"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/> Commission payouts can be done through PayPal or offsite payments.</li>
				</ul>
				<Link to={'/affiliates'} className="text-indigo-600 text-sm ">Manage Affiliate Settings</Link>
			</div>
			<Card title="Offer an Affiliate Program?" unstyled={true} subtitle="Affiliate programs allow influencers to earn a commission from referrals">
				<div className="divide-y">
					<div className="px-4 sm:px-6 py-4 flex items-center">
						<Switch
						  disabled={(user==null||user.affiliates_enabled_at==null)}
					      checked={enabled}
					      onChange={setEnabled}
					      className="flex-shrink-0 disabled:opacity-60 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					    >
					      <span className="sr-only">Use setting</span>
					      <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
					      <span
					        aria-hidden="true"
					        className={classNames(
					          enabled ? 'bg-indigo-600' : 'bg-gray-300',
					          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
					        )}
					      />
					      <span
					        aria-hidden="true"
					        className={classNames(
					          enabled ? 'translate-x-5' : 'translate-x-0',
					          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
					        )}
					      />
					    </Switch>
					    <label className="text-gray-700 text-sm font-medium ml-2">Yes, offer an affiliate program to hired influencers</label>
					</div>
					{enabled==true&&<><div className="px-4 sm:px-6 py-4">
						<div className="grid sm:grid-cols-2 gap-4">
						<div>
						<Select options={affiliateTypes} selected={type} setSelected={setType} label="Earning type" helpText="Reward affiliates with a fixed monetary amount per order or a percentage of sales."/>
						</div>
						{type.value=='fixed'&&<div><label className="block mb-0 font-medium text-sm text-gray-700">Earning per order</label>
						<span className="text-xs text-gray-600 block mb-2">The fixed dollar amount an affiliate earns for every order referral</span>
						<div className="flex items-center">
						<div className="px-4 py-2 rounded-l-md border-gray-400 border text-sm font-medium text-gray-500 bg-slate-50">$</div>
						<input type="number" className="py-2 text-sm px-4 rounded-r-md border-l-0 border-r border-t border-b border-gray-400 flex-1" placeholder="0.00" value={fixedValue} onChange={(e) => setFixedValue(e.target.value)}/>
						</div>
						</div>}
						{type.value=='percentage'&&<div><label className="block mb-0 font-medium text-sm text-gray-700">Percentage Earning</label>

						<span className="text-xs text-gray-600 block mb-2">Affiliates earn this percentage of revenue generated from referrals</span>
						<div className="flex items-center">
						<input type="number" className="py-2 text-sm px-1 rounded-l-md border-l text-right border-r-0 border-t border-b border-gray-400 flex-1" placeholder="0" value={percentage} onChange={(e) => setPercentage(e.target.value)} />
						<div className="px-4 py-2 rounded-r-md border-gray-400 border text-sm font-medium text-gray-500 bg-slate-50">%</div>
						</div>
						</div>}

						</div>
					</div>
					<div className="px-4 sm:px-6 py-4 flex items-center">
						<Switch
					      checked={coupon}
					      onChange={setCoupon}
					      className="flex-shrink-0 disabled:opacity-60 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
					    >
					      <span className="sr-only">Use setting</span>
					      <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
					      <span
					        aria-hidden="true"
					        className={classNames(
					          coupon ? 'bg-indigo-600' : 'bg-gray-300',
					          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
					        )}
					      />
					      <span
					        aria-hidden="true"
					        className={classNames(
					          coupon ? 'translate-x-5' : 'translate-x-0',
					          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
					        )}
					      />
					    </Switch>
					    <label className="text-gray-700 text-sm font-medium ml-2">Generate coupon codes for affiliates / hired influencers</label>
					</div>
					{
						coupon==true&&<div className="px-4 sm:px-6 py-4">
						{showInfo==true&&<div className="bg-cyan-50 rounded-md px-4 sm:px-6 py-4 mb-5 flex items-center">
							<InformationCircleIcon className="text-cyan-900 w-5 h-5 mr-2 block"/><p className="text-cyan-900 text-sm"> Please note that changes to discount settings will affect existing coupon codes generated for this campaign.</p>

						</div>}


							<div className="grid sm:grid-cols-2 gap-4">
						<div>
						<Select options={affiliateTypes} selected={discountType} setSelected={setDiscountType} label="Discount type" helpText="Customers using coupon codes can receive a percentage discount or fixed discount."/>
						</div>
						{discountType.value=='fixed'&&<div><label className="block mb-0 font-medium text-sm text-gray-700">Discount per order</label>
						<span className="text-xs text-gray-600 block mb-2">Referred customers get a fixed discount per order</span>
						<div className="flex items-center">
						<div className="px-4 py-2 rounded-l-md border-gray-400 border text-sm font-medium text-gray-500 bg-slate-50">$</div>
						<input type="number" className="py-2 text-sm px-4 rounded-r-md border-l-0 border-r border-t border-b border-gray-400 flex-1" placeholder="0.00" value={discountValue} onChange={(e) => setDiscountValue(e.target.value)}/>
						</div>
						</div>}
						{discountType.value=='percentage'&&<div><label className="block mb-0 font-medium text-sm text-gray-700">Discount Percentage</label>

						<span className="text-xs text-gray-600 block mb-2">Referred customers get a percentage of the subtotal as a discount.</span>
						<div className="flex items-center">
						<input type="number" className="py-2 text-sm px-1 rounded-l-md border-l text-right border-r-0 border-t border-b border-gray-400 flex-1" placeholder="0" value={discountPercentage} onChange={(e) => setDiscountPercentage(e.target.value)} />
						<div className="px-4 py-2 rounded-r-md border-gray-400 border text-sm font-medium text-gray-500 bg-slate-50">%</div>
						</div>
						</div>}

						</div>
						<div className="mt-4">
							<label className="block mb-0 font-medium text-sm text-gray-700">Minimum Order Amount</label>
							<span className="text-xs text-gray-600 block mb-2">Customer orders must be at least this amount in order to redeem the discount.</span>
							<div className="flex items-center">
							<div className="px-4 py-2 rounded-l-md border-gray-400 border text-sm font-medium text-gray-500 bg-slate-50">$</div>
							<input type="number" className="py-2 text-sm px-4 rounded-r-md border-l-0 border-r border-t border-b border-gray-400 flex-1" placeholder="0.00" value={minSubtotal} onChange={(e) => setMinSubtotal(e.target.value)}/>
							</div>
						</div>
						<div className="mt-4">
							<Select options={usageOptions} label="Coupon Usage Limit" helpText="Indicate if a customer can use coupons more than one" selected={usageOption} setSelected={setUsageOption}/>
						</div>
						</div>
					}

					</>}

				</div>
			</Card>
			<Button disabled={disabled} primary fullWidth className="mt-4" onClick={() => saveCampaign()}>Continue</Button>
		</div>
		<ToastContainer theme="dark" autoClose={5000} position="bottom-center" toastClassName="text-gray-50 !bg-black" bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/> 
	</Section>
}