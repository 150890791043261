import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { useAuthRequest } from '../Auth';
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ChevronDownIcon, CheckCircleIcon } from '@heroicons/react/solid'
import { TrashIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, AutoComplete, InputField, Alert} from '../components/Section';

const defaultSteps = [
	{id: 1, name: 'Products', href: '/campaign/{id}/products', status: 'current'},
	{id: 2, name: 'Social Media',href:  '/campaign/{id}/social',  status: 'upcoming'},
	{id: 3, name: 'Details', href:  '/campaign/{id}/details',  status: 'upcoming'},
	{id: 4, name: 'Preview',  href: '/campaign/{id}/preview',  status: 'upcoming'},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function Campaign({children, cookies}) {

	const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

	let navigate = useNavigate();

	const {id} = useParams();

	const [campaign, setCampaign] = useState(null);

	const [products, setProducts] = useState([]);

	const [steps, setSteps] = useState(defaultSteps);

	const [selectedProducts, setSelectedProducts] = useState([]);

	const [selectedVariants, setSelectedVariants] = useState([]);

	const [error, setError] = useState(null);

	const [discountOrders, setDiscountOrders] = useState(false);

	const [orderCreation, setOrderCreation] = useState(true);

	const [limit, setLimit] = useState('quantity');

	const [limitTotal, setLimitTotal] = useState(50);

	const [limitQuantity, setLimitQuantity] = useState(1);

	const [disabled, setDisabled] = useState(false);

	const removeProduct = (index) => {
		const _selectedProducts = [...selectedProducts];

		const _product = selectedProducts[index];

		const _selectedVariants = selectedVariants;

		_product.variants.forEach(variant => {
			if (_selectedVariants.indexOf(variant.id) >= 0) {
				_selectedVariants.splice(_selectedVariants.indexOf(variant.id), 1);
			}
		});

		_selectedProducts.splice(index, 1);
		setSelectedProducts(_selectedProducts);
		setSelectedVariants(_selectedVariants);

	}

	const toggleExpand = (id) => {
		const _products = selectedProducts.map(product => {
			if (product.id == id) {

				let expanded = product.expanded==true?false:true;

				return {...product, expanded};
			}

			return product;

		});

		setSelectedProducts(_products);
	}

	const handleVariantSelect = (e, variant, productId=false) => {
		
		var _selectedVariants = [...selectedVariants];

		if (e.target.checked && !selectedVariants.includes(variant.id)) {
			_selectedVariants.push(variant.id);
		} else {

			var index = _selectedVariants.indexOf(variant.id);
			if (index >= 0) {
				_selectedVariants.splice(index, 1);
			}

			
		}

		if (productId) {
			let _products = selectedProducts.map(product => {
				if (product.id == productId) {

					let count = 0;

					product.variants.forEach(variant => {
						if (_selectedVariants.includes(variant.id)) {
							count++;
						}
					});

					return {...product, selected_variant_count: count};
				}

				return product;

			});

			setSelectedProducts(_products);
		}

		setSelectedVariants(_selectedVariants);
	}

	const onSelectProduct = (_product) => {
		var found = false;
		for(var i = 0 ; i < selectedProducts.length ; i++) {
			if (selectedProducts[i].id == _product.id) {
				found = true;
			}
		}

		if (found) {
			return false;
		}

		const product = {..._product};

		product.selected_variant_count = product.variants.length;

		const _selectedProducts = [...selectedProducts];

		_selectedProducts.push(product);
		setSelectedProducts(_selectedProducts);

		const _selectedVariants = [...selectedVariants];

		product.variants.forEach(variant => {
			_selectedVariants.push(variant.id);
		});

		setSelectedVariants(_selectedVariants);
	}
	
	const loadProducts = async() => {
		const response = await fetch(`/api/v2/products`, {
	  		method: 'GET',
	  		headers: {
	  			Authorization: `Bearer ${cookies.get('token')}`
	  		}
	  	}).then(resp => resp.json());

	  	const {products, error, user} = response;

	  	if (error) {
	  		alert(error);
	  	}

	  	if (products) {
	  		setProducts(products);
	  	}

	  	if (user && user.pro == 1) {
	  		let _steps = [...defaultSteps];

			_steps.splice(2, 0, {
				id: 3,
				name: 'Affiliate Program',
				status: 'upcoming',
				href: `/campaign/${id}/affiliates`
			});

			setSteps(_steps);
	  	}
	}

	const loadCampaign = async() => {

		const response = await authFetch(`/api/v2/campaigns/${id}?products=all`, 'GET');

		const {products, variants, error, campaign, user} = response;

		if (error) {
			alert(error);
		}

		if (products && variants) {
			setSelectedProducts(products);
			setSelectedVariants(variants);
		}

		if (campaign) {
			setCampaign(campaign);

			setLimit(campaign.limit_type);
			if (campaign.limit_quantity != null) {
				setLimitQuantity(campaign.limit_quantity);
			}

			if (campaign.limit_total != null) {
				setLimitTotal(campaign.limit_total);
			}

			setDiscountOrders(campaign.discount_orders);

			setOrderCreation(campaign.order_creation);

			let _steps = [...defaultSteps];

			if (user.pro == 1) {
				
				_steps.splice(2, 0, {
					id: 3,
					name: 'Affiliate Program',
					status: 'current',
					href: `/campaign/${id}/affiliates`
				});
			}

			if (campaign.status == 'active') {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: true};
				});
				
			} else {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: false};
				});
			}

			setSteps(_steps);


		}



	}

	const saveCampaign = async() => {
		setDisabled(true);
		setError(null);

		const formData = new FormData();

		let endpoint = `/api/v2/campaigns`;

		if (id) {
			endpoint = `/api/v2/campaigns/${id}/products`
		}

		if (selectedVariants.length > 0) {
			selectedVariants.forEach(variantId => {
				formData.append('variant_id[]', variantId);
			});

			if (selectedVariants.length > 1) {
				formData.append('limit_type', limit);

				if (limit == 'quantity') {

					formData.append('limit_quantity', limitQuantity);

				} else if (limit == 'total') {

					formData.append('limit_total', limitTotal);

				}
			}


			formData.append('order_creation', orderCreation);

			formData.append('discount_orders', discountOrders);
		} else {
			formData.append('skip_products', 1);
		}

		const response = await fetch(endpoint, {
			method: 'POST',
			headers: {
	  			Authorization: `Bearer ${cookies.get('token')}`
	  		},
	  		body: formData
		}).then(resp => resp.json());

		const { message, error, redirect_url } = response;

		if (error) {
			setDisabled(false);
			setError(error);
		} 

		if (redirect_url) {
			navigate(redirect_url);
		}
	}

	const handleLimitChange = (e) => {
		if (e.target.checked) {
			setLimit(e.target.value);
		}
	}

	useEffect(() => {
		loadProducts();
		if (id) {
			loadCampaign();
		}
	}, []);

	const title = campaign!=null?campaign.title:'Create a Campaign';

	return <Section title={title}>
		<Steps steps={steps} className="my-2  max-w-6xl" campaignId={id?id:''}/>

		<div className="bg-cyan-50 text-cyan-900 px-4 sm:px-6 py-4 max-w-6xl mx-5 rounded">
			<h3 className="font-bold mb-2">Using Products in Campaigns</h3>
			<p className="text-sm mb-2">Select products that you would like to send influencers that you hire.</p>
			<ul className="mb-3">
				<li className="flex sm:items-center text-sm mb-2"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/><span className="flex-1">Influencers can choose products from your selection that they would like to receive</span></li>
				<li className="flex sm:items-center text-sm mb-2"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/><span className="flex-1">Shopify orders can be automatically created when you hire an influencer.</span></li>
				<li className="flex sm:items-center text-sm mb-2"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/><span className="flex-1">Pro users can offer products in lieu of monetary compensation.</span></li>
				<li className="flex sm:items-center text-sm mb-2"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/><span className="flex-1">Set limits on how many products / max. value an influencer can choose</span></li>
			</ul>
			<Link to={'/products'} className="text-indigo-600 text-sm ">Manage Product Settings</Link>
		</div>

		<Card title="Products" className="m-5 max-w-6xl" primaryAction={{label: 'Product Settings', url: '/products'}}>
			
			<div className="mt-1">
				<AutoComplete endpoint="/api/v2/products" onSelect={onSelectProduct} label="Search products" placeholder="Enter title of product to search..." />
			</div>
		</Card>


		{selectedProducts.length > 0?<Card title="Selected Products" className="mx-5  max-w-6xl" unstyled={true}>
		{
			selectedProducts.map((product, i) => {
				return <div className="border-b border-gray-300 px-4 sm:px-6 py-4">
					<div className="mx-auto px-0 md:flex md:items-center md:justify-between ">
			            <div className="flex items-center space-x-5">
			              <div className="flex-shrink-0">
			                <div className="relative">
			                  <img
			                    className="h-16 w-16 rounded-md"
			                    src={product.image_url||product.image.src}
			                    alt=""
			                  />
			                  <span className="absolute inset-0 shadow-inner rounded-md" aria-hidden="true" />
			                </div>
			              </div>
			              <div>
			                <h3 className="text-md font-bold text-gray-700">{product.name}</h3>
			                {product.variants.length>1?<button className="text-xs flex items-center text-gray-500" disabled={product.variants.length==1} onClick={() => toggleExpand(product.id)}>

			                {product.expanded?<ChevronDownIcon className="w-5 h-5 inline-block text-gray-600"/>:<ChevronRightIcon className="w-5 h-5 inline-block text-gray-600"/>}
			                {`${product.selected_variant_count} / ${product.variants.length}`}
			                  
			                  <span href="#" className="text-gray-500 ml-1">
			                    Variants Selected
			                  </span>
			                </button>:<p className="text-xs text-gray-500 block">1 Variant</p>}
			              </div>
			            </div>
			            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-reverse sm:space-y-0 sm:space-x-3 md:mt-0 md:flex-row md:space-x-3">
			              
			              <button
			                type="button"
			                className="inline-flex items-center justify-center px-4 py-2 border bg-transparent text-sm font-medium rounded-md shadow-sm text-gray-600 border-gray-400 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-blue-500"

			                onClick={() => removeProduct(i)}
			              >
			                <TrashIcon className="w-5 h-5 sm:w-4 sm:h-4"/>
			              </button>
			            </div>
			          </div>
					{product.expanded&&product.variants.length>1&&<fieldset className="mt-3 md:pl-20">
						{product.variants.map((variant) => {
							return (<div className="relative flex justify-between items-start mb-5" key={`variant-${variant.id}}`}>
						        <div className="flex h-5">
						          <input
						            id={`variant-${variant.id}`}
						            aria-describedby={`SKU ${variant.sku}`}
						            name={`variant_${variant.id}`}
						            type="checkbox"
						            checked={selectedVariants.includes(variant.id)}
						            value={variant.id}
						            onChange={(e) => handleVariantSelect(e, variant, product.id)}
						            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded mt-1"
						          />
						          <div className="ml-3 text-sm">
							          <label htmlFor={`variant_${variant.id}`} className="font-medium text-gray-700">
							            {variant.title}
							          </label>
							          <p id={`variant-${variant.id}-description`} className="text-xs uppercase text-gray-500">
							            {variant.sku}
							          </p>
							        </div>
						        </div>
						        
						        <div className="">
						        	<span className="font-medium text-gray-700 text-sm">${variant.price.toFixed(2)}</span>
						        </div>
						      </div>);
						})}
					</fieldset>}
				</div>
			})
		}</Card>:<Card title="No products selected yet" className="mx-5  max-w-6xl">
			<p className="text-gray-500 text-sm mb-2">Search for products you would like for influencers to review.</p>
			<p className="text-gray-500 text-sm">Your selected products will appear here.</p>
		</Card>}

		{selectedVariants.length>=2&&<Card title="Product settings" className="m-3  max-w-6xl">
			<p className="text-gray-500 text-sm my-3">
				You may limit how many products an influencer can pick for review.
			</p>
		<div className="space-y-5 mt-3">
				<div className="relative flex items-start">
		            <div className="flex items-center h-5">
		              <input
		              	id="limit-quantity"
		                aria-describedby={`limit-qauntity-description`}
		                name="plan"
		                type="radio"
		                value="quantity"
		                checked={limit == 'quantity'}
		                onChange={(e) => handleLimitChange(e)}
		                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
		              />
		            </div>
		            <div className="ml-3 text-sm">
		              <label htmlFor="limit-quantity" className="font-medium text-gray-700">
		                Limit by quantity
		              </label>
		              <span id={`limit-quanity-description`} className="text-gray-500 block">
		                Influencers are limited by quantity
		              </span>
		            </div>
		          </div>
		          <div className="pl-6">

		          	<InputField disabled={limit!='quantity'} type="number" value={limitQuantity} onChange={(e) => setLimitQuantity(e.target.value)} label="Limit quantity"/>
		          </div>

		          <div className="relative flex items-start">
		            <div className="flex items-center h-5">
		              <input
		              	id="limit-total"
		                aria-describedby={`limit-qauntity-description`}
		                name="plan"
		                type="radio"
		                value="total"
		                checked={limit == 'total'}
		                onChange={(e) => handleLimitChange(e)}
		                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
		              />
		            </div>
		            <div className="ml-3 text-sm">
		              <label htmlFor="limit-quantity" className="font-medium text-gray-700">
		                Limit by cost
		              </label>
		              <span id={`limit-quanity-description`} className="text-gray-500 block">
		                Influencers are limited by cost
		              </span>
		            </div>
		          </div>
		          <div className="pl-6">

		          	<InputField disabled={limit!='total'} type="number" value={limitTotal} onChange={(e) => setLimitTotal(e.target.value)} label="Limit total ($)"/>
		          </div>
		          <div className="relative flex items-start">
		            <div className="flex items-center h-5">
		              <input
		              	id="no-quantity"
		                aria-describedby={`limit-qauntity-description`}
		                name="plan"
		                type="radio"
		                value="none"
		                checked={limit == 'none'}
		                onChange={(e) => handleLimitChange(e)}
		                className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"
		              />
		            </div>
		            <div className="ml-3 text-sm">
		              <label htmlFor="limit-quantity" className="font-medium text-gray-700">
		                No limit
		              </label>
		              <span id={`limit-quanity-description`} className="text-gray-500 block">
		                Influencers are not limited in how many products they can select
		              </span>
		            </div>
		          </div>
		      </div>
			</Card>}

			{
				selectedProducts.length>0&&<Card title="Order Settings" className="mx-5 mt-3  max-w-6xl">
					<div className="relative flex items-start mb-3 mt-2">
				        <div className="flex items-center h-5">
				          <input
				            id={`order-creation`}
				            name={`order-creation`}
				            type="checkbox"
				            checked={orderCreation==true}
				            onChange={(e) => setOrderCreation(e.target.checked)}
				            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
				          />
				        </div>
				        <div className="ml-3 text-sm">
				          <label htmlFor={`order-creation`} className="font-medium text-gray-700">
				            Automatically create orders in Shopify
				          </label>
				          <p id={`order-creation-description`} className="font-small text-gray-500">
				            When influencers are hired, their orders are automatically generated in your store
				          </p>
				        </div>
				      </div>
				      <div className="relative flex items-start mb-3 mt-2">
				        <div className="flex items-center h-5">
				          <input
				            id={`discount-orders`}
				            name={`discount-orders`}
				            type="checkbox"
				            checked={discountOrders==true}
				            onChange={(e) => setDiscountOrders(e.target.checked)}
				            className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border border-gray-300 rounded"
				          />
				        </div>
				        <div className="ml-3 text-sm">
				          <label htmlFor={`discount-orders`} className="font-medium text-gray-700">
				            Apply 100% discount to automatically created orders
				          </label>
				          <p id={`discount-orders`} className="font-small text-gray-500">
				            For your accounting purposes, we can apply a full discount to influencers' orders generated in Shopify.
				          </p>
				        </div>
				      </div>
				</Card>
			}
		{
			error!=null&&<Alert title="Your campaign could not be saved" status="danger" className="m-3  max-w-6xl">
				<p>{error}</p>
			</Alert>
		}
		<div className="m-5  max-w-6xl">
		<Button fullWidth={true} primary disabled={disabled} onClick={() => saveCampaign()}>{selectedVariants.length==0?'Continue without products':'Continue'}</Button>
		</div>
	</Section>;
}

export default withCookies(Campaign);