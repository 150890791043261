import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  XCircleIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, ExclamationCircleIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, Modal, TextArea, Select, FileDrop, getServiceName} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';
import blogIcon from '../images/blog.svg';

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
    blog: {name: 'Blog', icon: blogIcon}
  };

const defaultSteps = [
  {id: '1', name: 'Services', status: 'current', step:1},
  {id: '2', name: 'Details', status: 'upcoming', step: 2}
];

const serviceOptions = [
  {name: ' --Select Social Media Platform', value: ''},
  {name: 'TikTok', icon: tiktokIcon, value: 'tiktok'},
  {name: 'Facebook', icon: facebookIcon, value: 'facebook'},
  {name: 'Instagram', icon: instagramIcon, value: 'instagram'},
  {name: 'Instagram Story', icon: instagramIcon, value: 'instagram_story'},
  {name: 'YouTube', icon: youtubeIcon, value: 'youtube'},
  {name: 'Twitter', icon: twitterIcon, value: 'twitter'},
];

const expressFees = [
  {name: 'Select express fee', value: ''}
];

const estimates = [
  {name: 'Select completion estimate', value: ''}
];

for(var i = 1; i <= 14; i++) {
  estimates.push({name: `${i} days`, value: i});
}

for(var i = 5; i <= 100; i+=5) {
  expressFees.push({name: `$${i}.00`, value: i});
}

export default function CreateGig() {

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const {id} = useParams();

  const navigate = useNavigate();

  const [primaryService, setPrimaryService] = useState(serviceOptions[0]);
  const [loaded, setLoaded] = useState(false);

  const [secondaryServices, setSecondaryServices] = useState([]);

  const [primaryFee, setPrimaryFee] = useState(0);
  const [services, setServices] = useState([]);

  const [user, setUser] = useState(null);

  const [gigTypes, setGigTypes] = useState([]);

  const [express, setExpress] = useState(false);

  const [expressFee, setExpressFee] = useState(expressFees[0]);

  const [expressEstimate, setExpressEstimate] = useState(estimates[0]);

  const [title, setTitle] = useState('');

  const [error, setError] = useState(null);

  const [primaryEstimate, setPrimaryEstimate] = useState(estimates[0]);
  const [description, setDescription] = useState('');

  const [fee, setFee] = useState(0);
  const [estimate, setEstimate] = useState(1);

  const [platformModal, setPlatformModal] = useState(false);

  const [selectedPlatform, setSelectedPlatform] = useState(null);

  const [step, setStep] = useState(1);

  const [steps, setSteps] = useState(defaultSteps);

  const [uploadError, setUploadError] = useState('');

  const [uploading, setUploading] = useState(false);

  const [galleryImageIds, setGalleryImageIds] = useState([]);

  const [selectedGigType, setSelectedGigType] = useState({name: '-- Choose promotion type', value: ''});

  const [videoUrl, setVideoUrl] = useState('');

  const [videoId, setVideoId] = useState(null);

  const [videoType, setVideoType] = useState(null);

  const removeImage = (index) => {
    const _galleryImageIds = [...galleryImageIds];

    _galleryImageIds.splice(index, 1);

    setGalleryImageIds(_galleryImageIds)
  }

  const uploadImage = async(files) => {

    setUploadError(false);
    setUploading(true);
    
    const body = new FormData();

    files.forEach(file => {
      body.append('images[]', file);
    })

    const response = await fetch('/api/image', 'POST', body);

    const {error, images} = response;

    if (error) {
      setUploadError(error);
    }

    if (images) {
      const _galleryImageIds = [...galleryImageIds];

      images.forEach(image => {
        _galleryImageIds.push(image.id);
      })

      setGalleryImageIds(_galleryImageIds)
    }

    setUploading(false);
  }

  const setSecondaryServiceFee = (serviceType, value) => {
    const _secondaryServices = secondaryServices.map(secondaryService => {
      if (secondaryService.service == serviceType) {
        return {...secondaryService, fee: value};
      }

      return secondaryService;
    });

    setSecondaryServices(_secondaryServices);
  }

  const setSecondaryServiceEstimate = (service, value) => {
    const _secondaryServices = secondaryServices.map(secondaryService => {
      if (secondaryService.service == service) {
        return {...secondaryService, estimate: value};
      }

      return secondaryService;
    });

    setSecondaryServices(_secondaryServices);
  }

  const nextStep = () => {
    var validationFailed = false;

    if (step == 1) {

      if (primaryService.value == '') {
        setError('Please choose a primary platform for this gig');
        return false;
      }

      if (parseFloat(primaryFee) < 10) {
        setError('Your primary fee must be at least $10');
        return false;
      }

      if (primaryEstimate.value == '') {
        setError('Please select a completion estimate for your primary platform');
        return false;
      }

      secondaryServices.forEach(service => {
        if (service.fee < 10 || service.estimate.value == '') {
          validationFailed = true;
        }
      });

      if (validationFailed == true) {
        setError('Please check that all of your additional platforms have a fee of at least $10 and also a completion estimate');
        return false;
      }

      if (express == true) {
        if (expressEstimate.value == '' ||expressFee.value == '') {
          setError('If you would like to offer express services, you must choose a fee amount and time estimate');
          return false;
        }

        if (expressEstimate.value >= primaryEstimate.value) {
          setError('If you would like to offer express services, your express completion estimate must be less than your primary completion estimate.');
          return false;
        }
      }

    } else if (step == 2) {
      if (title.length < 10) {
        setError('The title of your gig must be at least 10 characters');
        return false;
      }

      if (description.length < 25) {
        setError('Your gig description must be at least 25 characters');
        return false;
      }

      if (galleryImageIds.length == 0) {
        setError('Please upload one image to your gallery');
        return false;
      }

      if (videoUrl != '' && videoId == null) {
        setError('Your video URL is not valid');
        return false;
      }
    }


    if (step < steps.length) {
      setError(null);
      setStep(step+1);
      window.scrollTo(0,0);
    }

    if (step == steps.length) {
      submitGig();
    }


  }

  const addSelectedPlatform = () => {
    const _secondaryServices = [...secondaryServices];

    if (selectedPlatform != null) {
      _secondaryServices.push({
        service: selectedPlatform.service,
        fee: 0,
        estimate: estimates[0],
        details: selectedPlatform
      });
    }

    setPlatformModal(false);
    setSecondaryServices(_secondaryServices);
  }

  const removeSecondaryService = (index) => {
    const _secondaryServices = [...secondaryServices];

    _secondaryServices.splice(index, 1);

    setSecondaryServices(_secondaryServices);
  }

  const loadUser = async() => {

    let url = `/api/gigs`;

    if (id) {
      url = `/api/my-gigs/${id}`;
    }

    const response = await fetch(url, 'GET');

    const {user, gig_types, gig} = response;

    if (gig) {
      setDescription(gig.description_txt);
      setTitle(gig.title);
      let primaryService = serviceOptions.find(serviceOption => serviceOption.value==gig.primary_service);


      if (primaryService) {
        setPrimaryService(primaryService);
      }

      setPrimaryFee(gig.fee);

      let primaryEstimate = estimates.find(estimate => estimate.value==gig.estimate_days);


      if (primaryEstimate) {
        setPrimaryEstimate(primaryEstimate);
      }

      let secondaryServices = [];

      gig.services.forEach(service => {

        let details = user[service.service];

        let estimate = estimates.find(_estimate => _estimate.value==service.estimate_days);

        if (details) {
          secondaryServices.push({
            service: service.service,
            fee: service.fee,
            estimate: estimate,
            details
          });

        }

      });

      setSecondaryServices(secondaryServices);

      let galleryImageIds = [];

      gig.images.forEach(image => {
        galleryImageIds.push(image.image_id);
      });

      setGalleryImageIds(galleryImageIds);

      if (gig.subtype) {
        setSelectedGigType({
          name: gig.subtype.name,
          value: gig.subtype.id
        });
      }



      let express = gig.extras.find(extra => extra.code == 'express');

      if (express) {
        setExpress(true);

        let fee = expressFees.find(_fee => _fee.value==express.fee);

        if (fee) {
          setExpressFee(fee);
        }
        

        let estimate = estimates.find(_estimate => _estimate.value==express.estimate_days);

        if (estimate) {
          setExpressEstimate(estimate);
        }



      }

      if (gig.vimeo_video_id) {
        setVideoUrl(`https://vimeo.com/${gig.vimeo_video_id}`);
      }

      if (gig.youtube_video_id) {
        setVideoUrl(`https://www.youtube.com/watch?v=${gig.youtube_video_id}`);
      }
    }


    setUser(user);
    setGigTypes(gig_types);



  }

  const submitGig = async() => {
    const body = new FormData();

    body.append('description_txt', description);
    body.append('title', title);
    body.append('primary_service', primaryService.value);
    body.append('fee', primaryFee);
    body.append('estimate_days', primaryEstimate.value);
    body.append('subtype_id', selectedGigType.value);

    secondaryServices.forEach((service, i) => {
      body.append(`services[${i}][service]`, service.service);
      body.append(`services[${i}][price]`, service.fee);
      body.append(`services[${i}][time_days]`, service.estimate.value);
    });

    galleryImageIds.forEach(imageId => {
      body.append('images[]', imageId);
    });

    if (express == true) {

      body.append('extras[0][code]', 'express');
      body.append('extras[0][estimate_days]', expressEstimate.value);
      body.append('extras[0][fee]', expressFee.value);
    }

    if (videoUrl != '' && videoId != null && videoType != null) {
      body.append('video_type', videoType);
      if (videoType == 'youtube') {
        body.append('video_id', videoId);
      } else {
        body.append('video_id', videoId);
      }
    }

    setError(null);

    let url = '/api/gigs';

    if (id) {
      url = `/api/gigs/${id}`;
    }

    const response = await fetch(url, 'POST', body);

    const {error, message} = response;

    if (error) {
      setError(error);
    }

    if (message) {
      toast(message);
      setTimeout(() => {
        navigate('/influencers/gigs');
      }, 2000);
    }


  }

  useEffect(() => {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    var match = videoUrl.match(regExp);
    var youTubeId = (match&&match[7].length==11)? match[7] : false;
    var vimeoId = false;

    if (!youTubeId) {
      var result = videoUrl.match(/(?:www\.|player\.)?vimeo.com\/(?:channels\/(?:\w+\/)?|groups\/(?:[^\/]*)\/videos\/|album\/(?:\d+)\/video\/|video\/|)(\d+)(?:[a-zA-Z0-9_\-]+)?/i);
      
      vimeoId = result&&result[1]?result[1]:false;

      
    }

    if (!youTubeId && !vimeoId) {
      setVideoId(null);
      setVideoType(null);
    } else if (youTubeId) {
      setVideoId(youTubeId);
      setVideoType('youtube');
    } else if (vimeoId) {
      setVideoId(vimeoId);
      setVideoType('vimeo');
    }



  }, [videoUrl])

  useEffect(() => {
    loadUser();
  }, []);

  const primaryGigTypes = [{name: '-- Choose promotion type', value: ''}].concat(gigTypes.filter(type => type.parent_code == primaryService.value.replace('_', '-')).map(type => ({name: type.name, value: type.id}) ) );
  const disabled = (user==null||user.stripe_account_id==null);

  return <Section title={id?"Update Gig":"Create a Gig"}>
    <div className="max-w-6xl mx-5 pt-2 pb-20">
      <p className="text-gray-600 text-sm mb-5">Gigs are listings for influencers to offer their services to brands seeking promotion.</p>
      {
        user!=null&&user.stripe_account_id==null&&
        <div className="bg-orange-100 px-4 sm:px-6 py-3 mt-3 rounded-md md:flex justify-between mb-5">
          <div className="md:flex items-center">
          <ExclamationCircleIcon className="text-orange-900 mb-3 md:mb-0 w-10 h-10 md:w-5 md:h-5 block mx-auto md:mr-2"/>
          <p className="md:flex-1 text-orange-900 text-sm text-center md:text-left">You must create a Stripe Express account in order to create a gig.</p>
          </div>
          <Link to={`/influencers/stripe`} className="w-full block md:w-auto mt-3 md:mt-0 text-center border bg-indigo-600 text-white text-sm py-2 px-4 shadow font-medium border-indigo-600 rounded-md hover:bg-indigo-700 hover:border-indigo-700">Connect Stripe</Link>
        </div>
      }

      <nav aria-label="Progress" className={''}>
        <ol role="list" className="space-y-4 md:flex md:space-y-0 md:space-x-8 p-5">
          {steps.map((_step) => {


            return (
            <li key={step.name} className="md:flex-1">
              {_step.step <= step ? (
                <button
                  className="w-full group pl-4 py-2 flex flex-col border-l-4 border-indigo-600 hover:border-indigo-800 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                  onClick={() => setStep(_step.step)}
                >
                  <span className="text-xs text-indigo-600 font-semibold tracking-wide uppercase group-hover:text-indigo-800">
                    {_step.id}
                  </span>
                  <span className="text-sm font-medium">{_step.name}</span>
                </button>
              ) :  (
                <span
                  className="group pl-4 py-2 flex flex-col border-l-4 border-gray-200 hover:border-gray-300 md:pl-0 md:pt-4 md:pb-0 md:border-l-0 md:border-t-4"
                >
                  <span className="text-xs text-gray-500 font-semibold tracking-wide uppercase group-hover:text-gray-700">
                    {_step.id}
                  </span>
                  <span className="text-sm font-medium">{_step.name}</span>
                </span>
              )}
            </li>
          )
            })}
        </ol>
      </nav>

      {step==1&&<>
      <Card title="Primary Platform" unstyled={true} className="mb-8">
        <div className="sm:divide-y">
          <div className="px-4 sm:px-6 py-4">
            <Select label="Primary Platform" disabled={id||disabled} className="" options={serviceOptions} selected={primaryService} setSelected={setPrimaryService} helpText={`This will be the platform for which you will offer promotional services.`}/>
          </div>
          <div className="px-4 sm:px-6 py-4 grid sm:grid-cols-2 gap-5">
            <div>
              <InputField type="number" disabled={disabled} helpText="The baseline fee for this service" label="Your Fee ($)" placeholder="0.00" value={primaryFee} onChange={e => setPrimaryFee(e.target.value)} />
            </div>
            <div>
              <Select label="Completion Estimate" disabled={disabled}  helpText={`Approximately how long will it take for you to complete an assignment`} options={estimates} selected={primaryEstimate} setSelected={setPrimaryEstimate}/>
            </div>
          </div>
        </div>
      </Card>
      <Card title="Additional Platforms" className="mb-8" unstyled={true} primaryAction={{
        label: 'Add Platform',
        action: () => setPlatformModal(true),
        disabled: user!=null&&(primaryService.value==''||secondaryServices.length>=(user.services.length-1))
      }}>
      {
        secondaryServices.length>0&&secondaryServices.map((service, index) => <div key={`secondary-${service.service}`} className="px-4 sm:px-6 py-4 sm:flex items-center sm:grid sm:grid-cols-4">
            <div className="flex">
              <img src={socialMediaServices[service.service].icon} className="w-10 h-10"/>
              <div className="ml-3">
                <strong className="font-medium text-sm block">{getHandle(service.details)}</strong>
                <span className="text-xs block text-gray-600">{getServiceName(service.details)} &middot; {numeral(service.details.followers).format('0a')} followers</span>
              </div>
            </div>
            <div className="mt-2 sm:mt-0 sm:ml-3">
              <InputField label="Fee ($)" type="number" value={service.fee} placeholder="0.00" onChange={(e) => setSecondaryServiceFee(service.service, e.target.value)}/>
            </div>
            <div className="mt-2 sm:mt-0 sm:ml-3">
              <Select label="Days Until Completion" options={estimates} selected={service.estimate} setSelected={(val) => setSecondaryServiceEstimate(service.service, val)}/>
            </div>
            <div className="mt-2 sm:mt-0 text-right pt-3">
              <Button onClick={() => removeSecondaryService(index)} className="text-center block sm:w-auto w-full"><TrashIcon className="w-5 h-5"/></Button>
            </div>
          </div>)
      }
      {
        secondaryServices.length==0&&<div className="px-4 sm:px-6 py-20 flex items-center justify-center">
          <div className="text-center">
            <h3 className="font-medium text-md text-gray-600 mb-2">Add additional services</h3>
            <p className="text-gray-600 text-sm mb-2">You can allow brands to pay for additional posts on other social networks</p>
            <Button onClick={() => setPlatformModal(true)} primary disabled={primaryService.value==''}>Add a platform</Button>
          </div>
        </div>
      }
      </Card>
      <Card title="Express Service" className="mb-8">
        <div className="grid sm:grid-cols-3 gap-5">
          <div className="flex items-center">
            <input disabled={disabled} type="checkbox" className="border shadow rounded-md bg-white text-indigo-600" id="express" checked={express} onChange={e => setExpress(e.target.checked)}/>
            <label className="text-gray-600 text-sm ml-3" for="express" htmlFor="express">Offer faster completion for an additional fee</label>
          </div>
          <div>
            <Select disabled={disabled} label="Completion Estimate" helpText="How long it takes to complete an express assignment" selected={expressEstimate} setSelected={setExpressEstimate} options={estimates}/>
          </div>
          <div>
            <Select disabled={disabled} label="Additional Fee" helpText="Brands can opt to pay this amount for faster service" selected={expressFee} setSelected={setExpressFee} options={expressFees}/>
          </div>

        </div>
      </Card>
      </>}

      {
        step==2&&<>
          <Card title="Gig Details" className="mb-8">
            <InputField className="mb-3" label="Title *" helpText="Briefly name this gig let brands know what you're offering." placeholder="My Gig" value={title} onChange={e => setTitle(e.target.value)}/>
            <TextArea label="Description *" className="mb-3" helpText="" placeholder="I offer product reviews for the beauty industry" value={description} onChange={(e) => setDescription(e.target.value)}/>
            {false&&<Select label="Type of promotional content *" options={primaryGigTypes} selected={selectedGigType} setSelected={setSelectedGigType}/>}
            <FileDrop error={uploadError} uploading={uploading} thumbnailUrl={false} label="Gallery Images *" onChange={uploadImage} helpText="JPG, PNG, maximum size of 7MB" multiple={true} className="mt-3"/>
            {galleryImageIds.length>0&&<div className="grid grid-cols-2 sm:grid-cols-4 gap-4 mt-5">
            {
              galleryImageIds.map((galleryImageId, i) => <div className="flex flex-col items-center justify-center">
                <div className="w-full flex flex-1 justify-center items-center">
                  <img src={`https://res.cloudinary.com/wigmarket/h_300,c_fill,q_auto/${galleryImageId}.jpg`} className="object-contain w-full block"/>
                  
                </div>
                <button onClick={() => removeImage(i)} className="border w-full block py-3 px-5 text-center"><TrashIcon className="w-5 h-5 text-gray-600 block mx-auto"/></button>
              </div>)
            }
            </div>}

            <label className="font-medium text-sm text-gray-700 mt-3 block">Video URL (Optional)</label>
            <span className="block text-xs text-gray-600 mb-2 mt-1">Have a YouTube or Vimeo video explainer? Paste the URL here</span>
            <div className="flex border border-gray-300 shadow-sm rounded-md items-center divide-x divide-gray-300">
              <div className="px-2 py-2">
                {
                  videoId!=null?<CheckCircleIcon className="w-5 h-5 block text-green-600"/>:<XCircleIcon className="w-5 h-5 block text-gray-400"/>
                }
              </div>
              <input type="url" className="focus:ring-0 flex-1 border-0 px-4 py-2 text-sm text-gray-600" value={videoUrl} onChange={(e) => setVideoUrl(e.target.value)} placeholder="https://www.youtube.com/watch?v=..."/>
            </div>

          </Card>
        </>
      }
      {error!=null&&<Alert title="Hold up!" status="danger" className="mb-8">
        <p>{error}</p>
      </Alert>}

      {user!=null&&user.stripe_account_id==null&&<div className="mb-8">
          <span className="text-red-600 text-sm">You must create a Stripe Express account to create a gig and to get paid.</span>
        </div>}

      <Button disabled={disabled} fullWidth={1} primary={1} onClick={() => nextStep()}>{step<steps.length?'Continue':'Save Gig'}</Button>
    </div>
    {user!=null&&<Modal open={platformModal} setOpen={() => setPlatformModal(false)} primary={{label: 'Add Platform', action: () => addSelectedPlatform()}} secondary={{label: 'Close', action: () => setPlatformModal(false)}}>
      <h3 className="font-medium text-md text-gray-600">Add a secondary platform</h3>
      <p className="text-xs text-gray-600 mb-5">Choose add-on services for this gig. You can add fees and other details after you select a platform.</p>
      <div className="grid-cols-2 grid gap-4">
        {
          user!=null&&user.services.map(service => {
            if (service.service == primaryService.value) {
              return null;
            }

            if (secondaryServices.find(_service => service.service == _service.service)) {
              return null;
            }

            return <button className={"border truncate py-2 rounded-md px-4 w-full flex items-center "+(selectedPlatform!=null&&selectedPlatform.service == service.service?'bg-indigo-600 text-white':'text-gray-700')} onClick={() => setSelectedPlatform(service)}>
              <div className="w-10 h-10 block border bg-white flex justify-center items-center rounded-full aspect-square shadow">
                <img src={socialMediaServices[service.service].icon} className="w-5 h-5 block"/>
              </div>
              <div className="text-left  ml-2 truncate">
                <strong className="font-medium truncate text-sm block mb-0">{getHandle(service)}</strong>
                <span className="text-xs opacity-50 font-medium mt-0 block">{numeral(service.followers).format('')} followers</span>
              </div>
            </button>
          })
        }
      </div>

    </Modal>}
<ToastContainer autoClose={2000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
  </Section>
}