import React, { Fragment, useState, useEffect } from 'react'
import {
  Link, Outlet, useLocation, useNavigate
} from "react-router-dom";

import { useCookies } from 'react-cookie';
import Section from '../components/Section';

export default function SignOut({children}) {

	const navigate = useNavigate();

  	const [cookies, setCookie, removeCookie] = useCookies(['token']);

  	

  	useEffect(() => {
  		removeCookie('token');
  		navigate('/signin');
  	}, [])

	return <Section title="Signing out...">

	</Section>
}