import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  XCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon,
  LinkIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {Modal, getHandle, getURL, getServiceName,getOrderStatus, PageTabs, InputField, Alert, TextArea, Select, FileDrop, Card, StarRatingSelector} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';
import CardForm from '../components/CardForm';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

const imgs = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  instagram: instagramIcon,
  youtube: youtubeIcon,
  tiktok: tiktokIcon
};

const cancellationReasons = [
  { name: '-- Select a reason', value: ''},
  { name: 'Changed mind', value: 'change_mind'},
  { name: 'Both parties agree to end assignment', value: 'mutual'},
  { name: 'Influencer is not responsive', value: 'unresponsive'},
  { name: 'Refusal to complete agreed work', value: 'refusal'},
  { name: 'Fraud / Influencer is not who they say they are', value: 'fraud'},
  { name: 'Other', value: 'other'}
];

const disputeResults = [
  {name: 'Cancelled / Refund Issued', value: 'completed'},
  {name: 'Cancellation Request Declined', value: 'declined'},
  {name: 'Other', value: 'other'},
  {name: 'Still Pending', value: 'pending'}
];

export default function BrandProposal() {

  const {authenticatedFetch} = useAuthRequest();

  const authFetch = authenticatedFetch;

  const navigate = useNavigate();

  const { id } = useParams();

  const defaultTabs = [
      {name: 'Details', current: true, url: `/proposal/${id}`},
      {name: 'Messages', current: false, count: 0,  url: `/proposal/${id}/messages`}
  ];

  const [campaign, setCampaign] = useState(null);

  const [submitDisabled, setSubmitDisabled] = useState(false);

  const [changeModal, setChangeModal] = useState(false);

  const [changeTxt, setChangeTxt] = useState('');

  const [proposal, setProposal] = useState(null);

  const [messages, setMessages] = useState([]);

  const [submitError, setSubmitError] = useState(null);

  const [messageTxt, setMessageTxt] = useState('');

  const [tabs, setTabs] = useState(defaultTabs);

  const [hireModal, setHireModal] = useState(false);

  const [verifyModal, setVerifyModal] = useState(false);

  const [cancellationModal, setCancellationModal] = useState(false);

  const [cancellationRequestModal, setCancellationRequestModal] = useState(false);

  const [cancellationReason, setCancellationReason] = useState(cancellationReasons[0]);

  const [confirmCancellation, setConfirmCancellation] = useState(false);

  const [cancellationDetails, setCancellationDetails] = useState('');

  const [withdrawCancellationModal, setWithdrawCancellationModal] = useState(false);

  const [cards, setCards] = useState([]);

  const [selectedSource, setSelectedSource] = useState({id: '', name: '-- Select card'});

  const [createOrder, setCreateOrder] = useState(true);

  const [cancelAgreeModal, setCancelAgreeModal] = useState(false);
  const [cancelDeclineModal, setCancelDeclineModal] = useState(false);

  const [reviewModal, setReviewModal] = useState(false);

  const [rating, setRating] = useState(0);

  const [review, setReview] = useState('');

  const [couponCode, setCouponCode] = useState('');

  const [affiliateModal, setAffiliateModal] = useState(false);

  const initialLoad = async() => {

    const response = await authFetch(`/api/v2/proposals/${id}`, 'GET');

    const {campaign, proposal, error, user} = response;

    if (user.is_influencer == 1 && user.is_brand == 0) {
      navigate(`/influencers/campaign/${campaign.id}`);
    }

    if (campaign && proposal) {


      
      setCampaign(campaign);
      setProposal(proposal);

      if (proposal.unread_count > 0) {
        const _tabs = tabs.map(tab => {
          if (tab.name == 'Messages') {
            return {...tab, count: proposal.unread_count};
          }

          return tab;
        });

        setTabs(_tabs);
      }

      if (proposal.coupon_code) {
        setCouponCode(proposal.coupon_code);
      }
      window.scrollTo(0,0);
    }

    if (error) {
      toast(error);
    }

  }

  const respondToCancellationRequest = async(agree) => {

    const body = new FormData();

    body.append('agree', agree);

    const response = await authFetch(`/api/v2/proposals/${proposal.id}/cancellationRequest`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      initialLoad();
      setCancelAgreeModal(false);
      setCancelDeclineModal(false);
    }

    if (error) {
      toast(error);
    }
  }

  const openHiringModal = () => {
    if (campaign.max_price == 0) {
      setHireModal(true);
    } else {
      loadCards();
    }
  }

  const loadCards = async() => {
    const response = await authFetch(`/api/v2/cards`, 'GET');

    const { cards } = response;

    setCards(cards);
    setHireModal(true);
    //alert('here');
  }

  const hireInfluencer = async() => {

    setSubmitError(null);
    setSubmitDisabled(true);

    const body = new FormData();

    body.append('proposal_id', proposal.id);

    proposal.services.forEach(service => {
      if (service.hired) {
        body.append('hire['+service.service+']', '1');
      }
    });

    proposal.products.forEach(product => {
      if (product.hired) {
        body.append('proposal_product_ids[]', product.id);
      }
    });

    if (createOrder == 1) {
      body.append('create_order', '1');
    }

    body.append('token', selectedSource.id);

    body.append('total_amount', grandTotal);

    if (campaign.affiliate_program && campaign.affiliate_program.coupon == 1) {
      body.append('proposal_coupon_code', couponCode);
    }

    const response = await authFetch('/api/stripe', 'POST', body);

    const { message, error } = response;

    if (message) {
      toast(message);
      setHireModal(false);
      initialLoad();
      window.scrollTo(0, 0);
    }

    if (error) {
      setSubmitError(error);
    }

    setSubmitDisabled(false);
  }

  const withdrawCancellationRequest = async() => {
    const response = await authFetch(`/api/v2/proposals/${proposal.id}/withdrawCancellation`, 'POST');

    const {message, error} = response;

    if (message) {
      toast(message);
      setWithdrawCancellationModal(false);
      initialLoad();
      window.scrollTo(0,0);
    }

    if (error) {
      toast(error);
    }
  }

  const submitChangeRequest = async() => {
    const formData = new FormData();

    formData.append('changes', changeTxt);

    const response = await authFetch(`/api/v2/proposals/${proposal.id}/changes`, 'POST', formData);

    const {message, error} = response;
 
    if (message) {
      setChangeModal(false);
      toast(message);
      initialLoad();
      window.scrollTo(0, 0);
    }

    if (error) {
      toast(error);
    }
  }

  const toggleService = (serviceType) => {

    const _proposal = {...proposal};

    const _services = _proposal.services.map(service => {
      if (service.service == serviceType) {
        return {...service, hired: !service.hired};
      }
      return service;
    });

    _proposal.services = _services;

    setProposal(_proposal);
  }

  const toggleProduct = (id) => {
    const _proposal = {...proposal};

    const _products = _proposal.products.map(product => {
      if (product.id == id) {
        return {...product, hired: !product.hired};
      }

      return product;
    });

    _proposal.products = _products;

    setProposal(_proposal);
  }

  const submitCancellationRequest = async() => {
     const url = `/api/proposals/${proposal.id}/cancel`;

     const body = new FormData();

     body.append('reason', cancellationReason.value);
     body.append('details', cancellationDetails);

     const response = await authFetch(url, 'POST', body);

     const {message, error} = response;

     if (message) {
      toast(message);
      initialLoad();
      setCancellationRequestModal(false);
     }

     if (error) {
      toast(error);
     }
  }

  const hireInfluencerWithoutPayment = async() => {
    const body = new FormData();

    if (createOrder == 1) {
      body.append('create_order', '1');
    }

    proposal.services.forEach(service => {
      if (service.hired) {
        body.append('hire['+service.service+']', '1');
      }
    });

    proposal.products.forEach(product => {
      if (product.hired) {
        body.append('proposal_product_ids[]', product.id);
      }
    });

    if (campaign.affiliate_program && campaign.affiliate_program.coupon == 1) {
      body.append('proposal_coupon_code', couponCode);
    }

    const response = await authFetch(`/api/v2/proposals/${proposal.id}/hireWithoutPayment`, 'POST', body);

    const {message, error} = response;

    if (message) {
      toast(message);
      setHireModal(false);
      initialLoad();
    }

    if (error) {
      toast(error);
    }
  }

  const cancelProposal = async() => {
    const response = await authFetch(`/api/v2/proposals/${proposal.id}/cancel`, 'POST');

    const {message, error} = response;

    setCancellationModal(false);

    if (message) {
      toast(message);
      initialLoad();
    }

    if (error) {
      toast(error);
    }
  }

  const addCard = async(source) => {
    const body = new FormData();

    body.append('source', source.source.id);

    const response = await authFetch('/api/v2/stripe', 'POST', body);

    const {message, error, card} = response;

    if (message) {
      setSelectedSource(card);
      toast(message);
      loadCards();
    }

    if (error) {
      toast(error);
    }
  }

  const changeAffiliateStatus = async(status=0) => {
      const body = new FormData();

      body.append('enabled', status);

      const response = await authFetch(`/api/v2/proposals/${proposal.id}/affiliate`, 'POST', body);

      const {message, error} = response;

      if (message) {
        toast(message);
        initialLoad();
        setAffiliateModal(false);
      }

      if (error) {
        toast(error);
      }

    }


  const submitVerification = async() => {

    const body = new FormData();

    body.append('rating', rating);

    body.append('review', review);

    const response = await authFetch(`/api/proposals/${proposal.id}/verified`, 'POST', body);

    const { message, error } = response;

    if (message) {
      toast(message);
      setVerifyModal(false);
      initialLoad();
      window.scrollTo(0,0);
    }

    if (error) {
      toast(error);
    }
  }

  useEffect(() => {
    initialLoad();
  }, [id]);

  useEffect(() => {
    if (verifyModal == true) {
      setReviewModal(false);
    }
  }, [verifyModal]);

  useEffect(() => {
    if (reviewModal == true) {
      setVerifyModal(false);
    }
  }, [reviewModal]);

  if (proposal == null) {
    return null;
  }

  const totalFees = proposal.services.reduce((prev, current) => (prev+current.fee), 0);
  const subTotal = proposal.services.reduce((prev, current) => {
                    if (current.hired) {
                      return (prev+current.fee);
                    }
                    return prev;
                  }, 0);

  const bcFee = (subTotal*.1)+'';


  const grandTotal = subTotal+(campaign.user.pro?0:(subTotal*.1));




  return <Section title={campaign.title}>
    <div className="">
      <div className="flex items-center justify-between mt-8 max-w-3xl px-3 sm:px-6 lg:max-w-7xl">
        <div className="flex items-center">
          {
            proposal.user.image_id!=null?<img src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${proposal.user.image_id}.jpg`} className="w-48 h-48 rounded-md"/>:<div className="w-48 h-48 rounded-md flex justify-center items-center text-4xl font-bold bg-slate-100 text-slate-800">{proposal.user.name[0]}</div>
          }
          <div className="ml-5">
            <h1 className="font-bold text-gray-800 text-2xl">{proposal.user.name}</h1>
            <span className="text-gray-600"><strong>{numeral(proposal.user.follower_count).format('0a')}</strong> followers</span>
          </div>
        </div>
        
      </div>
      
      <div className="mt-8 max-w-3xl grid grid-cols-1 gap-6 px-3 sm:px-6 lg:max-w-7xl lg:grid-flow-col-dense lg:grid-cols-3">
        <div className="space-y-6 lg:col-start-1 lg:col-span-2">
        {
          proposal.status=='production'&&<Alert title={`You have hired ${proposal.user.name} for your campaign`} status="success">
            <p>
              Stay in touch with {proposal.user.name} via the Messages tab to get updates on progress.
            </p>
          </Alert>
        }

        {
          (proposal.status=='cancelled'||proposal.deleted_at!=null)&&<Alert title={`This assignment has been cancelled`} status="danger">
            <p>
              This proposal is no longer active. {proposal.order!=null&&proposal.order.refund_id!=null&&`You have been issued a refund of $${numeral(proposal.order.total_amount).format('0,0.00')}`}  
            </p>
          </Alert>
        }

        {
          proposal.pending_cancellation_request!=null&&proposal.pending_cancellation_request.disputed_at==null&&<Card title="Pending Cancellation Request" primaryAction={proposal.pending_cancellation_request.requested_by_user_id==campaign.user_id?{label:'Withdraw Request', action: () => setWithdrawCancellationModal(true)}:null}>
            {proposal.pending_cancellation_request.requested_by_user_id==campaign.user_id&&<>
              <p className="text-gray-600 text-sm mb-3">You have requested to end this assignment.</p>
              <p className="text-gray-600 text-sm mb-3">If <span className="text-indigo-600">{proposal.user.name}</span> agrees to your request, this assignment will end{proposal.order!=null?(<span>, and you will be refunded <span className="text-indigo-600">${numeral(proposal.order.total_amount).format('0,0.00')}</span></span>):'.'}</p>
              <label className="block text-gray-500 font-medium text-sm">Cancellation Reason</label>
            <p className="text-gray-600 text-sm mb-5">{cancellationReasons.find(reason => reason.value==proposal.pending_cancellation_request.reason_code).name}</p>
            <label className="block text-gray-500 font-medium text-sm">Additional Details</label>
            <p className="text-gray-600 text-sm">{proposal.pending_cancellation_request.details_txt!=''?proposal.pending_cancellation_request.details_txt:'No details provided'}</p>
            </>
          }

          

          {proposal.pending_cancellation_request.requested_by_user_id==proposal.user_id&&<>
              <p className="text-gray-600 text-sm mb-3">{proposal.user.name} has requested that this assignment be terminated.</p>
              <p className="text-gray-600 text-sm mb-5">If you agree to end this assignment, you will be refunded any payment held in escrow.</p>

              <div className="grid sm:grid-cols-2 gap-4 ">
                <Button primary onClick={() => setCancelAgreeModal(true)}>Cancel assignment</Button>
                <Button onClick={() => setCancelDeclineModal(true)}>Decline request</Button>
              </div>
            </>
          }
          </Card>
        }

        {
          proposal.pending_cancellation_request!=null&&proposal.pending_cancellation_request.disputed_at!=null&&<Card title="Dispute opened">
            <p className="text-gray-600 text-sm mb-3">A request to cancel this assignment has been declined by the other party.</p>
            <p className="text-gray-600 text-sm">A Beauty Clout admin will reach out to you. If you have any questions, you can also reach out to our staff at <a href="mailto:info@beautyclout.com" className="text-indigo-600 hover:text-indigo-700">info@beautyclout.com</a>.</p>
          </Card>
        }

        {
          proposal.status=='completed'&&<Alert title={`${proposal.user.name} has submitted work for review`} status="info">
            <p>Review the proofs and/or attachments below. If you are satisfied, you may <a href="#bottom" className="font-bold">verify their work</a>, which will release payment to {proposal.user.name}. You may also <a href="#bottom" className="font-bold">request changes</a> as needed.</p>
          </Alert>
        }

        {
          proposal.status=='change'&&<Alert title="You have submitted a change request" status="info">
            <p>Stay up to date with the influencer in the messages section.</p>
            <p>Once the requested changes are made, they will send the proposal back to you, so you can verify their work.</p>
          </Alert>
        }

        {
          proposal.status=='verified'&&<Alert title="You verified this assignment" status="success">
            <p>Nothing left to do here! You verified this assignment, and any applicable payment has been released to the influencer.</p>
          </Alert>
        }

        {
          proposal.admin_cancellation_request!=null&&<Card title="Result of Dispute">
            <p className="text-sm text-gray-600 mb-3">A Beauty Clout administrator reviewed a dispute regarding this assignment..</p>

            <label className="text-xs font-medium">Decision</label>
            <p className="text-sm text-gray-600 mb-3">{disputeResults.find(result => result.value==proposal.admin_cancellation_request.result_code).name}</p>
            {
              proposal.admin_cancellation_request.admin_details_txt!=null&&<>
                <label className="text-xs font-medium">Additional details</label>
                <p className="text-sm text-gray-600 mb-3">{proposal.admin_cancellation_request.admin_details_txt}</p>
              </>
            }
            <p className="text-xs text-gray-600">Submitted {moment(proposal.admin_cancellation_request.completed_at).fromNow()}</p>

          </Card>
        }

        <PageTabs tabs={tabs} navigate={navigate}/>
          <section>
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  Proposal Details
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Social media accounts, fees &amp; estimates</p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Submitted</dt>
                        <dd className="mt-1 text-sm text-gray-900">{moment(proposal.created_at).fromNow()}</dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">Total Influencer Fees</dt>
                        <dd className="mt-1 text-sm text-gray-900">${numeral(totalFees).format('0,0.00')}</dd>
                      </div>
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">Proposal</dt>
                        <dd className="mt-1 text-sm text-gray-900" dangerouslySetInnerHTML={{__html: proposal.proposal_txt.replaceAll("\n", '<br/>')}}>
                        </dd>
                      </div>
                    </dl>
              </div>
            </div>
          </section>
          <section>
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <div className="flex justify-between items-center">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  {proposal.user.name}'s Offered Services
                </h2>
                  {proposal.status=='proposed'&&<Link to={`/proposal/${proposal.id}/messages`} className="text-sm text-indigo-600">Request Changes</Link>}
                </div>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Select which services + fees you would like to accept.</p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-1">
                      {
                        proposal.services.map(service => {
                          let userService = proposal.user.services.find(_s => (_s.service==service.service));

                          let estimate = '< 1 week';

                          if (service.completion_weeks == 1) {
                            estimate = service.completion_weeks+' week';

                          } else if (service.completion_weeks > 1) {
                            estimate = service.completion_weeks+' weeks';
                          }

                          if (userService) {
                            return <div className="flex justify-between" key={`service-${service.id}`}>
                              <div className="flex items-center">
                                {proposal.status=='proposed'&&<div className="mr-4">
                                  <input type="checkbox" className="rounded-sm shadow border-gray-300" checked={service.hired} onChange={() => toggleService(service.service)}/>
                                </div>}
                                <img src={imgs[service.service]} className="w-10 h-10"/>
                                <div className="ml-5">
                                  {
                                    userService.redacted==0?<><a href={getURL(userService)} className="block text-sm text-indigo-600 font-medium">{getHandle(userService)}</a><span className="font-medium text-xs text-gray-600"><strong className="font-bold">{numeral(userService.followers).format('0a')}</strong> followers</span></>:<><span className="block text-sm text-indigo-600 font-medium italic">Account info removed</span><span className="font-medium text-xs text-gray-600"><strong className="font-bold">Follower count removed</strong></span></>
                                  }
                                  
                                </div>
                              </div>
                              <div className="text-right items-center">
                                <span className="font-bold text-sm text-gray-600 block">${numeral(service.fee).format('0,0.00')}</span>
                                <span className="font-medium text-xs text-gray-600">Completed in {estimate}</span>
                              </div>
                            </div>
                          }
                        })
                      }
                    </dl>
              </div>
            </div>
          </section>
          {campaign!=null && campaign.products.length>0 && <section>
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <div className="flex justify-between items-center">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  Selected Products
                </h2>
                {proposal.status=='proposed'&&<Link to={`/proposal/${proposal.id}/messages`} className="text-sm text-indigo-600">Request Changes</Link>}
                </div>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Approve products to send to {proposal.user.name} for review.</p>
              </div>
              <div className="border-t border-gray-200 px-4 py-5 sm:px-6 sm:grid-cols-2 grid gap-4">
                {
                  proposal.products.map(p => {

                    let variantOptions = p.variant.option_1;

                    if (p.variant.option_2 != null) {
                      variantOptions += ' / '+p.variant.option_2;
                    }

                    if (p.variant.option_3 != null) {
                      variantOptions += ' / '+p.variant.option_3;
                    }

                    return <div className="flex items-center" key={`product-${p.id}`}>
                        {proposal.status=='proposed'&&<input type="checkbox" className="rounded-sm shadow border-gray-300 mr-3" checked={p.hired} onChange={() => toggleProduct(p.id)}/>}
                        <img src={p.product.image.src} className="w-24 h-24 rounded-md shadow"/>
                        <div className="ml-2">
                          <strong className="block text-gray-600 font-medium text-sm">{p.product.title}</strong>
                          <span className="text-gray-600 text-xs">{variantOptions} &middot; <strong className="font-medium text-green-600">${numeral(p.variant.price).format('0,0.00')}</strong></span>
                        
                      </div>
                    </div>
                  })
                }
              </div>
              </div>  
            </section>}

            {
              campaign!=null&&campaign.affiliate_program!=null&&campaign.affiliate_program.coupon==1&&proposal.status=='proposed'&&<Card title="Affiliate Program" className="mb-8">
                <InputField type="text" value={couponCode} onChange={(e) => setCouponCode(e.target.value.toUpperCase())} label="Coupon Code" helpText="Hiring this influencer will also generate this coupon code"/>
              </Card>
            }

            {proposal.shopify_orders.length>0&&<section>
                <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:px-6">
                <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                  Shopify Orders
                </h2>
                <p className="mt-1 max-w-2xl text-sm text-gray-500">Ship products to this influencer for review</p>
                
              </div>
              {
                  proposal.shopify_orders.map(order => {

                    const status = getOrderStatus(order);

                    return <div key={`order-${order.id}`} className="border-t border-gray-200 flex justify-between px-4 py-5 sm:px-6">
                      <div className="flex">
                        <a target="_blank" href={`https://${campaign.user.store.shopify_domain}.myshopify.com/admin/orders/${order.id}`} className="text-indigo-600 font-bold text-sm">#{order.number}</a>
                        <div className="ml-5">
                          <p className="text-sm text-gray-600 font-medium">{order.first_name} {order.last_name}</p>
                          <p className="text-sm text-gray-600">{order.address_1}</p>
                          {order.address_2!=null&&<p className="text-sm text-gray-600">{order.address_2}</p>}
                          <p className="text-sm text-gray-600">{order.city}, {order.state} {order.zip}</p>
                          <p className="text-sm text-gray-600">{order.country}</p>
                        </div>
                        <div className="mt-0 ml-5">
                          {
                            order.fulfillments.map(fulfillment => <div className="mt-0">
                                <p className="text-sm text-gray-600"><strong className="font-medium">{fulfillment.carrier}</strong></p>
                                <p className="text-gray-600 text-sm mb-1"><a className="text-indigo-600" href={fulfillment.url} target="_blank">{fulfillment.tracking_number}</a></p>

                              </div>)
                          }
                        </div>  
                      </div>
                      <div>
                      <p className={`font-medium text-sm ${status.code=='delivered'?'text-green-600':'text-gray-500'}`}>{status.status}</p>
                      </div>
                    </div>})
                }
              </div>
              
              </section>}

              {
                proposal.status=='completed'&&<section>
                    <div className="bg-white shadow sm:rounded-lg divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                          Links &amp; Proofs
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">{proposal.user.name} can provide links to their social media posts here.</p>
                        
                      </div>
                      {
                        proposal.proofs.map(proof => <div className="px-4 py-5 sm:px-6" key={`proof-${proof.id}`}>
                            <a href={proof.url} className="text-indigo-600 text-sm" target="_blank">{proof.url}</a>
                          </div>)
                      }
                      {
                        proposal.proofs.length==0&&<div className="px-4 sm:px-6 py-20 text-center">
                          <LinkIcon className="h-10 w-10 block mx-auto text-gray-400"/>
                          <h3 className="my-1 text-md font-medium text-gray-600">No links</h3>
                          <p className="text-gray-600 text-sm">{proposal.user.name} did not include any links as proof</p>
                        </div>
                      }
                    </div>
                </section>
              }

              {
                proposal.status=='completed'&&<section>
                    <div className="bg-white shadow sm:rounded-lg divide-y divide-gray-200">
                      <div className="px-4 py-5 sm:px-6">
                        <h2 id="applicant-information-title" className="text-lg leading-6 font-medium text-gray-900">
                          Attachments &amp; Screenshots
                        </h2>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">{proposal.user.name} can attachment images and screenshots as proof of their work.</p>
                        
                      </div>
                      {proposal.attachments.length>0?<div className="px-4 py-5 sm:px-6 grid grid-cols-3">
                      {
                        proposal.attachments.map(proof => <div className="" key={`proof-${proof.id}`}>
                            <a href={`https://res.cloudinary.com/wigmarket/${proof.image_id}.jpg`} className="text-indigo-600 text-sm block h-full flex items-center" target="_blank">
                              <img src={`https://res.cloudinary.com/wigmarket/h_200,c_fill/${proof.image_id}.jpg`} className="object-contain"/>
                            </a>
                          </div>)
                      }


                      </div>:<div className="px-4 sm:px-6 py-20 text-center">
                          <PaperClipIcon className="h-10 w-10 block mx-auto text-gray-400"/>
                          <h3 className="my-1 text-md font-medium text-gray-600">No attachments</h3>
                          <p className="text-gray-600 text-sm">{proposal.user.name} did not attach any files or screenshots</p>
                        </div>
                      }
                    </div>
                </section>
              }

              {
                campaign!=null&&campaign.affiliate_program!=null&&proposal!=null&&['production', 'change', 'completed', 'verified', 'cancelled'].includes(proposal.status)&&<Card title="Affiliate Summary" primaryAction={{label: 'View Details', url: `/affiliates/${proposal.user_id}`}}>
                  <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-x-4 gap-y-8 mb-8">
                    <div>
                      <label className="text-sm text-gray-600 font-medium">Clicks</label>
                      <h3 className="text-lg text-gray-900 font-bold">{numeral(proposal.affiliate_clicks_count).format('0,0')}</h3>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600 font-medium">Orders</label>
                      <h3 className="text-lg text-gray-900 font-bold">{numeral(proposal.affiliate_conversions_count).format('0,0')}</h3>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600 font-medium">Total Earnings</label>
                      <h3 className="text-lg text-gray-900 font-bold">{numeral(proposal.affiliate_total_earned).format('$0,0.00')}</h3>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600 font-medium">Total Payouts</label>
                      <h3 className="text-lg text-gray-900 font-bold">{numeral(proposal.affiliate_total_paid).format('$0,0.00')}</h3>
                    </div>
                    <div>
                      <label className="text-sm text-gray-600 font-medium">Owed Payment</label>
                      <Link to={`/affiliates/${proposal.user_id}`} className="block text-lg text-indigo-600 font-bold">{numeral(proposal.affiliate_owed_amount).format('$0,0.00')}</Link>
                    </div>
                  </div>
                  <div className="space-y-8">
                    {campaign.affiliate_program.coupon==1&&proposal.coupon_code!=null&&<div>
                      <label className="text-sm text-gray-600 font-medium">Affiliate Coupon Code</label>
                      <h3 className="text-lg text-gray-900 font-bold">{proposal.coupon_code}</h3>
                    </div>}
                    <div>
                      <label className="text-sm text-gray-600 font-medium">Affiliate Status</label>
                      {
                        proposal.affiliate_enabled==1?<>
                          <div className="flex items-center justify-start text-gray-600 text-sm">
                            <CheckCircleIcon className="text-green-600 w-5 h-5 mr-1"/><span>Active &middot; <button onClick={() => setAffiliateModal(true)} type="button" className="text-xs text-indigo-600">Turn off</button></span>
                          </div>
                          <small className="text-xs text-gray-600">Orders and clicks coming from this {proposal.user.name}'s unique affiliate link are being tracked.</small>
                        </>:<>
                        <div className="flex items-center justify-start text-gray-600 text-sm">
                            <XCircleIcon className="text-red-600 w-5 h-5 mr-1"/><span>Inactive &middot; <button onClick={() => setAffiliateModal(true)} type="button" className="text-xs text-indigo-600">Turn on</button></span>
                          </div>
                          <small className="text-xs text-gray-600">Orders and clicks are no longer being tracked.</small>
                       </>
                      }
                    </div>
                    <div>
                      <label className="text-sm text-gray-600 font-medium block">Affiliate Link</label>
                      <a href={`https://${campaign.user.store.domain}?bc_aff_id=${proposal.id}`} className="text-sm text-indigo-600 hover:text-indigo-700">https://{campaign.user.store.domain}?bc_aff_id={proposal.id}</a>
                    </div>
                  </div>
                </Card>
              }


            <div className="pb-20 space-y-2 space-x-2" id="bottom">
          {proposal.status=='proposed'&&<>
            <Button className="w-full sm:w-auto" primary onClick={() => openHiringModal()}>Hire Influencer</Button>

            <Button className="w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2">Decline</Button>
          </>}

          {proposal.status=='completed'&&<>
            <Button className="w-full sm:w-auto" primary onClick={() => setReviewModal(true)}>Verify Work</Button>

            <Button className="w-full sm:w-auto mt-2 sm:mt-0 sm:ml-2" onClick={() => setChangeModal(true)}>Request Changes</Button>
          </>}

          {
            proposal.allow_cancel_request==1&&<>
              {proposal.pending_cancellation_request==null?
              <Button className="w-full sm:w-auto" onClick={() => setCancellationRequestModal(true)}>Request Cancellation</Button>:
              <Button className="w-full sm:w-auto" onClick={() => setWithdrawCancellationModal(true)}>Withdraw Cancellation Request</Button>
              }
            </>
          }

          {
            proposal.allow_cancel==1&&<>

              <Button className="w-full sm:w-auto" onClick={() => setCancellationModal(true)}>Cancel Assignment</Button>
            </>
          }
        </div>
        </div>
        <div>
          
        </div>
      </div>
    </div>
    
    <Modal open={verifyModal&&campaign.order!=null} setOpen={() => setVerifyModal(false)} primary={{label: 'Release Payment', action: () => submitVerification()}} secondary={{label: 'Back', action: () => setReviewModal(true)}}>
      <h2 className="text-xl font-bold text-center text-gray-900 mb-5">Release Payment to {proposal.user.name}</h2>
      <p className="text-gray-600 text-sm mb-3">Proceed if you are satisfied with this influencer's work, and would like to conclude this assignment.</p>
      <p className="text-gray-600 text-sm mb-3">This will release payment to the influencer, and this action is not reversible.</p>
    </Modal>

    <Modal open={verifyModal&&campaign.order==null} setOpen={() => setVerifyModal(false)} primary={{label: 'Confirm', action: () => submitVerification()}} secondary={{label: 'Back', action: () => setReviewModal(true)}}>
      <h2 className="text-xl font-bold text-center text-gray-900 mb-5">Verify Work + Conclude Assignment?</h2>
      <p className="text-gray-600 text-sm mb-3">Proceed if you are satisfied with this influencer's work, and would like to conclude this assignment.</p>
    </Modal>
    
    <Modal open={changeModal} setOpen={() => setChangeModal(false)} primary={{label: 'Request Changes', action: () => submitChangeRequest()}} secondary={{label: 'Close', action: () => setChangeModal(false)}}>
      <h2 className="text-xl font-bold text-center text-gray-900 mb-5">Request Changes</h2>
      <p className="text-gray-600 text-sm mb-3">If the influencer's work requires changes, you can specify those changes here.</p>
      <TextArea label="What needs to be changed?" value={changeTxt} onChange={(e) => setChangeTxt(e.target.value)}/>
    </Modal>

    <Modal open={cancellationRequestModal} setOpen={() => setCancellationRequestModal(false)} primary={{disabled: (cancellationReason.value==''), label: 'Send Cancellation Request', action: () => submitCancellationRequest()}} secondary={{label: 'Close', action: () => setCancellationRequestModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Cancel this assignment?</h2>
      <p className="text-gray-600 text-sm mb-5">At this stage, you can only request cancellation. {proposal.user.name} will be notified of your request. If the influencer agrees, this assignment will be terminated.</p>
      {
        proposal!=null&&proposal.order!=null&&proposal.order.total_amount > 0&&<p className="mb-5 text-gray-600 text-sm">If cancellation is successful, you will also be refunded your full payment of <strong className="font-medium text-indigo-600">${numeral(proposal.order.total_amount).format('0,0.00')}</strong></p>
      }
      <p className="mb-5 text-gray-600 text-sm">If no agreement is made, Beauty Clout staff will attempt to resolve any dispute.</p>
      <Select options={cancellationReasons} selected={cancellationReason} setSelected={setCancellationReason} label="Cancellation Reason *"/>
      <TextArea value={cancellationDetails} rows="4" onChange={e => setCancellationDetails(e.target.value)} label="Details" className="mt-3" placeholder="I would like to cancel this assignment, because..."/>
    </Modal>

    <Modal open={cancellationModal} setOpen={() => setCancellationModal(false)} primary={{disabled: !confirmCancellation, label: 'Cancel Assignment', action: () => cancelProposal()}} secondary={{label: 'Close', action: () => setCancellationModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Cancel this assignment?</h2>
      <p className="text-gray-600 text-sm mb-5">Are you sure you would like to terminate this assignment?</p>
      {
        proposal!=null&&proposal.order!=null&&proposal.order.total_amount > 0&&<p className="mb-5 text-gray-600 text-sm">You will be refunded your full payment of <strong className="font-medium text-indigo-600">${numeral(proposal.order.total_amount).format('0,0.00')}</strong></p>
      }
      <div className="mt-4 flex items-center">
        <input type="checkbox" className="shadow rounded-sm border" checked={confirmCancellation} id="cancel-proposal" onChange={(e) => setConfirmCancellation(e.target.checked)}/>
        <label htmlFor="cancel-proposal" for="cancel-proposal" className="ml-2 text-sm text-gray-600">I would like to cancel this assignment</label>
      </div>
    </Modal>

    <Modal open={withdrawCancellationModal} setOpen={() => setWithdrawCancellationModal(false)} primary={{ label: 'Confirm', action: () => withdrawCancellationRequest()}} secondary={{label: 'Close', action: () => setWithdrawCancellationModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Withdraw Your Cancellation Request?</h2>
      <p className="text-gray-600 text-sm mb-5">You have submitted a request to cancel this assignment.</p>
      <p className="text-gray-600 text-sm mb-5">Are you sure you would like to withdraw this request?</p>
      
    </Modal>

    <Modal open={cancelAgreeModal} setOpen={() => setCancelAgreeModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('agree')}} secondary={{label: 'Close', action: () => setCancelAgreeModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Agree to Cancel Assignment?</h2>
      <p className="text-gray-600 text-sm mb-3">By confirming, you will be cancelling this assignment. Any payment held in escrow will be returned to you.</p>
      <p className="text-gray-600 text-sm mb-5">This action cannot be reversed.</p>
    </Modal>

    <Modal open={cancelDeclineModal} setOpen={() => setCancelDeclineModal(true)} primary={{label: 'Confirm', action: () => respondToCancellationRequest('decline')}} secondary={{label: 'Close', action: () => setCancelDeclineModal(false)}}>
      <h2 className="text-xl font-bold text-gray-900 mb-5">Decline to Cancel Assignment?</h2>
      <p className="text-gray-600 text-sm mb-3">You are about to declining this influencer's request to cancel this assignment</p>
      <p className="text-gray-600 text-sm mb-5">A Beauty Clout moderator may reach out to you to assist in any dispute.</p>
    </Modal>

    <Modal open={(hireModal==true&&campaign.max_price == 0)} setOpen={() => setHireModal(false)} primary={{label: 'Hire influencer', action: () => hireInfluencerWithoutPayment()}} secondary={{label: 'Cancel', action: () => setHireModal(false)}}>
      <h2 className="text-xl font-bold text-center text-gray-900 mb-5">Hire {proposal.user.name} for this campaign?</h2>
      <p className="text-sm text-gray-600 mb-3">Since this is a free campaign, there is no payment due for this hire.</p>
      <div>
      <input type="checkbox" className="shadow rounded-sm border" checked={createOrder} id="create-shopify-order" onChange={(e) => setCreateOrder(e.target.checked)}/>
        <label htmlFor="create-shopify-order" className="ml-2 text-sm text-gray-600">Create a new order in Shopify to send products to influencer</label>
      </div>
    </Modal>


    <Modal open={(hireModal==true&&campaign.max_price > 0)} setOpen={() => setHireModal(false)} primary={{label: 'Hire influencer', action: hireInfluencer, disabled: submitDisabled}} secondary={{label: 'Cancel', action: () => setHireModal(false)}}>
      <h2 className="text-xl font-bold text-center text-gray-900 mb-5">Hire {proposal.user.name} for this campaign?</h2>
      <div className="mt-3">
        <Select options={cards} label="Payment source" selected={selectedSource} setSelected={setSelectedSource}/>
        </div>
        {selectedSource.id=='add'&&<CardForm onSuccess={addCard} onError={(error) => toast(error)}/>}
        <label className="text-sm text-gray-600 font-medium mt-4 mb-1 block">Order Summary</label>
        <table className="min-w-full border">
          <tbody className="divide-y divide-gray-200 bg-white">
          {
            proposal.services.map(service => {
              if (service.hired == false) {
                return null;
              }
              return <tr key={`itemized-${service.service}`}>
              <td className="whitespace-nowrap py-3 pl-4 pr-3 text-sm text-gray-500 sm:pl-6">
                {getServiceName(service)}
              </td>
              <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-700 text-right pr-4 sm:pr-6">${numeral(service.fee).format('0,0.00')}</td>
              </tr>
            })
          }
            <tr key={`itemized-subtotal`}>
              <td className="whitespace-nowrap py-3 pl-4 font-medium pr-3 text-sm text-gray-700 sm:pl-6">
                Subtotal
              </td>
              <td className="whitespace-nowrap px-3 font-medium py-3 text-sm text-gray-900 text-right pr-4 sm:pr-6">
                ${
                  numeral(subTotal).format('0,0.00')
                }
              </td>
            </tr>
            <tr key={`itemized-subtotal`}>
              <td className={`whitespace-nowrap py-3 pl-4 pr-3 text-sm text-gray-500 sm:pl-6`}>
                {campaign.user.pro?'Beauty Clout Fee Waived':'Beauty Clout Fee (10%)'}

              </td>
              <td className="whitespace-nowrap px-3 py-3 text-sm text-gray-900 text-right pr-4 sm:pr-6">
                <span className={(campaign.user.pro==1?'line-through mr-2':' ')}>${numeral(bcFee).format('0,0.00')}</span>

                {campaign.user.pro==1&&<span>$0.00</span>}
              </td>
            </tr>
            <tr key={`itemized-subtotal`}>
              <td className={`whitespace-nowrap py-3 pl-4 pr-3 font-bold text-sm text-gray-700 sm:pl-6 `}>
                Total

              </td>
              <td className="whitespace-nowrap px-3 py-3 font-bold text-sm text-green-600 text-right pr-4 sm:pr-6">
                ${numeral(grandTotal).format('0,0.00')}
              </td>
            </tr>
          </tbody>
        </table>
        {campaign != null && (campaign.products.length > 0) &&<div className="mt-4 flex items-center">
        <input type="checkbox" className="shadow rounded-sm border" checked={createOrder} id="create-shopify-order" onChange={(e) => setCreateOrder(e.target.checked)}/>
        <label htmlFor="create-shopify-order" className="ml-2 text-sm text-gray-600">Create a new order in Shopify to send products to influencer</label>
        </div>}

        {submitError!=null&&<div className="mt-3"><Alert title="Oops" status="danger">
          {submitError}
        </Alert></div>}
    </Modal>
    <Modal open={reviewModal} setOpen={() => setReviewModal(false)} primary={{label: 'Continue', action: () => setVerifyModal(true), disabled: (rating==0||review.length == 0)}} secondary={{label: 'Close', action: () => setReviewModal(false)}}>
      <div className="text-left">
      <h3 className="font-bold text-md mb-2">Review {proposal.user.name}'s work</h3>
      <p className="text-gray-600 text-sm mb-3 border-b pb-3">Before concluding this order, please rate &amp; review this {proposal.user.name}'s work.</p>
        <label className="text-gray-700 text-xs font-medium">Rate {proposal.user.name}'s work *</label>
        <StarRatingSelector value={rating} onChange={setRating}/>
        <label className="text-gray-700 text-xs font-medium mt-2">Additional details *</label>
        <TextArea value={review} onChange={(e) => setReview(e.target.value)} placeholder="Write a review for this influencer.." rows={3}/>
      </div>

    </Modal>
    <Modal open={affiliateModal} setOpen={() => setAffiliateModal(false)} primary={((proposal.affiliate_enabled==1)?{label: 'Deactivate Affiliate', action: () => changeAffiliateStatus(0)}:{label: 'Re-activate Affiliate', action: () => changeAffiliateStatus(1)})} secondary={{label: 'Close', action: () => setAffiliateModal(false)}}>
          {
            proposal.affiliate_enabled==1?<>
              <h4 className="font-bold mb-3">Deactivate affiliates for {proposal.user.name}?</h4>
              <p className="text-sm text-gray-600 mb-3"><span className="text-indigo-600">{campaign.title}</span></p>
              <p className="text-sm text-gray-600 mb-3">By deactivating affiliates for this influencer, you will stop tracking clicks + orders from this influencer moving forward.</p>
              <p className="text-sm text-gray-600">You can always reactivate affiliates again in the future.</p>
            </>:<>
              <h4 className="font-bold mb-3">Turn on affiliates for {proposal.user.name}?</h4>
              <p className="text-sm text-gray-600 mb-3"><span className="text-indigo-600">{campaign.title}</span></p>
              <p className="text-sm text-gray-600 mb-3">By re-activating affiliates for this influencer, you will start tracking clicks + orders from this influencer again.</p>
             
            </>
          }
        </Modal>

    <ToastContainer autoClose={4000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-900 h-1" className=" fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>       
  </Section>
}
