import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";


import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChatIcon, BellIcon, ThumbUpIcon, UserIcon, StarIcon, PaperAirplaneIcon, CashIcon, DocumentTextIcon, CheckCircleIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, XCircleIcon, ArrowCircleRightIcon } from '@heroicons/react/outline';
import Button from '../components/Button';
import Section, {Modal, getHandle, getURL, getServiceName,getOrderStatus, Pager, InputField, Alert, TextArea, Select, FileDrop, getIcon} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import { useAuthRequest } from '../Auth';
import CardForm from '../components/CardForm';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

const imgs = {
  facebook: facebookIcon,
  twitter: twitterIcon,
  instagram: instagramIcon,
  youtube: youtubeIcon,
  tiktok: tiktokIcon
};



export default function Notifications() {

  const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

  const [notifications, setNotifications] = useState(null);

  const loadNotifications = async(page=1) => {
    const response = await fetch(`/api/v2/notifications?page=${page}&mark_unread=1`);

    const {notifications, error} = response;

    if (notifications) {
      setNotifications(notifications);
    }

    if (error) {
      toast(error);
    }
  }



  useEffect(() => {
    loadNotifications();
  }, []);

  return <Section title="Notifications">
    <div className="max-w-6xl mx-5">
      {notifications!=null&&<div className="divide-y bg-white border-md mt-5 border rounded-md">
        {notifications.total==0&&<div className="px-4 sm:px-6 py-20">
            <BellIcon className="w-10 h-10 block mx-auto text-gray-600 mb-2"/>
            <h4 className="text-gray-900 font-medium text-center mb-3">No Notifications</h4>
            <p className="text-gray-600 text-center text-sm">You will receive notifications on this page letting you know of important updates and actions to take.</p>
            
          </div>}
        {
          notifications.data.map(notification => <div key={`notification-${notification.id}`} className="md:flex items-center justify-between px-4 sm:px-6 py-5">
              <div className="flex md:items-center">
                {getIcon(notification)}
                <Link className={"flex-1 text-sm text-gray-600 hover:text-indigo-600 "+(notification.seen_at==null?'font-medium':'')} to={notification.link}>{notification.notification_txt}</Link>
              </div>
              <span className="text-xs text-gray-400 ml-8 md:ml-0">{moment(notification.created_at).fromNow()}</span>
            </div>)
        }
        {notifications.total>0&&<div className="px-4 sm:px-6 py-5">
        <Pager data={notifications} changePage={loadNotifications}/>
        </div>}
      </div>
      }
    </div>
  </Section>
}