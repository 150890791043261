import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useSearchParams
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  ThumbUpIcon,
  UserIcon,
  ChevronDownIcon,
  XCircleIcon,
  LinkIcon
   } from '@heroicons/react/solid';
import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, ChartBarIcon, UploadIcon, FolderIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon, TrashIcon, RefreshIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, {MenuButton, Modal, TagInputField, getHandle, getURL, getServiceName,getOrderStatus, PageTabs, InputField, Alert, TextArea, Select, FileDrop, Card, USStates} from '../components/Section';
import CardForm from '../components/CardForm';
import moment from 'moment';
import numeral from 'numeral';
import { initFacebookSdk } from '../Facebook';
import { useAuthRequest } from '../Auth';
import PayPalIcon from '../images/paypal.svg';

function getTransactionStatus(status) {
  switch(status) {
    case 'pending' :
      return <span className="text-xs bg-gray-400 text-white px-2 rounded-full py-1">Pending</span>;
    case 'succeeded':
      return <span className="text-xs bg-green-600 text-white px-2 rounded-full py-1">Paid</span>;
    case 'failed':
      return <span className="text-xs bg-red-600 text-white">Failed</span>;
    default :
      return <span className="text-gray-400 text-gray-900 px-2 rounded-full py-1">Unknown</span>;
  }
}


export default function BrandViewAffiliate() {
    const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

    const navigate = useNavigate();
    
    const {id} = useParams();

    const [user, setUser] = useState(null);

    const [proposals, setProposals] = useState(null);

    const [eventsExpanded, setEventsExpanded] = useState([]);

    const [payoutsPendingExpanded, setPayoutsPendingExpanded] = useState([]);

    const [selectedProposal, setSelectedProposal] = useState(null);

    const [payoutNotes, setPayoutNotes] = useState('');

    const [submitting, setSubmitting] = useState(false);

    const [payoutsExpanded, setPayoutsExpanded] = useState([]);

    const [payPalProposal, setPayPalProposal] = useState(null);

    const [manualProposal, setManualProposal] = useState(null);

    const [payoutAmount, setPayoutAmount] = useState(0);

    /* server side functions */

    const loadAffiliate = async() => {
      const response = await fetch(`/api/v2/affiliates/${id}`, 'GET');

      const {user, proposals} = response;
      if (user && proposals) {
        setUser(user);
        setProposals(proposals);

      }
    }

    const submitPayPal = async() => {

      setSubmitting(true);
      const body = new FormData();

      body.append('proposal_id', payPalProposal.id);
      body.append('amount', payoutAmount);
      body.append('notes', payoutNotes);

      const response = await fetch(`/api/payout/paypal`, 'POST', body);

      const {message, error} = response;

      if (message) {
        toast(message);
        loadAffiliate();
        setPayPalProposal(null);
      }

      if (error) {
        toast(error);
      }

      setSubmitting(false);
    }

    const submitManualPayout = async() => {
      setSubmitting(true);
      const body = new FormData();

      body.append('proposal_id', manualProposal.id);
      body.append('amount', payoutAmount);
      body.append('notes', payoutNotes);

      const response = await fetch(`/api/payout/manual`, 'POST', body);

      const {message, error} = response;

      if (message) {
        toast(message);
        loadAffiliate();
        setManualProposal(null);
      }

      if (error) {
        toast(error);
      }

      setSubmitting(false);
    }

    /* Client side functions */

    const togglePendingPayoutsExpanded = (proposal) => {
      const proposalId = proposal.id;

      if (proposal.affiliate_pending_payouts.length == 0) {
        return false;
      }

      const _payoutsPendingExpanded = [...payoutsPendingExpanded];

      if (_payoutsPendingExpanded.includes(proposalId)) {
        _payoutsPendingExpanded.splice(_payoutsPendingExpanded.indexOf(proposalId), 1);
      } else {
        _payoutsPendingExpanded.push(proposalId);
      }

      setPayoutsPendingExpanded(_payoutsPendingExpanded);
    }

    const toggleEventsExpanded = (proposal) => {

      const proposalId = proposal.id;

      if (proposal.affiliate_conversions.length == 0) {
        return false;
      }

      const _eventsExpanded = [...eventsExpanded];

      if (_eventsExpanded.includes(proposalId)) {
        _eventsExpanded.splice(_eventsExpanded.indexOf(proposalId), 1);
      } else {
        _eventsExpanded.push(proposalId);
      }

      setEventsExpanded(_eventsExpanded);
    }

    const changeAffiliateStatus = async(status=0) => {
      const body = new FormData();

      body.append('enabled', status);

      const response = await fetch(`/api/v2/proposals/${selectedProposal.id}/affiliate`, 'POST', body);

      const {message, error} = response;

      if (message) {
        toast(message);
        loadAffiliate();
        setSelectedProposal(null);
      }

      if (error) {
        toast(error);
      }

    }

    const togglePayoutsExpanded = (proposal) => {

      const proposalId = proposal.id;

      if (proposal.affiliate_successful_payouts.length == 0) {
        return false;
      }

      const _payoutsExpanded = [...payoutsExpanded];

      if (_payoutsExpanded.includes(proposalId)) {
        _payoutsExpanded.splice(_payoutsExpanded.indexOf(proposalId), 1);
      } else {
        _payoutsExpanded.push(proposalId);
      }

      setPayoutsExpanded(_payoutsExpanded);
    }

    useEffect(() => {
      loadAffiliate();
    }, []);

    useEffect(() => {
      if (manualProposal == null && payPalProposal == null) {
        setPayoutAmount(0);
        setPayoutNotes('');
      }
    }, [manualProposal, payPalProposal]);

    if (proposals == null || user == null) {
      return null;
    }

    return <Section title={user.name}>
      <div className="max-w-6xl mx-5">
        <p className="text-gray-600 text-sm mb-5">Affiliate summary for campaigns &amp; gig orders</p>
        
          {
            proposals.data.map(proposal => {

              let pendingAmount = proposal.affiliate_pending_payouts.reduce((prev, current) => (prev+current.amount), 0);

              return <Card title={proposal.campaign.title} unstyled={true} key={`proposal-${proposal.id}`} className="mb-8" primaryAction={{label: 'View Proposal', url: `/proposal/${proposal.id}`}}>
        <div className="divide-y">
            <div className="flex justify-between items-center w-full py-3 px-4 sm:px-6">
              <span className="text-sm text-gray-600">Clicks</span>
              <span className="text-sm text-gray-600">{numeral(proposal.affiliate_clicks_count).format('0,0')}</span>
            </div>

        <div className="pb-4 pt-1" >
                <button className="flex justify-between items-center w-full py-3 px-4 sm:px-6" onClick={() => toggleEventsExpanded(proposal)}>
                  <div className="flex items-center">
                  {
                    proposal.affiliate_conversions.length>0&&(eventsExpanded.includes(proposal.id)?<ChevronDownIcon className="w-5 h-5 block mr-1"/>:<ChevronRightIcon className="w-5 h-5 block mr-1"/>)
                  }
                  <span className="text-indigo-600 text-sm">Earnings</span>
                  </div>
                  <span className="text-gray-600 text-sm font-medium">
                   ${
                      numeral(proposal.affiliate_total_earned).format('0,0.00')
                    }
                  </span>
                </button>
                <div className={`${eventsExpanded.includes(proposal.id)?'block':'hidden'} max-h-96 overflow-auto border-t px-4 sm:px-6`}>
                  {
                    proposal.affiliate_conversions.map(event => <div className="flex justify-between border-l ml-8 py-2" key={`event-${event.id}`}>
                        <div className=" border-gray-300 pl-5">
                          <a className="text-indigo-600 text-sm block" href={`https://${proposal.campaign.user.store.shopify_domain}.myshopify.com/admin/orders/${event.order_id}`}>Order #{event.order_number} </a>
                          <span className="text-xs text-gray-600 block">Subtotal: ${numeral(event.order_subtotal).format('0,0.00')} &middot; {moment(event.created_at).format('MMMM Do YYYY, h:mm a')}</span>
                        </div>
                        <span className="text-gray-600 text-sm font-medium">${numeral(event.earnings).format('0,0.00')}</span>
                      </div>)
                  }
                </div>
                <button className="flex justify-between items-center w-full py-3 border-t px-4 sm:px-6" onClick={() => togglePayoutsExpanded(proposal)}>
                  <div className="flex items-center">
                  {
                    proposal.affiliate_successful_payouts.length>0&&(payoutsExpanded.includes(proposal.id)?<ChevronDownIcon className="w-5 h-5 block mr-1"/>:<ChevronRightIcon className="w-5 h-5 block mr-1"/>)
                  }
                  <span className="text-indigo-600 text-sm">Completed Payouts</span>
                  </div>
                  <span className="text-gray-600 text-sm font-medium">
                   {
                      numeral(proposal.affiliate_total_paid).format('$0,0.00')
                    }
                  </span>
                </button>
                <div className={`${payoutsExpanded.includes(proposal.id)?'block':'hidden'} max-h-96 overflow-auto border-t mb-2 px-4 sm:px-6`}>
                  {
                    proposal.affiliate_successful_payouts.map(payout => <div className="flex justify-between border-l ml-8 py-2" key={`payout-${payout.id}`}>
                        <div className=" border-gray-300 pl-5">
                          <span className="text-gray-600 text-sm block mb-1">{payout.payment_method=='paypal'?`Transaction #${payout.transaction_id}`:'Manual Payment'}</span>
                          <span className="text-xs text-gray-600 block">{getTransactionStatus(payout.transaction_status)} &middot; {moment(payout.created_at).format('MMMM Do YYYY, h:mm a')}</span>
                        </div>
                        <span className="text-gray-600 text-sm font-medium">{numeral(payout.amount).format('$0,0.00')}</span>
                      </div>)
                  }
                </div>
                {
                  proposal.affiliate_pending_payouts.length>0&&<>
                       <button className="flex justify-between items-center w-full py-3 border-t px-4 sm:px-6" onClick={() => togglePendingPayoutsExpanded(proposal)}>
                        <div className="flex items-center">
                        {
                          proposal.affiliate_pending_payouts.length>0&&(payoutsExpanded.includes(proposal.id)?<ChevronDownIcon className="w-5 h-5 block mr-1"/>:<ChevronRightIcon className="w-5 h-5 block mr-1"/>)
                        }
                        <span className="text-indigo-600 text-sm">Pending Payouts</span>
                        </div>
                        <span className="text-gray-600 text-sm font-medium">
                         ${
                            numeral(pendingAmount).format('0,0.00')
                          }
                        </span>
                      </button>
                      <div className={`${payoutsPendingExpanded.includes(proposal.id)?'block':'hidden'} max-h-96 overflow-auto pt-2 border-t mb-2 px-4 sm:px-6`}>
                    {
                      proposal.affiliate_pending_payouts.map(payout => <div className="flex justify-between border-l ml-8 py-2" key={`payout-${payout.id}`}>
                          <div className=" border-gray-300 pl-5">
                            <span className="text-gray-600 text-sm block mb-1">Pending Payment</span>
                            <span className="text-xs text-gray-600 block">{moment(payout.created_at).format('MMMM Do YYYY, h:mm a')}</span>
                          </div>
                          <span className="text-gray-600 text-sm font-medium">{numeral(payout.amount).format('$0,0.00')}</span>
                        </div>)
                    }
                  </div>
                  </>
                }


                <div className="flex justify-between items-center py-3 border-t border-b px-4 sm:px-6">
                  <span className="text-gray-600 text-sm font-medium">Current Balance</span>
                  <span className={"text-sm font-medium "+(proposal.affiliate_owed_amount>0?'text-green-600':'text-gray-600')}>{numeral(proposal.affiliate_owed_amount).format('$0,0.00')}</span>
                </div>
                <div className="py-3 px-4 sm:px-6 border-b">
                  <p className="text-xs text-gray-600">Affiliate Status</p>
                  {
                    proposal.affiliate_enabled==1?<span className="flex justify-start items-center text-gray-600 text-sm"><CheckCircleIcon className="text-green-600 w-5 h-5 mr-1"/> Active</span>:<span className="flex justify-start items-center text-gray-600 text-sm"><XCircleIcon className="text-red-600 w-5 h-5 mr-1"/> Inactive</span>
                  }
                </div>
                <div className="grid grid-cols-2 sm:flex justify-start gap-1 mt-3 px-4 sm:px-6">



                  


                  {
                    proposal.affiliate_enabled==1?<button onClick={() => setSelectedProposal(proposal)} className="hover:bg-red-700 bg-red-600 px-4 sm:px-6 py-2 rounded-md text-white text-sm" type="button">Deactivate Affiliate</button>:
                    <button onClick={() => setSelectedProposal(proposal)} className="hover:bg-green-700  px-4 sm:px-6 py-2 rounded-md bg-green-600 text-white text-sm">Re-activate Affiliate</button>
                  }

                  <MenuButton label={'Make Payment'} options={[
                     { title: 'Pay with PayPal',
                      action: () => setPayPalProposal(proposal)
                    }, {
                      title: 'Manual Payment',
                      action: () => setManualProposal(proposal)
                    }
                    ]} className="" fullWidth={false} primary={true}/>
                </div>
              </div>

          </div>
        </Card>})
          }
      </div>
      {payPalProposal!=null&&user.has_paypal==1&&<Modal open={(payPalProposal!=null)} setOpen={() => setPayPalProposal(null)} primary={{disabled: (payoutAmount<=0||submitting==true), label: 'Make Payment', action: () => submitPayPal()}} secondary={{label: 'Close', action: () => setPayPalProposal(null)}}>
        <h3 className="font-bold text-md text-gray-900">Send a PayPal Payout</h3>

        <p className="text-gray-600 text-sm mb-3">Send live payments from your PayPal account.</p>

        <span className="block mt-3 text-xs font-medium text-gray-600">Balance Owed</span>
        <div className="block">
          <span className="text-gray-600 text-sm">{numeral(payPalProposal.affiliate_owed_amount).format('$0,0.00')}</span>
        </div>

        <span className="block mt-3 text-xs font-medium text-gray-600">Payout Amount</span>
        <div className="flex border rounded-md border-gray-300 border flex items-center">
        <div className="border-r border-gray-300 rounded-l-md px-3 py-2 bg-gray-50 text-sm font-medium text-gray-700">
          $
        </div>
        <input type="number" className="px-3 py-2  border-0 border-transparent flex-1 bg-transparent text-sm text-gray-600" value={payoutAmount} onChange={e => setPayoutAmount(e.target.value)}/>
        </div>

        <span className="block mt-3 text-xs font-medium text-gray-600">Internal Notes (Optional)</span>
        <textarea className="rounded-md text-sm text-gray-600 px-4 py-2 border-gray-300 w-full border" rows="3" placeholder="Notes for your own purposes" value={payoutNotes} onChange={e => setPayoutNotes(e.target.value)}></textarea>
        
      </Modal>}
      {payPalProposal!=null&&user.has_paypal==0&&<Modal open={(payPalProposal!=null)} setOpen={() => setPayPalProposal(null)} primary={{label: 'Go to Affiliates Page', action: () => navigate('/affiliates')}} secondary={{label: 'Close', action: () => setPayPalProposal(null)}}>
        <h3 className="font-bold text-md text-gray-900 mb-3">Connect PayPal Credentials</h3>

        <p className="text-gray-600 text-sm mb-3">To pay affiliates through PayPal, go to the <Link to={'/affiliates'} className="text-indigo-600">Affiliates page</Link>. Click the <span className="font-medium">PayPal Settings</span> button to enter your credentials.</p>
        <p className="text-gray-600 text-sm mb-3">You will need to create PayPal API Credentials, by following <a href="https://developer.paypal.com/docs/multiparty/get-started/" target="_blank" className="text-indigo-600">these instructions</a>.</p>

      </Modal>}
      {manualProposal!=null&&<><Modal open={(manualProposal!=null)} setOpen={() => setManualProposal(null)} primary={{label: 'Make Payment', action: () => submitManualPayout()}} secondary={{label: 'Close', action: () => setManualProposal(null)}}>
        <h3 className="font-bold text-md text-gray-900 mb-3">Add Manual Payment</h3>
        <p className="text-gray-600 text-sm mb-3">Add manual payments to log payments made to affiliates outside of the system.</p>


        <span className="block mt-3 text-xs font-medium text-gray-600">Balance Owed</span>
        <div className="block">
          <span className="text-gray-600 text-sm">{numeral(manualProposal.affiliate_owed_amount).format('$0,0.00')}</span>
        </div>

        <span className="block mt-3 text-xs font-medium text-gray-600">Payout Amount</span>
        <div className="flex border rounded-md border-gray-300 border flex items-center">
        <div className="border-r border-gray-300 rounded-l-md px-3 py-2 bg-gray-50 text-sm font-medium text-gray-700">
          $
        </div>
        <input type="number" className="px-3 py-2  border-0 border-transparent flex-1 bg-transparent text-sm text-gray-600" value={payoutAmount} onChange={e => setPayoutAmount(e.target.value)}/>
        </div>
        <span className="block mt-3 text-xs font-medium text-gray-600">Internal Notes (Optional)</span>
        <textarea className="rounded-md text-sm text-gray-600 px-4 py-2 border-gray-300 w-full border" rows="3" placeholder="Notes for your own purposes" value={payoutNotes} onChange={e => setPayoutNotes(e.target.value)}></textarea>
      </Modal></>}

      {
        selectedProposal!=null&&<Modal open={selectedProposal!=null} setOpen={() => setSelectedProposal(null)} primary={((selectedProposal.affiliate_enabled==1)?{label: 'Deactivate Affiliate', action: () => changeAffiliateStatus(0)}:{label: 'Re-activate Affiliate', action: () => changeAffiliateStatus(1)})} secondary={{label: 'Close', action: () => setSelectedProposal(null)}}>
          {
            selectedProposal.affiliate_enabled==1?<>
              <h4 className="font-bold mb-3">Deactivate affiliates for {user.name}?</h4>
              <p className="text-sm text-gray-600 mb-3"><span className="text-indigo-600">{selectedProposal.campaign.title}</span></p>
              <p className="text-sm text-gray-600 mb-3">By deactivating affiliates for this influencer, you will stop tracking clicks + orders from this influencer moving forward.</p>
              <p className="text-sm text-gray-600">You can always reactivate affiliates again in the future.</p>
            </>:<>
              <h4 className="font-bold mb-3">Turn on affiliates for {user.name}?</h4>
              <p className="text-sm text-gray-600 mb-3"><span className="text-indigo-600">{selectedProposal.campaign.title}</span></p>
              <p className="text-sm text-gray-600 mb-3">By re-activating affiliates for this influencer, you will start tracking clicks + orders from this influencer again.</p>
             
            </>
          }
        </Modal>
      }
      <ToastContainer autoClose={3500} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-900 h-1" className=" fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
    </Section>

}