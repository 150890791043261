import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, InputField, Alert} from '../components/Section';
import useAuthRequest from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

const defaultSteps = [
	{id: 1, name: 'Products', href: '/campaign/{id}/products', status: 'complete'},
	{id: 2, name: 'Social Media',href:  '/campaign/{id}/social',  status: 'current'},
	{id: 3, name: 'Details', href:  '/campaign/{id}/details',  status: 'upcoming'},
	{id: 4, name: 'Preview',  href: '/campaign/{id}/preview',  status: 'upcoming'},
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function SocialMediaSelector({service, enabled, setEnabled}) {

	const services = {
		tiktok: {name: 'TikTok', icon: tiktokIcon},
		facebook: {name: 'Facebook', icon: facebookIcon},
		instagram: {name: 'Instagram', icon: instagramIcon},
		youtube: {name: 'YouTube', icon: youtubeIcon},
		twitter: {name: 'Twitter', icon: twitterIcon},
	};

	const platform = services[service];

	return <div className="flex justify-start items-center p-3">
			<Switch
      checked={enabled}
      onChange={setEnabled}
      className="flex-shrink-0 group relative rounded-full inline-flex items-center justify-center h-5 w-10 cursor-pointer focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <span className="sr-only">Use setting</span>
      <span aria-hidden="true" className="pointer-events-none absolute bg-white w-full h-full rounded-md" />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'bg-indigo-600' : 'bg-gray-300',
          'pointer-events-none absolute h-4 w-9 mx-auto rounded-full transition-colors ease-in-out duration-200'
        )}
      />
      <span
        aria-hidden="true"
        className={classNames(
          enabled ? 'translate-x-5' : 'translate-x-0',
          'pointer-events-none absolute left-0 inline-block h-5 w-5 border border-gray-200 rounded-full bg-white shadow transform ring-0 transition-transform ease-in-out duration-200'
        )}
      />
    </Switch>
    <div className={`ml-3`} style={{opacity: enabled?1:.5}}>
    <img src={platform.icon} className="fill-blue-600 h-14 w-14" style={{fill: '#002200'}}/>
    </div>
	</div>
}

function CampaignSocial({children, cookies}) {

	let navigate = useNavigate();

	const { id } = useParams();

	const [steps, setSteps] = useState(defaultSteps);

	const [error, setError] = useState(null);

	const [campaign, setCampaign] = useState(null);

	const loadCampaign = async() => {
		const response = await fetch(`/api/v2/campaigns/${id}`, {
			method: 'GET',
			headers: {
				Authorization: `Bearer ${cookies.get('token')}`
			}
		}).then(resp => resp.json());

		const {campaign, error, user} = response;

		if (campaign) {

			campaign.services.forEach(service => {
				switch(service.service) {
					case 'facebook':
						setFacebook(true);
						break;
					case 'twitter':
						setTwitter(true);
						break;
					case 'tiktok':
						setTikTok(true);
						break;
					case 'instagram':
						setInstagram(true);
						break;
					case 'youtube':
						setYouTube(true);
						break;
					default:
						break;
				}
			});
			setCampaign(campaign);


		  	let _steps = [...defaultSteps];


			if (user && user.pro == 1) {

				_steps.splice(2, 0, {
					id: 3,
					name: 'Affiliate Program',
					status: 'upcoming',
					href: `/campaign/${id}/affiliates`
				});

		  	}


			if (campaign.status == 'active') {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: true};
				});
				
			} else {
				_steps = _steps.map((step, i) => {


					return {...step, id: (i+1), linked: false};
				});
			}


			setSteps(_steps);

			window.scrollTo(0,0);

		}

	}

	const saveCampaign = async() => {

		const formData = new FormData();
		if (tikTok) {
			formData.append('tiktok', tikTok);
		}

		if (facebook) {
			formData.append('facebook', facebook);
		}

		if (twitter) {
			formData.append('twitter', twitter);
		}

		if (instagram) {
			formData.append('instagram', instagram);
		}

		if (youTube) {
			formData.append('youtube', youTube);
		}
		

		const response = await fetch(`/api/v2/campaigns/${id}/social`, {
			method: 'POST',
			headers: {
	  			Authorization: `Bearer ${cookies.get('token')}`
	  		},
	  		body: formData
		}).then(resp => resp.json());

		const { message, error, redirect_url } = response;

		if (error) {
			setError(error);
		}

		if (redirect_url) {
			navigate(redirect_url);
		}
	}


	const [tikTok, setTikTok] = useState(false);
	const [facebook, setFacebook] = useState(false);
	const [instagram, setInstagram] = useState(false);
	const [twitter, setTwitter] = useState(false);
	const [youTube, setYouTube] = useState(false);

	const disabled = !(tikTok||facebook||instagram||twitter||youTube);

	useEffect(() => {
		loadCampaign();
	}, []);

	const title = campaign!=null?campaign.title:'Create a Campaign';

	return <Section title={title}>
		<Steps steps={steps} className="my-2 max-w-6xl" campaignId={id?id:''}/>
		<Card title="Social Media" className="m-3 max-w-6xl">
			<p className="text-gray-600 text-sm mb-3">Choose the platforms that you are interested in promoting your products on.</p>
			<div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
				<SocialMediaSelector service="tiktok" enabled={tikTok} setEnabled={setTikTok} />
				<SocialMediaSelector service="facebook" enabled={facebook} setEnabled={setFacebook} />
				<SocialMediaSelector service="instagram" enabled={instagram} setEnabled={setInstagram} />
				<SocialMediaSelector service="twitter" enabled={twitter} setEnabled={setTwitter} />
				<SocialMediaSelector service="youtube" enabled={youTube} setEnabled={setYouTube} />
			</div>
		</Card>
		{
			error!=null&&<Alert status="danger" title="Your changes were not saved" className="m-3">
				<p>{error}</p>
			</Alert>
		}
		<div className="mx-3 max-w-6xl">
			<Button fullWidth primary={true} disabled={disabled} onClick={() => saveCampaign()}>Continue</Button>
		</div>
		</Section>
};

export default withCookies(CampaignSocial);