import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Disclosure } from '@headlessui/react'
import {
  Link, Outlet, useLocation, useNavigate
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, BellIcon } from '@heroicons/react/solid'
import { CalendarIcon, ClockIcon, CogIcon, ChartBarIcon, TrendingUpIcon, GlobeAltIcon, MenuAlt1Icon, MailIcon, MenuIcon, XIcon, FolderIcon, ViewListIcon, HomeIcon, InboxIcon, UsersIcon, ShoppingBagIcon } from '@heroicons/react/outline'
import {useAuthRequest} from '../Auth';
import { getIcon } from '../components/Section';
import { ToastContainer, toast } from 'react-toastify';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';




const userNavigation = [
  { name: 'Your Profile', href: '#' },
  { name: 'Settings', href: '#' },
  { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Frame() {
  const navigation = [
  { name: 'Brands', href: '/brand', current: document.location.href.indexOf('/brand')>=0 },
  { name: 'Influencers', href: '/influencer', current: document.location.href.indexOf('/influencer')>=0 },
  { name: 'Gigs', href: '/gigs', current: document.location.href.indexOf('gig')>=0 },
]
	return <>
     
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-white">
          {({ open }) => (
            <>
              <div className="mx-auto max-w-7xl px-4 ">
                <div className="flex h-16 justify-between">
                  <div className="flex">
                    <div className="flex flex-shrink-0 items-center">
                      <Link to={'/'}><img
                        className="block h-8 w-auto lg:hidden"
                        src="https://res.cloudinary.com/wigmarket/c_fill,h_200/beauty-clout/bc-horizontal.png"
                        alt="Your Company"
                      />
                      <img
                        className="hidden h-8 w-auto lg:block"
                        src="https://res.cloudinary.com/wigmarket/c_fill,h_200/beauty-clout/bc-horizontal.png"
                        alt="Your Company"
                      /></Link>
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'border-indigo-500 text-gray-900'
                              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700',
                            'inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                  <div className="hidden sm:ml-6 sm:flex sm:items-center">
                    <button
                      type="button"
                      className="hidden rounded-full bg-white p-1 text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      <span className="sr-only">View notifications</span>
                      <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button>
                    <Link to={'/signin'} className="bg-slate-900 text-white text-sm rounded-md px-4 font-medium block sm:inline-block py-2">Sign in as Influencer</Link>

                    {/* Profile dropdown */}
                    <Menu as="div" className="relative ml-3">
                      <div>
                        <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                          <span className="sr-only">Open user menu</span>
                         
                        </Menu.Button>
                      </div>
                      <Transition
                        as={Fragment}
                        enter="transition ease-out duration-200"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                          {userNavigation.map((item) => (
                            <Menu.Item key={item.name}>
                              {({ active }) => (
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                  )}
                                >
                                  {item.name}
                                </Link>
                              )}
                            </Menu.Item>
                          ))}
                        </Menu.Items>
                      </Transition>
                    </Menu>
                  </div>
                  <div className="-mr-2 flex items-center sm:hidden">
                    {/* Mobile menu button */}
                    <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                      <span className="sr-only">Open main menu</span>
                      {open ? (
                        <XIcon className="block h-6 w-6" aria-hidden="true" />
                      ) : (
                        <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                      )}
                    </Disclosure.Button>
                  </div>
                </div>
              </div>

              <Disclosure.Panel className="sm:hidden">
                <div className="space-y-1 pt-2 pb-3">
                  {navigation.map((item) => (
                    <Disclosure.Button
                      key={item.name}
                      as="a"
                      href={item.href}
                      className={classNames(
                        item.current
                          ? 'bg-indigo-50 border-indigo-500 text-indigo-700'
                          : 'border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800',
                        'block pl-3 pr-4 py-2 border-l-4 text-base font-medium'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      {item.name}
                    </Disclosure.Button>
                  ))}
                </div>
                <div className="border-t border-gray-200 pt-4 pb-3">
                  <Link to={'/signin'} className="border-transparent text-gray-600 hover:bg-gray-50 hover:border-gray-300 hover:text-gray-800 block pl-3 pr-4 py-2 border-l-4 text-base font-medium ">Sign in as Influencer</Link>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
        <div className="bg-gray-50 pb-20 overflow-y-hidden">
        <div className="py-0 ">
          <Outlet/>
        </div>
        </div>
        <footer className="bg-white">
        <div className="container mx-auto text-center py-10">
          <div className="max-w-xs flex justify-between space-x-3 mx-auto mb-5">
            <a href="https://www.facebook.com/beautyclout"><img src={facebookIcon} className="w-5 h-5 block"/></a>
            <a href="https://instagram.com/beautyclout"><img src={instagramIcon} className="w-5 h-5 block"/></a>
            <a href="https://twitter.com/beautyclout"><img src={twitterIcon} className="w-5 h-5 block"/></a>
            <a href="https://www.youtube.com/watch?v=-tyKCOwqH5g"><img src={youtubeIcon} className="w-5 h-5 block"/></a>
            <a href="mailto:info@beautyclout.com"><MailIcon className="w-5 h-5 block text-slate-600"/></a>
          </div>
          <Link to={'https://beautyclout.com/'} className="text-xs text-gray-500">&copy; {new Date().getFullYear()} Beauty Clout. All rights reserved.</Link>
        </div>
      </footer>
      </div>
      
    </>;
}