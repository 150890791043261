import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useSearchParams
} from "react-router-dom";
import { withCookies, Cookies, useCookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  StarIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, HeartIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, TextArea, Select, FileDrop, Gig, GigsLoadingPage, Pager} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statuses = [
  {name: 'Invited', status: 'pending'},
  {name: 'Accepted', status: 'accepted'},
  {name: 'Declined', status: 'rejected'}
];

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
  };

const socialMediaServicesArray = [
    {name: 'TikTok', icon: tiktokIcon, code: 'tiktok'},
    {name: 'Facebook', icon: facebookIcon, code: 'facebook'},
    {name: 'Instagram', icon: instagramIcon, code: 'instagram'},
    {name: 'YouTube', icon: youtubeIcon, code: 'youtube'},
    {name: 'Twitter', icon: twitterIcon, code: 'twitter'},
  ];

const estimates = [
    {id: -1, name: '-- Select'},
    {id: 0, name: '< 1 week'},
    {id: 1, name: '1 week'},
    {id: 2, name: '2 weeks'},
    {id: 3, name: '3 weeks'},
  ];

const sortOptions = [
	{name: 'Most Relevant', value: 'relevant'},
	{name: 'Most Popular', value: 'popular'},
	{name: 'Highest Rating', value: 'rating'},
	{name: 'Lowest Price', value: 'lowest_price'},
	{name: 'Highest Price', value: 'highest_price'},
	{name: 'Recently Added', value: 'recent'}
];

const audienceSizes = [
	{name: 'All Audience Sizes', value: 'all'},
	{name: 'Less than 10k followers', value: '10k'},
	{name: '10k - 100k followers', value: '10-100k'},
	{name: '100k - 500k followers', value: '100-500k'},
	{name: '500k - 1m followers', value: '500-1m'},
	{name: '> 1m followers', value: '1m'},
];


export default function GigSearchResults() {

	const [searchParams, setSearchParams] = useSearchParams();

	const navigate = useNavigate();

	const q = searchParams.get('q');

	const page = searchParams.get('page')?searchParams.get('page'):'1';

	const {authenticatedFetch} = useAuthRequest();

  //	const fetch = authenticatedFetch;

	const [gigs, setGigs] = useState(null);

	const [queryString, setQueryString] = useState(q);

	const [services, setServices] = useState([]);

	const [selectedSort, setSelectedSort] = useState(sortOptions[0]);

	const [audienceSize, setAudienceSize] = useState(audienceSizes[0]);

	const loadSearch = async() => {

		setGigs(null);

		let servicesParams = '';

		socialMediaServicesArray.forEach(service => {
			if (searchParams.has(service.code)) {
				servicesParams += `&services[]=${service.code}`
			}
		});

		const sort = searchParams.has('sort')?searchParams.get('sort'):'relevant';

		const audience = searchParams.has('audience')?searchParams.get('audience'):'all';

		const response = await fetch(`/api/v2/gigSearch?q=${q}&page=${page}${servicesParams}&sort=${sort}&audience=${audience}`,{method: 'GET'}).then(resp => resp.json());

		const { gigs } = response;

		setGigs(gigs);
	}

	const doNewSearch = (e) => {
		e.preventDefault();
		let url = `/gigs-search?q=${queryString}&page=1`;

		services.forEach(service => {
			url += `&${service}=1`;
		});

		url += `&sort=${selectedSort.value}`;

		url += `&audience=${audienceSize.value}`;

		navigate(url);

		return false;

	}

	const changePage = (page) => {

		let url = `/gigs-search?q=${q}&page=${page}`;

		socialMediaServicesArray.forEach(service => {
			if (searchParams.has(service.code)) {
				url += `&${service.code}=1`;
			}
		});

		const sort = searchParams.has('sort')?searchParams.get('sort'):'relevant';

		const audience = searchParams.has('audience')?searchParams.get('audience'):'all';

		url += `&sort=${sort}&audience=${audience}`;

		navigate(url);
	}

	const toggleService = (service, checked) => {
		let _services = [...services];
		if (checked) {
			_services.push(service);
		} else {
			_services.splice(_services.indexOf(service), 1);
		}

		setServices(_services);
	}

	useEffect(() => {
		loadSearch();
		window.scrollTo(0,0);
	}, [searchParams]);

	return <>

		<div className="mx-auto mt-2 max-w-7xl min-h-screen">
		{
			gigs!=null?<span className="block text-gray-500 text-sm mb-5">{gigs.total} results</span>:<span className="animate-pulse bg-gray-200 inline-block text-sm w-24 mb-5">&nbsp;</span>
		}
		<div className="">
		<div className="2xl:col-span-2">
			<form onSubmit={doNewSearch} method="GET">
			<div className="flex">
				<input type="text" className="border-l border-t rounded-l-md border-b py-2 px-3 border-r-0 border-gray-200 text-sm flex-1" placeholder="Search by influencer's name or keywords..." value={queryString} onChange={e => setQueryString(e.target.value)}/>
				<button type="submit" className="rounded-r-md bg-indigo-600 text-sm text-white py-2 px-5">Search Gigs</button>
			</div>
			</form>
		</div>
		<div className="md:flex md:justify-between my-5">
			<div className="flex">
			{
				socialMediaServicesArray.map(service => <div key={`service-${service.code}`} className="flex items-center mr-3">
					<input id={`service-${service.code}`} type="checkbox" checked={services.includes(service.code)} onChange={(e) => toggleService(service.code, e.target.checked)} className="inline-block border-gray-200 rounded-sm mr-2 text-indigo-600"/>
					<label className="text-gray-700 text-xs font-medium inline-block" for={`service-${service.code}`}>{service.name}</label>
				</div>)
			}
			</div>
			<div className="mt-5 md:mt-0 md:flex md:space-x-3 space-y-3 md:space-y-0">
				<Select options={audienceSizes} selected={audienceSize} setSelected={setAudienceSize}/>
				<Select options={sortOptions} selected={selectedSort} setSelected={setSelectedSort}/>
			</div>
		</div>
		</div>
		{
			gigs==null&&<GigsLoadingPage/>
		}
		{gigs!=null&&gigs.total>0&&<div className="grid md:grid-cols-3 2xl:grid-cols-4 gap-4">
		{
			gigs!=null&&gigs.data.map(gig => <Gig gig={gig} key={`gig-${gig.id}`}/>)
		}
		</div>}
		{
			gigs!=null&&gigs.total==0&&<div className="py-20 my-20 px-4 sm:px-6 flex items-center justify-center">
				<div className="text-center">
				<h3 className="font-medium mb-3">Sorry, we found no gigs matching your search</h3>
				<p className="text-sm text-gray-600">Try broadening your search for better results.</p>
				</div>
			</div>
		}
		{gigs!=null&&<div className="my-8">

			

			<Pager data={gigs} changePage={changePage}/>
		</div>}
		</div>

	</>
}