import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useSearchParams
} from "react-router-dom";

export default function Redirect({campaign=false, brand=false, proposal=false}) {

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const {id} = useParams();

  const performRedirect = () => {

    var url = false;

    if (brand == false) {

      if (campaign == true) {

        if (searchParams.has('campaign_id')) {
          url = '/influencers/campaign/'+searchParams.get('campaign_id');
        } else if (id) {
          url = '/influencers/campaign/'+id;
        }
      }


    } else {
      if (proposal == true) {
        if (id) {
          url = '/proposal/'+id;
        }
      } else if (campaign == true) {
        if (!id) {
          url = '/campaigns/active';
        }
      }


    }

    if (url) {
      navigate(url);
    } else {
      navigate('/dashboard');
    }


  }

  useEffect(() => {
    performRedirect();
  }, []);

  return <div>

  </div>

}