import React, { useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChatIcon, MailIcon, BadgeCheckIcon, UserIcon, ArrowRightIcon, PaperAirplaneIcon, CashIcon, DocumentTextIcon, CheckCircleIcon, ClockIcon, CurrencyDollarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, ArrowCircleRightIcon } from '@heroicons/react/outline'

import Section, {InputField, TextArea, USStates, DashboardCard, FileDrop, TagInputField, Modal, SocialMediaSetting, Steps, Card, Pager, Select, StarRatingSelector} from '../components/Section';
import SidePanel from '../components/SidePanel';
import Button from '../components/Button';
import { ToastContainer, toast } from 'react-toastify';
import { useAuthRequest } from '../Auth';
import { initFacebookSdk } from '../Facebook';
import numeral from 'numeral';
import moment from 'moment';
import updateKey from '../components/UpdateUserSetting';
import 'react-toastify/dist/ReactToastify.css';

export default function InfluencerStripe() {

	const {authenticatedFetch} = useAuthRequest();

  const fetch = authenticatedFetch;

	const [user, setUser] = useState(null);

	const loadUser = async() => {
		const response = await fetch(`/api/user`, 'GET');

		const {user} = response;

		if (user) {
			setUser(user);
		}
	}

	const createStripeAccount = async() => {
		const response = await fetch('/api/stripe/express');

	    const { url } = response;

	    if (url) {
	      window.open(url);
	    }
	}

	const goToStripe = async() => {
		const response = await fetch(`/api/stripe/dashboard`, 'GET');

		const {error, url} = response;

		if (error) {
			toast(error);
		}

		if (url) {
			window.top.location.href = url;
		}
	}

	const goToPayPal = async() => {
		const response = await fetch(`/api/paypal`, 'GET');

		const {error, redirect_url} = response;

		if (error) {
			toast(error);
		}

		if (redirect_url) {
			window.top.location.href = redirect_url;
		}
	}

	useEffect(() => {
		loadUser();
	}, []);

	return <Section title="Payment">
		<div className="max-w-5xl mx-5">
			<p className="text-sm text-gray-600 mt-2 mb-5">We use <a href="https://www.stripe.com/" target="_blank" className="text-indigo-600 hover:text-indigo-700">Stripe</a> to manage money transfers safely and securely.</p>
			{
				user==null&&<><div className="rounded-md bg-gray-200 animate-pulse h-48">
					&nbsp;
				</div>
				<div className="rounded-md bg-gray-200 animate-pulse h-48">
					&nbsp;
				</div>
				</>
			}

			{
				user!=null&&user.stripe_account_id==null&&<div className="bg-orange-50 mb-8 px-4 sm:px-6 py-4 rounded-md md:flex items-center">
					<ExclamationCircleIcon className="text-orange-600 md:w-5 md:h-5 h-10 w-10 block md:mr-2 mx-auto"/>
					<p className="text-orange-600 text-sm text-center md:text-left">A Stripe Express account is required to get paid for work on Beauty Clout. Get started below.</p>
				</div>
			}

			{user!=null&&<Card title="Gigs & Campaigns" className="mb-8">
				<p className="text-sm text-gray-600 mb-3">Stripe is a trusted solution used by many businesses to manage payments and money transfers.</p>

				<p className="text-sm text-gray-600 mb-5">When you complete an assignment, your payment is sent to your Stripe Express account, and then transferred to your bank account or debit card. A Stripe express account is required to create <Link to="/influencers/gigs" className="text-indigo-600 hover:text-indigo-700">gigs</Link> and to receive invitations from brands.</p>
				
				{user.stripe_account_id!=null&&<div className="mb-5">
                <div className="text-sm text-gray-600 font-medium flex items-center"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/> <div>Stripe Connected</div></div>
                                
              </div>}
              {user.stripe_account_id==null&&<div className="mb-5">
                <div className="text-sm text-gray-600 font-medium flex items-center"><XCircleIcon className="text-red-600 w-5 h-5 block mr-1"/> <div>Stripe Not Connected</div></div></div>}

				{
					user.stripe_account_id!=null?<Button primary onClick={() => goToStripe()} className="w-full sm:w-auto flex items-center justify-center"><ArrowRightIcon className="text-white w-4 h-4 block mr-2"/>Manage Your Stripe Account</Button>:
					<Button primary onClick={() => createStripeAccount()} className="w-full sm:w-auto flex items-center justify-center"><ArrowRightIcon className="text-white w-4 h-4 block mr-2"/>Create a Stripe Express Account</Button>
				}
			</Card>}

			{user!=null&&<Card title="Affiliate Payments">
				<p className="text-sm text-gray-600 mb-3">Brands will often offer commission to influencers for sales made through affiliate links.</p>

				<p className="text-sm text-gray-600 mb-5">Please note that this type of payment is separate from Stripe payouts for work completed. Affiliate payments are done through PayPal. In order to receive PayPal payments for affiliate commission, you can sign into your PayPal account below</p>
				
				{user.paypal_connected_at!=null&&<div className="mb-5">
                <div className="text-sm text-gray-600 font-medium flex items-center"><CheckCircleIcon className="text-green-600 w-5 h-5 block mr-1"/> <div>Connected Account: <span className="font-normal">{user.paypal_email}</span></div></div>
                                
              </div>}
              {user.paypal_connected_at==null&&<div className="mb-5">
                <div className="text-sm text-gray-600 font-medium flex items-center"><XCircleIcon className="text-gray-600 w-5 h-5 block mr-1"/> <div>PayPal Not Connected</div></div></div>}

				<Button primary onClick={() => goToPayPal()} className="w-full sm:w-auto flex items-center justify-center"><ArrowRightIcon className="text-white w-4 h-4 block mr-2"/>Sign in to PayPal</Button>
			</Card>}
		</div>
	</Section>
}