import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition, Switch } from '@headlessui/react'
import {
  Link, useParams, useNavigate
} from "react-router-dom";
import { withCookies, Cookies } from 'react-cookie';
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, ArrowNarrowLeftIcon,
  CheckIcon,
  CheckCircleIcon,
  HomeIcon,
  PaperClipIcon,
  QuestionMarkCircleIcon,
  ChevronLeftIcon,
  StarIcon,
  ThumbUpIcon,
  UserIcon
   } from '@heroicons/react/solid';

import { ToastContainer, toast } from 'react-toastify';
import { CalendarIcon, TrashIcon, ChartBarIcon, FolderIcon, HeartIcon, BellIcon, MenuIcon, InboxIcon, UsersIcon } from '@heroicons/react/outline'
import Button from '../components/Button';
import Section, { Steps, Card, SearchComboBox, getHandle, getOrderStatus, InputField, Alert, PageTabs, TextArea, Select, FileDrop, Gig} from '../components/Section';
import moment from 'moment';
import numeral from 'numeral';
import DatePicker from "react-datepicker";
import SocialMediaSelector from '../components/SocialMediaSelector';
import { useAuthRequest } from '../Auth';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const statuses = [
  {name: 'Invited', status: 'pending'},
  {name: 'Accepted', status: 'accepted'},
  {name: 'Declined', status: 'rejected'}
];

const socialMediaServices = {
    tiktok: {name: 'TikTok', icon: tiktokIcon},
    facebook: {name: 'Facebook', icon: facebookIcon},
    instagram: {name: 'Instagram', icon: instagramIcon},
    youtube: {name: 'YouTube', icon: youtubeIcon},
    twitter: {name: 'Twitter', icon: twitterIcon},
  };

const estimates = [
    {id: -1, name: '-- Select'},
    {id: 0, name: '< 1 week'},
    {id: 1, name: '1 week'},
    {id: 2, name: '2 weeks'},
    {id: 3, name: '3 weeks'},
  ];

var searchTimeoutId = null;




export default function BrowseGigs() {

  const {authenticatedFetch} = useAuthRequest();

  //const fetch = authenticatedFetch;

  const navigate = useNavigate();

  const [sections, setSections] = useState([]);

  const [services, setServices] = useState([]);

  const [searchQuery, setSearchQuery] = useState('');


  const loadGigs = async() => {
    const response = await fetch(`/api/v2/gigs`).then(resp => resp.json());

    const { sections, services, error } = response;

    setSections(sections);

    setServices(services);
  }

  const submitSearch = (e) => {
    e.preventDefault();

    let url = `/gigs-search?q=${escape(searchQuery)}`

    navigate(url);
    return false;
  }


  useEffect(() => {
    loadGigs();
  }, []);

  return <div className="bg-gray-50">
    <div className="bg-cover 2xl:bg-contain h-[calc(100vh-200px)] bg-center lg:bg-right-top bg-no-repeat bg-[#f3d774]" style={{backgroundImage: "url('https://images.unsplash.com/photo-1551727974-8af20a3322f1')"}}>
      <div className="md:mx-5 px-5 lg:px-0 h-full bg-white/80 md:bg-transparent flex items-center max-w-7xl lg:mx-auto">
        <div className="w-full px-5 lg:w-1/2">
          <h1 className="text-4xl font-bold text-black">Find Influencers to Hire <span className="italic">Instantly</span></h1>
          <p className="text-black opacity-60 mt-3 mb-4">Gigs are promotional services listed by influencers that you can purchase on the spot!</p>
          <form onSubmit={submitSearch} className="mt-3">
          <div className="sm:flex items-center w-full">
            
              <input type="text" className="text-sm sm:flex-1 w-full sm:w-auto sm:rounded-l-md border-t border-l border-b px-4 py-3 bg-white border-gray-50" placeholder="Search influencers or keywords..." onChange={e => setSearchQuery(e.target.value)} value={searchQuery}/>
              <button className="bg-slate-900 text-white font-medium py-3 px-8 w-full text-center sm:w-auto text-sm sm:rounded-r-md">Search<span className="inline sm:hidden">&nbsp;Gigs</span></button>
            
          </div>
          </form>
        </div>
      </div>
    </div>
    <div className="mx-auto mt-10">
    <section className="mx-5">
      <h3 className="font-bold text-2xl text-gray-800 my-3">Browse by platform</h3>
      <div className="sm:space-x-3 pt-3 space-y-4">
      {
        services.map(service => <Link to={`/gigs/platform/${service.code}/all`} className="inline-block rounded-full py-3 px-4 border text-xs sm:text-sm mr-2 sm:mr-0" key={`service-${service.code}`}>{service.name}</Link>)
      }
      </div>
    </section>
    <section className=" mx-5 ">
      {
        sections.map((section, i) => {

          if (section.gigs.length == 0) {
            return null;
          }

          return <div className="my-8" key={`section-${i}`}>
            <h3 className="font-bold text-2xl text-gray-800 my-3">{section.title}</h3>
            <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 3xl:grid-cols-6 gap-4">
              {
                section.gigs.map(gig => <Gig key={`gig-${gig.id}`} gig={gig}/>)
              }
            </div>
          </div>
        })
      }
    </section>
  </div>
  </div>
}