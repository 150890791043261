import React, { Fragment, useState, useEffect } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, Outlet, useLocation, useNavigate
} from "react-router-dom";
import { ChevronRightIcon, DotsVerticalIcon, SearchIcon, SelectorIcon, BellIcon } from '@heroicons/react/solid'
import { CalendarIcon, ClockIcon, CogIcon, ChartBarIcon, TrendingUpIcon, GlobeAltIcon, MenuAlt1Icon, MailIcon, MenuIcon, XIcon, FolderIcon, ViewListIcon, HomeIcon, InboxIcon, UsersIcon, ShoppingBagIcon } from '@heroicons/react/outline'
import {useAuthRequest, authed} from '../Auth';
import { getIcon } from '../components/Section';
import Dashboard from './Dashboard';
import Frame from './Frame';

export default function CheckAuth({children}) {
	const context = useAuthRequest();

	const authed = context.authed;

	const {pathname} = useLocation();

	const [authenticated, setAuthenticated] = useState(null);

	const check = async() => {
		const _authenticated = await authed();

		setAuthenticated(_authenticated);

	
	}

	React.useEffect(() => {
		check();
	}, [pathname]);

	if (authenticated == null) {
		return null;
	}

	return authenticated?<Dashboard><Outlet/></Dashboard>:<Frame><Outlet/></Frame>;


}