import React, { Fragment, useState, useEffect, useRef } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  Link, useParams, useNavigate, useLocation
} from "react-router-dom";
import moment from 'moment';
import {useAuthRequest} from '../Auth';
import { ToastContainer, toast } from 'react-toastify';
import Slider from "react-slick";
import { ChevronRightIcon, ChevronLeftIcon, DotsVerticalIcon, SearchIcon, SelectorIcon } from '@heroicons/react/solid'
import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, TrashIcon } from '@heroicons/react/outline'
import Section, {Tabs, Pager, Card, Modal} from '../components/Section';
import tiktokIcon from '../images/tiktok.svg';
import facebookIcon from '../images/facebook.svg';
import twitterIcon from '../images/twitter.svg';
import instagramIcon from '../images/instagram.svg';
import youtubeIcon from '../images/youtube.svg';

const defaultTabs = [
  { name: 'All', url: '/orders/all', key: 'all', current: false },
  { name: 'Active', url: '/orders/active', key: 'active', current: false },
  { name: 'Pending', url: '/orders/pending', key: 'pending', current: false },
  { name: 'Completed', url: '/orders/complete', key: 'completed', current: false },
  { name: 'Expired', url: '/orders/expired', key: 'expired', current: false },
];

const socialMediaServices = {
	tiktok: {name: 'TikTok', icon: tiktokIcon},
	facebook: {name: 'Facebook', icon: facebookIcon},
	instagram: {name: 'Instagram', icon: instagramIcon},
	instagram_story: {name: 'Instagram Story', icon: instagramIcon},
	youtube: {name: 'YouTube', icon: youtubeIcon},
	twitter: {name: 'Twitter', icon: twitterIcon},
	blog: {name: 'Blog', icon: twitterIcon}
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}


export default function InfluencerGigs() {

	const {authenticatedFetch} = useAuthRequest();

  	const fetch = authenticatedFetch;

	const [gigs, setGigs] = useState(null);

	const [selectedGig, setSelectedGig] = useState(null);

	const [slideIndex, setSlideIndex] = useState(0);

	const [user, setUser] = useState(null);

	const [showExplainer, setShowExplainer] = useState(false);

	const sliderRef = useRef();

	const deleteSelectedGig = async() => {
		const response = await fetch(`/api/v2/gigs/${selectedGig.id}/delete`, 'POST');

		const {error, message} = response;

		if (message) {
			loadMyGigs();
			toast(message);
			setSelectedGig(null);
		}

		if (error) {
			toast(error);
		}
	}

	const loadMyGigs = async(page=1) => {
		const response = await fetch(`/api/v2/my-gigs?page=${page}`, 'GET');

		const {error, gigs, user} = response;

		if (gigs) {
			setGigs(gigs);
			setUser(user);
		}
	}

	const nextSlide = () => {

		if (slideIndex >= 2) {
			setShowExplainer(false);

			let body = new FormData();

			body.append('value', '1');

			fetch(`/api/v2/userSettings/gigs`, 'POST', body);
			setSlideIndex(0);
			sliderRef.current.slickGoTo(0);

		} else {
			sliderRef.current.slickNext();
		}

	}

	React.useEffect(() => {
		
		if (user != null) {
			setShowExplainer(user.show_gig_explainer);
		}
	}, [user]);

	React.useEffect(() => {
		loadMyGigs();
	}, []);

	//console.log('slideIndex', slideIndex);

	return <><Section title="Gigs">
		<div className="max-w-6xl mx-5">
			<p className="text-sm text-gray-600 mt-2 mb-8">Gigs are influencer services that brands can purchase directly. <button onClick={() => setShowExplainer(true)} className="text-indigo-600 hover:text-indigo-700">Learn more.</button></p>
			{
				gigs!=null&&<Card title="Your Gigs" className="mt-5" unstyled={true} primaryAction={{label: 'Create Gig', url: '/influencers/gig'}}>
					{
						gigs.total>0&&<><div className="w-full">
							<div className="divide-y w-full">
							{
								gigs.data.map(gig => <div key={`gig-${gig.id}`} className=" md:grid md:grid-cols-2 w-full">
										<div className="py-4 px-4 sm:px-6">
											<div className='flex items-center space-x-2'>
											{gig.image?<img src={`https://res.cloudinary.com/wigmarket/w_200,h_200,c_fill/${gig.image.image_id}.jpg`} className="rounded-md shadow w-16 h-16 block"/>:<div className="w-16 h-16 rounded-md shadow bg-slate-50 flex justify-center items-center"><span className="text-xs uppercase text-slate-600 font-medium">My Gig</span></div>}
												<div>
													<Link to={`/influencers/gig/${gig.id}`} className="text-sm text-indigo-600">{gig.title}</Link>
													<div className="space-x-1">
														<img src={socialMediaServices[gig.primary_service].icon} className="w-4 h-4 inline-block"/>
														{
															gig.services.map(service => <img src={socialMediaServices[service.service].icon} className="w-4 h-4 inline-block" key={`service-${gig.id}-${service.service}`}/>)
														}
													</div>
												</div>
											</div>
										</div>
										<div className="text-right pb-4 md:py-4 px-4 sm:px-6">
											<div className="flex items-center justify-end h-full">
											<Link to={`/influencers/orders/pending?gig_id=${gig.id}`} className="hover:shadow py-2 px-3 bg-indigo-600 text-white text-sm rounded-md flex-1 text-center">View Orders</Link>
											<Link to={`/influencers/gig/${gig.id}`} className="hover:shadow ml-1 py-2 px-3 border text-sm text-gray-600 font-medium rounded-md">Edit Gig</Link>
											<button onClick={() => setSelectedGig(gig)} className="hover:shadow border ml-1 py-2 px-3 text-sm text-gray-600 rounded-md"><TrashIcon className="w-5 h-5 block text-gray-600"/></button>
											</div>
										</div>
									</div>)
							}
							</div>
						</div>
						<div className="py-4 px-4 sm:px-6 border-t">
							<Pager data={gigs} changePage={loadMyGigs}/>
						</div>
						</>
					}
					{
						gigs.total==0&&<div className="px-4 sm:px-6 py-20 flex items-center justify-center">
							<div className="text-center">
								<UsersIcon className="w-12 h-12 block mx-auto text-gray-600 mb-3"/>
								<h3 className="text-gray-600 text-md font-medium">Get Started with Gigs</h3>
								<p className="text-sm text-gray-600 my-3">Gigs let you showcase your services to brands looking to hire influencers quickly.</p>
								<Link to={`/influencers/gig`} className="inline-block mt-3 px-4 py-3 sm:px-6 bg-indigo-600 text-white text-sm rounded-md">Create Your First Gig</Link>
							</div>
						</div>
					}
				</Card>
			}
		</div>
	</Section>
	<Modal open={selectedGig!=null} setOpen={() => setSelectedGig(null)} primary={{label: 'Delete Gig', action: () => deleteSelectedGig()}} secondary={{label: 'Back', action: () => setSelectedGig(null)}}>
		{selectedGig!=null&&<>
			<h3 className="font-bold text-md mb-3">Delete this gig?</h3>
			<p className="text-sm text-indigo-600 mb-3">{selectedGig.title}</p>
			<p className="text-sm text-gray-600">This action cannot be reversed.</p>
			</>
		}
	</Modal>
	<ToastContainer autoClose={2000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5 text-white" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2"/>

	<Modal open={showExplainer} setOpen={() => {return false;}} primary={{label: slideIndex>=2?'Got it':'Next', action: () => nextSlide()}}>
		<h3 className="font-bold text-md mb-3 text-gray-900">Welcome to Gigs!</h3>
		<Slider dots={true} ref={sliderRef} beforeChange={(current, next) => setSlideIndex(next)} infinite={false}>
			<div>
				<img src="https://res.cloudinary.com/wigmarket/image/upload/v1664986896/beauty-clout/screenshot1.png" className="w-full border block mb-3"/> 
				<p className="text-center text-sm text-gray-600 mb-3">With Gigs, influencers can offer their services for easy hiring, instead of waiting for invitations from brands.</p>
			</div>
			<div>
				<img src="https://res.cloudinary.com/wigmarket/image/upload/v1664986892/beauty-clout/screenshot2.png" className="w-full block border mb-3"/>
				<p className="text-center text-sm text-gray-600 mb-3">Describe what you are offering, add services, and list your fees.</p>
			</div>
			<div>
				<img src="https://res.cloudinary.com/wigmarket/image/upload/v1664987801/beauty-clout/screenshot3.png" className="w-full block border mb-3"/>
				<p className="text-center text-sm text-gray-600 mb-3">Orders for your gigs can be accessed in the Gig Orders section on the sidebar.</p>
			</div>

		</Slider>
		<div className="">&nbsp;</div>

		
	</Modal>
	<ToastContainer autoClose={2000} position="bottom-center" toastClassName="bg-gray-900 text-gray-50 " bodyClassName="text-sm pt-5 px-5" progressClassName="bg-gray-700 h-1" className="fixed z-50 bottom-4 left-1/2 transform -translate-x-1/2
"/>
	</>
}